import { combineReducers } from '@reduxjs/toolkit';

import { reducer as collectionEditReducer } from './CollectionEdit/CollectionEdit.reducer';
import { reducer as collectionSearchReducer } from './CollectionSearch/CollectionSearch.reducer';
import { reducer as contentListReducer } from './ContentList/ContentList.reducer';
import { reducer as createCollectionReducer } from './CreateCollection/CreateCollection.reducer';
import { reducer as suggestionListReducer } from './SuggestionList/SuggestionList.reducer';

export const collectionToolReducer = combineReducers({
  create: createCollectionReducer,
  edit: collectionEditReducer,
  search: collectionSearchReducer,
  contentList: contentListReducer,
  suggestionList: suggestionListReducer,
});
