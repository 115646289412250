import { WaveformData } from '../../components/audioPreview/audioProgressBar';
import { StockItemType } from '../batch/batch.types';

export enum AudioActionType {
  changeTrack,
  updateWaveformData,
  updateElapsedTime,
  changePlaybackState,
  togglePlaybackState,
  seekToTime,
  stockItemErrored,
  stockItemFullyViewed,
  changeReviewBatch,
}

export enum PlaybackState {
  paused,
  playing,
}

export interface AudioPlayerAction<T = AudioActionType> {
  readonly actionType: T;
}

export interface AudioPlayerActionForStockItem<T> extends AudioPlayerAction<T> {
  readonly stockItemId: string;
}

export interface ChangeTrackAction extends AudioPlayerActionForStockItem<AudioActionType.changeTrack> {
  readonly audioSrc: string | undefined;
}

export const changeTrack = (stockItem: StockItemType): ChangeTrackAction => {
  return {
    actionType: AudioActionType.changeTrack,
    stockItemId: stockItem._id,
    audioSrc: stockItem.content.download.mp3?.full.uri ?? undefined,
  };
};

export interface UpdateWaveformDataAction extends AudioPlayerActionForStockItem<AudioActionType.updateWaveformData> {
  readonly waveformData: WaveformData | undefined;
}

export const updateWaveformData = (
  stockItemId: string,
  waveformData: WaveformData | undefined
): UpdateWaveformDataAction => {
  return {
    actionType: AudioActionType.updateWaveformData,
    stockItemId,
    waveformData,
  };
};

export interface ChangePlaybackStateAction extends AudioPlayerAction {
  readonly actionType: AudioActionType.changePlaybackState;
  readonly playbackState: PlaybackState;
}

export const changePlaybackState = (playbackState: PlaybackState): ChangePlaybackStateAction => {
  return {
    actionType: AudioActionType.changePlaybackState,
    playbackState,
  };
};

export interface TogglePlaybackStateAction extends AudioPlayerAction {
  readonly actionType: AudioActionType.togglePlaybackState;
}

export const togglePlaybackState = (): TogglePlaybackStateAction => {
  return {
    actionType: AudioActionType.togglePlaybackState,
  };
};

export interface UpdateElapsedTimeAction extends AudioPlayerActionForStockItem<AudioActionType.updateElapsedTime> {
  readonly elapsedTimeMs: number;
}

export const updateElapsedTime = (stockItemId: string, elapsedTimeMs: number): UpdateElapsedTimeAction => {
  return {
    actionType: AudioActionType.updateElapsedTime,
    stockItemId,
    elapsedTimeMs,
  };
};

export interface SeekToTimeAction extends AudioPlayerAction {
  readonly actionType: AudioActionType.seekToTime;
  readonly seekTimeMs: number;
}

export const seekToTime = (seekTimeMs: number): SeekToTimeAction => {
  return {
    actionType: AudioActionType.seekToTime,
    seekTimeMs,
  };
};

export type StockItemErroredAction = AudioPlayerActionForStockItem<AudioActionType.stockItemErrored>;
export const stockItemErrored = (stockItemId: string): StockItemErroredAction => {
  return {
    actionType: AudioActionType.stockItemErrored,
    stockItemId,
  };
};

export type StockItemFullyViewedAction = AudioPlayerActionForStockItem<AudioActionType.stockItemFullyViewed>;
export const stockItemFullyViewed = (stockItemId: string): StockItemFullyViewedAction => {
  return {
    actionType: AudioActionType.stockItemFullyViewed,
    stockItemId,
  };
};

export interface ChangeReviewBatchAction extends AudioPlayerAction<AudioActionType.changeReviewBatch> {
  readonly stockItemIds: string[];
  readonly reviewBatchId: string;
}
export const changeReviewBatch = (stockItemIds: string[], reviewBatchId: string): ChangeReviewBatchAction => {
  return {
    actionType: AudioActionType.changeReviewBatch,
    stockItemIds,
    reviewBatchId,
  };
};
