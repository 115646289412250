import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { logger } from '../util/logger';

const env = process.env;
const baseURL = env.REACT_APP_WASABI_BASE_URI || '';

const requestInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  config.withCredentials = true;
  return config;
};

const errorInterceptor = (error: AxiosError): Promise<AxiosError> => {
  logger.error('Error in HttpClient', error);
  return Promise.reject(error);
};

const wasabiAxios = axios.create({
  baseURL,
});

wasabiAxios.interceptors.response.use(undefined, errorInterceptor);
wasabiAxios.interceptors.request.use(requestInterceptor, errorInterceptor);

export { wasabiAxios };
