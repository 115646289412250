import { Button, Modal } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import { floor } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import PaginationControls from '../../common/components/pagination/PaginationControls';
import PaginationSummary from '../../common/components/pagination/PaginationSummary';
import { useDispatch } from '../../common/store/store';
import { changePageNumber, searchCollections } from '../store/CollectionSearch/CollectionSearch.reducer';
import {
  selectPagination,
  selectSearchOptions,
  selectSearchResults,
} from '../store/CollectionSearch/CollectionSearch.selectors';
import { resetCreateCollection } from '../store/CreateCollection/CreateCollection.reducer';
import CreateCollection from './createCollection/CreateCollection';
import CollectionSearchControls from './search/containers/CollectionSearchControls';
import CollectionSearchResults from './search/containers/CollectionSearchResults';

const CollectionSearch = (): JSX.Element => {
  const dispatch = useDispatch();
  const { searchOptions, pagination } = useSelector(collectionSearchSelector);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    dispatch(resetCreateCollection());
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const promise = dispatch(searchCollections());
    return promise.abort.bind(promise);
  }, [searchOptions, dispatch, pagination]);

  return (
    <>
      <div className="text-right">
        <Button color="primary" variant="contained" onClick={handleOpen}>
          Create Collection
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="create-collection-modal"
          aria-describedby="create-collection-modal"
        >
          <CreateCollection />
        </Modal>
      </div>

      <CollectionSearchControls />
      <hr className="m-4" />
      <Pagination />
      <CollectionSearchResults />
    </>
  );
};
export default CollectionSearch;

const collectionSearchSelector = createSelector(
  [selectSearchOptions, selectPagination],
  (searchOptions, pagination) => ({
    searchOptions,
    pagination,
  })
);

const Pagination = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const { noSearchResults, totalCount, start, end, numberOfPages, pageNumber } = useSelector(paginationSelector);

  if (noSearchResults) {
    return null;
  }

  const onChange = (pageNumber: number) => dispatch(changePageNumber(pageNumber));

  return (
    <div className="pb-4 text-center justify-center">
      <div className="pb-2">
        <PaginationSummary total={totalCount} start={start} end={end} />
      </div>
      <PaginationControls numberOfPages={numberOfPages} currentPage={pageNumber} onChange={onChange} />
    </div>
  );
};

const paginationSelector = createSelector([selectPagination, selectSearchResults], (pagination, searchResults) => {
  if (!searchResults) {
    return {
      noSearchResults: true,
      totalCount: -1,
      start: -1,
      end: -1,
      numberOfPages: -1,
      pageNumber: -1,
    };
  }

  const { totalCount } = searchResults;
  const { offset, resultsPerPage, pageNumber } = pagination;
  const start = offset + 1;
  let end = offset + 1 + resultsPerPage;
  if (end > totalCount) {
    end = totalCount;
  }

  return {
    noSearchResults: false,
    totalCount,
    start,
    end,
    numberOfPages: floor(totalCount / resultsPerPage),
    pageNumber,
  };
});
