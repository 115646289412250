import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import difference from 'lodash/difference';
import isArray from 'lodash/isArray';
import shuffle from 'lodash/shuffle';
import union from 'lodash/union';

import { getContentTypeToCategoryMapping } from '../../../common/util/util';
import { UpdateMetaResponse } from '../../api/BatchReviewApi';
import {
  BatchState,
  ContentTypesInterface,
  GroupsMapInterface,
  initialState,
  KeywordsMapInterface,
  StockItemMapInterface,
  StockItemsInterface,
} from './batch.state';
import {
  ClaimBatchSuccessPayload,
  ContentClassStats,
  GroupType,
  ReviewStatuses,
  StockItemType,
  UserStats,
} from './batch.types';

export const batchSlice = createSlice({
  name: 'batch',
  initialState,
  reducers: {
    claimBatchStarted: (state) => {
      state.isLoading = true;
      state.batchClaimed = false;
    },
    claimBatchSucceeded: (state, action: PayloadAction<ClaimBatchSuccessPayload>) => {
      const stockItems = action.payload.formattedStockItems;
      const normalizedStockItems = stockItems.reduce((map: StockItemMapInterface, item: StockItemType) => {
        const tempMap = map;
        tempMap[item._id] = item;
        return tempMap;
      }, {});
      const { templateMeta } = action.payload;
      const batchVisibleSize = action.payload.batchVisibleSize || 20;
      const stockItemIds = shuffle(stockItems.map((stockItem: StockItemType) => stockItem._id));
      const visibleIds = stockItemIds.slice(0, batchVisibleSize);
      const hiddenIds = stockItemIds.slice(batchVisibleSize);
      const selectedIds = [visibleIds[0]];
      state = setSelectedBatchUpdateValues(state, normalizedStockItems, selectedIds);

      state.isLoading = false;
      state.batchClaimed = true;
      state.reviewBatchId = action.payload.reviewBatchId;
      state.templateMeta = templateMeta;
      const contributor = stockItems[0]?.creatorUser?.stats?.contributor;
      state.contributor = {
        acceptanceRatio: action.payload.contributorInfo.acceptanceRatio || 0,
        numPending: action.payload.contributorInfo.numPending || 0,
        firstName: stockItems[0]?.creatorUser?.profile?.contact?.firstName || '',
        lastName: stockItems[0]?.creatorUser?.profile?.contact?.lastName || '',
        username: stockItems[0]?.creatorUser?.account?.username || '',
        videoStats: parseStatsFromWasabi(contributor?.video),
        audioStats: parseStatsFromWasabi(contributor?.audio),
        imageStats: parseStatsFromWasabi(contributor?.image),
      };

      state.viewedStockItems = [];
      state.stockItems = {
        ...state.stockItems,
        byId: normalizedStockItems,
        visibleIds,
        hiddenIds,
        selectedIds,
      };
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    claimBatchFailed: (state) => {
      state.isLoading = false;
      state.batchClaimed = false;
    },
    updateStockItemMetaSucceeded: (state, action: PayloadAction<UpdateMetaResponse>) => {
      const { byId, selectedIds } = state.stockItems;
      const stockItemId = action.payload.stockItem._id;
      byId[stockItemId] = getStockItemWithUpdatedMeta(byId, stockItemId, action.payload);

      state = setSelectedBatchUpdateValues(state, byId, selectedIds);
      state.stockItems = { ...state.stockItems, byId };
    },
    rejectStockItemSucceeded: (state, action: PayloadAction<string>) => {
      const { byId } = state.stockItems;
      const selectedStockItem = byId[action.payload];
      selectedStockItem.review.status = ReviewStatuses.rejected;
      byId[action.payload] = selectedStockItem;

      state.stockItems = { ...state.stockItems, byId };
    },
    approveBatchRequestStarted: (state) => {
      state.isApproving = true;
    },
    approveBatchRequestSucceeded: (state) => {
      state.isLoading = true;
      state.batchClaimed = false;
    },
    approveBatchRequestEnded: (state) => {
      state.isApproving = false;
    },
    skipStockItemSucceeded: (state, action: PayloadAction<string>) => {
      const { byId } = state.stockItems;
      const selectedStockItem = byId[action.payload];
      selectedStockItem.review.status = ReviewStatuses.skipped;
      byId[action.payload] = selectedStockItem;

      state.stockItems = { ...state.stockItems, byId };
    },
    updateManyStockItemsMetaSucceeded: (state, action: PayloadAction<UpdateMetaResponse[]>) => {
      const { byId, selectedIds } = state.stockItems;
      const newById = getUpdatesForMultiStockItemMetaUpdate(byId, action.payload);
      state = setSelectedBatchUpdateValues(state, newById, selectedIds);
      state.stockItems = { ...state.stockItems, byId: newById };
    },
    selectPreview: (
      state,
      action: PayloadAction<{
        selectedId: string;
        setCurrent: boolean;
        shiftKeyPressed: boolean;
        addPreviousItemToViewed: boolean;
        previousId: string | undefined | null;
      }>
    ) => {
      const { selectedId, setCurrent, shiftKeyPressed, previousId, addPreviousItemToViewed } = action.payload;
      if (setCurrent && previousId && !state.viewedStockItems.includes(previousId) && addPreviousItemToViewed) {
        state.viewedStockItems.push(previousId);
      }
      const sortedSelectedIds = getUpdatedSelectedIds(state.stockItems, selectedId, setCurrent, shiftKeyPressed);
      state = setSelectedBatchUpdateValues(state, state.stockItems.byId, sortedSelectedIds);
      state.stockItems = {
        ...state.stockItems,
        selectedIds: sortedSelectedIds,
      };
    },
    resetSelectedPreviews: (state) => {
      const { selectedIds, byId } = state.stockItems;
      const selectedId = selectedIds[0];
      state = setSelectedBatchUpdateValues(state, byId, [selectedId]);
      state.stockItems = { ...state.stockItems, selectedIds: [selectedId] };
    },
  },
});

export default batchSlice.reducer;

// HELPER FUNCTIONS

function getStockItemWithUpdatedMeta(stockItemsById: StockItemMapInterface, id: string, data: UpdateMetaResponse) {
  const stockItem = stockItemsById[id];
  stockItem.meta = data.stockItem.meta;
  stockItem.validationFailure = data.validationFailure;
  stockItem.contentRequestId = data.stockItem.contentRequestId;
  stockItem.content.type = data.stockItem.content.type;
  return stockItem;
}

function getUpdatesForMultiStockItemMetaUpdate(stockItemsById: StockItemMapInterface, responses: UpdateMetaResponse[]) {
  return responses.reduce((byId, currentResponse) => {
    const tempById = byId;
    tempById[currentResponse.stockItem._id] = getStockItemWithUpdatedMeta(
      byId,
      currentResponse.stockItem._id,
      currentResponse
    );
    return tempById;
  }, stockItemsById);
}

function getAggregatedMeta(stockItemsById: StockItemMapInterface, selectedIds: string[]) {
  let byCategoryTitle: GroupsMapInterface = {};
  let byMoodTitle: GroupsMapInterface = {};
  let byGenreTitle: GroupsMapInterface = {};
  let byInstrumentTitle: GroupsMapInterface = {};
  const byKeyword: KeywordsMapInterface = {};
  const byContentType: ContentTypesInterface = {};
  selectedIds.forEach((id: string) => {
    const stockItem = stockItemsById[id];
    const categories = stockItem.meta.stockBlocks?.categories || [];
    const moods = stockItem.meta.audio?.moods || [];
    const genres = stockItem.meta.audio?.genres || [];
    const instruments = stockItem.meta.audio?.instruments || [];
    const keywords = stockItem.meta.keywords || [];

    byCategoryTitle = aggregateGroup(categories, stockItem._id, byCategoryTitle);
    byMoodTitle = aggregateGroup(moods, stockItem._id, byMoodTitle);
    byGenreTitle = aggregateGroup(genres, stockItem._id, byGenreTitle);
    byInstrumentTitle = aggregateGroup(instruments, stockItem._id, byInstrumentTitle);

    keywords.forEach((keyword: string) => {
      // restricted keywords in JS like "constructor" are still valid keywords in stock item meta. Explicitly check result is an array before continuing.
      const stockItemIds = isArray(byKeyword[keyword]) ? byKeyword[keyword] : [];
      stockItemIds.push(stockItem._id);
      byKeyword[keyword] = stockItemIds;
    });
    const currentContentType = getContentTypeToCategoryMapping(stockItem.content.type);
    if (currentContentType) {
      byContentType[currentContentType] = byContentType[currentContentType]
        ? byContentType[currentContentType].concat([id])
        : [id];
    }
  });
  return {
    byCategoryTitle,
    byMoodTitle,
    byInstrumentTitle,
    byGenreTitle,
    byKeyword,
    byContentType,
  };
}

function aggregateGroup(groups: GroupType[], stockItemId: string, byTitle: GroupsMapInterface) {
  groups.forEach((group: GroupType) => {
    if (!group) return;
    const stockItemIds = byTitle[group.title] || [];
    stockItemIds.push(stockItemId);
    byTitle[group.title] = stockItemIds;
  });
  return byTitle;
}

// Reminder the currently selected preview is always index 0
function getUpdatedSelectedIds(
  stockItemData: StockItemsInterface,
  selectedId: string,
  setCurrent: boolean,
  shiftKeyPressed: boolean
) {
  const { selectedIds, visibleIds } = stockItemData;

  // User selected preview with shift pressed so bulk add new selected ids
  if (shiftKeyPressed) {
    const currentIndex = visibleIds.indexOf(selectedIds[0]);
    const selectedIndex = visibleIds.indexOf(selectedId);
    const lowerBound = selectedIndex < currentIndex ? selectedIndex : currentIndex;
    const upperBound = selectedIndex > currentIndex ? selectedIndex : currentIndex;
    const selectedRange = visibleIds.slice(lowerBound, upperBound + 1);
    return union(selectedIds, selectedRange);
  }

  // Update the selected id to the current preview
  if (setCurrent) {
    const index = selectedIds.indexOf(selectedId);
    const updatedSelectedIds = selectedIds;

    // Current Id is already in the selected group, make sure it's index 0
    if (index > -1) {
      selectedIds.splice(index, 1);
      updatedSelectedIds.unshift(selectedId);
      return updatedSelectedIds;
    }

    // Current Id is not already in the selected group so should be the only
    // selected item
    return [selectedId];
  }

  // Selected id is not the currently selected preview so just add/remove from the
  // selected group
  const isAlreadySelected = selectedIds.indexOf(selectedId) > -1;
  if (isAlreadySelected) {
    // Remove from selected ids
    return difference(selectedIds, [selectedId]);
  }
  // Add to selected ids
  return union(selectedIds, [selectedId]);
}

function setSelectedBatchUpdateValues(state: BatchState, stockItemsById: StockItemMapInterface, selectedIds: string[]) {
  const { byCategoryTitle, byKeyword, byContentType, byGenreTitle, byInstrumentTitle, byMoodTitle } = getAggregatedMeta(
    stockItemsById,
    selectedIds
  );

  state.selectedCategories = {
    ...state.selectedCategories,
    ...{ byTitle: byCategoryTitle },
  };
  state.selectedMoods = {
    ...state.selectedMoods,
    ...{ byTitle: byMoodTitle },
  };
  state.selectedGenres = {
    ...state.selectedGenres,
    ...{ byTitle: byGenreTitle },
  };
  state.selectedInstruments = {
    ...state.selectedInstruments,
    ...{ byTitle: byInstrumentTitle },
  };
  state.selectedKeywords = {
    ...state.selectedKeywords,
    byKeyword,
  };
  state.selectedContentTypes = {
    ...state.selectedContentTypes,
    byContentType,
  };
  return state;
}

function parseStatsFromWasabi(wasabiStats: UserStats | undefined): ContentClassStats {
  const manuallyApproved = wasabiStats?.numManuallyApproved || 0;
  const manuallyRejected = wasabiStats?.numManuallyRejected || 0;
  /*
  Since we don't track the number of submissions (what actually went into manual review) in Wasabi,
  we are using the sum of numManuallyApproved and numManuallyRejected to get the same information.
   */
  const numSubmitted = manuallyApproved + manuallyRejected;

  return {
    numApproved: wasabiStats?.numApproved || 0,
    numPublished: wasabiStats?.numPublished || 0,
    numRejected: wasabiStats?.numRejected || 0,
    numUploaded: wasabiStats?.numUploaded || 0,
    numManuallyApproved: manuallyApproved,
    numManuallyRejected: manuallyRejected,
    numSubmitted,
  };
}
