import React, { useEffect, useRef } from 'react';

import { ContentClass } from '../../../common/enums/ContentClasses';
import AudioTrackListItem from '../audioTrackListItem/AudioTrackListItem';
import { PreviewState } from '../previewStateStatusIcon/PreviewStateStatusIcon';
import VideoPreviewThumbnail from '../videoPreviewThumbnail/VideoPreviewThumbnail';

type OwnProps = BatchContentListItemProps & {
  contentClass: string;
  isInappropriate?: boolean;
  title?: string;
  durationMs?: number;
  contentRequestId?: number;
};

export type BatchContentListItemProps = {
  handleClick: (
    stockItemId: string,
    withShiftKey: boolean,
    addPreviousItemToViewed: boolean,
    index?: number,
    setCurrent?: boolean
  ) => void;
  handleKeyDown: (
    event: React.KeyboardEvent<HTMLDivElement>,
    stockItemId: string,
    index: number,
    addPreviousItemToViewed: boolean
  ) => void;
  handleSelectionToggle: (stockItemId: string, addPreviousItemToViewed: boolean) => void;
  previewState: PreviewState;
  thumbnailUrl: string;
  stockItemId: string;
  isCurrent: boolean;
  isSelected: boolean;
  idx: number;
  numberOfSelectedIds: number;
  selectedId: string;
};

export type GenericBatchContentListItemProps = BatchContentListItemProps & {
  thumbnailRef: React.RefObject<HTMLDivElement>;
  handlePreviewClick: (e: React.MouseEvent, addPreviousItemToViewed: boolean) => void;
};

export default function BatchContentListItem(props: OwnProps): JSX.Element {
  const { contentClass, isCurrent, isInappropriate, durationMs, title } = props;

  const thumbnailRef = useRef<HTMLDivElement>(null);
  const focusOnThumbnail = () => {
    if (thumbnailRef?.current) {
      thumbnailRef.current.focus();
    }
  };

  useEffect(() => {
    if (isCurrent) {
      focusOnThumbnail();
    }
  }, [isCurrent]);

  const handlePreviewClick = (e: React.MouseEvent, addPreviousItemToViewed: boolean): void => {
    e.preventDefault();
    const { handleClick, stockItemId, idx } = props;
    focusOnThumbnail();
    handleClick(stockItemId, e.shiftKey, addPreviousItemToViewed, idx);
  };

  return contentClass === ContentClass.video ? (
    <VideoPreviewThumbnail
      {...props}
      isInappropriate={!!isInappropriate}
      thumbnailRef={thumbnailRef}
      handlePreviewClick={handlePreviewClick}
    />
  ) : (
    <AudioTrackListItem
      {...props}
      key={props.stockItemId}
      durationMs={durationMs || 0}
      title={title || ''}
      thumbnailRef={thumbnailRef}
      handlePreviewClick={handlePreviewClick}
    />
  );
}
