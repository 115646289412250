import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { useState } from 'react';

import { RemoveIcon, SelectIcon } from '../../assets/icons/select-remove-icon';
import theme from '../../util/theme';

type SelectionIconProps = {
  handleClick: () => void;
  isSelected: boolean;
};

const activeOutlineColor = theme.colors.blue[500];
const inactiveOutlineColor = theme.colors.gray[500];

const ActiveRemoveIcon = (): JSX.Element => <RemoveIcon outlineColor={activeOutlineColor} />;
const InactiveRemoveIcon = (): JSX.Element => <RemoveIcon outlineColor={inactiveOutlineColor} />;
const ActiveSelectIcon = (): JSX.Element => <SelectIcon outlineColor={activeOutlineColor} />;
const InactiveSelectIcon = (): JSX.Element => <SelectIcon outlineColor={inactiveOutlineColor} />;
const getSelectIcon = (active: boolean, isSelected: boolean): React.ComponentType<SvgIconProps> => {
  if (isSelected) {
    return active ? ActiveRemoveIcon : InactiveRemoveIcon;
  }
  return active ? ActiveSelectIcon : InactiveSelectIcon;
};

export const SelectionIcon = ({ handleClick, isSelected }: SelectionIconProps): JSX.Element => {
  const [active, setActive] = useState<boolean>(false);

  const styles = active ? { color: theme.colors.blue[500] } : {};
  return (
    <SvgIcon
      component={getSelectIcon(active, isSelected)}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        handleClick();
      }}
      sx={styles}
      onMouseEnter={() => {
        setActive(true);
      }}
      onMouseLeave={() => {
        setActive(false);
      }}
    />
  );
};
