import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ContentClass } from '../../../common/enums/ContentClasses';
import { AsyncThunkConfig } from '../../../common/store/store';
import CollectionApi from '../../api/CollectionApi';
import { Collection, CreateCollectionDraft } from '../../api/CollectionApi.types';

export interface CreateCollectionState {
  collection: CreateCollectionDraft;
  createdId?: number;
  isCreating?: boolean;
  error?: string;
}

const initialState: CreateCollectionState = {
  collection: {
    name: '',
    classes: [],
    isActive: true,
  },
};

const createCollection = createAsyncThunk<Collection, CreateCollectionDraft, AsyncThunkConfig>(
  'create/createCollection',
  async (collection: CreateCollectionDraft, { getState }) => {
    const { _id: userId } = getState().user;
    return await CollectionApi.createCollection(collection, userId || '');
  }
);

const createCollectionSlice = createSlice({
  name: 'create',
  initialState,
  reducers: {
    resetCreateCollection: (state) => {
      state.createdId = undefined;
      state.isCreating = undefined;
      state.error = undefined;
      state.collection = {
        name: '',
        classes: [],
        isActive: true,
      };
    },
    updateName: (state, action: PayloadAction<string>) => {
      state.collection.name = action.payload;
    },
    updateClasses: (state, action: PayloadAction<ContentClass[]>) => {
      state.collection.classes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCollection.rejected, (state, action) => {
        state.isCreating = false;
        if (action.error.message) {
          state.error = action.error.message;
        } else {
          state.error = 'Failed to create collection';
        }
      })
      .addCase(createCollection.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(createCollection.fulfilled, (state, action) => {
        state.isCreating = false;
        if (action.payload._id) {
          state.createdId = action.payload._id;
        }
      });
  },
});

const { actions, reducer } = createCollectionSlice;
const { updateClasses, updateName, resetCreateCollection } = actions;

export { updateClasses, updateName, resetCreateCollection, initialState, reducer, createCollection };
