import { Button } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import Alert, { AlertType } from '../../../../common/components/alert/Alert';
import { useDispatch } from '../../../../common/store/store';
import { logger } from '../../../../common/util/logger';
import { addAllToCollection, resetError } from '../../../store/CollectionEdit/CollectionEdit.reducer';
import { selectCollection } from '../../../store/CollectionEdit/CollectionEdit.selectors';

const CollectionBulkAddSelectFile = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const collection = useSelector(selectCollection);

  const inputFile = useRef<HTMLInputElement>(null);
  const fileReader = new FileReader();

  const [contentIds, setContentIds] = useState<string[]>([]);
  const [fileName, setFileName] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  if (!collection) {
    return null;
  }

  const openFileBrowse = () => {
    clearFile();
    if (inputFile && inputFile.current) {
      inputFile.current.click();
    } else {
      logger.warn('No input file defined');
    }
  };

  const validateFileAndUpload = () => {
    setError(null);
    if (inputFile && inputFile.current && inputFile.current.files?.length) {
      if (inputFile.current.files[0].type !== 'text/csv') {
        setError('File must be a csv');
      } else {
        setFileName(inputFile.current.files[0].name);
        fileReader.onloadend = readFile;
        fileReader.readAsText(inputFile.current.files[0]);
      }
    }
  };

  const readFile = () => {
    const fileContent = (fileReader.result as string).split(/\r?\n/);
    // If the file is just a single empty line show an error
    if (fileContent.length === 1 && fileContent[0] === '') {
      setError('File uploaded is empty');
      setFileName(null);
    } else {
      const filteredFileContent = filterBlankLines(fileContent);
      setContentIds(filteredFileContent);
    }
  };

  const filterBlankLines = (fileContent: string[]) => fileContent.filter((fileLine) => fileLine.trim() !== '');

  const clearFile = () => {
    setContentIds([]);
    setFileName(null);
    return dispatch(resetError());
  };

  const uploadContent = () => {
    if (contentIds) {
      void dispatch(addAllToCollection(contentIds));
    }
  };

  return (
    <div className="pt-4">
      {error ? <Alert title={error || 'An unexpected error occurred'} type={AlertType.ERROR} /> : null}
      <div className="flex flex-row space-x-6">
        <div>
          <input
            type="file"
            onChange={validateFileAndUpload}
            id="add-urlid"
            style={{ display: 'none' }}
            ref={inputFile}
          />
          <Button variant="contained" color="primary" onClick={openFileBrowse}>
            Select File for Bulk Add
          </Button>
        </div>
        {fileName ? (
          <div className="px-2 rounded rounded border-l-4 items-center font-normal border-blue-500 text-blue-700 bg-blue-100">
            {fileName}
            <Button color="primary" onClick={clearFile}>
              x
            </Button>
          </div>
        ) : null}
      </div>
      <div className="flex flex-col space-y-3 m-4">
        {contentIds.map((contentId: string) => (
          <div
            className="flex flex-row w-full items-center px-4 py-2 rounded border-l-4 items-center font-normal border-cool-gray-500 bg-cool-gray-100"
            key={contentId}
          >
            {contentId}
          </div>
        ))}
      </div>
      {fileName ? (
        <Button variant="contained" color="primary" onClick={uploadContent}>
          Add
        </Button>
      ) : null}
    </div>
  );
};
export default CollectionBulkAddSelectFile;
