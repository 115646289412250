import React, { ReactNode } from 'react';

import { ReactComponent as ErrorIcon } from '../../assets/icons/alert-error.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/alert-info.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/alert-success.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/alert-warning.svg';

type OwnProps = {
  title: string | JSX.Element;
  messages?: string[];
  type: AlertType;
};

export enum AlertType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

type StyleLookupMap = Readonly<Record<AlertType, { color: string; icon: ReactNode }>>;
const styleLookup: StyleLookupMap = Object.freeze({
  [AlertType.SUCCESS]: {
    color: 'green',
    icon: <SuccessIcon className="flex-none" />,
  },
  [AlertType.INFO]: {
    color: 'blue',
    icon: <InfoIcon className="flex-none" />,
  },
  [AlertType.WARNING]: {
    color: 'orange',
    icon: <WarningIcon className="flex-none" />,
  },
  [AlertType.ERROR]: {
    color: 'red',
    icon: <ErrorIcon className="flex-none" />,
  },
});

export default function Alert({ type, title, messages }: OwnProps): JSX.Element {
  const { color, icon } = styleLookup[type];
  const borderColor = `border-${color}-500`;
  const textColor = `text-${color}-700`;
  const bgColor = `bg-${color}-100`;

  return (
    <div
      className={`py-6 px-4 rounded border-l-8 items-center w-full font-normal ${borderColor} ${textColor} ${bgColor}`}
      role="alert"
    >
      <div className="space-x-4 flex">
        {icon}
        <span>
          {title}
          <ul className={`list-disc pl-4 ${textColor}`}>
            {messages?.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        </span>
      </div>
    </div>
  );
}
