import { ContentClass } from '../../common/enums/ContentClasses';
import { ContentDistributionType } from '../../common/enums/ContentDistributionType';
import { ContentStatus } from '../../common/enums/ContentStatus';
import { ContentType } from '../../common/enums/ContentTypes';
import CollectionStatus from '../enums/CollectionStatus';
import CrossSellButtonClass from '../enums/CrossSellButtonClass';
import { CollectionSuggestionByClass } from './CollectionApiSuggestion.types';

export interface CrossSellInfo {
  backgroundImageUrl?: string;
  buttonClass?: CrossSellButtonClass;
  linkText?: string;
  title?: string;
  url?: string;
}

export interface CollectionView {
  allowSeoIndex: boolean;
  crossSell?: {
    left?: CrossSellInfo;
    right?: CrossSellInfo;
  };
  description?: string;
  directoryBackgroundImageUrl?: string;
  heroBackgroundImageUrl?: string;
  heroSubtitle?: string;
  heroTitle?: string;
  isExplore: boolean;
  isHomepage: boolean;
  metaDescription?: string;
  metaKeywords?: string;
  metaTitle?: string;
  previewClipId?: number;
  redirectUri?: string;
  routeUri?: string;
  showInDirectory: boolean;
  showInMainNavDropdown?: boolean;
  topTags?: string[];
}

export interface Collection {
  _id: number;
  content?: CollectionContent;
  createdAt?: string;
  isActive: boolean;
  isSearchIndexable: boolean;
  name: string;
  notes?: string;
  showInApi: boolean;
  status: CollectionStatus;
  stockblocksId?: number;
  suggestion?: CollectionSuggestionByClass;
  classes: ContentClass[];
  updatedAt?: string;
  view?: CollectionView;
}

export type CollectionContent = Partial<Record<ContentClass, number[]>>;

export interface CreateCollectionDraft {
  contentIds?: number[];
  isActive?: boolean;
  isSearchIndexable?: boolean;
  name: string;
  notes?: string;
  showInApi?: boolean;
  status?: CollectionStatus;
  classes: ContentClass[];
  view?: CollectionView;
}

export interface CollectionSearchParams {
  name?: string;
}

export interface CollectionSearchResult {
  readonly collections: Collection[];
  readonly totalCount: number;
}

interface ContentMeta {
  title?: string;
  keywords?: string[];
}

interface EncodingFile {
  size?: string;
  uri: string;
}

// although urlId is required in the content-service by the time it gets here
export interface Content {
  _id: number;
  contributorPortalId?: string;
  stockblocksId?: number;
  urlId: string;
  externalId?: string;
  contributorId: string;
  originalContributorId?: string;
  class: ContentClass;
  type: ContentType;
  status: ContentStatus;
  distributionType: ContentDistributionType;
  activationDate?: Date;
  deletionDate?: Date;
  meta: ContentMeta;
  encodings: {
    thumbnail: EncodingFile[];
    preview: EncodingFile[];
  };
}

export enum RejectionReason {
  QUALITY = 'QUALITY',
  RELEVANCY = 'RELEVANCY',
  DEI = 'DEI',
  REPETITIVE = 'REPETITIVE',
  TRENDS = 'TRENDS',
  OTHER = 'OTHER',
}

export enum ChangeNoticeType {
  PUBLISH = 'PUBLISH',
  IMPORT = 'IMPORT',
  CLONE = 'CLONE',
  REJECT_ALL = 'REJECT_ALL',
}
