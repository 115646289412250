import { Image, Link } from '@mui/icons-material';
import { Card, CardActions, CardContent, CardMedia, IconButton, Tooltip } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import React from 'react';
import { ConnectableElement } from 'react-dnd';
import { useSelector } from 'react-redux';

import { ContentClass } from '../../../../../common/enums/ContentClasses';
import { selectCollection } from '../../../../store/CollectionEdit/CollectionEdit.selectors';
import { simpleSelectors } from '../../../../store/ContentList/ContentList.reducer';
import { selectContentList } from '../../../../store/ContentList/ContentList.selectors';
import { useDragCollectionContent, useDropCollectionContent } from '../../curationDragHooks';
import DeleteDialog from './DeleteDialog';

const selector = (contentId: number) =>
  createSelector([selectCollection, selectContentList, () => contentId], (collection, contentList, id) => {
    return {
      collection,
      content: simpleSelectors.selectById(contentList, id),
    };
  });

interface ContentItemProps {
  id: number;
  location: number;
  contentClass: ContentClass;
}

const ContentItem = (props: ContentItemProps): JSX.Element | null => {
  const { id, location, contentClass } = props;

  const { collection, content } = useSelector(selector(id));

  const [copied, setCopied] = React.useState(false);

  const { drag, isDragging } = useDragCollectionContent(id, location, contentClass);
  const drop = useDropCollectionContent(id, location, contentClass);

  const needRejectionReason = collection?.suggestion?.[contentClass]?.content?.approvedContentIds
    ? collection?.suggestion?.[contentClass]?.content?.approvedContentIds?.includes(id) || false
    : false;

  const opacity = isDragging ? 'opacity-50' : '';
  const thumbnail =
    'https://dm0qx8t0i9gc9.cloudfront.net/thumbnails/image/rDtN98Qoishumwih/image-placeholder-stroke-icon_MyL9N8L__thumb.jpg';

  if (!content) {
    return null;
  }

  const copyDirectoryBackgroundImageUrl = () => {
    setCopied(false);
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(content.encodings.thumbnail[0].uri)
        .then(() => {
          setCopied(true);
        })
        .catch((error) => console.log(error));
    }
  };

  const image = content.encodings.thumbnail.length ? content.encodings.thumbnail[0].uri : thumbnail;

  const contentClassPath = content.class === ContentClass.image ? 'images' : content.class;

  let linkToContent;
  if (process.env.REACT_APP_WASABI_BASE_URI === 'https://contribute.storyblocks.com') {
    linkToContent = `https://www.storyblocks.com/${contentClassPath}/stock/${content.urlId || ''}`;
  } else if (process.env.REACT_APP_WASABI_BASE_URI === 'https://wasabi-staging.videoblocksdev.com') {
    linkToContent = `https://sb-staging.videoblocksdev.com/${contentClassPath}/stock/${content.urlId || ''}`;
  } else {
    linkToContent = `https://www.storyblocks.com/${contentClassPath}/stock/${content.urlId || ''}`;
  }

  return (
    <div>
      <Card
        ref={(node: ConnectableElement) => drag(drop(node))}
        className={`mt-4 w-80 h-52 relative border-2 border-transparent hover:border-gray-500 hover:shadow-lg cursor-move ${opacity}`}
      >
        <CardMedia component="img" className="w-full h-full" image={image} alt={content.meta.title} />

        <CardContent className="absolute bottom-0 w-full text-sm text-white bg-gradient-to-t from-black">
          <h3 className="font-bold absolute inset-x-0 bottom-0 px-2 text-center truncate max-w-30">
            {content.meta.title}
          </h3>
        </CardContent>
        <CardActions className="absolute top-0 w-full bg-gradient-to-b from-gray-400 to-transparent">
          <div className="grid grid-cols-2 w-full self-start">
            <div className="col-span-1 col-start-1 flex justify-start">
              <div>
                <Tooltip title="Copy link to image">
                  <IconButton onClick={copyDirectoryBackgroundImageUrl}>
                    <Image color={(copied && 'primary') || 'action'} />
                  </IconButton>
                </Tooltip>
              </div>
              <div>
                <a target="_blank" rel="noopener noreferrer" href={linkToContent}>
                  <Tooltip title="View on Storyblocks.com">
                    <IconButton>
                      <Link color="action" />
                    </IconButton>
                  </Tooltip>
                </a>
              </div>
            </div>
            <div className="col-span-1 col-start-2 flex justify-end">
              <DeleteDialog id={id} needRejectionReason={needRejectionReason} contentClass={contentClass} />
            </div>
          </div>
        </CardActions>
      </Card>
      {content.class === ContentClass.audio && content.encodings.preview[0] && (
        <audio className="pt-4" controls>
          <source src={content.encodings.preview[0].uri} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}
      {content.class === ContentClass.audio && !content.encodings.preview[0] && <p>Encoding preview not available.</p>}
    </div>
  );
};

export default ContentItem;
