import { ContentType } from '../../../common/enums/ContentTypes';
import { ReviewPermission } from '../../enums/ReviewPermissions';
import { Reviewer } from './manageReviewer.types';

export const initialState: ManageReviewerState = {
  isLoading: true,
  reviewers: [],
  isModalOpen: false,
  editedReviewerPermissions: {
    [ContentType.FOOTAGE]: false,
    [ContentType.LEGACY_MOTION_BACKGROUND]: false,
    [ContentType.TEMPLATE]: false,
    [ContentType.VR_360]: false,
    [ContentType.VECTOR]: false,
    [ContentType.ILLUSTRATION]: false,
    [ContentType.PHOTO]: false,
    [ContentType.MUSIC]: false,
  },
  userNameBeingEdited: '',
};

export interface ManageReviewerState {
  reviewers: Reviewer[];
  isLoading: boolean;
  selectedUserId?: string;
  isModalOpen: boolean;
  editedReviewerPermissions: Record<ReviewPermission, boolean>;
  userNameBeingEdited: string;
}
