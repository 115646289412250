import { RootState } from '../../../common/store/store';
import { CreateCollectionDraft } from '../../api/CollectionApi.types';

export const selectDraftCollection = (state: RootState): CreateCollectionDraft =>
  state.collectionTool.create.collection;

export const selectCreatedId = (state: RootState): number | undefined => state.collectionTool.create.createdId;

export const selectIsCreating = (state: RootState): boolean | undefined => state.collectionTool.create.isCreating;

export const selectError = (state: RootState): string | undefined => state.collectionTool.create.error;
