import { Transition } from '@headlessui/react';
import { createSelector } from '@reduxjs/toolkit';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ContentType } from '../../enums/ContentTypes';
import Roles from '../../enums/Roles';
import { isNavigablePrivateRoute, NavigablePrivateRoute, PRIVATE_ROUTES, PrivateRoute } from '../../routes';
import { useDispatch } from '../../store/store';
import { logoutUser } from '../../store/user/user.actions';
import { selectPermissions, selectProfileImage, selectRoles, selectUsername } from '../../store/user/user.selectors';
import { getFlagValue, hasRequiredValue } from '../../util/util';

interface OwnProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  currentPath: string;
}

function SideNav({ isOpen, setIsOpen, currentPath }: OwnProps): JSX.Element {
  const dispatch = useDispatch();
  const { permissions, roles, username, profileImage } = useSelector(selector);

  const routesAvailableToUser = getAvailablePaths(roles, permissions);
  const sideNavLinks = formatLinks(currentPath, routesAvailableToUser);

  return (
    <Transition show={isOpen}>
      <div>
        <div className="fixed inset-0 flex z-40">
          <Transition.Child
            className="fixed inset-0"
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="absolute inset-0 bg-gray-600 opacity-75" onClick={() => setIsOpen(!isOpen)} />
          </Transition.Child>
          <Transition.Child
            className="relative flex-1 flex flex-col max-w-xs w-full bg-white px-7"
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="absolute top-0 right-0 -mr-6 mt-7 rounded-full shadow-md bg-white">
              <button
                className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
                aria-label="Close sidebar"
                type="button"
                onClick={() => setIsOpen(!isOpen)}
              >
                <svg className="h-6 w-6 text-black" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="flex-1 h-0 pt-5 pb-4 mt-5 overflow-y-auto">
              <div className="flex-shrink-0 flex items-center">
                <img className="h-8 w-auto ml-2" src="/images/SB_Logo.svg" alt="Logo" />
              </div>
              <nav className="mt-10 space-y-1 border-t-2 border-gray-100 flex flex-col pt-7">
                {sideNavLinks}
                <button type="button" onClick={() => dispatch(logoutUser())} className="sideNavLink text-gray-700">
                  <img src="/images/sideNav/default/logoutIcon.svg" alt="logout" />
                  <div className="ml-4">Log Out</div>
                </button>
              </nav>
            </div>
            <div className="flex-shrink-0 flex py-4 px-1">
              <div className="flex-shrink-0 group block">
                <div className="flex items-center">
                  <div>
                    <img
                      className="inline-block h-10 w-10 rounded-full"
                      src={profileImage || '/images/portfolio-profile.png'}
                      alt=""
                      data-private={'lipsum'}
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-base leading-6 font-normal text-black" data-private={'lipsum'}>
                      {username}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
        </div>
      </div>
    </Transition>
  );
}
export default SideNav;

const selector = createSelector(
  [selectPermissions, selectRoles, selectUsername, selectProfileImage],
  (permissions, roles, username, profileImage) => ({ permissions, roles, username, profileImage })
);

const getAvailablePaths = (
  roles: Roles[] | undefined,
  permissions: ContentType[] | undefined
): NavigablePrivateRoute[] => {
  const navigableRoutes = Object.values<PrivateRoute>(PRIVATE_ROUTES).filter(isNavigablePrivateRoute);
  return navigableRoutes.filter((route: NavigablePrivateRoute) => {
    const hasRoles = hasRequiredValue(roles, route.roles);
    const hasPermissionsIfNeeded = route.reviewPermissions?.length
      ? hasRequiredValue(permissions, route.reviewPermissions)
      : true;
    const featureEnabled = !route.featureFlag || getFlagValue(route.featureFlag);
    return hasRoles && hasPermissionsIfNeeded && featureEnabled;
  });
};

const formatLinks = (currentPath: string, availableRoutes: NavigablePrivateRoute[]): JSX.Element[] => {
  return availableRoutes.map((route) => {
    const { title, path, imagePath } = route;
    const isActive = path === currentPath;
    const image = isActive ? imagePath.active : imagePath.default;

    return (
      <Link
        key={title}
        to={path}
        className={`sideNavLink mt-1 ${isActive ? 'font-bold text-black bg-gray-200' : 'font-normal text-gray-700'}`}
      >
        <img src={image} alt={title} />
        <div className="ml-4">{title}</div>
      </Link>
    );
  });
};
