import { isNil } from 'lodash';

import { ContentType } from '../../../common/enums/ContentTypes';
import { AppThunk } from '../../../common/store/store';
import { showErrorToast, showSuccessToast } from '../../../common/store/toasts/toasts.reducer';
import { logger } from '../../../common/util/logger';
import { getGroupsFromTitles } from '../../../common/util/util';
import BatchReviewApi, { UpdateMetaResponse } from '../../api/BatchReviewApi';
import { StockItemDataForEdit } from '../../containers/multiItemEdit/MultiItemEdit';
import { batchSlice } from './batch.reducer';
import { ClaimBatchSuccessPayload, GroupType, GroupTypeMap } from './batch.types';

const {
  claimBatchFailed,
  claimBatchStarted,
  claimBatchSucceeded,
  updateStockItemMetaSucceeded,
  rejectStockItemSucceeded,
  approveBatchRequestStarted,
  approveBatchRequestSucceeded,
  approveBatchRequestEnded,
  skipStockItemSucceeded,
  updateManyStockItemsMetaSucceeded,
  selectPreview,
  resetSelectedPreviews,
} = batchSlice.actions;

export const makeClaimBatchRequest =
  (contentTypes: ContentType[]): AppThunk =>
  async (dispatch) => {
    dispatch(claimBatchStarted());

    try {
      const api = new BatchReviewApi();
      const payload: unknown = await api.getBatch(contentTypes);
      if (isClaimBatchSuccess(payload)) {
        dispatch(claimBatchSucceeded(payload));
      } else {
        dispatch(claimBatchFailed());
      }
    } catch (error) {
      dispatch(claimBatchFailed());
      const message = 'Error claiming next batch';
      dispatch(showErrorToast(message));
      logger.error(message, error);
    }
  };

const isClaimBatchSuccess = (payload: unknown): payload is ClaimBatchSuccessPayload => {
  return (
    typeof payload === 'object' &&
    !isNil(payload) &&
    'formattedStockItems' in payload &&
    'reviewBatchId' in payload &&
    'contributorInfo' in payload &&
    'templateMeta' in payload
  );
};

// export const GET_STATS_REQUEST = 'GET_STATS_REQUEST';
//
// export function makeGetStatsRequest() {
//   return async (dispatch: React.Dispatch<any>): Promise<void> => {
//     dispatch(startedAsyncAction(GET_STATS_REQUEST));
//
//     try {
//       const payload = await getReviewerStats();
//       const reviewerStats = get(payload, 'data.data[0]', {});
//       dispatch(succeededAsyncAction(GET_STATS_REQUEST, reviewerStats));
//     } catch (error) {
//       dispatch(failedAsyncAction(GET_STATS_REQUEST, error));
//       dispatch(triggerErrorToast('Snap! We were unable to retrieve your latest stats.'));
//     }
//   };
// }

export const updateStockItemMeta =
  (
    stockItemId: string,
    stockItemCreatorId: string,
    key: string,
    value: string[] | boolean | string | GroupType[] | GroupTypeMap,
    toastEnabled = true
  ): AppThunk =>
  async (dispatch) => {
    try {
      const api = new BatchReviewApi();
      const payload = await api.updateMeta(stockItemId, stockItemCreatorId, key, value);
      dispatch(updateStockItemMetaSucceeded(payload));
      if (toastEnabled) {
        dispatch(showSuccessToast('Meta updated'));
      }
    } catch (error) {
      dispatch(showErrorToast('Failed to update meta'));
    }
  };

export const rejectStockItem =
  (stockItemId: string, rejectionReasonId: string, rejectComment?: string, softReject?: boolean): AppThunk =>
  async (dispatch) => {
    try {
      const api = new BatchReviewApi();
      await api.rejectStockItem(stockItemId, rejectionReasonId, rejectComment, softReject);
      dispatch(rejectStockItemSucceeded(stockItemId));
      // TODO
      // Update user stats after rejecting item
      // dispatch(makeGetStatsRequest());
    } catch (error) {
      dispatch(showErrorToast('Failed rejecting Stock Item'));
    }
  };

export const approveBatch =
  (
    reviewBatchId: string,
    stockItemIds: string[],
    autoApproveStockItemIds: string[],
    contentTypes: ContentType[]
  ): AppThunk =>
  async (dispatch) => {
    dispatch(approveBatchRequestStarted());
    try {
      const api = new BatchReviewApi();
      const payload = await api.batchApprove(reviewBatchId, stockItemIds, autoApproveStockItemIds);
      dispatch(approveBatchRequestSucceeded());
      dispatch(showSuccessToast(`${payload.numApproved} stock items approved`));
      // Claim and start fresh if successful
      dispatch(makeClaimBatchRequest(contentTypes));
    } catch (error) {
      dispatch(showErrorToast('Error approving batch'));
    } finally {
      dispatch(approveBatchRequestEnded());
    }
  };

export const rejectBatch =
  (stockItemIds: string[], rejectReason: string, rejectComment: string, softReject?: boolean): AppThunk =>
  async (dispatch) => {
    try {
      const api = new BatchReviewApi();
      await api.rejectStockItems(stockItemIds, rejectReason, rejectComment, softReject);
    } catch (error) {
      dispatch(
        showErrorToast(`Error rejecting stock items in batch with the following rejection reason: ${rejectReason}`)
      );
    }
  };

export const skipStockItem =
  (reviewBatchId: string, stockItemId: string, notes: string): AppThunk =>
  async (dispatch) => {
    try {
      const api = new BatchReviewApi();
      await api.skipStockItem(reviewBatchId, stockItemId, notes);
      dispatch(skipStockItemSucceeded(stockItemId));
    } catch (error) {
      dispatch(showErrorToast('Snap! Failed to skip stock item. Try reloading the page.'));
    }
  };

const getUpdateMetaKey = (key: keyof StockItemDataForEdit): string => {
  if (key === 'moods' || key === 'genres' || key === 'instruments' || key === 'hasVocals') {
    return `audio.${key}`;
  }
  return key;
};

const getUpdateMetaValue = (
  key: keyof StockItemDataForEdit,
  stockItem: StockItemDataForEdit,
  allGroups?: GroupType[]
) => {
  if (key === 'moods' || key === 'genres' || key === 'instruments') {
    const newValueMap = stockItem[key];
    if (newValueMap !== undefined && allGroups) {
      return getGroupsFromTitles(newValueMap, allGroups);
    }
  }
  return stockItem[key];
};

export const updateManyStockItemsMeta =
  (stockItems: StockItemDataForEdit[], key: keyof StockItemDataForEdit, allGroups?: GroupType[]): AppThunk =>
  async (dispatch) => {
    let numUpdated = 0;
    let numFailed = 0;
    const responses = await Promise.all(
      stockItems.map(async (stockItem) => {
        try {
          const api = new BatchReviewApi();
          const updateKey = getUpdateMetaKey(key);
          const newValue = getUpdateMetaValue(key, stockItem, allGroups);

          if (newValue !== undefined) {
            const payload = await api.updateMeta(stockItem.id, stockItem.creatorId, updateKey, newValue);
            numUpdated += 1;
            return payload;
          }
        } catch (error) {
          logger.error('Error updating stock item meta in bulk request', error);
          numFailed += 1;
          return null;
        }
      })
    );
    const responsesWithNullRemoved = responses.filter((r): r is UpdateMetaResponse => !isNil(r));
    dispatch(updateManyStockItemsMetaSucceeded(responsesWithNullRemoved));

    if (numUpdated > 0) {
      dispatch(showSuccessToast(`Meta updated for ${numUpdated} items`));
    }
    if (numFailed > 0) {
      dispatch(
        showErrorToast(`Snap! An unexpected problem was encountered preventing the updating of ${numFailed} items`)
      );
    }
  };

export const selectPreviewById =
  (
    selectedId: string,
    setCurrent: boolean,
    shiftKeyPressed: boolean,
    addPreviousItemToViewed: boolean,
    previousId?: string
  ): AppThunk =>
  (dispatch) => {
    dispatch(
      selectPreview({
        selectedId,
        setCurrent,
        shiftKeyPressed,
        addPreviousItemToViewed,
        previousId: selectedId !== previousId ? previousId : null,
      })
    );
  };

export const resetAllSelectedPreviews = (): AppThunk => (dispatch) => {
  dispatch(resetSelectedPreviews());
};
