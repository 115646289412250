import { Chip, ChipProps, SvgIcon } from '@mui/material';
import React from 'react';

import { ReactComponent as CloseIcon } from '../../assets/icons/close-keyword-chip.svg';
import theme from '../../util/theme';

const MultiSelectChip = ({ onDelete, ...props }: ChipProps) => {
  return (
    <Chip
      onMouseDown={(e) => e.stopPropagation()}
      clickable={true}
      deleteIcon={
        <SvgIcon
          component={CloseIcon}
          viewBox="0 0 9 9"
          sx={{
            color: theme.colors.gray[500],
            marginLeft: '10px',
            marginRight: 0,
            width: '9px',
            height: '9px',
          }}
        />
      }
      onDelete={() => onDelete && onDelete(props.label)}
      {...props}
      sx={{
        backgroundColor: theme.colors.gray[100],
        border: `1px solid ${theme.colors.gray[200]}`,
        borderRadius: '4px',
        padding: '0 8px',
        textAlign: 'left',
        marginRight: '8px',
        marginBottom: '4px',
        height: '2rem',
      }}
    />
  );
};

const renderChips = (selectedValues: string[], disabled?: boolean, onDelete?: (selected: string) => void) => {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {selectedValues.map((value) => (
        <MultiSelectChip key={value} label={value} onDelete={onDelete} disabled={disabled} />
      ))}
    </div>
  );
};

const renderSelectedAsCommaString = (selectedValues: string[]) => selectedValues.join(', ');

export const renderValues = (
  selectedValues: string[],
  renderValuesAsChips: boolean,
  disabled?: boolean,
  onDelete?: (selected: string) => void
): string | JSX.Element => {
  return renderValuesAsChips
    ? renderChips(selectedValues, disabled, onDelete)
    : renderSelectedAsCommaString(selectedValues);
};
