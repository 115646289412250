import { ContentClass } from '../../../common/enums/ContentClasses';
import { ContentType } from '../../../common/enums/ContentTypes';
import { VisualStandards } from '../../enums/VisualStandards';

export interface ContentClassStats {
  numManuallyApproved: number;
  numManuallyRejected: number;
  numPublished: number;
  numUploaded: number;
  numRejected: number;
  numSubmitted: number;
  numApproved: number;
}

export type ContributorType = {
  acceptanceRatio: number;
  numPending: number;
  firstName: string;
  lastName: string;
  username: string;

  videoStats: ContentClassStats;
  audioStats: ContentClassStats;
  imageStats: ContentClassStats;
};

export type TemplateMetaType = {
  highestResolutions: TemplateMetaMap[];
  softwareVersions: TemplateMetaMap[];
  plugins: TemplateMetaMap[];
};

export type TemplateMetaMap = {
  text: string;
  value: string;
};

export type GroupType = {
  id: number;
  title: string;
  searchType?: string;
  type?: string;
};

export type GroupTypeMap = {
  [key: string]: GroupType[];
};

export type StockItemIdType = string;

export type StockItemType = {
  _id: StockItemIdType;
  review: {
    status: ReviewStatuses;
  };
  content: {
    class: ContentClass;
    type: ContentType;
    thumbnail: {
      small: string[];
      svg?: string[];
    };
    download: {
      mp3?: {
        full: {
          uri: string;
        };
      };
    };
  };
  previewStats: {
    audioCodec?: string;
    // title
    // description
    downloadFilename?: string;
    bitrateKbps?: number;
    fileSizeBytes?: number;
    width?: number;
    height?: number;
    frameRate?: number;
    videoCodec?: string;
    colorProfile?: string;
  };
  meta: {
    isEditorial?: boolean;
    isSensitiveContent?: boolean;
    keywords: string[];
    title: string;
    description?: string;
    whiteBalance?: string;
    focalLength?: string;
    lens?: string;
    maxResolution?: string;
    softwareVersion?: string;
    softwarePlugins?: string[];
    stockBlocks?: {
      categories?: GroupType[];
    };
    audio?: {
      bitDepth?: number;
      bpm?: number;
      sampleRate?: number;
      hasVocals: boolean;
      moods: GroupType[];
      genres: GroupType[];
      instruments: GroupType[];
    };
    visualStandards?: VisualStandards[];
    brandValues?: string[];
    durationMs?: number;
    isExclusive?: boolean;
  };
  previewUri: string;
  downloadUri: string;
  releases: ReleaseType[];
  validationFailure?: ValidationFailureType;
  thumbnailUrl: string;
  creatorId: string;
  deleted?: boolean;
  contentRequestId?: number;
};

export type ReleaseType = {
  review: {
    status: ReleaseStatuses.approved | ReleaseStatuses.rejected;
  };
  fileUrl: string;
  fileName: string;
};

export type ValidationFailureType = {
  [type in ValidationFailures]: string;
};

export type ValidationFailures =
  | 'title'
  | 'keywords'
  | 'meta'
  | 'moods'
  | 'genres'
  | 'sfxCategories'
  | 'categories'
  | 'artists';

export enum ReleaseStatuses {
  approved = 'approved',
  rejected = 'rejected',
}

// Skipped isn't an actual status in the DB it is just used for the frontend
export enum ReviewStatuses {
  uploaded = 'uploaded',
  pendingAuto = 'pending-auto',
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
  skipped = 'skipped',
}

export type StockItemThumbnailData = {
  id: string;
  title?: string;
  durationMs?: number;
  thumbnailUrl: string;
  isCurrent: boolean;
  isInvalid: boolean;
  isReviewed: boolean;
  isRejected: boolean;
  isViewed: boolean;
  isSelected: boolean;
  isInappropriate?: boolean;
  contentRequestId?: number;
};

export interface UserStats {
  numUploaded?: number;
  numPublished?: number;
  numManuallyApproved?: number;
  numRejected?: number;
  numManuallyRejected?: number;
  numApproved?: number;
}

/**
 * "users" collection in wasabi mongo
 */
export interface User {
  profile?: {
    contact?: {
      firstName?: string;
      lastName?: string;
    };
  };
  account?: {
    username?: string;
  };
  stats?: {
    contributor?: {
      video?: UserStats;
      image?: UserStats;
      audio?: UserStats;
    };
  };
}

/**
 * The StockItem returned by the Wasabi batch endpoint also contains information about the creator of the stock item.
 * This user information is not normally a part of the stock item.
 */
export interface StockItemWithCreator extends StockItemType {
  creatorUser?: User;
}

export interface ClaimBatchSuccessPayload {
  reviewBatchId: string;
  formattedStockItems: StockItemWithCreator[];
  contributorInfo: {
    acceptanceRatio: number;
    numPending: number | null;
  };
  templateMeta: TemplateMetaType;
  batchVisibleSize?: number;
}
