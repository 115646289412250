import React from 'react';

import CollectionStatus from '../../../enums/CollectionStatus';
import SearchOptionChip from './SearchOptionChip';

interface Props {
  onChange: (status?: CollectionStatus) => void;
  selectedStatus?: CollectionStatus;
}

const CollectionStatusSelect = ({ onChange, selectedStatus }: Props): JSX.Element => {
  const isDraftSelected = selectedStatus === CollectionStatus.DRAFT;
  const isAwaitingSelected = selectedStatus === CollectionStatus.AWAITING_SUGGESTIONS;
  const isReviewSelected = selectedStatus === CollectionStatus.REVIEW_SUGGESTIONS;
  const isPublishedSelected = selectedStatus === CollectionStatus.PUBLISHED;
  const isArchiveSelected = selectedStatus === CollectionStatus.ARCHIVED;

  const onClick = (status: CollectionStatus, currentlySelected: boolean) =>
    onChange(currentlySelected ? undefined : status);

  return (
    <div className="flex flex-wrap w-full">
      <span className="text-sm my-auto mr-4">Collection Status</span>
      <span className="flex flex-wrap">
        <SearchOptionChip
          text="Draft"
          isActive={isDraftSelected}
          onClick={() => onClick(CollectionStatus.DRAFT, isDraftSelected)}
        />
        <SearchOptionChip
          text="Published"
          isActive={isPublishedSelected}
          onClick={() => onClick(CollectionStatus.PUBLISHED, isPublishedSelected)}
        />
        <SearchOptionChip
          text="Awaiting Suggestions"
          isActive={isAwaitingSelected}
          onClick={() => onClick(CollectionStatus.AWAITING_SUGGESTIONS, isAwaitingSelected)}
        />
        <SearchOptionChip
          text="Review Suggestions"
          isActive={isReviewSelected}
          onClick={() => onClick(CollectionStatus.REVIEW_SUGGESTIONS, isReviewSelected)}
        />
        <SearchOptionChip
          text="Archived"
          isActive={isArchiveSelected}
          onClick={() => onClick(CollectionStatus.ARCHIVED, isArchiveSelected)}
        />
      </span>
    </div>
  );
};
export default CollectionStatusSelect;
