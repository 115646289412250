import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ReviewersBatchStatsResponse } from '../../api/ReviewerBatchStatsApi';
import { initialState } from './reviewerBatchStats.state';

export const reviewerBatchStatsSlice = createSlice({
  name: 'reviewBatchStats',
  initialState,
  reducers: {
    getReviewBatchStatsStarted: (state) => {
      state.isLoading = true;
      state.contentTypeReviewerBatchStats = undefined;
    },
    getReviewBatchStatsSucceeded: (state, action: PayloadAction<ReviewersBatchStatsResponse>) => {
      const { music, template, vr360, animation, footage } = action.payload.queueBatchInfo;
      state.isLoading = false;
      state.contentTypeReviewerBatchStats = action.payload.data;
      state.queueCounts = {
        audio: music,
        template,
        vr360,
        video: {
          totalBatches: animation.totalBatches + footage.totalBatches,
          totalItems: animation.totalItems + footage.totalItems,
        },
      };
    },
    getReviewBatchStatsFailed: (state) => {
      state.contentTypeReviewerBatchStats = undefined;
      state.isLoading = false;
      state.queueCounts = {
        audio: { totalBatches: -1, totalItems: -1 },
        template: { totalBatches: -1, totalItems: -1 },
        vr360: { totalBatches: -1, totalItems: -1 },
        video: { totalBatches: -1, totalItems: -1 },
      };
    },
  },
});

export default reviewerBatchStatsSlice.reducer;
