import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

import theme from '../../util/theme';

export type IconProps = {
  outlineColor?: string;
} & SvgIconProps;

export const RemoveIcon = ({ outlineColor, ...props }: IconProps): JSX.Element => {
  const iconColor = outlineColor ?? theme.colors.gray[500];
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
      <path
        d="M19.5 10C19.5 15.2467 15.2467 19.5 10 19.5C4.75329 19.5 0.5 15.2467 0.5 10C0.5 4.75329 4.75329 0.5 10 0.5C15.2467 0.5 19.5 4.75329 19.5 10Z"
        fill="white"
        stroke={iconColor}
      />
      <path
        d="M4.99646 11.05C4.43589 11.05 3.98146 10.5955 3.98146 10.035C3.98146 9.4744 4.43589 9.01997 4.99646 9.01997H14.9665C15.527 9.01997 15.9815 9.4744 15.9815 10.035C15.9815 10.5955 15.527 11.05 14.9665 11.05L4.99646 11.05Z"
        fill={iconColor}
      />
    </SvgIcon>
  );
};

export const SelectIcon = ({ outlineColor, ...props }: IconProps): JSX.Element => {
  const iconColor = outlineColor ?? theme.colors.gray[500];
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
      <path
        d="M19.5 10C19.5 15.2467 15.2467 19.5 10 19.5C4.75329 19.5 0.5 15.2467 0.5 10C0.5 4.75329 4.75329 0.5 10 0.5C15.2467 0.5 19.5 4.75329 19.5 10Z"
        fill="white"
        stroke={iconColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99647 3.97815C9.4359 3.97816 8.98147 4.43259 8.98147 4.99315L8.98147 9.01996L4.99627 9.01996C4.4357 9.01996 3.98127 9.47439 3.98127 10.035C3.98127 10.5955 4.4357 11.05 4.99627 11.05L8.98147 11.05L8.98147 14.9632C8.98147 15.5237 9.4359 15.9782 9.99647 15.9782C10.557 15.9782 11.0115 15.5237 11.0115 14.9632L11.0115 11.05H14.9663C15.5268 11.05 15.9813 10.5955 15.9813 10.035C15.9813 9.47439 15.5268 9.01996 14.9663 9.01996H11.0115L11.0115 4.99315C11.0115 4.43259 10.557 3.97815 9.99647 3.97815Z"
        fill={iconColor}
      />
    </SvgIcon>
  );
};
