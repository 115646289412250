import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import tailwindTheme from '../../util/theme';
import { DropdownMenuIcon } from '../dropdownMenuIcon/DropdownMenuIcon';
import SelectInput from '../selectInput/SelectInput';

type OwnProps = {
  options: { value: string; label: string }[];
  selectedValue: string;
  placeholder?: string;
  id: string;
  labelId: string;
  label: string;
  disabled?: boolean;
};

function SingleSelect({
  options,
  selectedValue,
  placeholder,
  id,
  labelId,
  label,
  disabled,
  onChange,
}: OwnProps & SelectProps): JSX.Element {
  const theme = useTheme();
  const labelStyles = {
    fontSize: '0.875rem',
    lineHeight: '1rem',
    color: '#000000',
    fontFamily: tailwindTheme.fontFamily.sans,
    fontWeight: tailwindTheme.fontWeight.semibold,
  };
  const selectStyles = {
    padding: '0 0.75rem 0 0.75rem',
    marginTop: -theme.spacing(1),
    minHeight: '2.5rem',
    border: `1px solid ${tailwindTheme.colors.gray[400]}`,
    display: 'flex',
    alignItems: 'center',
  };

  return (
    <FormControl fullWidth disabled={disabled || false}>
      <InputLabel shrink id={labelId} sx={labelStyles}>
        {label}
      </InputLabel>
      <Select
        input={<SelectInput />}
        labelId={labelId}
        id={id}
        value={selectedValue}
        onChange={onChange}
        sx={selectStyles}
        IconComponent={DropdownMenuIcon}
        SelectDisplayProps={{ style: { padding: 0 } }}
      >
        {placeholder && (
          <MenuItem value="">
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {options.map((option) => {
          return (
            <MenuItem key={option.label} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default SingleSelect;
