import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';

import theme from '../../util/theme';

function TextArea(props: TextFieldProps): JSX.Element {
  const { colors } = theme;
  const styles = {
    '& .MuiOutlinedInput-root': {
      padding: '8px 12px',
      'min-height': '40px',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.gray[400],
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.blue[500],
      borderWidth: '2px',
      borderRadius: '4px',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.blue[500],
      borderWidth: '2px',
      borderRadius: '4px',
    },
  };

  return (
    <TextField
      sx={styles}
      maxRows={props.maxRows || 4}
      id={props.id}
      placeholder={props.placeholder || ''}
      value={props.defaultValue}
      onChange={props.onChange}
      disabled={props.disabled}
      label={props.label}
      variant={props.variant}
      multiline
      fullWidth
    />
  );
}

export default TextArea;
