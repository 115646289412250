import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { useDispatch } from '../../common/store/store';
import { loadNewCollection } from '../store/CollectionEdit/CollectionEdit.reducer';
import CollectionEditPanel from './collectionEditPanel/CollectionEditPanel';

const CollectionEdit = (): JSX.Element => {
  const { collectionId } = useParams<{ collectionId: string }>();
  const dispatch = useDispatch();

  const numericId = Number.parseInt(collectionId);

  useEffect(() => {
    if (!isNaN(numericId)) {
      void dispatch(loadNewCollection(numericId));
      window.sessionStorage.clear();
    }
  }, [numericId, dispatch]);

  if (isNaN(numericId)) {
    return <Redirect to="/collection-tool/search" />;
  }

  return <CollectionEditPanel />;
};
export default CollectionEdit;
