import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import tailwindTheme from '../../../../common/util/theme';
import { selectCollection } from '../../../store/CollectionEdit/CollectionEdit.selectors';
import CollectionCurationAutomatedSuggestions from './subTabs/CollectionCurationAutomatedSuggestions';
import CollectionCurationContentList from './subTabs/CollectionCurationContentList';

enum CurationTabValue {
  AUTOMATED_SUGGESTIONS = 'automatedSuggestions',
  CONTENT_LIST = 'contentList',
}

const CollectionCurationTab = (): JSX.Element | null => {
  const [tabValue, setTabValue] = React.useState(
    window.sessionStorage.getItem('curationTabValue') || CurationTabValue.CONTENT_LIST
  );
  const collection = useSelector(selectCollection);

  useEffect(() => {
    window.sessionStorage.setItem('curationTabValue', tabValue);
  }, [tabValue]);

  if (!collection) {
    return null;
  }

  const handleTabChange = (event: React.ChangeEvent<unknown>, newTabValue: CurationTabValue) => {
    setTabValue(newTabValue);
  };

  return (
    <TabContext value={tabValue}>
      <TabList
        variant="standard"
        value={tabValue}
        onChange={handleTabChange}
        aria-label="Curation Tabs"
        TabIndicatorProps={{ sx: { display: 'none' } }}
        sx={{
          backgroundColor: tailwindTheme.colors.gray[200],
          borderRadius: '20px',
          display: 'inline-block',
          maxHeight: '40px',
          minHeight: 'unset',
          minWidth: 'unset',
        }}
      >
        <Tab
          label="Automated Suggestions"
          value={CurationTabValue.AUTOMATED_SUGGESTIONS}
          sx={{
            backgroundColor:
              tabValue === CurationTabValue.AUTOMATED_SUGGESTIONS ? '#36414D' : tailwindTheme.colors.gray[200],
            borderRadius: '20px',
            color: tabValue === CurationTabValue.AUTOMATED_SUGGESTIONS ? '#FFFFFF !important' : '#626C78',
            fontSize: '14px',
            lineHeight: '14px',
            minHeight: 'unset',
            outline: 'unset !important',
            padding: '12px 16px',
            textTransform: 'capitalize',
          }}
        />
        <Tab
          label="Content List"
          value={CurationTabValue.CONTENT_LIST}
          sx={{
            backgroundColor: tabValue === CurationTabValue.CONTENT_LIST ? '#36414D' : tailwindTheme.colors.gray[200],
            borderRadius: '20px',
            color: tabValue === CurationTabValue.CONTENT_LIST ? '#FFFFFF !important' : '#626C78',
            fontSize: '14px',
            lineHeight: '14px',
            minHeight: 'unset',
            outline: 'unset !important',
            padding: '12px 16px',
            textTransform: 'capitalize',
          }}
        />
      </TabList>
      <div>
        <TabPanel sx={{ padding: 'unset' }} value={CurationTabValue.AUTOMATED_SUGGESTIONS}>
          <CollectionCurationAutomatedSuggestions />
        </TabPanel>
        <TabPanel sx={{ padding: 'unset' }} value={CurationTabValue.CONTENT_LIST}>
          <CollectionCurationContentList />
        </TabPanel>
      </div>
    </TabContext>
  );
};

export default CollectionCurationTab;
