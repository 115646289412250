import { RejectionReason } from './rejectionReason.types';

export const initialState: RejectionReasonState = {
  contentTypeRejectionReasons: undefined,
};

export interface RejectionReasonState {
  contentTypeRejectionReasons?: ContentTypeRejectionReasonsInterface;
}

export type ContentTypeRejectionReasonsInterface = {
  footage?: RejectionReason[];
  animation?: RejectionReason[];
  template?: RejectionReason[];
  vr360?: RejectionReason[];
  photo?: RejectionReason[];
  illustration?: RejectionReason[];
  vector?: RejectionReason[];
  music?: RejectionReason[];
};
