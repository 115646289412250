import { createTheme } from '@mui/material';

import theme from './common/util/theme';

const muiTheme = createTheme({
  typography: {
    fontFamily: theme.fontFamily.sans,
  },
  components: {},
});
export default muiTheme;
