import React, { useEffect } from 'react';

function ContributorPortalRedirect(): JSX.Element {
  const env = process.env;
  const wasabiBaseUri = env.REACT_APP_WASABI_BASE_URI || '';

  useEffect(() => {
    window.location.href = wasabiBaseUri;
  });

  return <h1>Redirecting...</h1>;
}

export default ContributorPortalRedirect;
