import { theme } from '../../tailwind-theme';

export default {
  fontFamily: {
    sans: theme.fontFamily.sans.join(','),
  },
  fontWeight: {
    normal: Number.parseInt(theme.fontWeight.normal),
    semibold: Number.parseInt(theme.fontWeight.semibold),
  },
  colors: theme.colors,
  padding: theme.padding,
  margin: theme.margin,
} as const;
