import { TabContext, TabPanel } from '@mui/lab';
import { Box, Tab, Tabs, TextField } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { ContentClass } from '../../../../../common/enums/ContentClasses';
import { useDispatch } from '../../../../../common/store/store';
import { saveCollection } from '../../../../store/CollectionEdit/CollectionEdit.reducer';
import { selectCollection } from '../../../../store/CollectionEdit/CollectionEdit.selectors';
import ImportCollection from '../../ImportCollection';
import CollectionBulkAddSelectFile from '../../bulk/CollectionBulkAddSelectFile';
import CollectionCurationSingleAdd from '../CollectionCurationSingleAdd';
import ContentItemList from '../contentItem/ContentItemList';

const CollectionCurationContentList = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const collection = useSelector(selectCollection);
  if (!collection) {
    return null;
  }
  const [tabValue, setTabValue] = React.useState(collection.classes[0]);
  const handleTabChange = (event: React.ChangeEvent<unknown>, newTabValue: ContentClass) => {
    setTabValue(newTabValue);
  };

  const handleAdminNotesChange = (event: React.FocusEvent<HTMLInputElement>) => {
    void dispatch(
      saveCollection({
        id: collection._id,
        updates: {
          notes: event.target.value,
        },
      })
    );
  };

  return (
    <div>
      <div className="grid grid-cols-2">
        <div className="col-start-1 col-span-1 pr-4">
          <div className="flex flex-col">
            <CollectionCurationSingleAdd />
          </div>
          <div className="flex flex-col pt-4">
            <CollectionBulkAddSelectFile />
          </div>
          {collection.classes.length > 1 && (
            <div className="pt-4">
              <ImportCollection />
            </div>
          )}
        </div>
        <div className="col-start-2 col-span-1">
          <TextField
            id="notes"
            label="Admin Notes"
            variant="outlined"
            minRows={2}
            onBlur={handleAdminNotesChange}
            defaultValue={collection.notes}
            multiline
            fullWidth
          />
        </div>
      </div>
      {collection.classes.length > 1 ? (
        <div className="pt-4">
          <TabContext value={tabValue}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="Content Class Toggle">
              {collection.classes.map((contentClass, key) => (
                <Tab key={key} label={contentClass} value={contentClass} />
              ))}
            </Tabs>
            <Box className="border-2 rounded border-slate-200">
              {collection.classes.map((contentClass, key) => (
                <TabPanel key={key} value={contentClass}>
                  <ContentItemList contentClass={contentClass} />
                </TabPanel>
              ))}
            </Box>
          </TabContext>
        </div>
      ) : (
        <ContentItemList contentClass={collection.classes[0]} />
      )}
    </div>
  );
};

export default CollectionCurationContentList;
