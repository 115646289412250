import { createSelector } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Loading from '../../../../common/components/loading/Loading';
import { ContentClass } from '../../../../common/enums/ContentClasses';
import { batchVideoContentTypes, ContentType } from '../../../../common/enums/ContentTypes';
import { useDispatch } from '../../../../common/store/store';
import ContentDetailsTable from '../../../components/contentDetailsTable/ContentDetailsTable';
import CreatorDetailsTable from '../../../components/creatorDetailsTable/CreatorDetailsTable';
import Preview from '../../../components/preview/Preview';
import BatchActionButtons from '../../../containers/batchActionButtons/BatchActionButtons';
import BatchContentList from '../../../containers/batchContentList/BatchContentList';
import MultiItemEdit from '../../../containers/multiItemEdit/MultiItemEdit';
import SingleItemEdit from '../../../containers/singleItemEdit/SingleItemEdit';
import { makeClaimBatchRequest } from '../../../store/batch/batch.actions';
import {
  selectBatchClaimed,
  selectContributor,
  selectCurrentStockItem,
  selectIsBatchLoading,
  selectSelectedIds,
  selectVisibleStockItems,
} from '../../../store/batch/batch.selectors';
import { makeBrandValuesRequest } from '../../../store/brandValues/brandValues.actions';
import { makeGroupsRequest } from '../../../store/groups/groups.action';
import { makeRejectReasonsRequest } from '../../../store/rejectionReason/rejectionReason.actions';

function VideoBatchReview(): JSX.Element {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(makeClaimBatchRequest(batchVideoContentTypes));
    dispatch(makeRejectReasonsRequest());
    dispatch(makeBrandValuesRequest());
    dispatch(makeGroupsRequest(ContentClass.video));
  }, [dispatch]);

  const { batchClaimed, isBatchLoading, currentStockItem, contributor, isMultiEdit, visibleStockItems } =
    useSelector(selector);

  // As this app grows we should create a more sophisticated loading
  // scheme
  if (isBatchLoading || !batchClaimed) {
    return <Loading isBatchLoading={isBatchLoading} />;
  }

  return (
    <div className="grid grid-cols-12 gap-6 videoBatch">
      <div className="col-span-12 md:col-span-5 flex flex-col">
        {contributor && <CreatorDetailsTable creator={contributor} contentClass={ContentClass.video} />}
        <ContentDetailsTable stockItem={currentStockItem} />
        <div className="mt-4">
          <Preview stockItem={currentStockItem} />
        </div>
        <BatchActionButtons contentTypes={batchVideoContentTypes} visibleStockItems={visibleStockItems} />
        <BatchContentList contentClass={ContentClass.video} columnsPerRow={4} visibleStockItems={visibleStockItems} />
      </div>
      <div className="col-span-12 md:col-span-7 flex flex-col">
        <div className="p-6 bg-white">
          {isMultiEdit ? (
            <MultiItemEdit contentClass={ContentClass.video} />
          ) : (
            <SingleItemEdit
              contentClass={ContentClass.video}
              allowedContentTypes={[ContentType.FOOTAGE, ContentType.LEGACY_MOTION_BACKGROUND]}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default VideoBatchReview;

const selector = createSelector(
  [
    selectBatchClaimed,
    selectIsBatchLoading,
    selectCurrentStockItem,
    selectContributor,
    selectSelectedIds,
    selectVisibleStockItems,
  ],
  (batchClaimed, isBatchLoading, currentStockItem, contributor, selectedIds, visibleStockItems) => ({
    batchClaimed,
    isBatchLoading,
    currentStockItem,
    contributor,
    visibleStockItems,
    isMultiEdit: selectedIds.length > 1,
  })
);
