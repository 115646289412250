import { createSelector } from '@reduxjs/toolkit';
import React from 'react';
import { useSelector } from 'react-redux';

import { KeyboardKeys } from '../../../common/enums/KeyboardKeys';
import { useDispatch } from '../../../common/store/store';
import { PreviewState } from '../../components/previewStateStatusIcon/PreviewStateStatusIcon';
import BatchContentListItem from '../../components/reviewListItem/BatchContentListItem';
import { selectPreviewById } from '../../store/batch/batch.actions';
import { selectSelectedId, selectSelectedIds } from '../../store/batch/batch.selectors';
import { StockItemThumbnailData } from '../../store/batch/batch.types';

type OwnProps = {
  contentClass: string;
  columnsPerRow: number;
  visibleStockItems: StockItemThumbnailData[];
};

function BatchContentList(props: OwnProps): JSX.Element {
  const { contentClass, columnsPerRow, visibleStockItems } = props;
  const dispatch = useDispatch();
  const { selectedId, numberOfSelectedIds } = useSelector(selector);

  const handleCurrentPreviewSelected = (
    stockItemId: string,
    withShiftKey: boolean,
    addPreviousItemToViewed: boolean
  ) => {
    dispatch(selectPreviewById(stockItemId, true, withShiftKey, addPreviousItemToViewed, selectedId));
  };

  const handleSelectionToggle = (stockItemId: string, addPreviousItemToViewed: boolean) => {
    dispatch(selectPreviewById(stockItemId, false, false, addPreviousItemToViewed, selectedId));
  };

  const handleKeyDown = (
    event: React.KeyboardEvent,
    stockItemId: string,
    idx: number,
    addPreviousItemToViewed: boolean
  ) => {
    let newIndex = -1;
    let newIndexId: string | null = null;
    switch (event.key) {
      case KeyboardKeys.ENTER:
        newIndexId = stockItemId;
        break;
      case KeyboardKeys.ARROW_LEFT: {
        newIndex = idx - 1;
        if (newIndex >= 0) {
          newIndexId = visibleStockItems[newIndex].id;
        }
        break;
      }
      case KeyboardKeys.ARROW_UP: {
        newIndex = idx - columnsPerRow;
        if (newIndex >= 0) {
          newIndexId = visibleStockItems[newIndex].id;
        }
        break;
      }
      case KeyboardKeys.ARROW_RIGHT: {
        newIndex = idx + 1;
        if (newIndex < visibleStockItems.length) {
          newIndexId = visibleStockItems[newIndex].id;
        }
        break;
      }
      case KeyboardKeys.ARROW_DOWN: {
        newIndex = idx + columnsPerRow;
        if (newIndex < visibleStockItems.length) {
          newIndexId = visibleStockItems[newIndex].id;
        }
        break;
      }
      default:
        break;
    }

    if (newIndexId) dispatch(selectPreviewById(newIndexId, true, false, addPreviousItemToViewed, selectedId));
  };
  const percentWidthOfCell = 100 / columnsPerRow;

  return (
    <div className="flex flex-row flex-wrap">
      {visibleStockItems.map((item, idx) => (
        <div style={{ width: `${percentWidthOfCell}%` }} key={item.id}>
          <BatchContentListItem
            contentClass={contentClass}
            previewState={getPreviewState(item)}
            thumbnailUrl={item.thumbnailUrl}
            stockItemId={item.id}
            isCurrent={item.isCurrent}
            isSelected={item.isSelected}
            isInappropriate={item.isInappropriate}
            title={item.title}
            durationMs={item.durationMs}
            contentRequestId={item.contentRequestId}
            handleClick={handleCurrentPreviewSelected}
            handleKeyDown={handleKeyDown}
            handleSelectionToggle={handleSelectionToggle}
            idx={idx}
            numberOfSelectedIds={numberOfSelectedIds}
            selectedId={selectedId}
          />
        </div>
      ))}
    </div>
  );
}
export default BatchContentList;

const selector = createSelector([selectSelectedId, selectSelectedIds], (selectedId, selectedIds) => ({
  selectedId,
  numberOfSelectedIds: selectedIds.length,
}));

function getPreviewState(item: StockItemThumbnailData): PreviewState {
  if (item.isRejected) {
    return PreviewState.REJECTED;
  }
  if (item.isInvalid) {
    return PreviewState.INVALID;
  }
  if (!item.isViewed && !item.isReviewed) {
    return PreviewState.NONE;
  }
  if (item.isViewed && !item.isInvalid) {
    return PreviewState.APPROVED;
  }
  return PreviewState.NONE;
}
