import { ContributorType, StockItemIdType, StockItemType, TemplateMetaType } from './batch.types';

export const initialState: BatchState = {
  isLoading: true,
  isApproving: false,
  batchClaimed: false,
  reviewBatchId: undefined,
  contributor: undefined,
  viewedStockItems: [],
  templateMeta: {
    highestResolutions: [],
    softwareVersions: [],
    plugins: [],
  },
  stockItems: {
    // Create object for quick look up and edits
    byId: {},
    // Use ID arrays to preserve sort order
    visibleIds: [],
    hiddenIds: [],
    // Currently selected stock item is always index 0
    selectedIds: [],
  },
  selectedCategories: {
    byTitle: {},
  },
  selectedMoods: {
    byTitle: {},
  },
  selectedGenres: {
    byTitle: {},
  },
  selectedInstruments: {
    byTitle: {},
  },
  selectedKeywords: {
    byKeyword: {},
  },
  selectedContentTypes: {
    byContentType: {},
  },
};

export interface BatchState {
  isLoading: boolean;
  isApproving: boolean;
  batchClaimed: boolean;
  templateMeta: TemplateMetaType;
  reviewBatchId?: string;
  contributor?: ContributorType;
  viewedStockItems: string[];
  stockItems: StockItemsInterface;
  selectedCategories: SelectedGroupsInterface;
  selectedMoods: SelectedGroupsInterface;
  selectedGenres: SelectedGroupsInterface;
  selectedInstruments: SelectedGroupsInterface;
  selectedKeywords: SelectedKeywordsInterface;
  selectedContentTypes: SelectedContentTypesInterface;
}

export interface StockItemsInterface {
  // Create object for quick look up and edits
  byId: StockItemMapInterface;
  // Use ID arrays to preserve sort order
  visibleIds: string[];
  hiddenIds: string[];
  // Currently selected stock item is always index 0
  selectedIds: string[];
}

export interface SelectedGroupsInterface {
  byTitle: GroupsMapInterface;
}

export interface SelectedKeywordsInterface {
  byKeyword: KeywordsMapInterface;
}

export interface SelectedContentTypesInterface {
  byContentType: ContentTypesInterface;
}

export interface StockItemMapInterface {
  [key: string]: StockItemType;
}

export interface GroupsMapInterface {
  [key: string]: StockItemIdType[];
}

export interface KeywordsMapInterface {
  [key: string]: StockItemIdType[];
}

export interface ContentTypesInterface {
  [key: string]: StockItemIdType[];
}
