import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox as MaterialCheckbox, CircularProgress } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { FormProvider, Resolver, useForm, useFormContext, useFormState } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Button, { ButtonSize, ButtonTextSize, ButtonType } from '../../../../common/components/button/Button';
import { useDispatch } from '../../../../common/store/store';
import { CollectionView } from '../../../api/CollectionApi.types';
import Checkbox from '../../../components/form/Checkbox';
import Chips from '../../../components/form/Chips';
import Select from '../../../components/form/Select';
import Text from '../../../components/form/Text';
import CrossSellButtonClass from '../../../enums/CrossSellButtonClass';
import { updateCollectionView } from '../../../store/CollectionEdit/CollectionEdit.reducer';
import { selectCollection, selectIsUpdating } from '../../../store/CollectionEdit/CollectionEdit.selectors';
import { CollectionViewFormValidation } from '../../../store/CollectionEdit/CollectionEdit.types';
import { ButtonClassOptions, FormInputNames, FormSchema } from './CollectionViewTab.types';

const MainInputs = (): JSX.Element => {
  const { control } = useFormContext<CollectionViewFormValidation>();
  const { errors } = useFormState<CollectionViewFormValidation>({ control });

  return (
    <>
      <Text
        name={FormInputNames.ROUTE_URI}
        control={control}
        id="route-uri"
        label="Route URI*"
        placeholder="Enter a Route URI"
        error={errors[FormInputNames.ROUTE_URI]}
      />
      <Text
        name={FormInputNames.REDIRECT_URI}
        control={control}
        id="redirect-uri"
        label="Redirect URI"
        placeholder="Enter a Redirect URI"
        error={errors[FormInputNames.REDIRECT_URI]}
      />
      <Text
        name={FormInputNames.DIRECTORY_BACKGROUND_IMAGE_URL}
        control={control}
        id="directory-background-image-url"
        label="Directory Background Image Url*"
        placeholder="Enter a Directory Background Image Url"
        error={errors[FormInputNames.DIRECTORY_BACKGROUND_IMAGE_URL]}
      />
      <Text
        name={FormInputNames.HERO_TITLE}
        control={control}
        id="hero-title"
        label="Hero Title*"
        placeholder="Enter a Hero Title"
        error={errors[FormInputNames.HERO_TITLE]}
      />
      <Text
        name={FormInputNames.HERO_SUBTITLE}
        control={control}
        id="hero-subtitle"
        label="Hero Subtitle*"
        placeholder="Enter a Hero Subtitle"
        error={errors[FormInputNames.HERO_SUBTITLE]}
      />
      <Text
        name={FormInputNames.HERO_BG_IMG_URL}
        control={control}
        id="hero-background-image-url"
        label="Hero Background Image URL*"
        placeholder="Enter a Hero Background Image URL"
        error={errors[FormInputNames.HERO_BG_IMG_URL]}
      />
      <Text
        name={FormInputNames.DESCRIPTION}
        control={control}
        id="description"
        label="Description*"
        placeholder="Enter a Description"
        rows={5}
        error={errors[FormInputNames.DESCRIPTION]}
      />
      <Text
        name={FormInputNames.META_TITLE}
        control={control}
        id="meta-title"
        label="Meta Title*"
        placeholder="Enter a Meta Title"
        error={errors[FormInputNames.META_TITLE]}
      />
      <Text
        name={FormInputNames.META_DESCRIPTION}
        control={control}
        id="meta-description"
        label="Meta Description*"
        placeholder="Enter a Meta Description"
        rows={5}
        error={errors[FormInputNames.META_DESCRIPTION]}
      />
      <Text
        id="meta-keywords"
        name={FormInputNames.META_KEYWORDS}
        control={control}
        label="Meta Keywords*"
        placeholder="Enter Meta Keywords"
        error={errors[FormInputNames.META_KEYWORDS]}
      />
      <Text
        name={FormInputNames.PREVIEW_CLIP_ID}
        control={control}
        id="preview-clip-id"
        label="Preview Clip ID"
        placeholder="Enter a Preview Clip ID"
        error={errors[FormInputNames.PREVIEW_CLIP_ID]}
      />
      <Chips
        id="top-tags"
        name={FormInputNames.TOP_TAGS}
        control={control}
        label="Top Tags"
        error={errors[FormInputNames.TOP_TAGS]}
      />
      <Checkbox
        name={FormInputNames.IS_EXPLORE}
        control={control}
        id="is-explore"
        label="Explore Collection"
        error={errors[FormInputNames.ALLOW_SEO_INDEX]}
      />
      <Checkbox
        name={FormInputNames.IS_HOMEPAGE}
        control={control}
        id="is-homepage"
        label="Homepage Collection"
        error={errors[FormInputNames.ALLOW_SEO_INDEX]}
      />
      <Checkbox
        name={FormInputNames.SHOW_IN_DIRECTORY}
        control={control}
        id="show-in-directory"
        label="Show In Directory"
        error={errors[FormInputNames.ALLOW_SEO_INDEX]}
      />
      <Checkbox
        name={FormInputNames.SHOW_IN_MAIN_NAV_DROPDOWN}
        control={control}
        id="show-in-main-nav-dropdown"
        label="Show In Main Nav Dropdown"
        error={errors[FormInputNames.ALLOW_SEO_INDEX]}
      />
      <Checkbox
        name={FormInputNames.ALLOW_SEO_INDEX}
        control={control}
        id="allow-seo-index"
        label="Allow SEO Index"
        error={errors[FormInputNames.ALLOW_SEO_INDEX]}
      />
    </>
  );
};

const CrossSellInputs = ({
  isLeftCrossSellOpen,
  isRightCrossSellOpen,
  handleLeftCrossSellOpen,
  handleRightCrossSellOpen,
}: {
  isLeftCrossSellOpen: boolean;
  isRightCrossSellOpen: boolean;
  handleLeftCrossSellOpen: () => void;
  handleRightCrossSellOpen: () => void;
}): JSX.Element => {
  const { control } = useFormContext<CollectionViewFormValidation>();
  const { errors } = useFormState<CollectionViewFormValidation>({ control });

  return (
    <div className="mt-4">
      <h2 className="text-2xl text-gray-900">Cross Sell</h2>
      <div className="flex">
        <div className="w-1/2">
          <h3 className="inline text-xl text-gray-900">Left</h3>
          <MaterialCheckbox checked={isLeftCrossSellOpen} onChange={handleLeftCrossSellOpen} />
          {isLeftCrossSellOpen ? (
            <>
              <div className="pr-4">
                <Text
                  name={FormInputNames.CS_LEFT_BG_IMG_URL}
                  control={control}
                  id="cross-sell-left-bg-img-url"
                  label="Image URL"
                  placeholder="Enter an Image URL"
                  required={true}
                  error={errors.crossSell?.left?.backgroundImageUrl}
                />
              </div>
              <div className="pr-4">
                <Select
                  name={FormInputNames.CS_LEFT_BUTTON_CLASS}
                  control={control}
                  options={ButtonClassOptions}
                  id="cross-sell-left-button-class"
                  label="Button Class"
                  required={true}
                  error={errors.crossSell?.left?.buttonClass}
                />
              </div>
              <div className="pr-4">
                <Text
                  name={FormInputNames.CS_LEFT_LINK_TEXT}
                  control={control}
                  id="cross-sell-left-link-text"
                  label="Link Text"
                  placeholder="Enter Link Text"
                  required={true}
                  error={errors.crossSell?.left?.linkText}
                />
              </div>
              <div className="pr-4">
                <Text
                  name={FormInputNames.CS_LEFT_TITLE}
                  control={control}
                  id="cross-sell-left-title"
                  label="Title"
                  placeholder="Enter a Title"
                  required={true}
                  error={errors.crossSell?.left?.title}
                />
              </div>
              <div className="pr-4">
                <Text
                  name={FormInputNames.CS_LEFT_URL}
                  control={control}
                  id="cross-sell-left-url"
                  label="URL"
                  placeholder="Enter a URL"
                  required={true}
                  error={errors.crossSell?.left?.url}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="w-1/2">
          <h3 className="inline text-xl text-gray-900">Right</h3>
          <MaterialCheckbox checked={isRightCrossSellOpen} onChange={handleRightCrossSellOpen} />
          {isRightCrossSellOpen ? (
            <>
              <Text
                name={FormInputNames.CS_RIGHT_BG_IMG_URL}
                control={control}
                id="cross-sell-right-bg-img-url"
                label="Image URL"
                placeholder="Enter an Image URL"
                required={true}
                error={errors.crossSell?.right?.backgroundImageUrl}
              />
              <Select
                name={FormInputNames.CS_RIGHT_BUTTON_CLASS}
                control={control}
                id="cross-sell-right-button-class"
                label="Button Class"
                options={ButtonClassOptions}
                required={true}
                error={errors.crossSell?.right?.buttonClass}
              />
              <Text
                name={FormInputNames.CS_RIGHT_LINK_TEXT}
                control={control}
                id="cross-sell-right-link-text"
                label="Link Text"
                placeholder="Enter Link Text"
                required={true}
                error={errors.crossSell?.right?.linkText}
              />
              <Text
                name={FormInputNames.CS_RIGHT_TITLE}
                control={control}
                id="cross-sell-right-title"
                label="Title"
                placeholder="Enter a Title"
                required={true}
                error={errors.crossSell?.right?.title}
              />
              <Text
                name={FormInputNames.CS_RIGHT_URL}
                control={control}
                id="cross-sell-right-url"
                label="URL"
                placeholder="Enter a URL"
                required={true}
                error={errors.crossSell?.right?.url}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

const SubmitButton = (): JSX.Element => {
  const isUpdating = useSelector(selectIsUpdating);

  const { control } = useFormContext<CollectionViewFormValidation>();
  const { isValid } = useFormState<CollectionViewFormValidation>({ control });

  return (
    <div className="flex justify-end p-4">
      <Button
        message="Save"
        type={ButtonType.PRIMARY}
        size={ButtonSize.MEDIUM}
        textSize={ButtonTextSize.MEDIUM}
        isSubmit={true}
        disabled={!isValid || isUpdating}
      />
    </div>
  );
};

const CollectionViewForm = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const { collection, isUpdating } = useSelector(collectionViewFormSelector);

  const [isLeftCrossSellOpen, setLeftCrossSellOpen] = useState(!!collection?.view?.crossSell?.left);
  const [isRightCrossSellOpen, setRightCrossSellOpen] = useState(!!collection?.view?.crossSell?.right);

  const view = collection?.view;

  const formMethods = useForm<CollectionViewFormValidation>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(FormSchema) as Resolver<CollectionViewFormValidation>,
    defaultValues: {
      ...{
        crossSell: {
          // the select component wants default values otherwise it throws a warning
          left: {
            buttonClass: CrossSellButtonClass.AB_GREEN,
          },
          right: {
            buttonClass: CrossSellButtonClass.AB_GREEN,
          },
        },
      },
      ...view,
    },
  });

  if (isUpdating) {
    return (
      <div data-testid="collection-view-loading" className="flex flex-row flex-wrap pt-6 justify-center">
        <CircularProgress />
      </div>
    );
  }

  const handleLeftCrossSellOpen = () => {
    setLeftCrossSellOpen(!isLeftCrossSellOpen);
    formMethods.reset(formMethods.getValues(), { keepErrors: true });
  };

  const handleRightCrossSellOpen = () => {
    setRightCrossSellOpen(!isRightCrossSellOpen);
    formMethods.reset(formMethods.getValues(), { keepErrors: true });
  };

  const onSubmit = (collectionView: CollectionView) => {
    if (!isLeftCrossSellOpen) {
      delete collectionView.crossSell?.left;
    }
    if (!isRightCrossSellOpen) {
      delete collectionView.crossSell?.right;
    }
    if (!isRightCrossSellOpen && !isLeftCrossSellOpen) {
      delete collectionView.crossSell;
    }

    void dispatch(updateCollectionView(collectionView));
  };

  return (
    <FormProvider {...formMethods}>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <MainInputs />
        <CrossSellInputs
          isLeftCrossSellOpen={isLeftCrossSellOpen}
          isRightCrossSellOpen={isRightCrossSellOpen}
          handleLeftCrossSellOpen={handleLeftCrossSellOpen}
          handleRightCrossSellOpen={handleRightCrossSellOpen}
        />
        <SubmitButton />
      </form>
    </FormProvider>
  );
};
export default CollectionViewForm;

const collectionViewFormSelector = createSelector([selectCollection, selectIsUpdating], (collection, isUpdating) => ({
  collection,
  isUpdating,
}));
