import { ThemeProvider } from '@mui/material/styles';
import LogRocket from 'logrocket';
import LogrocketFuzzySearch from 'logrocket-fuzzy-search-sanitizer';
import setupLogRocketReact from 'logrocket-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import { LOG_ROCKET_APP_ID, LOG_ROCKET_ENABLED } from './common/config/logRocket';
import { store } from './common/store/store';
import './index.css';
import muiTheme from './muiTheme';
import './tailwind.output.css';

const privateLogRocketFields = [
  'email',
  'password',
  'username',
  'login',
  'firstName',
  'lastName',
  'profileImage',
  'trustedIps',
  'tokens',
  'paymentEligibility',
  'userProvidedData',
  'address',
  'company',
  'phone',
  'bio',
  'voluntarySelfIdentification',
  'country',
  'searchableFields',
  'portfolioUrls',
  'companyName',
  'companyOrFreelancer',
  'publisher',
  'publisherPro',
  'artistPro',
  'alias',
  'youtubeChannelIds',
  'raceEthnicity',
  'genderIdentity',
  'physicalAbility',
  'about',
  'personalWebsiteUrl',
  'facebookProfileUrl',
  'linkedinProfileUrl',
  'twitterProfileUrl',
];

if (LOG_ROCKET_ENABLED) {
  const { requestSanitizer, responseSanitizer } = LogrocketFuzzySearch.setup(privateLogRocketFields);
  LogRocket.init(LOG_ROCKET_APP_ID, {
    console: { isEnabled: false },
    dom: {
      inputSanitizer: true,
    },
    network: {
      // logrocket allows for an optional body field in the request, but the sanitizer library has it as required
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      requestSanitizer,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      responseSanitizer,
    },
    shouldCaptureIP: false,
  });
  setupLogRocketReact(LogRocket);
}

declare global {
  interface Window {
    SB_CONFIG: {
      features: {
        [key: string]: boolean;
      };
    };
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={muiTheme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
