import clone from 'lodash/clone';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import min from 'lodash/min';
import omit from 'lodash/omit';
import round from 'lodash/round';

import { checkRequiredFieldsByContentType } from '../../../common/util/util';
import { StockItemMapInterface } from '../../store/batch/batch.state';
import { ReleaseStatuses, ReviewStatuses, StockItemType } from '../../store/batch/batch.types';

export function getValidPendingStockItems(visibleStockItemIds: string[], byId: StockItemMapInterface): string[] {
  return visibleStockItemIds.filter((id: string) => {
    const stockItem = byId[id];
    return stockItem.review.status === ReviewStatuses.pending && isStockItemValid(stockItem, false);
  });
}

export function getStockItemsToApprove(visibleStockItemIds: string[], byId: StockItemMapInterface): string[] {
  return visibleStockItemIds.filter((id: string) => {
    const stockItem = byId[id];
    return checkRequiredFieldsByContentType(stockItem);
  });
}

export function getStockItemsToAutoApprove(
  numStockItemsToApprove: number,
  numVisibleStockItem: number,
  hiddenStockItemIds: string[],
  stockItemsById: StockItemMapInterface,
  contributorAcceptanceRatio = 1
): string[] {
  const pageAcceptanceRatio = round(numStockItemsToApprove / numVisibleStockItem, 3);
  const shouldApproveHiddenItems = pageAcceptanceRatio >= (min([0.95, contributorAcceptanceRatio]) || 0.95);
  if (!shouldApproveHiddenItems) {
    return [];
  }
  // NOTE: This logic is entirely from the Contributor Portal. Based on current batching, we should never have hidden
  // ids, however we do want to handle gracefully if we run into them by only approving content that meets our
  // standards and logging a warning
  return filter(hiddenStockItemIds, (id) => isHiddenStockItemEligibleForApproval(stockItemsById[id]));
}

export function isStockItemValid(stockItem: StockItemType, ignoreCategories: boolean): boolean {
  const validationFailure = get(stockItem, 'validationFailure', {});
  let validationFailureObj = clone(validationFailure);
  if (ignoreCategories) {
    validationFailureObj = omit(validationFailureObj, 'categories');
  }
  return isEmpty(validationFailureObj);
}

export function isHiddenStockItemEligibleForApproval(stockItem: StockItemType): boolean {
  // Check not template
  // Check if it has any release, ensure all are valid
  // Check does not contain inappropriate language
  // Check does not contain a validation failure
  // Check has visual standards and values

  const isPending = get(stockItem, 'review.status') === ReviewStatuses.pending;
  const isTemplate = get(stockItem, 'content.type') === 'template';

  let releasesValid = true;
  if (stockItem.releases) {
    stockItem.releases.forEach((release) => {
      if (!(release.review && release.review.status === ReleaseStatuses.approved)) {
        releasesValid = false;
      }
    });
  }

  const isInappropriate = stockItem.meta.isSensitiveContent;
  const isValid = isStockItemValid(stockItem, true);
  const meetsDemand = checkRequiredFieldsByContentType(stockItem);

  return isPending && isValid && releasesValid && !isTemplate && !isInappropriate && meetsDemand;
}
