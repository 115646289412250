import { yupResolver } from '@hookform/resolvers/yup';
import { Alert as MuiAlert, TabContext, TabPanel } from '@mui/lab';
import { Box, Snackbar, Tab, Tabs } from '@mui/material';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import {
  FormProvider,
  Resolver,
  useFieldArray,
  useForm,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import Alert, { AlertType } from '../../../../../common/components/alert/Alert';
import Button, { ButtonSize, ButtonTextSize, ButtonType } from '../../../../../common/components/button/Button';
import { ContentClass } from '../../../../../common/enums/ContentClasses';
import { useDispatch } from '../../../../../common/store/store';
import { ContentSuggestionRequest } from '../../../../api/CollectionApiSuggestion.types';
import Checkbox from '../../../../components/form/Checkbox';
import Select from '../../../../components/form/Select';
import Text from '../../../../components/form/Text';
import CollectionStatus from '../../../../enums/CollectionStatus';
import { requestCollectionContentSuggestions } from '../../../../store/CollectionEdit/CollectionEdit.reducer';
import { selectCollection } from '../../../../store/CollectionEdit/CollectionEdit.selectors';
import {
  AudioContentType,
  CollectionRequestType,
  FormInputNames,
  FormSchema,
  ImageContentType,
  SuggestionFormValidation,
  ValidContentType,
  VideoContentType,
} from '../CollectionCurationTab.types';
import SuggestedContent from './automatedSuggestion/SuggestedContent';

const MainFormInputs = (): JSX.Element => {
  const { control } = useFormContext<SuggestionFormValidation>();
  const { errors } = useFormState<SuggestionFormValidation>({ control });

  return (
    <>
      <Text
        name={FormInputNames.MAX_ITEMS_TO_RETURN}
        control={control}
        id="max-items-to-return"
        label="Maximum Number of Items"
        placeholder="Enter the maximum number of items"
        error={errors[FormInputNames.MAX_ITEMS_TO_RETURN]}
        required={true}
      />

      <Checkbox
        name={FormInputNames.CAN_CONTAIN_REPEATS}
        control={control}
        id="can-contain-repeats"
        label="Include Repeats"
        error={errors[FormInputNames.CAN_CONTAIN_REPEATS]}
      />
    </>
  );
};

const KeywordFormInputs = (props: { suggestionClass: ContentClass }): JSX.Element => {
  const { suggestionClass } = props;
  const [keywordIndex, setKeywordIndex] = useState<number>(0);

  const { control } = useFormContext<SuggestionFormValidation>();
  const { fields, append, remove } = useFieldArray<SuggestionFormValidation>({
    control,
    name: FormInputNames.INPUT_VALUES,
  });

  const { errors } = useFormState<SuggestionFormValidation>({ control });
  const watchKeywordFields = useWatch<SuggestionFormValidation>({ control, name: FormInputNames.INPUT_VALUES });
  const defaultType =
    suggestionClass === ContentClass.video
      ? ValidContentType.footage
      : suggestionClass === ContentClass.audio
      ? ValidContentType.music
      : ValidContentType.photo;
  const selectOptions =
    suggestionClass === ContentClass.video
      ? VideoContentType
      : suggestionClass === ContentClass.audio
      ? AudioContentType
      : ImageContentType;

  return (
    <>
      <div className="flex flex-wrap pt-2">
        <h3 className="text-xl pb-4 w-full">Keywords</h3>

        {fields.map((item, index) => {
          const keywordValid = watchKeywordFields[index]?.[FormInputNames.KEYWORD];

          const keywordClassNames = classnames('px-4', 'py-2', 'text-white', 'rounded-l', 'hover:bg-gray-700', {
            'bg-gray-700': index === keywordIndex,
            'bg-gray-800': index !== keywordIndex,
            'bg-red-500': !keywordValid,
          });

          return (
            <div key={item.id} className="pr-2 pb-4 cursor-pointer">
              <span className={keywordClassNames} onClick={() => setKeywordIndex(index)}>
                {watchKeywordFields[index]?.[FormInputNames.KEYWORD] || 'MISSING KEYWORD'}
              </span>
              <span
                className="px-4 py-2 bg-gray-800 hover:bg-gray-700 text-white rounded-r"
                onClick={() => {
                  remove(index);
                  if (index === keywordIndex) {
                    setKeywordIndex(index === 0 ? 0 : index - 1);
                  } else {
                    index < keywordIndex && setKeywordIndex(keywordIndex - 1);
                  }
                }}
              >
                x
              </span>
            </div>
          );
        })}

        <div className="cursor-pointer pr-4 pb-2">
          <span
            className="px-4 py-2 bg-gray-800 hover:bg-gray-700 text-white rounded"
            onClick={() => {
              append({
                keyword: '',
                contentClass: suggestionClass,
                contentType: defaultType,
                isMandatory: false,
                weight: 1,
              });
              setKeywordIndex(fields.length);
            }}
          >
            +
          </span>
        </div>
      </div>

      {/*for some reason we need to conditionally render the form for the currently selected keyword by mapping over the fields*/}
      {/*and matching the index. if we don't then it won't stay synced with the current keywordIndex and won't re-render when*/}
      {/*a different keyword is selected*/}
      {fields.map((item, index) => {
        return (
          <div key={item.id}>
            {index === keywordIndex && (
              <div className="flex flex-row">
                <div className="flex-grow pr-16">
                  <Text
                    name={`${FormInputNames.INPUT_VALUES}.${keywordIndex}.${FormInputNames.KEYWORD}`}
                    control={control}
                    id={`input-values-${keywordIndex}-${FormInputNames.KEYWORD}`}
                    label="Keyword"
                    placeholder="Enter a Keyword"
                    error={errors[FormInputNames.INPUT_VALUES]?.[keywordIndex]?.[FormInputNames.KEYWORD]}
                  />
                </div>

                <div className="flex-grow pr-16">
                  <Select
                    name={`${FormInputNames.INPUT_VALUES}.${keywordIndex}.${FormInputNames.CONTENT_CLASS}`}
                    control={control}
                    options={{ [suggestionClass]: suggestionClass }}
                    id={`input-values-${keywordIndex}-${FormInputNames.CONTENT_CLASS}`}
                    label="Content Class"
                    error={errors[FormInputNames.INPUT_VALUES]?.[keywordIndex]?.[FormInputNames.CONTENT_CLASS]}
                    disabled={true}
                  />
                </div>

                <div className="flex-grow pr-16">
                  <Select
                    name={`${FormInputNames.INPUT_VALUES}.${keywordIndex}.${FormInputNames.CONTENT_TYPE}`}
                    control={control}
                    options={selectOptions}
                    id={`input-values-${keywordIndex}-${FormInputNames.CONTENT_TYPE}`}
                    label="Content Type"
                    error={errors[FormInputNames.INPUT_VALUES]?.[keywordIndex]?.[FormInputNames.CONTENT_TYPE]}
                  />
                </div>

                <Checkbox
                  name={`${FormInputNames.INPUT_VALUES}.${keywordIndex}.${FormInputNames.IS_MANDATORY}`}
                  control={control}
                  id={`input-values-${keywordIndex}-${FormInputNames.IS_MANDATORY}`}
                  label="Is Mandatory"
                  error={errors[FormInputNames.INPUT_VALUES]?.[keywordIndex]?.[FormInputNames.IS_MANDATORY]}
                />
              </div>
            )}
          </div>
        );
      })}

      {!fields.length && (
        <div className="w-full pt-4">
          <Alert type={AlertType.ERROR} title="Please add keyword data before requesting content" />
        </div>
      )}
    </>
  );
};

const SubmitButton = (): JSX.Element => {
  const { control } = useFormContext<SuggestionFormValidation>();
  const { isValid } = useFormState<SuggestionFormValidation>({ control });

  return (
    <div className="py-4">
      <Button
        message="Request Content"
        type={ButtonType.PRIMARY_YELLOW}
        size={ButtonSize.MEDIUM}
        textSize={ButtonTextSize.SMALL}
        isSubmit={true}
        disabled={!isValid}
      />
    </div>
  );
};

const CollectionCurationAutomatedSuggestions = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const collection = useSelector(selectCollection);
  if (!collection) return null;

  const [snackOpen, setSnackOpen] = React.useState(false);
  const [suggestionClass, updateSuggestionClass] = useState(
    (window.sessionStorage.getItem('contentSuggestionClassTabValue') as ContentClass) || collection.classes[0]
  );

  const request: ContentSuggestionRequest = collection?.suggestion?.[suggestionClass]?.content?.request || {
    maxItemsToReturn: 10,
    startingIndex: 0,
    canContainRepeats: false,
    inputValues: [],
    requestType: CollectionRequestType.CONTENT_FROM_KEYWORDS,
    requestId: '',
    requestDate: new Date(),
  };
  const formMethods = useForm<SuggestionFormValidation>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(FormSchema) as Resolver<SuggestionFormValidation>,
    defaultValues: {
      ...{
        [FormInputNames.CAN_CONTAIN_REPEATS]: false,
        [FormInputNames.INPUT_VALUES]: [],
      },
      ...request,
    },
  });

  useEffect(() => {
    window.sessionStorage.setItem('contentSuggestionClassTabValue', suggestionClass);
  }, [suggestionClass]);

  useEffect(() => {
    formMethods.reset({
      ...{
        [FormInputNames.CAN_CONTAIN_REPEATS]: false,
        [FormInputNames.INPUT_VALUES]: [],
      },
      ...request,
    });
  }, [suggestionClass]);

  const handleTabChange = (event: React.ChangeEvent<unknown>, newTabValue: ContentClass) => {
    updateSuggestionClass(newTabValue);
  };

  const onSubmit = (suggestionData: SuggestionFormValidation): void => {
    const suggestion = {
      contentClassForSuggestion: suggestionClass,
      suggestion: {
        ...collection.suggestion,
        [suggestionClass]: {
          content: {
            ...collection.suggestion?.[suggestionClass]?.content,
            request: {
              ...suggestionData,
              requestDate: new Date(),
              requestType: CollectionRequestType.CONTENT_FROM_KEYWORDS,
              requestId: uuidv4(),
              startingIndex: 0,
            },
          },
        },
      },
    };
    void dispatch(requestCollectionContentSuggestions({ id: collection._id, suggestion }));
    setSnackOpen(true);
  };

  const preventEnter = (e: React.KeyboardEvent<HTMLFormElement>) => e.key === 'Enter' && e.preventDefault();

  return (
    <>
      {collection.status === CollectionStatus.PUBLISHED ? (
        <div className="py-6">
          <Alert type={AlertType.WARNING} title="Requesting suggestions disabled for published collections." />
        </div>
      ) : (
        <>
          <div className="pt-4 w-30">
            <TabContext value={suggestionClass}>
              <Tabs value={suggestionClass} onChange={handleTabChange} aria-label="Content Class Toggle">
                {collection.classes.map((contentClass, key) => (
                  <Tab key={key} label={contentClass} value={contentClass} />
                ))}
              </Tabs>
              <Box className="border-2 rounded border-slate-200">
                {collection.classes.map((contentClass, key) => (
                  <TabPanel key={key} value={contentClass}>
                    {(collection.suggestion?.[suggestionClass]?.content?.pendingContentIds?.length || 0) > 0 ? (
                      <div className="py-6">
                        <Alert
                          type={AlertType.INFO}
                          title="Please finish reviewing the pending suggestions before making a new request."
                        />
                      </div>
                    ) : (
                      <FormProvider {...formMethods}>
                        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                        <form onSubmit={formMethods.handleSubmit(onSubmit)} onKeyDown={preventEnter}>
                          <fieldset
                            className="flex flex-row flex-wrap mt-4"
                            disabled={[CollectionStatus.PUBLISHED, CollectionStatus.ARCHIVED].includes(
                              collection.status
                            )}
                          >
                            <div className="w-1/4 pr-16">
                              <MainFormInputs />
                            </div>
                            <div className="w-3/4 pl-16">
                              <KeywordFormInputs suggestionClass={suggestionClass} />
                            </div>
                            <div className="w-full flex justify-end">
                              <SubmitButton />
                              <Snackbar
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                                open={snackOpen}
                                autoHideDuration={6000}
                                onClose={() => setSnackOpen(false)}
                              >
                                <MuiAlert onClose={() => setSnackOpen(false)} severity="success">
                                  Suggestions requested
                                </MuiAlert>
                              </Snackbar>
                            </div>
                          </fieldset>
                        </form>
                      </FormProvider>
                    )}
                    <SuggestedContent contentClass={suggestionClass} />
                  </TabPanel>
                ))}
              </Box>
            </TabContext>
          </div>
        </>
      )}
    </>
  );
};
export default CollectionCurationAutomatedSuggestions;
