import { AnyAction, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import LogRocket from 'logrocket';
import { useDispatch as reduxUseDispatch } from 'react-redux';

import { collectionToolReducer } from '../../collection-tool/store/CollectionTool.reducer';
import { reviewReducer } from '../../review-app/store/review.reducer';
import { LOG_ROCKET_ENABLED } from '../config/logRocket';
import { reducer as toastsReducer } from './toasts/toasts.reducer';
import userReducer from './user/user.reducer';

const allReducers = {
  user: userReducer,
  collectionTool: collectionToolReducer,
  review: reviewReducer,
  toasts: toastsReducer,
};

export const rootReducer = combineReducers(allReducers);

export const store = configureStore({
  reducer: allReducers,
  middleware: (getDefaultMiddleware) => {
    if (LOG_ROCKET_ENABLED) {
      return getDefaultMiddleware().concat(LogRocket.reduxMiddleware());
    }
    return getDefaultMiddleware();
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
export type AppDispatch = typeof store.dispatch;

export const useDispatch = () => reduxUseDispatch<AppDispatch>();

// @see: @reduxjs/toolkit/dist/typings.d.ts # AsyncThunkConfig
export interface AsyncThunkConfig {
  state: RootState;
  dispatch: AppDispatch;
}
