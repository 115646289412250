import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialState, ToastTypes } from './toasts.state';

const toastsSlice = createSlice({
  name: 'toasts',
  initialState,
  reducers: {
    showErrorToast: (state, action: PayloadAction<string>) => {
      state.toastType = ToastTypes.error;
      state.message = action.payload;
    },
    showSuccessToast: (state, action: PayloadAction<string>) => {
      state.toastType = ToastTypes.success;
      state.message = action.payload;
    },
    resetToasts: (state) => {
      state.toastType = ToastTypes.none;
      state.message = '';
    },
  },
});

const { actions, reducer } = toastsSlice;
const { showErrorToast, showSuccessToast, resetToasts } = actions;

export { reducer, showErrorToast, showSuccessToast, resetToasts };
