import React from 'react';

import { ReactComponent as TikIcon } from '../../assets/icons/tikmark.svg';

export type OwnProps = {
  isChecked: boolean;
  disabled?: boolean;
};

export default function CheckBoxIcon({ isChecked, disabled }: OwnProps): JSX.Element {
  const getCheckboxClasses = () => {
    if (disabled) {
      if (isChecked) {
        return 'bg-gray-300 border-gray-300';
      } else {
        return 'bg-white border-gray-300';
      }
    }
    if (isChecked) {
      return 'bg-blue-500 border-blue-500';
    }
    return 'bg-white border-gray-600';
  };

  return (
    <div className="relative">
      <div className={`rounded-sm border w-4 h-4 ${getCheckboxClasses()}`} />
      {isChecked && <TikIcon className="absolute m-auto p-1 left-0 bottom-0 right-0 top-0 w-4 h-4" />}
    </div>
  );
}
