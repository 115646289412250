import React, { useState } from 'react';
import { Redirect, Route } from 'react-router-dom';

import SideNav from '../containers/sideNav/SideNav';
import { ContentType } from '../enums/ContentTypes';
import Roles from '../enums/Roles';
import { ROUTES, PRIVATE_ROUTES } from '../routes';
import { hasRequiredValue } from '../util/util';

type OwnProps = {
  Component: React.ElementType;
  isLoggedIn: boolean;
  path: string;
  exact: boolean;
  userRoles: Roles[];
  requiredRoles: Roles[];
  reviewerPermissions?: ContentType[];
  requiredPermissions?: ContentType[];
};

function PrivateRoute({
  Component,
  isLoggedIn,
  requiredRoles,
  userRoles,
  requiredPermissions,
  reviewerPermissions,
  path,
  ...rest
}: OwnProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  if (!isLoggedIn) {
    return <Redirect to={ROUTES.LOGIN} />;
  }

  if (
    !hasRequiredValue(userRoles, requiredRoles) ||
    (requiredPermissions?.length && !hasRequiredValue(reviewerPermissions, requiredPermissions))
  ) {
    return <Redirect to={PRIVATE_ROUTES.HOME.path} />;
  }

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <SideNav isOpen={isOpen} setIsOpen={setIsOpen} currentPath={path} />
      <div className="flex-1 overflow-auto focus:outline-none" tabIndex={0}>
        <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200">
          <button
            className="px-4 border-r border-cool-gray-200 text-cool-gray-400 focus:outline-none focus:bg-cool-gray-100 focus:text-cool-gray-600"
            aria-label="Open sidebar"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
          >
            <svg
              className="h-6 w-6 transition ease-in-out duration-150"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
            </svg>
          </button>
        </div>
        <main className="container mx-auto focus:outline-none my-4" tabIndex={0}>
          <Route path={path} {...rest} render={(props) => <Component {...props} />} />
        </main>
      </div>
    </div>
  );
}

export default PrivateRoute;
