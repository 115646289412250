import { FormGroup } from '@mui/material';
import React from 'react';

import SingleCheckBox from '../singleCheckBox/SingleCheckBox';

type Option = {
  label: string;
  value: string;
  isChecked: boolean;
};

export type OwnProps = {
  id: string;
  disabled?: boolean;
  options: Option[];
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

function CheckBoxGroup(props: OwnProps): JSX.Element {
  return (
    <FormGroup row>
      {props.options.map((option) => (
        <div className="bg-gray-200 mr-2 mb-2 ml-0 pl-4 rounded" key={`${props.id}-${option.value}`}>
          <SingleCheckBox
            isChecked={option.isChecked}
            label={option.label}
            handleChange={props.handleChange}
            disabled={props.disabled}
            value={option.value}
          />
        </div>
      ))}
    </FormGroup>
  );
}

export default CheckBoxGroup;
