import React from 'react';

import { formatTime } from '../../../common/util/util';

type TimeDisplayProps = {
  timeInMs: number;
};
const TimeDisplay = ({ timeInMs }: TimeDisplayProps) => (
  <p className="font-semibold text-xs leading-4 text-gray-700">{formatTime(timeInMs)}</p>
);

type TimeBarProps = {
  currentTimeMs: number;
  durationMs: number;
};

export const TimeBar = ({ currentTimeMs, durationMs }: TimeBarProps): JSX.Element => {
  return (
    <div className="flex justify-between mt-1">
      <TimeDisplay timeInMs={currentTimeMs} />
      <TimeDisplay timeInMs={durationMs} />
    </div>
  );
};
