import { ContentClass } from '../../../common/enums/ContentClasses';
import { AppThunk } from '../../../common/store/store';
import { showErrorToast } from '../../../common/store/toasts/toasts.reducer';
import { logger } from '../../../common/util/logger';
import { GroupsApi } from '../../api/GroupsApi';
import { groupsSlice } from './groups.reducer';

const { groupsSucceeded } = groupsSlice.actions;
export const makeGroupsRequest =
  (contentClass: ContentClass): AppThunk =>
  async (dispatch) => {
    try {
      const api = new GroupsApi();
      const payload = await api.getGroups(contentClass);
      dispatch(groupsSucceeded({ ...payload, contentClass }));
    } catch (err) {
      dispatch(showErrorToast('Error requesting categories'));
      logger.error('Error requesting categories', err);
    }
  };
