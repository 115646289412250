import { Select, SelectProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

const SelectInput = (props: SelectProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Select
      {...props}
      sx={{
        marginTop: theme.spacing(3),
        borderRadius: 4,
        position: 'relative',
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
          borderRadius: 4,
          borderColor: '#80bdff',
          boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
      }}
    />
  );
};
export default SelectInput;
