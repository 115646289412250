import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Chip, IconButton } from '@mui/material';
import { ChartOptions, registerables, registry } from 'chart.js';
import { isEmpty, sum } from 'lodash';
import React, { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import { ContentClass } from '../../../common/enums/ContentClasses';
import { ContentType } from '../../../common/enums/ContentTypes';
import { useDispatch } from '../../../common/store/store';
import tailwindTheme from '../../../common/util/theme';
import { makeReviewBatchStatsRequest } from '../../store/reviewerBatchStats/reviewerBatchStats.actions';
import { selectContentTypeReviewerBatchStats } from '../../store/reviewerBatchStats/reviewerBatchStats.selectors';
import { ReviewerBatchStatsType } from '../../store/reviewerBatchStats/reviewerBatchStats.types';

// required so that babel will not tree-shake chart.js components that we need to use
// see https://github.com/sgratzl/chartjs-chart-wordcloud/issues/4
// 08-19-2022 - Clark
registry.add(...(registerables || []));

type Dataset = {
  label: string;
  data: number[];
  backgroundColor: string;
};

type GraphData = {
  labels: string[];
  datasets: Dataset[];
};

type ReviewerBatchContentClass = ContentClass.video | ContentType.STATS_TEMPLATES | ContentClass.audio;

const VISIBLE_DATASET_LENGTH = 14;
const DATASET_INCREMENT = 13;

const firstColor = tailwindTheme.colors.blue[400];
const secondColor = tailwindTheme.colors.yellow[600];
const thirdColor = tailwindTheme.colors.pink[400];

const colorArray = [firstColor, secondColor, thirdColor];

function calculateTotal(currentGraph: GraphData): number {
  const datasets = currentGraph.datasets;
  return datasets.reduce((acc, dataset) => {
    return acc + sum(dataset.data);
  }, 0);
}

function formatGraphDate(batchDate: string): string {
  const stringDate: string = batchDate.split('T')[0];
  const dateObj: Date = new Date(stringDate + 'T00:00:00');
  return new Intl.DateTimeFormat('en-US').format(dateObj);
}

function convertDataToGraphStats(
  contentTypeReviewerBatchStats: ReviewerBatchStatsType,
  contentClass: ReviewerBatchContentClass,
  startIndex: number,
  endIndex: number,
  colorArray: string[]
): GraphData {
  const data = contentTypeReviewerBatchStats[contentClass];
  const keys = Object.keys(data);
  const batchDates = data[keys[0]]
    .slice(startIndex, endIndex + 1)
    .map((dateCounts: { date: string }) => formatGraphDate(dateCounts.date));
  const datasets = keys.map((key, index) => {
    const batchCounts = data[key]
      .slice(startIndex, endIndex + 1)
      .map((dateCounts: { count: number }) => dateCounts.count);
    const formattedKey = key.charAt(0).toUpperCase() + key.slice(1);
    return {
      label: formattedKey,
      data: batchCounts,
      backgroundColor: colorArray[index],
    };
  });

  return {
    labels: batchDates,
    datasets: datasets,
  };
}

function ReviewerBatchStatsChart(): JSX.Element {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(makeReviewBatchStatsRequest());
  }, [dispatch]);

  const contentTypeReviewerBatchStats = useSelector(selectContentTypeReviewerBatchStats);

  const [contentClass, setContentClass] = React.useState<ReviewerBatchContentClass>(ContentClass.video);
  const [currentGraph, setCurrentGraph] = React.useState<GraphData>({ labels: [], datasets: [] });
  const [startIndex, setStartIndex] = React.useState(0);
  const [endIndex, setEndIndex] = React.useState(DATASET_INCREMENT);
  const [currentTotalCount, setCurrentTotalCount] = React.useState(0);

  const activeBorderClasses = 'font-bold border-b-2 border-blue-600';
  const inactiveBorderClasses = 'border-b-2 border-gray-400';

  const videoBorderClasses = contentClass === ContentClass.video ? activeBorderClasses : inactiveBorderClasses;
  const audioBorderClasses = contentClass === ContentClass.audio ? activeBorderClasses : inactiveBorderClasses;
  const templatesBorderClasses =
    contentClass === ContentType.STATS_TEMPLATES ? activeBorderClasses : inactiveBorderClasses;

  const options: ChartOptions<'bar'> = {
    plugins: {
      legend: {
        align: 'end',
        labels: {
          usePointStyle: true,
          padding: 40,
          font: {
            family: tailwindTheme.fontFamily.sans,
            size: 16,
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 20,
      },
    },
  };
  useEffect(() => {
    if (contentTypeReviewerBatchStats && !isEmpty(contentTypeReviewerBatchStats)) {
      const firstSubContentClass = Object.keys(contentTypeReviewerBatchStats[contentClass])[0];
      const datasetDates =
        contentTypeReviewerBatchStats &&
        contentTypeReviewerBatchStats[contentClass] &&
        contentTypeReviewerBatchStats[contentClass][firstSubContentClass]
          ? contentTypeReviewerBatchStats[contentClass][firstSubContentClass]
          : [];
      const newEndIndex = datasetDates.length - 1;
      const newStartIndex = Math.max(0, newEndIndex - DATASET_INCREMENT);
      setStartIndex(newStartIndex);
      setEndIndex(newEndIndex);
    }
  }, [contentTypeReviewerBatchStats, contentClass]);

  useEffect(() => {
    if (contentTypeReviewerBatchStats && !isEmpty(contentTypeReviewerBatchStats)) {
      const graphData = convertDataToGraphStats(
        contentTypeReviewerBatchStats,
        contentClass,
        startIndex,
        endIndex,
        colorArray
      );
      setCurrentGraph(graphData);
    }
  }, [contentClass, startIndex, endIndex, contentTypeReviewerBatchStats]);

  useEffect(() => {
    if (!isEmpty(currentGraph)) {
      const newCurrentTotal = calculateTotal(currentGraph);
      setCurrentTotalCount(newCurrentTotal);
    }
  }, [currentGraph]);

  function handleContentClassChange(contentClass: ReviewerBatchContentClass) {
    setContentClass(contentClass);
  }

  const displayDateChip =
    !isEmpty(currentGraph) && currentGraph.labels.length > 0
      ? currentGraph.labels[0] + ' - ' + currentGraph.labels[currentGraph.labels.length - 1]
      : null;

  function handleBackClick() {
    const firstSubContentClass =
      contentTypeReviewerBatchStats && !isEmpty(contentTypeReviewerBatchStats)
        ? Object.keys(contentTypeReviewerBatchStats[contentClass])[0]
        : '';
    const datasetDates =
      contentTypeReviewerBatchStats &&
      contentTypeReviewerBatchStats[contentClass] &&
      contentTypeReviewerBatchStats[contentClass][firstSubContentClass]
        ? contentTypeReviewerBatchStats[contentClass][firstSubContentClass]
        : [];
    const newStartIndex = Math.max(0, startIndex - VISIBLE_DATASET_LENGTH);
    const newEndIndex = Math.min(datasetDates.length - 1, newStartIndex + DATASET_INCREMENT);

    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);
  }

  function handleForwardClick() {
    const firstSubContentClass =
      contentTypeReviewerBatchStats && !isEmpty(contentTypeReviewerBatchStats)
        ? Object.keys(contentTypeReviewerBatchStats[contentClass])[0]
        : '';
    const datasetDates =
      contentTypeReviewerBatchStats &&
      contentTypeReviewerBatchStats[contentClass] &&
      contentTypeReviewerBatchStats[contentClass][firstSubContentClass]
        ? contentTypeReviewerBatchStats[contentClass][firstSubContentClass]
        : [];
    const newEndIndex = Math.min(datasetDates.length - 1, endIndex + VISIBLE_DATASET_LENGTH);
    const newStartIndex = Math.max(0, newEndIndex - DATASET_INCREMENT);

    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);
  }

  function forwardButtonDisabled() {
    if (!isEmpty(contentTypeReviewerBatchStats)) {
      const firstSubContentClass =
        contentTypeReviewerBatchStats && !isEmpty(contentTypeReviewerBatchStats)
          ? Object.keys(contentTypeReviewerBatchStats[contentClass])[0]
          : '';
      const datasetDates =
        contentTypeReviewerBatchStats &&
        contentTypeReviewerBatchStats[contentClass] &&
        contentTypeReviewerBatchStats[contentClass][firstSubContentClass]
          ? contentTypeReviewerBatchStats[contentClass][firstSubContentClass]
          : [];
      return datasetDates ? datasetDates.length - 1 === endIndex : false;
    }

    return true;
  }

  function backButtonDisabled() {
    return 0 === startIndex;
  }

  return (
    <div className={'py-8 px-12 bg-white rounded-lg shadow-reviewChart relative'} data-testid="reviewer-stats-chart">
      <div className={'flex justify-center'}>
        <div className={'flex'}>
          <button
            className={`px-4 text-lg focus:outline-none relative border-solid ${videoBorderClasses}`}
            onClick={() => {
              handleContentClassChange(ContentClass.video);
            }}
          >
            Video
          </button>
          <button
            className={`px-4 text-lg focus:outline-none relative border-solid ${audioBorderClasses}`}
            onClick={() => {
              handleContentClassChange(ContentClass.audio);
            }}
          >
            Audio
          </button>
          <button
            className={`px-4 text-lg focus:outline-none relative border-solid ${templatesBorderClasses}`}
            onClick={() => {
              handleContentClassChange(ContentType.STATS_TEMPLATES);
            }}
          >
            Templates
          </button>
        </div>
        <div className={'flex position: absolute right-8'}>
          <IconButton
            size={'small'}
            className={'justify-items-end'}
            disabled={backButtonDisabled()}
            onClick={() => handleBackClick()}
          >
            <ChevronLeft />
          </IconButton>
          <Chip label={displayDateChip} variant="outlined" style={{ marginLeft: 'auto', borderRadius: '4px' }} />
          <IconButton
            size={'small'}
            className={'justify-items-end'}
            disabled={forwardButtonDisabled()}
            onClick={() => handleForwardClick()}
          >
            <ChevronRight />
          </IconButton>
        </div>
      </div>
      <div>
        <div className={'flex position:absolute'}>Total: {currentTotalCount}</div>
        <div>
          <Bar data={currentGraph} options={options} />
        </div>
      </div>
    </div>
  );
}

export default ReviewerBatchStatsChart;
