import React from 'react';

import EditReviewerModal from '../../../containers/manageReviewersTable/EditReviewerModal';
import ManageReviewersTable from '../../../containers/manageReviewersTable/ManageReviewersTable';

function ManageReviewers(): JSX.Element {
  return (
    <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
      <div className="text-center">
        <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
          Manage Reviewers
          <br className="xl:hidden" />
        </h2>
        <p className="italic font-extralight text-small py-4">Click on column header(s) to sort or filter.</p>
      </div>
      <EditReviewerModal />
      <ManageReviewersTable />
    </main>
  );
}

export default ManageReviewers;
