import React, { useState } from 'react';
import 'video.js/dist/video-js.css';

import { ContentType } from '../../../common/enums/ContentTypes';
import { StockItemType } from '../../store/batch/batch.types';
import './VideoPreview.scss';
import VrVideoJS from './VrVideoJs';

type OwnProps = {
  stockItem: StockItemType;
};

function VideoPreview({ stockItem }: OwnProps): JSX.Element {
  const { thumbnailUrl, previewUri, contentRequestId } = stockItem;
  const [isVrToggleActive, setIsVrToggleActive] = useState(false);
  const [isVideoPaused, setIsVideoPaused] = useState(false);
  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    poster: thumbnailUrl,
    sources: [
      {
        src: previewUri,
        type: 'video/mp4',
      },
    ],
  };

  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-12 md:col-span-12">
        <div className="viewer">
          {isVrToggleActive ? (
            <VrVideoJS options={videoJsOptions}></VrVideoJS>
          ) : (
            <div>
              <video
                data-testid="widescreen-viewer"
                poster={thumbnailUrl}
                src={previewUri}
                controls
                autoPlay
                loop
                onPause={() => setIsVideoPaused(true)}
                onPlay={() => setIsVideoPaused(false)}
                className={'w-full'}
              >
                HTML5 is required for viewing the video.
              </video>
              {contentRequestId && isVideoPaused && (
                <span
                  className={`absolute top-4 left-4 z-20 text-sm text-white font-bold rounded-md bg-pink-700 px-10 py-0.5`}
                >
                  CONTENT REQUEST
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      {stockItem.content.type === ContentType.VR_360 ? (
        <div className="col-span-12 md:col-span-6 flex flex-row">
          <button
            className={`widescreen-toggle ${isVrToggleActive ? 'text-gray-700 bg-white' : 'text-white bg-blue-700'}`}
            onClick={() => setIsVrToggleActive(false)}
          >
            <span className="px-2.5">Widescreen View</span>
          </button>
          <button
            className={`vr-toggle ${isVrToggleActive ? 'text-white bg-blue-700' : 'text-gray-700 bg-white'}`}
            onClick={() => setIsVrToggleActive(true)}
          >
            <span className="px-2.5">360 View</span>
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default VideoPreview;
