import { createSelector } from '@reduxjs/toolkit';
import React from 'react';
import { useSelector } from 'react-redux';

import { useDispatch } from '../../../../common/store/store';
import { CollectionSearchOptions } from '../../../api/CollectionApi';
import { changeIncludeMultiAsset, changeSearchOptions } from '../../../store/CollectionSearch/CollectionSearch.reducer';
import {
  selectIncludeMultiAsset,
  selectSearchOptions,
} from '../../../store/CollectionSearch/CollectionSearch.selectors';
import CollectionNameInput from '../components/CollectionNameInput';
import CollectionSortSelect from '../components/CollectionSortSelect';
import CollectionStatusSelect from '../components/CollectionStatusSelect';
import ContentClassSelect from '../components/ContentClassSelect';
import MultiAssetCheckbox from '../components/MultiAssetCheckbox';

const CollectionSearchControls = (): JSX.Element => {
  const dispatch = useDispatch();
  const { searchOptions, includeMultiAsset } = useSelector(selector);

  const updateOptions = (changes: Partial<CollectionSearchOptions>) => dispatch(changeSearchOptions(changes));

  return (
    <div className="grid grid-cols-2 gap-4">
      <div>
        <CollectionNameInput onChange={(name) => updateOptions({ name })} />
        <div className="pt-4 justify-right">
          <CollectionSortSelect
            sortBy={searchOptions.sortBy}
            sortDirection={searchOptions.sortDirection}
            onSortByChange={(sortBy) => updateOptions({ sortBy })}
            onSortDirectionChange={(sortDirection) => updateOptions({ sortDirection })}
          />
        </div>
      </div>
      <div>
        <div>
          <ContentClassSelect
            onChange={(classes) => {
              if (includeMultiAsset) {
                updateOptions({ includeClasses: classes });
              } else {
                updateOptions({ classes });
              }
            }}
            selectedClasses={searchOptions.classes || searchOptions.includeClasses || []}
          />
        </div>
        <div className="pt-4">
          <MultiAssetCheckbox
            onChange={(includeMultiAsset) => dispatch(changeIncludeMultiAsset(includeMultiAsset))}
            includeMultiAsset={includeMultiAsset}
          />
        </div>
        <div className="pt-4">
          <CollectionStatusSelect
            onChange={(status) => updateOptions({ status })}
            selectedStatus={searchOptions.status}
          />
        </div>
      </div>
    </div>
  );
};
export default CollectionSearchControls;

const selector = createSelector([selectSearchOptions, selectIncludeMultiAsset], (searchOptions, includeMultiAsset) => ({
  searchOptions,
  includeMultiAsset,
}));
