import { AppThunk } from '../../../common/store/store';
import { showErrorToast } from '../../../common/store/toasts/toasts.reducer';
import BrandValuesApi from '../../api/BrandValuesApi';
import { brandValuesSlice } from './brandValues.reducer';

const { brandValuesSucceeded } = brandValuesSlice.actions;

export const makeBrandValuesRequest = (): AppThunk => async (dispatch) => {
  try {
    const api = new BrandValuesApi();
    const payload = await api.getBrandValues();
    dispatch(brandValuesSucceeded(payload));
  } catch (error) {
    dispatch(showErrorToast('Error requesting brand values'));
  }
};
