import { ConnectDragSource, ConnectDropTarget, useDrag, useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';

import { ContentClass } from '../../../common/enums/ContentClasses';
import { updateReorderPreview } from '../../store/CollectionEdit/CollectionEdit.reducer';

const dragType = 'CONTENT_COLLECTION_CURATION';

interface DragObject {
  contentId: number;
}

export const useDragCollectionContent = (
  contentId: number,
  originalIndex: number,
  contentClass: ContentClass
): {
  drag: ConnectDragSource;
  isDragging: boolean;
} => {
  const dispatch = useDispatch();
  const [{ isDragging }, drag] = useDrag<DragObject, unknown, { isDragging: boolean }>(
    () => ({
      type: dragType,
      item: { contentId },
      collect: (monitor) => ({ isDragging: monitor.isDragging() }),
      end: (item, monitor) => {
        const { contentId } = item;
        if (!monitor.didDrop()) {
          // if dragging ended and the item was not dropped on a valid drop surface, restore location before dragging started
          dispatch(updateReorderPreview({ contentId, newIndex: originalIndex, contentClass }));
        }
      },
    }),
    [contentId, originalIndex]
  );
  return { drag, isDragging };
};

export const useDropCollectionContent = (
  currentContentId: number,
  dropIndex: number,
  contentClass: ContentClass
): ConnectDropTarget => {
  const dispatch = useDispatch();
  const [, drop] = useDrop<DragObject>(
    () => ({
      accept: dragType,
      hover: (item) => {
        if (currentContentId !== item.contentId) {
          dispatch(updateReorderPreview({ contentId: item.contentId, newIndex: dropIndex, contentClass }));
        }
      },
    }),
    [dropIndex, dispatch]
  );
  return drop;
};
