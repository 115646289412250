import { AppThunk } from '../../../common/store/store';
import { showErrorToast } from '../../../common/store/toasts/toasts.reducer';
import { logger } from '../../../common/util/logger';
import ReviewerBatchStatsApi from '../../api/ReviewerBatchStatsApi';
import { reviewerBatchStatsSlice } from './reviewerBatchStats.reducer';

const { getReviewBatchStatsStarted, getReviewBatchStatsSucceeded, getReviewBatchStatsFailed } =
  reviewerBatchStatsSlice.actions;

export const makeReviewBatchStatsRequest = (): AppThunk => async (dispatch) => {
  dispatch(getReviewBatchStatsStarted());

  try {
    const api = new ReviewerBatchStatsApi();
    const payload = await api.getReviewerBatchStats();
    dispatch(getReviewBatchStatsSucceeded(payload));
  } catch (error) {
    const message = 'Error retrieving reviewer batch stats';
    dispatch(getReviewBatchStatsFailed());
    dispatch(showErrorToast(message));
    logger.error(message, error);
  }
};
