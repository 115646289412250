import { ContentType } from '../../common/enums/ContentTypes';

export const ReviewPermissions = {
  [ContentType.FOOTAGE]: 'Footage',
  [ContentType.LEGACY_MOTION_BACKGROUND]: 'Animation',
  [ContentType.TEMPLATE]: 'Template',
  [ContentType.VR_360]: 'Vr360',
  [ContentType.VECTOR]: 'Vector',
  [ContentType.ILLUSTRATION]: 'Illustration',
  [ContentType.PHOTO]: 'Photo',
  [ContentType.MUSIC]: 'Music',
};
export type ReviewPermission = keyof typeof ReviewPermissions;

const reviewPermissionKeys = Object.keys(ReviewPermissions);
export const isReviewPermission = (value: string): value is ReviewPermission => reviewPermissionKeys.includes(value);
