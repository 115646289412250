import { wasabiAxios } from '../../common/api/wasabiAxios';
import { Reviewer } from '../store/manageReviewer/manageReviewer.types';

export interface GetReviewersListResponse {
  data: Reviewer[];
}

export interface UpdateReviewerPermissionsResponse {
  updateReviewerStockItemType: boolean;
}

class ManageReviewersApi {
  public getReviewerStats = async (): Promise<unknown> => {
    return wasabiAxios.get<unknown>('/review-api/v1/reviewers/stats/:reviewerUserId');
  };

  public getReviewersList = async (): Promise<GetReviewersListResponse> => {
    return (await wasabiAxios.get<GetReviewersListResponse>('/review-api/v1/reviewers/list')).data;
  };

  public updateReviewerPermissions = async (
    stockItemTypes: string[],
    userId: string
  ): Promise<UpdateReviewerPermissionsResponse> => {
    const putData = { stockItemTypes, userId };
    return (
      await wasabiAxios.put<UpdateReviewerPermissionsResponse>(
        '/review-api/v1/reviewers/permission-reviewer-stockitem-type',
        putData
      )
    ).data;
  };
}

export default ManageReviewersApi;
