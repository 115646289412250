import { createSelector } from '@reduxjs/toolkit';
import React from 'react';
import { useSelector } from 'react-redux';

import { ContentClass } from '../../../common/enums/ContentClasses';
import { ContentType } from '../../../common/enums/ContentTypes';
import { useDispatch } from '../../../common/store/store';
import AudioSingleItemEdit from '../../components/audioSingleItemEdit/AudioSingleItemEdit';
import VideoSingleItemEdit from '../../components/videoSingleItemEdit/VideoSingleItemEdit';
import { updateStockItemMeta } from '../../store/batch/batch.actions';
import {
  selectCurrentStockItem,
  selectTemplateMeta,
  selectViewedStockItems,
  selectVisibleIds,
} from '../../store/batch/batch.selectors';
import { GroupType } from '../../store/batch/batch.types';
import { selectVideoCategories } from '../../store/groups/groups.selectors';

type OwnProps = {
  contentClass: ContentClass;
  allowedContentTypes: ContentType[];
};

function SingleItemEdit({ contentClass, allowedContentTypes }: OwnProps): JSX.Element {
  const dispatch = useDispatch();

  const { currentStockItem, categories, visibleStockItemCount, viewedStockItems, templateMeta } = useSelector(selector);

  const updateMeta = (
    stockItemId: string,
    stockItemCreatorId: string,
    key: string,
    value: string[] | boolean | string | GroupType[],
    toastsEnabled = true
  ) => {
    dispatch(updateStockItemMeta(stockItemId, stockItemCreatorId, key, value, toastsEnabled));
  };

  return contentClass === ContentClass.audio ? (
    <AudioSingleItemEdit currentStockItem={currentStockItem} updateStockItemMeta={updateMeta} />
  ) : (
    <VideoSingleItemEdit
      templateMeta={templateMeta}
      currentStockItem={currentStockItem}
      categories={categories || []}
      visibleStockItemCount={visibleStockItemCount}
      hasItemBeenViewed={viewedStockItems.includes(currentStockItem._id)}
      updateStockItemMeta={updateMeta}
      categoriesDisabled={allowedContentTypes.includes(ContentType.VR_360)}
      allowedContentTypes={allowedContentTypes}
    />
  );
}
export default SingleItemEdit;

const selector = createSelector(
  [selectCurrentStockItem, selectVideoCategories, selectVisibleIds, selectViewedStockItems, selectTemplateMeta],
  (currentStockItem, categories, visibleIds, viewedStockItems, templateMeta) => ({
    currentStockItem,
    categories,
    visibleStockItemCount: visibleIds.length,
    viewedStockItems,
    templateMeta,
  })
);
