import { createSelector } from '@reduxjs/toolkit';
import { difference } from 'lodash';
import LogRocket from 'logrocket';
import React from 'react';
import { useSelector } from 'react-redux';

import Button, { ButtonSize, ButtonTextSize, ButtonType } from '../../../common/components/button/Button';
import { ContentType } from '../../../common/enums/ContentTypes';
import { useDispatch } from '../../../common/store/store';
import { approveBatch, rejectBatch } from '../../store/batch/batch.actions';
import {
  selectContributor,
  selectIsApproving,
  selectReviewBatchId,
  selectStockItems,
  selectViewedStockItems,
} from '../../store/batch/batch.selectors';
import { StockItemThumbnailData } from '../../store/batch/batch.types';
import {
  getStockItemsToApprove,
  getStockItemsToAutoApprove,
  getValidPendingStockItems,
} from './BatchActionButtons.util';

type OwnProps = {
  contentTypes: ContentType[];
  visibleStockItems: StockItemThumbnailData[];
};

function BatchActionButtons({ contentTypes, visibleStockItems }: OwnProps): JSX.Element {
  const dispatch = useDispatch();
  const {
    reviewBatchId,
    stockItemsToApprove,
    stockItemsToAutoApprove,
    stockItemsToReject,
    viewedStockItemsCount,
    visibleStockItemsCount,
    currentStockItem,
    isApproving,
  } = useSelector(selector);

  const pendingInvalidItemsCount = visibleStockItems.filter((item) => item.isInvalid && !item.isRejected).length;

  const handleApproveBatchClick = (contentTypes: ContentType[]) => {
    if (reviewBatchId) {
      // We call the approve batch function no matter what, in order to set 'dateApproved' on the batch and claim the next batch
      dispatch(approveBatch(reviewBatchId, stockItemsToApprove, stockItemsToAutoApprove, contentTypes));
      if (stockItemsToReject.length) {
        const rejectionMessage = `This footage doesn’t meet customer demand. We are accepting content with higher visual standards that represent our values.`;
        dispatch(rejectBatch(stockItemsToReject, `Doesn't meet demand`, rejectionMessage));
      }
    }
  };

  const singleItemInCollection = visibleStockItemsCount === 1;

  const itemsRemainingToViewCount = singleItemInCollection ? 0 : visibleStockItemsCount - viewedStockItemsCount;

  return (
    <div className="py-4">
      <div className="flex py-2 justify-between">
        {itemsRemainingToViewCount > 0 || pendingInvalidItemsCount > 0 ? (
          <div className="bg-white rounded-md px-3 py-1.5 text-default flex">
            To review: {itemsRemainingToViewCount}
            <div className="border-l-2 border-gray-100 pr-3 ml-3" />
            Invalid: {pendingInvalidItemsCount}
          </div>
        ) : (
          <Button
            isSubmit={false}
            type={ButtonType.PRIMARY}
            onClickAction={() => handleApproveBatchClick(contentTypes)}
            size={ButtonSize.MEDIUM}
            textSize={ButtonTextSize.MEDIUM}
            message={isApproving ? 'Approving...' : 'Batch Approve'}
            disabled={isApproving}
          />
        )}
        <a href={currentStockItem.downloadUri} target="_blank" rel="noopener noreferrer" className="mr-2">
          <Button
            isSubmit={false}
            type={ButtonType.SECONDARY}
            size={ButtonSize.MEDIUM}
            textSize={ButtonTextSize.MEDIUM}
            message="Download"
          />
        </a>
      </div>
    </div>
  );
}

export default BatchActionButtons;

const selector = createSelector(
  [selectReviewBatchId, selectStockItems, selectContributor, selectViewedStockItems, selectIsApproving],
  (reviewBatchId, stockItems, contributor, viewedStockItems, isApproving) => {
    const { byId, selectedIds, visibleIds, hiddenIds } = stockItems;

    if (hiddenIds.length) {
      LogRocket.captureMessage('Hidden ids exist', {
        extra: { autoApproveStockItemIds: JSON.stringify(hiddenIds) },
      });
    }

    const currentStockItem = byId[selectedIds[0]];
    const validStockItems = getValidPendingStockItems(visibleIds, byId);
    const stockItemsToApprove = getStockItemsToApprove(validStockItems, byId);
    const stockItemsToAutoApprove = getStockItemsToAutoApprove(
      stockItemsToApprove.length,
      visibleIds.length,
      hiddenIds,
      byId,
      contributor?.acceptanceRatio
    );

    const stockItemsToReject = difference(validStockItems, stockItemsToApprove);

    return {
      currentStockItem,
      reviewBatchId,
      visibleStockItemsCount: visibleIds.length,
      viewedStockItemsCount: viewedStockItems.length,
      stockItemsToApprove,
      stockItemsToAutoApprove,
      stockItemsToReject,
      isApproving,
    };
  }
);
