import { debounce, isEmpty } from 'lodash';
import React, { useMemo } from 'react';

interface Props {
  onChange: (name?: string) => void;
}

const CollectionNameInput = ({ onChange }: Props): JSX.Element => {
  const debouncedChange = useMemo(() => debounce(onChange, 300), [onChange]);

  return (
    <div className="flex flex-wrap">
      <label htmlFor="collection-name-search-input" className="my-auto mx-2 text-sm">
        Collection Name
      </label>
      <input
        className="form-input rounded my-auto"
        id="collection-name-search-input"
        placeholder="Partial name..."
        onChange={(event) => {
          const value = event.target.value;
          const newValue = isEmpty(value.trim()) ? undefined : value;
          debouncedChange(newValue);
        }}
      />
    </div>
  );
};
export default CollectionNameInput;
