import { TextField } from '@mui/material';
import React from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

import theme from '../../../common/util/theme';
import { FormErrorProp } from './Form.types';

interface OwnProps<T> extends UseControllerProps<T> {
  id: string;
  label: string;
  placeholder?: string;
  rows?: number;
  disabled?: boolean;
  required?: boolean;
}

function Text<T>(props: OwnProps<T> & FormErrorProp): JSX.Element {
  const { name, control, id, label, placeholder, rows, error, disabled, required } = props;

  const {
    field: { onChange, onBlur, value },
  } = useController({ name, control });

  return (
    <div className="flex py-4">
      <TextField
        fullWidth
        label={label}
        InputLabelProps={{ shrink: !!placeholder }}
        placeholder={placeholder || label}
        variant="standard"
        multiline={Boolean(rows)}
        minRows={rows}
        id={id}
        value={value as string}
        onChange={onChange}
        onBlur={onBlur}
        error={Boolean(error)}
        helperText={error && (error.message || `Please enter a ${label}`)}
        disabled={disabled}
        required={required}
        sx={{
          '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.colors.gray[700]}`,
          },
          '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.colors.gray[300]}`,
          },
          '&:hover .MuiOutlinedInput-root:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.colors.blue[500]}`,
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.colors.blue[500]}`,
          },
          '& .MuiOutlinedInput-input': { color: `${theme.colors.gray[900]}` },
          '& .Mui-disabled .MuiOutlinedInput-input': { color: `${theme.colors.gray[300]}` },
          '&:hover :not(.Mui-disabled) .MuiOutlinedInput-input': { color: `${theme.colors.gray[900]}` },
          '& .MuiInputLabel-outlined': { color: `${theme.colors.gray[700]}` },
          '& .MuiInputLabel-outlined.Mui-focused': { color: `${theme.colors.blue[500]}` },
        }}
      />
    </div>
  );
}

export default Text;
