import React, { createRef, RefObject } from 'react';

import theme from '../../../common/util/theme';
import { AudioLoadingBar } from './audioLoadingBar';

export type WaveformData = Record<'left' | 'right', string[]>;
type AudioProgressBarProps = {
  currentTimeMs: number;
  durationTimeMs: number;
  waveformData: WaveformData | undefined;
  setTimeSeeked: (msTimeSeeked: number) => void;
};
export const AudioProgressBar = ({
  currentTimeMs,
  durationTimeMs,
  waveformData,
  setTimeSeeked,
}: AudioProgressBarProps): JSX.Element | null => {
  const generateWaveformImage = () => {
    if (waveformData) {
      const seekingLine: RefObject<HTMLDivElement> = createRef();
      const leftPeaks = waveformData.left;
      const rightPeaks = waveformData.right;

      const leftMax = Math.max(...leftPeaks.map((peak) => parseFloat(peak)));
      const rightMax = Math.max(...leftPeaks.map((peak) => parseFloat(peak)));

      const leftPathData = leftPeaks.map((value, i) =>
        i % 2 === 0 ? `l 0,-${value} l 1,0 l 0,${value} l -1,0 m 2,0` : null
      );
      const rightPathData = rightPeaks.map((value, i) =>
        i % 2 === 0 ? `l 0,${value} l 1,0 l 0,-${value} l -1,0 m 2,0` : null
      );

      const boxHeight = Math.ceil(Math.max(leftMax, rightMax)) * 2;
      const viewBox = `0 0 1001 ${boxHeight}`;
      const pathData = `M 0,${boxHeight / 2} ${leftPathData.join(' ')} M 0,${boxHeight / 2} ${rightPathData.join(
        ' '
      )} Z`;

      const offset = durationTimeMs && currentTimeMs ? currentTimeMs / durationTimeMs : 0;
      const waveformGradientId = 'progressPlayer';
      const borderGradientId = 'borderPlayer';

      const pathStyle = {
        fill: `url(#${waveformGradientId})`,
      };
      const boundaryStyle = {
        fill: `url(#${borderGradientId})`,
      };

      const initialColor = theme.colors.gray[300];
      const progressColor = theme.colors.blue[500];

      const getCurrentPositionOnWaveform = (event: React.MouseEvent<HTMLDivElement>) => {
        const clickedPosition = event.currentTarget.getBoundingClientRect().left;
        return (event.clientX - clickedPosition) / event.currentTarget.clientWidth;
      };

      return (
        <div
          className="relative cursor-pointer"
          onMouseMove={(event) => {
            const position = getCurrentPositionOnWaveform(event);
            const positionPercentage = position * 100;
            if (seekingLine.current) {
              seekingLine.current.classList.remove('hidden');
              seekingLine.current.classList.add('block');
              seekingLine.current.style.left = `${positionPercentage}%`;
            }
          }}
          onMouseLeave={() => {
            if (seekingLine.current) {
              seekingLine.current.classList.add('hidden');
            }
          }}
          onClick={(event) => {
            const position = getCurrentPositionOnWaveform(event);
            const msTimeClicked = durationTimeMs * position;
            setTimeSeeked(msTimeClicked);
          }}
        >
          <svg
            viewBox={viewBox}
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            width="100%"
            height="61px"
          >
            <defs>
              <linearGradient id={waveformGradientId}>
                <stop offset={offset} stopColor={progressColor} />
                <stop offset={offset} stopColor={initialColor} />
              </linearGradient>
              <linearGradient id={borderGradientId}>
                <stop offset={offset} stopColor={theme.colors.blue[100]} />
                <stop offset={offset} stopColor={theme.colors.gray[100]} />
              </linearGradient>
            </defs>
            <rect x="0" y="0" width="1001" height={boxHeight} style={boundaryStyle} />
            <path d={pathData} style={pathStyle} />
          </svg>
          <div className="h-full top-0 w-2px bg-gray-700 absolute hidden" ref={seekingLine} />
        </div>
      );
    } else {
      return <AudioLoadingBar />;
    }
  };

  return generateWaveformImage();
};
