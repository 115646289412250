export enum KeyboardKeys {
  SPACE = ' ',
  TAB = 'Tab',
  ENTER = 'Enter',
  COMMA = ',',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_UP = 'ArrowUp',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_DOWN = 'ArrowDown',
}
