import { RootState } from '../../../common/store/store';
import { Collection } from '../../api/CollectionApi.types';

export const selectCollection = (state: RootState): Collection | undefined => state.collectionTool.edit.collection;

export const selectIsUpdating = (state: RootState): boolean => state.collectionTool.edit.isUpdating;

export const selectIsLoading = (state: RootState): boolean => state.collectionTool.edit.isLoading;

export const selectNewContentIdOrder = (state: RootState): number[] | undefined =>
  state.collectionTool.edit.newContentIdOrder;

export const selectError = (state: RootState): string | undefined => state.collectionTool.edit.error;

export const selectErrorMessages = (state: RootState): string[] | undefined => state.collectionTool.edit.errorMessages;

export const selectClonedId = (state: RootState): number | undefined => state.collectionTool.edit.clonedId;
