import React from 'react';

type OwnProps = {
  isBatchLoading: boolean;
};

function Loading({ isBatchLoading }: OwnProps): JSX.Element {
  const message = isBatchLoading
    ? 'Claiming next collection to batch review.'
    : 'No more stock items for batch review.';
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-7">{message}</div>
    </div>
  );
}

export default Loading;
