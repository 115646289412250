import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Content } from '../../api/CollectionApi.types';
import { fetchContentFromIds } from '../storeUtil';

const contentAdapter = createEntityAdapter<Content>({
  // Assume IDs are stored in a field other than `book.id`
  selectId: (content) => content._id,
});

const initialState = contentAdapter.getInitialState({
  isSuggestionListLoading: false,
  rejectedList: new Array<number>(),
  suggestionListError: '',
});

const fetchSuggestionList = createAsyncThunk(
  'suggestionList/fetchSuggestionList',
  async (suggestionList: number[], { dispatch }) => {
    dispatch(resetRejected());
    return await fetchContentFromIds(suggestionList);
  }
);

const suggestedContentSlice = createSlice({
  name: 'suggestionList',
  initialState: initialState,
  reducers: {
    addSelectedToRejected: (state, action: PayloadAction<number>) => {
      state.rejectedList = state.rejectedList.concat(action.payload);
    },
    removeSelectedFromRejected: (state, action: PayloadAction<number>) => {
      state.rejectedList = state.rejectedList.filter((id) => id !== action.payload);
    },
    resetRejected: (state) => {
      state.rejectedList = [];
    },
    // Can pass adapter functions directly as case reducers.  Because we're passing this
    // as a value, `createSlice` will auto-generate the `bookAdded` action type / creator
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSuggestionList.pending, (state) => {
        state.isSuggestionListLoading = true;
      })
      .addCase(fetchSuggestionList.fulfilled, (state, action) => {
        state.isSuggestionListLoading = false;
        contentAdapter.setAll(state, action.payload);
      })
      .addCase(fetchSuggestionList.rejected, (state, action) => {
        state.isSuggestionListLoading = false;
        if (action.error.message) {
          state.suggestionListError = action.error.message;
        } else {
          state.suggestionListError = 'Failed to load content list';
        }
      });
  },
});

const simpleSelectors = contentAdapter.getSelectors();

const { reducer, actions } = suggestedContentSlice;

const { addSelectedToRejected, removeSelectedFromRejected, resetRejected } = actions;

export {
  reducer,
  fetchSuggestionList,
  simpleSelectors,
  initialState,
  addSelectedToRejected,
  removeSelectedFromRejected,
  resetRejected,
};
