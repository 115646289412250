import React from 'react';

import { ReactComponent as ApprovedIcon } from '../../../common/assets/icons/approved.svg';
import { ReactComponent as InvalidIcon } from '../../../common/assets/icons/invalid.svg';
import { ReactComponent as RejectedIcon } from '../../../common/assets/icons/rejected.svg';

export type OwnProps = {
  previewState: PreviewState;
  classes?: string;
};

export enum PreviewState {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  INVALID = 'invalid',
  NONE = 'none',
}

export default function PreviewStateStatusIcon(props: OwnProps): JSX.Element | null {
  const { previewState, classes } = props;

  switch (previewState) {
    case PreviewState.REJECTED:
      return <RejectedIcon className={classes} />;
    case PreviewState.INVALID:
      return <InvalidIcon className={classes} />;
    case PreviewState.APPROVED:
      return <ApprovedIcon className={classes} />;
    default:
      return null;
  }
}
