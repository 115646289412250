import { Autocomplete, TextField, FormControl, ClickAwayListener } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { RejectionReason } from '../../../review-app/store/rejectionReason/rejectionReason.types';
import theme from '../../util/theme';
import { DropdownMenuIcon } from '../dropdownMenuIcon/DropdownMenuIcon';

type OwnProps = {
  options: {
    value: string;
    label: string;
  }[];
  onSelectionUpdated: (newSelection: RejectionReason | null) => void;
  selectedValue?: string;
  placeholder: string;
  disabled?: boolean;
  openChangeValue?: string;
};

function AutocompleteSelect(props: OwnProps): JSX.Element {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean | undefined>(false);
  const [isFieldEmpty, setIsFieldEmpty] = useState<boolean>(true);
  const { colors } = theme;
  useEffect(() => {
    if (props.openChangeValue) {
      setIsDropdownOpen(true);
    }
  }, [props.openChangeValue]);

  const shouldShowPlaceholder = !props.selectedValue && isFieldEmpty;

  const getSelectedItem = () => {
    const item = props.options.find((opt) => opt.value === props.selectedValue);
    return item || null;
  };

  return (
    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => setIsDropdownOpen(false)}>
      <FormControl fullWidth>
        <Autocomplete
          options={props.options}
          onChange={(event, newValue) => props.onSelectionUpdated(newValue)}
          disabled={props.disabled || false}
          onOpen={() => setIsDropdownOpen(true)}
          onClose={() => setIsDropdownOpen(false)}
          open={isDropdownOpen}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          getOptionLabel={(option) => option.label}
          popupIcon={<DropdownMenuIcon sx={{ '&:hover': { backgroundColor: 'transparent' } }} />}
          value={getSelectedItem()}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={(event) => setIsFieldEmpty(event.target.value === '')}
              placeholder={shouldShowPlaceholder ? props.placeholder : ''}
              variant="outlined"
              className="my-auto"
              sx={{
                '& input::placeholder': { color: colors.gray[500], opacity: '100%' },
                '& .MuiAutocomplete-input:first-child': { 'padding-left': '0 !important' },
              }}
            />
          )}
          sx={{
            '& .MuiOutlinedInput-notchedOutline': { borderColor: colors.gray[400] },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.blue[500],
              borderWidth: '2px',
              borderRadius: '4px',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.blue[500],
              borderWidth: '2px',
              borderRadius: '4px',
            },
            '&&& .MuiOutlinedInput-input': { padding: '0' },
            '&&': { height: '40px', padding: '8px 12px' },
          }}
        />
      </FormControl>
    </ClickAwayListener>
  );
}

export default AutocompleteSelect;
