import React from 'react';

import { CollectionSortableField, SortDirection } from '../../../api/CollectionApi';

export interface CollectionSortSelectProps {
  sortBy?: CollectionSortableField;
  sortDirection?: SortDirection;
  onSortByChange: (sortBy: CollectionSortableField) => void;
  onSortDirectionChange: (sortDirection: SortDirection) => void;
}

const CollectionSortSelect = ({
  sortBy,
  sortDirection,
  onSortByChange,
  onSortDirectionChange,
}: CollectionSortSelectProps): JSX.Element => {
  return (
    <div className="flex flex-wrap">
      <label className="mx-2 my-auto text-sm" htmlFor="collection-sort-by">
        Sort By
      </label>
      <select
        className="form-select rounded"
        id="collection-sort-by"
        value={sortBy}
        onChange={(e) => onSortByChange(e.target.value as CollectionSortableField)}
      >
        <option value={CollectionSortableField.name}>Name</option>
        <option value={CollectionSortableField.createdAt}>Created At</option>
        <option value={CollectionSortableField.updatedAt}>Updated At</option>
      </select>
      <label className="mx-2 my-auto text-sm" htmlFor="collection-sort-direction" hidden={true}>
        Sort Direction
      </label>
      <select
        className="form-select ml-0.5 rounded"
        id="collection-sort-direction"
        value={sortDirection}
        onChange={(e) => onSortDirectionChange(e.target.value as SortDirection)}
      >
        <option value={SortDirection.ASC}>Ascending</option>
        <option value={SortDirection.DESC}>Descending</option>
      </select>
    </div>
  );
};
export default CollectionSortSelect;
