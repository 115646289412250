import { Divider, Typography } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Button, { ButtonSize, ButtonTextSize, ButtonType } from '../../../../../../common/components/button/Button';
import { ContentClass } from '../../../../../../common/enums/ContentClasses';
import { useDispatch } from '../../../../../../common/store/store';
import { saveCollection } from '../../../../../store/CollectionEdit/CollectionEdit.reducer';
import { selectCollection } from '../../../../../store/CollectionEdit/CollectionEdit.selectors';
import { selectRejectedList } from '../../../../../store/SuggestionList/SuggestionList.selectors';
import ContentSuggestionStatus from '../../ContentSuggestionStatus';
import RejectionReasonModal from './RejectionReasonModal';
import SuggestionList from './SuggestionList';

type OwnProps = {
  contentClass: ContentClass;
};

const SuggestedContent = (props: OwnProps): JSX.Element | null => {
  const { contentClass } = props;
  const dispatch = useDispatch();
  const { collection, rejectedList } = useSelector(selector);

  const [open, setOpen] = useState(false);
  const contentClassSuggestion = collection?.suggestion?.[contentClass];
  if (!contentClassSuggestion) {
    return null;
  }
  const pendingContentIds = contentClassSuggestion?.content?.pendingContentIds ?? [];
  const approvedContentIds = contentClassSuggestion?.content?.approvedContentIds ?? [];

  const onApproveSubmit = () => {
    const dedupedApprovedIds = Array.from(new Set(approvedContentIds.concat(pendingContentIds)));
    void dispatch(
      saveCollection({
        id: collection._id,
        updates: {
          content: {
            [contentClass]: collection.content?.[contentClass]
              ? collection.content?.[contentClass]?.concat(pendingContentIds)
              : pendingContentIds,
          },
          suggestion: {
            [contentClass]: {
              ...contentClassSuggestion,
              content: {
                ...contentClassSuggestion?.content,
                pendingContentIds: [],
                approvedContentIds: dedupedApprovedIds,
              },
            },
          },
        },
      })
    );
  };
  return (
    <div>
      <Divider />
      <ContentSuggestionStatus contentClass={contentClass} />
      {pendingContentIds.length > 0 && (
        <div>
          <div className="flex justify-end pt-6">
            <div className="px-4">
              <Button
                message={rejectedList.length ? 'Reject Selected Items' : 'Reject All Items'}
                type={ButtonType.INFO_HOLLOW}
                size={ButtonSize.MEDIUM}
                textSize={ButtonTextSize.SMALL}
                isSubmit={false}
                onClickAction={() => setOpen(true)}
              />
              <RejectionReasonModal
                open={open}
                setOpen={setOpen}
                rejectedList={rejectedList}
                contentClass={contentClass}
              />
            </div>
            <div className="px-4">
              <Button
                message="Send To Content List"
                type={ButtonType.PRIMARY_YELLOW}
                size={ButtonSize.MEDIUM}
                textSize={ButtonTextSize.SMALL}
                isSubmit={false}
                onClickAction={onApproveSubmit}
              />
            </div>
          </div>
          <div className="pt-6">
            <Typography variant="h6" gutterBottom>
              {`${capitalize(contentClass)} Suggested Items`}
            </Typography>
            <Typography variant="overline" gutterBottom>
              Request Id: {contentClassSuggestion?.content?.request?.requestId}, Total Items:{' '}
              {pendingContentIds.length || 'n/a'}
            </Typography>
            <SuggestionList contentClass={contentClass} />
          </div>
        </div>
      )}
    </div>
  );
};
export default SuggestedContent;

const selector = createSelector([selectCollection, selectRejectedList], (collection, rejectedList) => ({
  collection,
  rejectedList,
}));
