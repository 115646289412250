import { Cancel, Delete } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material';
import React from 'react';

import { ContentClass } from '../../../../../common/enums/ContentClasses';
import { useDispatch } from '../../../../../common/store/store';
import { RejectionReason } from '../../../../api/CollectionApi.types';
import { removeOneFromCollection } from '../../../../store/CollectionEdit/CollectionEdit.reducer';

type DeleteDialogProps = {
  id: number;
  needRejectionReason: boolean;
  contentClass: ContentClass;
};

const DeleteDialog = ({ id, needRejectionReason, contentClass }: DeleteDialogProps): JSX.Element => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [reason, setReason] = React.useState(RejectionReason.QUALITY);
  const deleteContent = () => {
    if (needRejectionReason) {
      void dispatch(removeOneFromCollection({ contentId: id, reason, contentClass }));
    } else {
      void dispatch(removeOneFromCollection({ contentId: id, contentClass }));
    }
  };
  return (
    <>
      <Tooltip title="Remove">
        <IconButton color="secondary" onClick={() => setOpen(true)}>
          <Delete />
        </IconButton>
      </Tooltip>
      <Dialog onClose={() => setOpen(false)} aria-labelledby="confirm-remove-content" open={open}>
        <DialogTitle>Remove Content from Collection?</DialogTitle>
        <DialogActions>
          {needRejectionReason && (
            <FormControl>
              <InputLabel id="rejection-reason-label">Reason</InputLabel>
              <Select
                labelId="rejection-reason-label"
                id="rejection-reason-select"
                value={reason}
                label="Rejection Reason"
                onChange={(event) => setReason(event.target.value as RejectionReason)}
              >
                {Object.values(RejectionReason).map((reason, key) => (
                  <MenuItem key={key} value={reason}>
                    {reason}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <IconButton onClick={deleteContent}>
            Yes <Delete />
          </IconButton>
          <IconButton onClick={() => setOpen(false)}>
            No <Cancel />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default DeleteDialog;
