import React from 'react';

import ChipInput from '../../../common/components/ChipInput';
import { validateKeywordList } from '../../../common/util/util';

type OwnProps = {
  keywords: string[];
  readOnly?: boolean;
  id: string;
  label: string;
  handleKeywordsChange: (keywords: string[]) => void;
};

function Keywords(props: OwnProps): JSX.Element {
  const { keywords, handleKeywordsChange, id } = props;

  const onChange = (keywords: string[]) => {
    const validatedKeywords = validateKeywordList(keywords);
    handleKeywordsChange(validatedKeywords);
  };

  return (
    <ChipInput
      id={id}
      value={keywords}
      onChange={(e, values) => onChange(values)}
      placeholder="Type keywords here"
      disabled={props.readOnly || false}
      label={props.label}
    />
  );
}

export default Keywords;
