import React from 'react';
import { useSelector } from 'react-redux';

import { ContentClass } from '../../../common/enums/ContentClasses';
import { StockItemType } from '../../store/batch/batch.types';
import { selectGenres, selectInstruments, selectMoods } from '../../store/groups/groups.selectors';
import { GroupSelect } from './groupSelect';

type OwnProps = {
  stockItem: StockItemType;
};

export const MoodsSelect = ({ stockItem }: OwnProps): JSX.Element => {
  const allMoods = useSelector(selectMoods);

  return (
    <GroupSelect
      keyName={'moods'}
      labelName={'Moods:'}
      stockItemId={stockItem._id}
      contentClass={ContentClass.audio}
      stockItemCreatorId={stockItem.creatorId}
      allTypes={allMoods}
      selectedTypes={stockItem.meta.audio?.moods}
    />
  );
};

export const GenresSelect = ({ stockItem }: OwnProps): JSX.Element => {
  const allGenres = useSelector(selectGenres);

  return (
    <GroupSelect
      keyName={'genres'}
      labelName={'Genres:'}
      stockItemId={stockItem._id}
      contentClass={ContentClass.audio}
      stockItemCreatorId={stockItem.creatorId}
      allTypes={allGenres}
      selectedTypes={stockItem.meta.audio?.genres}
    />
  );
};

export const InstrumentsSelect = ({ stockItem }: OwnProps): JSX.Element => {
  const allInstruments = useSelector(selectInstruments);

  return (
    <GroupSelect
      keyName={'instruments'}
      labelName={'Instruments:'}
      stockItemId={stockItem._id}
      contentClass={ContentClass.audio}
      stockItemCreatorId={stockItem.creatorId}
      allTypes={allInstruments}
      selectedTypes={stockItem.meta.audio?.instruments}
    />
  );
};
