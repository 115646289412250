export enum VisualStandards {
  VIBRANCY_CONTRAST = 'vibrancyAndContrast',
  EMOTIONAL_INSPIRING = 'emotionalAndInspiring',
  CINEMATIC_PRACTICAL = 'cinematicButPractical',
}

export const allVisualStandards = [
  VisualStandards.VIBRANCY_CONTRAST,
  VisualStandards.EMOTIONAL_INSPIRING,
  VisualStandards.CINEMATIC_PRACTICAL,
];

export function labelMapping(standard: string): string {
  switch (standard) {
    case VisualStandards.EMOTIONAL_INSPIRING:
      return 'Emotional & Inspiring';
    case VisualStandards.VIBRANCY_CONTRAST:
      return 'Vibrancy & Contrast';
    case VisualStandards.CINEMATIC_PRACTICAL:
      return 'Cinematic but Practical';
    default:
      return '';
  }
}
