import { ContentType } from '../../../common/enums/ContentTypes';
import { RootState } from '../../../common/store/store';
import { ContentTypeRejectionReasonsInterface } from './rejectionReason.state';
import { RejectionReason } from './rejectionReason.types';

type RejectionReasonSelector = (state: RootState) => RejectionReason[];

export const selectRejectionReasons = (state: RootState): ContentTypeRejectionReasonsInterface | undefined =>
  state.review.rejectionReason.contentTypeRejectionReasons;

export const selectRejectionReasonForContentType = (contentType: ContentType): RejectionReasonSelector => {
  switch (contentType) {
    case ContentType.FOOTAGE:
      return selectFootageRejectionReasons;
    case ContentType.LEGACY_MOTION_BACKGROUND:
      return selectAnimationRejectionReasons;
    case ContentType.ILLUSTRATION:
      return selectIllustrationRejectionReasons;
    case ContentType.MUSIC:
      return selectMusicRejectionReasons;
    case ContentType.PHOTO:
      return selectPhotoRejectionReasons;
    case ContentType.TEMPLATE:
      return selectTemplateRejectionReasons;
    case ContentType.VECTOR:
      return selectVectorRejectionReasons;
    case ContentType.VR_360:
      return selectVr360RejectionReasons;
    default:
      return defaultRejectionReasonSelector;
  }
};

const selectFootageRejectionReasons: RejectionReasonSelector = (state) =>
  state.review.rejectionReason.contentTypeRejectionReasons?.footage ?? [];

const selectAnimationRejectionReasons: RejectionReasonSelector = (state) =>
  state.review.rejectionReason.contentTypeRejectionReasons?.animation ?? [];

const selectIllustrationRejectionReasons: RejectionReasonSelector = (state) =>
  state.review.rejectionReason.contentTypeRejectionReasons?.illustration ?? [];

const selectMusicRejectionReasons: RejectionReasonSelector = (state) =>
  state.review.rejectionReason.contentTypeRejectionReasons?.music ?? [];

const selectPhotoRejectionReasons: RejectionReasonSelector = (state) =>
  state.review.rejectionReason.contentTypeRejectionReasons?.photo ?? [];

const selectTemplateRejectionReasons: RejectionReasonSelector = (state) =>
  state.review.rejectionReason.contentTypeRejectionReasons?.template ?? [];

const selectVectorRejectionReasons: RejectionReasonSelector = (state) =>
  state.review.rejectionReason.contentTypeRejectionReasons?.vector ?? [];

const selectVr360RejectionReasons: RejectionReasonSelector = (state) =>
  state.review.rejectionReason.contentTypeRejectionReasons?.vr360 ?? [];

const defaultRejectionReasonSelector: RejectionReasonSelector = () => [];
