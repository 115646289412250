import { SelectChangeEvent } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import tailwindTheme from '../../util/theme';
import { DropdownMenuIcon } from '../dropdownMenuIcon/DropdownMenuIcon';
import MultiSelect, { Options } from './MultiSelect';

type MultiSelectDropdownProps = {
  allOptions: Options[];
  selectedOptions: Options[];
  setSelectedOptions: (options: Options[]) => void;
  label: string;
  disabled?: boolean;
};

export const MultiSelectDropdown = ({
  allOptions,
  selectedOptions,
  setSelectedOptions,
  label,
  disabled,
  ...props
}: MultiSelectDropdownProps): JSX.Element => {
  const theme = useTheme();
  const styles = {
    labelStyles: {
      root: {
        fontSize: '0.875rem',
        lineHeight: '1rem',
        color: '#000000',
        fontFamily: tailwindTheme.fontFamily.sans,
        fontWeight: tailwindTheme.fontWeight.semibold,
      },
    },
    selectStyles: {
      root: {
        padding: '0.25rem 0.75rem 0 0.75rem',
        marginTop: -theme.spacing(1),
        minHeight: '2.25rem',
        border: `1px solid ${tailwindTheme.colors.gray[400]}`,
      },
      icon: { marginRight: '0.5rem', height: '1.5rem', width: '1.5rem', top: 0 },
    },
    checkBoxStyles: {
      checked: {
        '&.MuiCheckbox-colorSecondary': {
          color: tailwindTheme.colors.blue[500],
        },
      },
    },
  };

  const allOptionsMap = allOptions.reduce<Record<string, Options>>((acc, record) => {
    acc[record.value] = record;
    return acc;
  }, {});

  const onChange = (e: SelectChangeEvent<unknown>) => {
    const valuesSelected = (e?.target?.value as string[]) ?? [];
    const optionsSelected = valuesSelected.map((val: string) => allOptionsMap[val]);
    setSelectedOptions(optionsSelected);
  };

  const onDelete = (selectedValue: string) => {
    setSelectedOptions(selectedOptions.filter((option) => option.value !== selectedValue));
  };

  return (
    <MultiSelect
      options={allOptions}
      selected={selectedOptions}
      id={`${label.toLowerCase()}-select`}
      labelId={`${label.toLowerCase()}-label`}
      label={label}
      renderValuesAsChips={true}
      customStyles={styles}
      IconComponent={DropdownMenuIcon}
      onChange={onChange}
      onDelete={onDelete}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      }}
      {...props}
      disabled={disabled}
    />
  );
};
