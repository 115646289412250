import React from 'react';

export type OwnProps = {
  message: string;
  icon?: string;
  disabled?: boolean;
  onClickAction?: () => void;
  type: ButtonType;
  size: ButtonSize;
  textSize: ButtonTextSize;
  isSubmit: boolean;
  className?: string;
};

export enum ButtonType {
  PRIMARY = 'primary',
  PRIMARY_YELLOW = 'primary-yellow',
  SECONDARY = 'secondary',
  ERROR = 'error',
  INFO = 'info',
  INFO_LIGHT = 'info-light',
  INFO_HOLLOW = 'info-hollow',
  INFO_ACTIVE = 'info-active',
}

export enum ButtonSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export enum ButtonTextSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

function Button({
  type,
  message,
  icon,
  disabled = false,
  size,
  isSubmit,
  onClickAction,
  textSize,
  className = '',
}: OwnProps): JSX.Element {
  const tailwindClass = `font-medium rounded-full transition ease-in-out duration-150 ${getSizeClasses(
    size
  )} ${getColorClasses(type, disabled)} ${getTextSizeClasses(textSize)}`;

  const handleClick = () => {
    if (onClickAction) {
      onClickAction();
    }
  };

  return (
    <button
      className={`button ${className} ${tailwindClass}`}
      type={isSubmit ? 'submit' : 'button'}
      disabled={disabled}
      onClick={handleClick}
    >
      {message}
      {icon}
    </button>
  );
}

function getColorClasses(type: ButtonType, disabled: boolean) {
  switch (type) {
    case ButtonType.PRIMARY:
      return `text-white ${disabled ? 'bg-green-200' : 'bg-green-700 hover:bg-green-500'}`;
    case ButtonType.PRIMARY_YELLOW:
      return `text-black ${disabled ? 'bg-yellow-300' : 'bg-yellow-500 hover:bg-yellow-400'}`;
    case ButtonType.SECONDARY:
      return 'text-black bg-transparent border-black border-2';
    case ButtonType.INFO:
      return 'text-black bg-gray-400';
    case ButtonType.ERROR:
      return 'text-white bg-red-700';
    case ButtonType.INFO_LIGHT:
      return 'bg-gray-200 text-black';
    case ButtonType.INFO_HOLLOW:
      return 'bg-transparent border border-black text-black';
    case ButtonType.INFO_ACTIVE:
      return 'bg-blue-700 text-white';
    default:
      return 'text-black bg-gray-400';
  }
}

function getSizeClasses(size: ButtonSize) {
  switch (size) {
    case ButtonSize.LARGE:
      return 'px-10 py-3';
    case ButtonSize.MEDIUM:
      return 'px-5 py-1.5';
    case ButtonSize.SMALL:
      return 'px-3 py-1.5';
    default:
      return 'px-5 py-1.5';
  }
}

function getTextSizeClasses(size: ButtonTextSize) {
  switch (size) {
    case ButtonTextSize.LARGE:
      return 'text-xl';
    case ButtonTextSize.MEDIUM:
      return 'text-default';
    case ButtonTextSize.SMALL:
      return 'text-sm';
    default:
      return 'text-m';
  }
}

export default Button;
