import React from 'react';
import { Link } from 'react-router-dom';

import { Collection } from '../../../api/CollectionApi.types';

interface CollectionResultCardProps {
  collection: Collection;
}

const CollectionResultCard = ({ collection }: CollectionResultCardProps): JSX.Element => {
  const { _id, name, view, content, classes } = collection;

  const image = view?.directoryBackgroundImageUrl
    ? view?.directoryBackgroundImageUrl
    : 'https://dm0qx8t0i9gc9.cloudfront.net/thumbnails/image/rDtN98Qoishumwih/image-placeholder-stroke-icon_MyL9N8L__thumb.jpg';

  const contentClass = classes.length > 1 ? 'Multi-Asset' : classes[0];
  const numberOfItems = Object.values(content ?? {}).reduce((total, content) => total + content.length, 0);

  return (
    <Link to={`/collection-tool/edit/${_id}`} className="p-2">
      <div className="relative overflow-hidden rounded-2xl border-solid border-2 border-transparent hover:border-gray-500 hover:shadow-lg w-72 h-48">
        <div className="bg-gradient-to-t from-gray-900 to-transparent via-transparent absolute inset-0 w-full h-full" />
        <div className="top-4 right-4 w-full absolute px-4 text-right font-bold m-0 text-base text-white no-underline text-shadow">
          {numberOfItems} <span className="capitalize">{contentClass}</span> Items
        </div>
        <img src={image} className="w-full h-full object-none" alt={name} />
        <h3 className="absolute bottom-0 left-0 flex items-end p-4 m-0 text-xl text-white no-underline whitespace-pre-wrap font-bold">
          {name}
        </h3>
      </div>
    </Link>
  );
};
export default CollectionResultCard;
