import { Button, CircularProgress } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import Alert, { AlertType } from '../../../../../common/components/alert/Alert';
import { ContentClass } from '../../../../../common/enums/ContentClasses';
import { useDispatch } from '../../../../../common/store/store';
import { loadNewCollection } from '../../../../store/CollectionEdit/CollectionEdit.reducer';
import { selectCollection } from '../../../../store/CollectionEdit/CollectionEdit.selectors';
import MetadataAutomatedSuggestion from './MetadataAutomatedSuggestion';

type OwnProps = {
  contentClass: ContentClass;
};
const CollectionMetadataSuggestionStatus = ({ contentClass }: OwnProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const selector = useMemo(
    () =>
      createSelector([selectCollection], (collection) => {
        const pendingTopTagsLength = collection?.suggestion?.[contentClass]?.metadata?.pendingTopTags?.length;
        const pendingKeywordsLength = collection?.suggestion?.[contentClass]?.metadata?.pendingKeywords?.length;
        const responseDate = collection?.suggestion?.[contentClass]?.metadata?.responseDate;
        const requestDate = collection?.suggestion?.[contentClass]?.metadata?.request?.requestDate;
        return {
          collection,
          pendingTopTagsLength,
          pendingKeywordsLength,
          responseDate,
          requestDate,
        };
      }),
    [contentClass]
  );

  const { collection, pendingTopTagsLength, pendingKeywordsLength, responseDate, requestDate } = useSelector(selector);
  //Status no request
  if (!requestDate) {
    return (
      <div className="text-center">
        <p>Please request suggestions for this collection.</p>
      </div>
    );
  }
  //Status finished reviewing
  if (!pendingKeywordsLength && !pendingTopTagsLength && responseDate && responseDate > requestDate) {
    return (
      <>
        {collection?.suggestion?.[contentClass]?.metadata?.statusMessage && (
          <StatusMessage message={collection?.suggestion?.[contentClass]?.metadata?.statusMessage || ''} />
        )}
        <div className="grid flex justify-center py-8">
          <div className="row-start-1 py-6 px-24 bg-gray-200">
            <div>
              <p>No metadata suggestions to review.</p>
            </div>
          </div>
        </div>
      </>
    );
  }
  //Status pending response (either new request or only request with no response)
  if ((responseDate ? responseDate < requestDate : !!requestDate) && collection) {
    return (
      <div className="grid flex justify-center pt-8">
        <div className="row-start-1 py-6 px-24 bg-gray-200">
          <div>
            <p>Waiting for metadata suggestions...</p>
          </div>
          <div className="flex justify-center">
            <Button color="primary" onClick={() => void dispatch(loadNewCollection(collection._id))}>
              Refresh
            </Button>
          </div>
        </div>
        <div className="row-start-2 flex justify-center py-6">
          <CircularProgress />
        </div>
      </div>
    );
  }

  //if there's a status and there's pending contentIds
  if (collection?.suggestion?.[contentClass]?.metadata?.statusMessage) {
    return <StatusMessage message={collection?.suggestion?.[contentClass]?.metadata?.statusMessage || ''} />;
  }

  //if no status, return automated suggestions
  return <MetadataAutomatedSuggestion contentClass={contentClass} />;
};

const StatusMessage = (props: { message: string }): JSX.Element => {
  return (
    <div className="pt-6">
      <Alert type={AlertType.WARNING} title="Collection Assistant Status" messages={[props.message]} />
    </div>
  );
};

export default CollectionMetadataSuggestionStatus;
