import { wasabiAxios } from '../../common/api/wasabiAxios';
import { ContentClass } from '../../common/enums/ContentClasses';
import { GroupType } from '../store/batch/batch.types';

export interface GetGroupsResponse {
  groups: GroupType[];
}

export class GroupsApi {
  public getGroups = async (contentClass: ContentClass): Promise<GetGroupsResponse> => {
    return (await wasabiAxios.get<GetGroupsResponse>(`/review-api/v1/groups/${contentClass}`)).data;
  };
}
