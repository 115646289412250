import { createSelector } from '@reduxjs/toolkit';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsSearching, selectSearchResults } from '../../../store/CollectionSearch/CollectionSearch.selectors';
import CollectionResultCard from '../components/CollectionResultCard';

const CollectionSearchResults = (): JSX.Element | null => {
  const { isSearching, searchResults } = useSelector(selector);

  if (!searchResults) {
    return null;
  }

  const { collections } = searchResults;

  const resultCards = collections.map((collection, index) => (
    <CollectionResultCard collection={collection} key={index} />
  ));

  return (
    <div className="flex flex-wrap justify-center">
      <LoadingDiv isSearching={isSearching} />
      {resultCards}
    </div>
  );
};
export default CollectionSearchResults;

const selector = createSelector([selectIsSearching, selectSearchResults], (isSearching, searchResults) => ({
  isSearching,
  searchResults,
}));

const LoadingDiv = ({ isSearching }: { isSearching: boolean }): JSX.Element | null => {
  if (isSearching) {
    return (
      <div
        data-testid="collection-search-results-loading"
        className="opacity-50 bg-gray-600 fixed w-full h-full z-30 transition ease-in-out duration-300"
      />
    );
  }
  return null;
};
