import React from 'react';
import { useSelector } from 'react-redux';

import { QueueInfo } from '../../../review-app/api/ReviewerBatchStatsApi';
import ReviewerBatchStatsChart from '../../../review-app/components/reviewerBatchStatsChart/ReviewerBatchStatsChart';
import { selectQueueCounts } from '../../../review-app/store/reviewerBatchStats/reviewerBatchStats.selectors';
import Alert, { AlertType } from '../../components/alert/Alert';

function Home(): JSX.Element {
  return (
    <div className="relative overflow-hidden">
      <main className=" mx-auto max-w-screen-xl px-4">
        <div className="text-center">
          <ReviewQueueInfo />
          <h2 className="pb-6 text-4xl tracking-tight leading-10 font-bold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
            Reviewer Stats
            <br />
          </h2>
          <ReviewerBatchStatsChart />
        </div>
      </main>
    </div>
  );
}

export default Home;

const ReviewQueueInfo = (): JSX.Element => {
  const queueCounts = useSelector(selectQueueCounts);
  const { audio, video, vr360, template } = queueCounts;

  if (Object.values(queueCounts).some((n) => n < 0)) {
    return (
      <Alert title="Error getting the number of available review batches. Please try again." type={AlertType.WARNING} />
    );
  }

  return (
    <div className="p-4 bg-white rounded-lg shadow-reviewChart w-1/5 mx-auto m-4">
      <h3 className="font-bold">Ready For Review</h3>
      <table className="mx-auto">
        <thead>
          <tr>
            <th />
            <th className="px-2">Batches</th>
            <th className="px-2">Items</th>
          </tr>
        </thead>
        <tbody>
          <QueueInfoRow label={'Video'} data={video} />
          <QueueInfoRow label={'Audio'} data={audio} />
          <QueueInfoRow label={'Template'} data={template} />
          <QueueInfoRow label={'VR360'} data={vr360} />
        </tbody>
      </table>
    </div>
  );
};

const QueueInfoRow = (props: { label: string; data: QueueInfo }): JSX.Element => {
  const { label, data } = props;
  return (
    <tr>
      <td className="text-left">{label}</td>
      <td className="px-2" data-testid={`total-batches-${label.toLowerCase()}`}>
        {data.totalBatches}
      </td>
      <td className="px-2" data-testid={`total-items-${label.toLowerCase()}`}>
        {data.totalItems}
      </td>
    </tr>
  );
};
