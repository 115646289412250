import { wasabiAxios } from '../../common/api/wasabiAxios';
import { ContentClass } from '../../common/enums/ContentClasses';
import CollectionStatus from '../enums/CollectionStatus';
import {
  ChangeNoticeType,
  Collection,
  CollectionSearchResult,
  CreateCollectionDraft,
  RejectionReason,
} from './CollectionApi.types';

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum CollectionSortableField {
  name = 'name',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
}

export interface CollectionSearchOptions {
  name?: string;
  status?: CollectionStatus;
  classes?: ContentClass[];
  sortBy?: CollectionSortableField;
  sortDirection?: SortDirection;
  includeClasses?: ContentClass[];
  excludeClasses?: ContentClass[];
}

const createCollection = async (collection: CreateCollectionDraft, userId: string): Promise<Collection> => {
  return (
    await wasabiAxios.post<Collection>(`/review-api/v1/collection`, {
      source: 'manual',
      requesterId: userId,
      ...collection,
    })
  ).data;
};

const searchCollections = async (
  searchOptions: CollectionSearchOptions,
  offset: number,
  limit: number
): Promise<CollectionSearchResult> => {
  const { name, status, classes, sortBy, sortDirection, includeClasses, excludeClasses } = searchOptions;
  const params = { name, status, classes, sortBy, sortDirection, offset, limit, includeClasses, excludeClasses };

  return (await wasabiAxios.get<CollectionSearchResult>('/review-api/v1/collection', { params })).data;
};

const getByCollectionId = async (collectionId: number): Promise<Collection> => {
  return (await wasabiAxios.get<Collection>(`/review-api/v1/collection/${collectionId}`)).data;
};

const updateCollection = async (
  collectionId: number,
  dataToChange: Partial<Collection>,
  userId: string
): Promise<Collection> => {
  return (
    await wasabiAxios.put<Collection>(`/review-api/v1/collection/${collectionId}`, {
      ...dataToChange,
      requesterId: userId,
      source: 'manual',
    })
  ).data;
};

const requestCollectionContentSuggestions = async (
  collectionId: number,
  suggestion: Partial<Collection>
): Promise<Collection> => {
  return (
    await wasabiAxios.post<Collection>(`/review-api/v1/collection/${collectionId}/request-content-suggestions`, {
      source: 'manual',
      ...suggestion,
    })
  ).data;
};

const requestCollectionMetadataSuggestions = async (
  collectionId: number,
  suggestion: Partial<Collection>
): Promise<Collection> => {
  return (
    await wasabiAxios.post<Collection>(`/review-api/v1/collection/${collectionId}/request-metadata-suggestions`, {
      source: 'manual',
      ...suggestion,
    })
  ).data;
};

const generateChangeNotice = async (
  collectionId: number,
  changeNoticeType: ChangeNoticeType,
  rejectEntireCollection?: boolean,
  rejectEntireCollectionReason?: RejectionReason,
  rejectContentClass?: ContentClass
): Promise<Collection> => {
  return (
    await wasabiAxios.post<Collection>(`/review-api/v1/collection/${collectionId}/change-notice`, {
      source: 'manual',
      rejectEntireCollection,
      rejectEntireCollectionReason,
      rejectContentClass,
      changeNoticeType,
    })
  ).data;
};

const requestMoreContentSuggestions = async (collectionId: number, contentClass: ContentClass) => {
  return (
    await wasabiAxios.post<Collection>(`/review-api/v1/collection/${collectionId}/request-more-content-suggestions`, {
      source: 'manual',
      contentClassForSuggestion: contentClass,
    })
  ).data;
};

const importContent = async (
  collectionId: number,
  importCollectionId: number,
  contentClass: ContentClass,
  requesterId: string
) => {
  return (
    await wasabiAxios.post<Collection>(`/review-api/v1/collection/${collectionId}/import-content`, {
      source: 'manual',
      collectionId: importCollectionId,
      contentClass,
      requesterId,
    })
  ).data;
};

export default {
  createCollection,
  searchCollections,
  getByCollectionId,
  updateCollection,
  requestCollectionContentSuggestions,
  requestCollectionMetadataSuggestions,
  requestMoreContentSuggestions,
  generateChangeNotice,
  importContent,
};
