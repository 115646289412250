import { ContentType } from '../enums/ContentTypes';
import Roles from '../enums/Roles';
import { wasabiAxios } from './wasabiAxios';

interface UserLoginBody {
  login: string;
  password: string;
}

export interface UserResponse {
  user: {
    email: string;
    firstName: string;
    lastName: string;
    username: string;
    profileImage: string;
    roles: Roles[];
    _id: string;
    allowedContentTypes: ContentType[];
  };
}

class AuthApi {
  public loginUser = async (body: UserLoginBody): Promise<UserResponse> => {
    return (await wasabiAxios.post<UserResponse>('/review-api/v1/login', body)).data;
  };

  public getUser = async (): Promise<UserResponse> => {
    return (await wasabiAxios.get<UserResponse>('/review-api/v1/user')).data;
  };

  public logoutUser = async (): Promise<unknown> => {
    return (await wasabiAxios.post<unknown>('/review-api/v1/logout', {})).data;
  };
}

export default AuthApi;
