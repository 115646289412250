export enum ContentStatus {
  UPLOADED = 'uploaded',
  PENDING_AUTO = 'pending-auto',
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  ACTIVE = 'active',
  INACTIVE = 'inactive', // Content is inactive but encodings still exist
  DELETED = 'deleted', // Content is inactive and encodings have been deleted
}
