import { InputLabel, MenuItem, Select as MaterialSelect } from '@mui/material';
import React from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

import theme from '../../../common/util/theme';
import { FormErrorProp } from './Form.types';

interface OwnProps<T> extends UseControllerProps<T> {
  id: string;
  label: string;
  options: Record<string, string | number>;
  disabled?: boolean;
  required?: boolean;
}

function Select<T>(props: OwnProps<T> & FormErrorProp): JSX.Element {
  const { name, control, id, label, options, disabled, error, required } = props;

  const {
    field: { onChange, value },
  } = useController({ name, control });

  return (
    <div className="py-4">
      <div className="relative">
        {label && (
          <InputLabel
            htmlFor={id}
            sx={{
              position: 'absolute',
              fontSize: '12px',
              top: '-6px',
              left: '8px',
              padding: '0 6px',
              backgroundColor: 'white',
              zIndex: 1,
              color: '#626C7',
            }}
          >
            {label}
          </InputLabel>
        )}

        <MaterialSelect
          fullWidth
          variant="outlined"
          id={id}
          value={value}
          onChange={onChange}
          disabled={disabled}
          required={required}
          error={!!error}
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${theme.colors.gray[700]}`,
            },
            '&:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${theme.colors.blue[500]}`,
            },
          }}
        >
          {Object.keys(options).map((key) => (
            <MenuItem key={key} value={key}>
              {options[key]}
            </MenuItem>
          ))}
        </MaterialSelect>
      </div>
    </div>
  );
}

export default Select;
