export const initialState: BrandValuesState = {
  contentBrandValues: undefined,
};

export interface BrandValuesState {
  contentBrandValues?: BrandValuesInterface;
}

export type BrandValuesInterface = {
  [key: string]: string;
};
