import { RootState } from '../../../common/store/store';
import { CollectionSearchOptions } from '../../api/CollectionApi';
import { CollectionSearchResult } from '../../api/CollectionApi.types';
import { CollectionSearchPaginationState } from './CollectionSearch.reducer';

export const selectIsSearching = (state: RootState): boolean => state.collectionTool.search.isSearching;

export const selectSearchResults = (state: RootState): CollectionSearchResult | undefined =>
  state.collectionTool.search.searchResults;

export const selectSearchOptions = (state: RootState): CollectionSearchOptions =>
  state.collectionTool.search.searchOptions;

export const selectIncludeMultiAsset = (state: RootState): boolean => state.collectionTool.search.includeMultiAsset;

export const selectPagination = (state: RootState): CollectionSearchPaginationState =>
  state.collectionTool.search.pagination;
