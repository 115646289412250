import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';

import { logger } from '../../../common/util/logger';
import { WaveformData } from '../../components/audioPreview/audioProgressBar';
import {
  AudioActionType,
  AudioPlayerAction,
  ChangePlaybackStateAction,
  ChangeReviewBatchAction,
  ChangeTrackAction,
  PlaybackState,
  SeekToTimeAction,
  StockItemErroredAction,
  StockItemFullyViewedAction,
  UpdateElapsedTimeAction,
  UpdateWaveformDataAction,
} from './AudioPlayer.actions';

export enum AudioViewedState {
  VIEWED = 1,
  NOT_VIEWED,
  PARTIALLY_VIEWED,
}

export interface AudioPlayerStockItem {
  waveformData?: WaveformData;
  elapsedTimeMs: number;
  errored: boolean;
  waveformError: boolean;
  viewedState: AudioViewedState;
}

export interface AudioPlayerStockItemMap {
  [stockItemId: string]: AudioPlayerStockItem;
}

export interface AudioPlayerState {
  stockItems: AudioPlayerStockItemMap;
  player: {
    stockItemId?: string;
    audioMp3Url?: string;
    playbackState: PlaybackState;
    isWaveformLoading: boolean;
    seekTimeMs?: number;
  };
  reviewBatchId: string;
}

/**
 * @param stockItemIds {string[]} List of StockItemIDs to be reviewed in the current batch. Used for priming the state with default values - if you forget to do this you will get null reference errors.
 * @param reviewBatchId {string} The batch id that the list of stock item ids came from
 * @return {Readonly<AudioPlayerState>} initial state for audio player
 */
export const initialAudioPlayerState = (stockItemIds: string[], reviewBatchId: string): Readonly<AudioPlayerState> => {
  return {
    stockItems: createDefaultStockItemMap(stockItemIds),
    player: {
      playbackState: PlaybackState.paused,
      isWaveformLoading: true,
    },
    reviewBatchId: reviewBatchId,
  };
};

const createDefaultStockItemMap = (stockItemIds: string[]) => {
  return stockItemIds.reduce((map: AudioPlayerStockItemMap, id) => {
    map[id] = {
      elapsedTimeMs: 0,
      errored: false,
      waveformError: false,
      viewedState: AudioViewedState.NOT_VIEWED,
    };
    return map;
  }, {});
};

const changeTrackCallback = (prevState: AudioPlayerState, action: ChangeTrackAction) => {
  const { audioSrc, stockItemId } = action;
  const nextState = cloneDeep(prevState);
  const previousStockItemId = prevState.player.stockItemId;
  if (stockItemId !== previousStockItemId) {
    nextState.player.stockItemId = stockItemId;
    nextState.player.audioMp3Url = audioSrc;
    nextState.player.isWaveformLoading = false;
    nextState.player.playbackState = isNil(previousStockItemId) ? PlaybackState.paused : PlaybackState.playing;
  }
  return nextState;
};

const updateWaveformDataCallback = (prevState: AudioPlayerState, action: UpdateWaveformDataAction) => {
  const nextState = cloneDeep(prevState);

  const { waveformData, stockItemId } = action;
  nextState.stockItems[stockItemId].waveformData = waveformData;
  nextState.stockItems[stockItemId].waveformError = !waveformData;
  return nextState;
};

const updateElapsedTimeCallback = (prevState: AudioPlayerState, action: UpdateElapsedTimeAction) => {
  const nextState = cloneDeep(prevState);
  const { elapsedTimeMs, stockItemId } = action;
  nextState.stockItems[stockItemId].elapsedTimeMs = elapsedTimeMs;
  if (nextState.stockItems[stockItemId].viewedState !== AudioViewedState.VIEWED) {
    nextState.stockItems[stockItemId].viewedState = AudioViewedState.PARTIALLY_VIEWED;
  }
  return nextState;
};

const changePlaybackStateCallback = (prevState: AudioPlayerState, action: ChangePlaybackStateAction) => {
  const { playbackState } = action;
  const nextState = cloneDeep(prevState);
  nextState.player.playbackState = playbackState;
  return nextState;
};

const togglePlaybackStateCallback = (prevState: AudioPlayerState) => {
  const { playbackState: previousPlaybackState } = prevState.player;
  const nextState = cloneDeep(prevState);
  const nextPlaybackState =
    previousPlaybackState === PlaybackState.playing ? PlaybackState.paused : PlaybackState.playing;
  nextState.player.playbackState = nextPlaybackState;
  return nextState;
};

const seekToTimeCallback = (prevState: AudioPlayerState, action: SeekToTimeAction) => {
  const nextState = cloneDeep(prevState);
  const { seekTimeMs } = action;
  nextState.player.seekTimeMs = seekTimeMs;
  return nextState;
};

const stockItemErroredCallback = (prevState: AudioPlayerState, action: StockItemErroredAction) => {
  const nextState = cloneDeep(prevState);
  const { stockItemId } = action;
  nextState.stockItems[stockItemId].errored = true;
  return nextState;
};

const stockItemFullyViewedCallback = (prevState: AudioPlayerState, action: StockItemFullyViewedAction) => {
  const nextState = cloneDeep(prevState);
  const { stockItemId } = action;
  nextState.stockItems[stockItemId].viewedState = AudioViewedState.VIEWED;
  return nextState;
};

const changeReviewBatchCallback = (prevState: AudioPlayerState, action: ChangeReviewBatchAction) => {
  const { stockItemIds, reviewBatchId } = action;
  const nextState = cloneDeep(prevState);
  nextState.reviewBatchId = reviewBatchId;
  nextState.stockItems = createDefaultStockItemMap(stockItemIds);
  return nextState;
};

export const audioPlayerReducer = (
  prevState: Readonly<AudioPlayerState>,
  action: AudioPlayerAction
): Readonly<AudioPlayerState> => {
  const { actionType } = action;

  switch (actionType) {
    case AudioActionType.changeTrack:
      return changeTrackCallback(prevState, action as ChangeTrackAction);

    case AudioActionType.updateWaveformData:
      return updateWaveformDataCallback(prevState, action as UpdateWaveformDataAction);

    case AudioActionType.updateElapsedTime:
      return updateElapsedTimeCallback(prevState, action as UpdateElapsedTimeAction);

    case AudioActionType.changePlaybackState:
      return changePlaybackStateCallback(prevState, action as ChangePlaybackStateAction);

    case AudioActionType.togglePlaybackState:
      return togglePlaybackStateCallback(prevState);

    case AudioActionType.seekToTime:
      return seekToTimeCallback(prevState, action as SeekToTimeAction);

    case AudioActionType.stockItemErrored:
      return stockItemErroredCallback(prevState, action as StockItemErroredAction);

    case AudioActionType.stockItemFullyViewed:
      return stockItemFullyViewedCallback(prevState, action as StockItemFullyViewedAction);

    case AudioActionType.changeReviewBatch:
      return changeReviewBatchCallback(prevState, action as ChangeReviewBatchAction);

    default:
      logger.error('Unknown audio player action type received', undefined, { actionType });
      break;
  }
  return cloneDeep(prevState);
};
