import LogRocket from 'logrocket';

import { LOG_ROCKET_ENABLED } from '../config/logRocket';

const isProd = process.env.NODE_ENV === 'production';

type LogLevel = 'info' | 'log' | 'warn' | 'debug' | 'error';

// taken from the LogRocket types
type TagPayload = Record<string, string | number | boolean>;

const log = (message: string, payload: TagPayload = {}, level: LogLevel = 'log', error?: unknown): void => {
  if (LOG_ROCKET_ENABLED) {
    if (level === 'error') {
      const capturePayload = {
        extra: payload,
      };

      if (isError(error)) {
        capturePayload.extra = {
          ...capturePayload.extra,
          message,
        };
        LogRocket.captureException(error, capturePayload);
      } else {
        LogRocket.captureMessage(message, capturePayload);
      }
    } else {
      LogRocket[level](message, payload);
    }
  }

  if (!isProd) {
    // this is so we don't have empty objects printing out and have to account for that in tests
    if (Object.keys(payload).length === 0) console[level](message);
    else console[level](message, JSON.parse(JSON.stringify(payload)));
  }
};

const isError = (error: unknown): error is Error => {
  return error instanceof Error;
};

export const logger = {
  debug: (message: string, payload?: TagPayload): void => log(message, payload, 'debug'),
  info: (message: string, payload?: TagPayload): void => log(message, payload, 'info'),
  log: (message: string, payload?: TagPayload): void => log(message, payload, 'log'),
  warn: (message: string, payload?: TagPayload): void => log(message, payload, 'warn'),
  error: (message: string, error?: unknown, payload?: TagPayload): void => log(message, payload, 'error', error),
};
