import { Button, CircularProgress } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import React from 'react';
import { useSelector } from 'react-redux';

import Alert, { AlertType } from '../../../../common/components/alert/Alert';
import { ContentClass } from '../../../../common/enums/ContentClasses';
import { RootState, useDispatch } from '../../../../common/store/store';
import { loadNewCollection, requestMoreContentSuggestions } from '../../../store/CollectionEdit/CollectionEdit.reducer';
import { selectCollection, selectIsUpdating } from '../../../store/CollectionEdit/CollectionEdit.selectors';

const selectorForContentClass = (contentClass: ContentClass) => (state: RootState) => selector(state, contentClass);

type OwnProps = {
  contentClass: ContentClass;
};
const ContentSuggestionStatus = (props: OwnProps): JSX.Element | null => {
  const { contentClass } = props;
  const dispatch = useDispatch();
  const { collection, notRequestedYet, finishedReviewing, waitingForResponse, statusMessage, isUpdating } = useSelector(
    selectorForContentClass(contentClass)
  );

  const onRequestMore = () => {
    if (collection) {
      void dispatch(requestMoreContentSuggestions({ collectionId: collection._id, contentClass }));
    }
  };

  if (notRequestedYet) {
    return (
      <div className="w-1/2 pt-8 mx-auto">
        <Alert title="Suggestions have not been requested for this collection." type={AlertType.INFO} />
      </div>
    );
  }

  if (finishedReviewing) {
    const allContentReviewedMessage = (
      <span>
        All content suggestions have been reviewed.{' '}
        <Button variant="contained" onClick={onRequestMore} disabled={isUpdating}>
          Request More
        </Button>
      </span>
    );

    return (
      <>
        {statusMessage && <StatusMessage message={statusMessage} />}
        <div className="w-1/2 pt-8 mx-auto">
          <Alert title={allContentReviewedMessage} type={AlertType.INFO} />
        </div>
      </>
    );
  }

  if (waitingForResponse && collection) {
    return (
      <div className="grid flex justify-center pt-8">
        <div className="row-start-1 py-6 px-24 bg-gray-200">
          <div>
            <p>Waiting for content suggestions...</p>
          </div>
          <div className="flex justify-center">
            <Button color="primary" onClick={() => void dispatch(loadNewCollection(collection._id))}>
              Refresh
            </Button>
          </div>
        </div>
        <div className="row-start-2 flex justify-center py-6">
          <CircularProgress />
        </div>
      </div>
    );
  }

  //if there's a status and there's pending contentIds
  if (statusMessage) {
    return <StatusMessage message={statusMessage} />;
  }
  return null;
};

const StatusMessage = (props: { message: string }): JSX.Element => {
  return (
    <div className="pt-8">
      <Alert type={AlertType.WARNING} title="Collection Assistant Status" messages={[props.message]} />
    </div>
  );
};

export default ContentSuggestionStatus;

const selector = createSelector(
  [(state: RootState, contentClass: ContentClass) => contentClass, selectCollection, selectIsUpdating],
  (contentClass, collection, isUpdating) => {
    const content = collection?.suggestion?.[contentClass]?.content;

    const hasPendingContent = content?.pendingContentIds && content.pendingContentIds.length > 0;
    const responseDate = content?.responseDate;
    const requestDate = content?.request?.requestDate;
    const statusMessage = content?.statusMessage;

    const notRequestedYet = !content?.request?.requestDate || false;
    const finishedReviewing = !hasPendingContent && responseDate && requestDate && responseDate > requestDate;
    const waitingForResponse =
      !hasPendingContent && ((responseDate && requestDate && responseDate < requestDate) || !!requestDate);

    return { collection, notRequestedYet, finishedReviewing, waitingForResponse, statusMessage, isUpdating };
  }
);
