import { RootState } from '../../../common/store/store';
import { ReviewPermission } from '../../enums/ReviewPermissions';
import { Reviewer } from './manageReviewer.types';

export const selectReviewers = (state: RootState): (Reviewer & { id: string })[] =>
  state.review.manageReviewer.reviewers.map((reviewer) => ({
    ...reviewer,
    id: reviewer.userId,
  }));

export const selectIsModalOpen = (state: RootState): boolean => state.review.manageReviewer.isModalOpen;

export const selectEditedReviewerPermissions = (state: RootState): Record<ReviewPermission, boolean> =>
  state.review.manageReviewer.editedReviewerPermissions;

export const selectUserNameBeingEdited = (state: RootState): string => state.review.manageReviewer.userNameBeingEdited;
