import React from 'react';
import { Control, Path, useController } from 'react-hook-form';

import ChipInput from '../../../common/components/ChipInput';
import { FormErrorProp } from './Form.types';

interface OwnProps<T> extends FormErrorProp {
  label: string;
  id: string;
  name: Path<T>;
  control: Control<T>;
}

function Chips<T>(props: OwnProps<T>): JSX.Element {
  const { label, error, id, control, name } = props;

  const {
    field: { onChange, onBlur, value },
  } = useController({ name, control });

  return (
    <div className="flex py-4">
      <ChipInput
        id={id}
        value={value as string[]}
        onChange={(e, val) => onChange(val)}
        onBlur={onBlur}
        placeholder="Start typing values here"
        label={label}
        error={!!error}
        helperText={error && error.message}
      />
    </div>
  );
}

export default Chips;
