import { CheckCircle, Link, RadioButtonUnchecked } from '@mui/icons-material';
import { Card, CardActions, CardContent, CardMedia, Checkbox, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { ContentClass } from '../../../../../../common/enums/ContentClasses';
import { RootState, useDispatch } from '../../../../../../common/store/store';
import { selectContentById } from '../../../../../store/ContentList/ContentList.selectors';
import {
  addSelectedToRejected,
  removeSelectedFromRejected,
} from '../../../../../store/SuggestionList/SuggestionList.reducer';

interface SuggestedContentItemProps {
  id: number;
}

const SuggestedContentItem = (props: SuggestedContentItemProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const { id } = props;
  const content = useSelector(selector(id));

  const thumbnail =
    'https://dm0qx8t0i9gc9.cloudfront.net/thumbnails/image/rDtN98Qoishumwih/image-placeholder-stroke-icon_MyL9N8L__thumb.jpg';

  const updateRejectedList = (changeEvent: React.ChangeEvent, checked: boolean) => {
    if (checked) {
      dispatch(addSelectedToRejected(id));
    } else {
      dispatch(removeSelectedFromRejected(id));
    }
  };

  //If unable to fetch content
  if (!content) {
    return (
      <Card className={`mt-4 w-80 h-52 relative border-2 border-transparent`}>
        <div className="flex h-full">
          <CardMedia component="img" className="w-full h-full" image={thumbnail} alt={`thumbnail-${id}`} />
        </div>
        <CardContent className="absolute bottom-0 w-full text-sm text-white bg-gradient-to-t from-black">
          <h3 className="font-bold absolute inset-x-0 bottom-0 px-2 text-center truncate max-w-30">
            Unable to fetch content (ID: {id})
          </h3>
        </CardContent>
        <CardActions className="absolute top-0 w-full bg-gradient-to-b from-gray-400 to-transparent">
          <div className="grid grid-cols-2 w-full self-start">
            <div className="col-span-1 col-start-2 flex justify-end">
              <Checkbox
                color="secondary"
                icon={<RadioButtonUnchecked />}
                checkedIcon={<CheckCircle />}
                onChange={updateRejectedList}
              />
            </div>
          </div>
        </CardActions>
      </Card>
    );
  }

  const image = content.encodings?.thumbnail?.length ? content.encodings.thumbnail[0].uri : thumbnail;

  let linkToContent;
  if (process.env.REACT_APP_WASABI_BASE_URI === 'https://contribute.storyblocks.com') {
    linkToContent = `https://www.storyblocks.com/${content.class}/stock/${content.urlId || ''}`;
  } else if (process.env.REACT_APP_WASABI_BASE_URI === 'https://wasabi-staging.videoblocksdev.com') {
    linkToContent = `https://sb-staging.videoblocksdev.com/${content.class}/stock/${content.urlId || ''}`;
  } else {
    linkToContent = `https://www.storyblocks.com/${content.class}/stock/${content.urlId || ''}`;
  }

  return (
    <div>
      <Card className={`mt-4 w-80 h-52 relative border-2 border-transparent`}>
        <div className="flex h-full">
          <CardMedia component="img" className="w-full h-full" image={image} alt={content.meta.title} />
        </div>
        <CardContent className="absolute bottom-0 w-full text-sm text-white bg-gradient-to-t from-black">
          <h3 className="font-bold absolute inset-x-0 bottom-0 px-2 text-center truncate max-w-30">
            {content.meta.title}
          </h3>
        </CardContent>
        <CardActions className="absolute top-0 w-full bg-gradient-to-b from-gray-400 to-transparent">
          <div className="grid grid-cols-2 w-full self-start">
            <div className="col-span-1 col-start-1 flex justify-start">
              <div>
                <a target="_blank" rel="noopener noreferrer" href={linkToContent}>
                  <Tooltip title="View on Storyblocks.com">
                    <IconButton>
                      <Link color="action" />
                    </IconButton>
                  </Tooltip>
                </a>
              </div>
            </div>
            <div className="col-span-1 col-start-2 flex justify-end">
              <Checkbox
                color="secondary"
                icon={<RadioButtonUnchecked />}
                checkedIcon={<CheckCircle />}
                onChange={updateRejectedList}
              />
            </div>
          </div>
        </CardActions>
      </Card>
      {content.class === ContentClass.audio && content.encodings?.preview?.[0]?.uri && (
        <audio className="pt-4" controls>
          <source src={content.encodings.preview[0].uri} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}
    </div>
  );
};
export default SuggestedContentItem;

const selector = (contentId: number) => (state: RootState) => selectContentById(state, contentId);
