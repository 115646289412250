import { Layers } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { GridCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useDispatch } from '../../../common/store/store';
import { logger } from '../../../common/util/logger';
import { openEditReviewerModal, refreshReviewers } from '../../store/manageReviewer/manageReviewer.reducer';
import { selectReviewers } from '../../store/manageReviewer/manageReviewer.selectors';

function ReviewersTable(): JSX.Element | null {
  const dispatch = useDispatch();
  useEffect(() => {
    void dispatch(refreshReviewers());
  }, [dispatch]);

  const reviewers = useSelector(selectReviewers);

  if (!reviewers || reviewers.length === 0) {
    return null;
  }

  // Webstorm says the "renderCell" method below is unused, but it lies - Clark - 2022-10-31
  // noinspection JSUnusedGlobalSymbols
  const columns: GridColDef[] = [
    {
      headerName: 'Edit Permissions',
      sortable: false,
      filterable: false,
      field: 'userId',
      renderCell: (params: GridCellParams<string>) => {
        const userId = params.value;

        if (!userId) {
          logger.error('No user id for user, which should never happen.', { rowNode: params.rowNode });
          return;
        }

        return (
          <IconButton aria-label="edit" onClick={() => dispatch(openEditReviewerModal(userId))}>
            <Layers />
          </IconButton>
        );
      },
    },
    { headerName: 'First', field: 'firstName' },
    { headerName: 'Last', field: 'lastName' },
    { headerName: 'Email', field: 'email' },
    { headerName: 'Day Reviewed', field: 'dayReviewed' },
    { headerName: 'Day Approved', field: 'dayApproved' },
    { headerName: 'Day Rejected', field: 'dayRejected' },
    { headerName: 'Day Skipped', field: 'daySkipped' },
    { headerName: 'Week Reviewed', field: 'weekReviewed' },
    { headerName: 'Week Approved', field: 'weekApproved' },
    { headerName: 'Week Rejected', field: 'weekRejected' },
    { headerName: 'Week Skipped', field: 'weekSkipped' },
    { headerName: 'Month Reviewed', field: 'monthReviewed' },
    { headerName: 'Month Approved', field: 'monthApproved' },
    { headerName: 'Month Rejected', field: 'monthRejected' },
    { headerName: 'Month Skipped', field: 'monthSkipped' },
    { headerName: 'Total Reviewed', field: 'totalReviewed' },
    { headerName: 'Total Approved', field: 'totalApproved' },
    { headerName: 'Total Rejected', field: 'totalRejected' },
    { headerName: 'Total Skipped', field: 'totalSkipped' },
  ].map((c) => ({ ...c, headerAlign: 'center', headerClassName: 'bg-yellow-500' }));

  return (
    <div className="flex h-full grow" data-private={'lipsum'}>
      <DataGrid columns={columns} rows={reviewers} pageSize={10} autoHeight disableSelectionOnClick density="compact" />
    </div>
  );
}

export default ReviewersTable;
