import LogRocket from 'logrocket';
import React, { useEffect, useRef } from 'react';

import { logger } from '../../../common/util/logger';
import { getMillisecondsFromSeconds, getSecondsFromMilliseconds } from '../../../common/util/util';
import {
  AudioPlayerAction,
  PlaybackState,
  stockItemErrored,
  stockItemFullyViewed,
  updateElapsedTime,
} from '../../store/audio/AudioPlayer.actions';
import { AudioPlayerState } from '../../store/audio/AudioPlayer.reducer';

interface OwnProps {
  dispatch: React.Dispatch<AudioPlayerAction>;
  audioPlayerState: AudioPlayerState;
  skipStockItem: () => void;
}

export const AudioElement = ({ dispatch, audioPlayerState, skipStockItem }: OwnProps): JSX.Element => {
  const ref = useRef<HTMLAudioElement>(null);
  const lastStockItemId = useRef<string>();

  const { player, stockItems } = audioPlayerState;
  const { stockItemId, audioMp3Url, playbackState, seekTimeMs } = player;
  const { elapsedTimeMs } = stockItemId ? stockItems[stockItemId] : { elapsedTimeMs: 0 };

  const updatePlaybackState = () => {
    if (ref.current && stockItemId) {
      if (playbackState === PlaybackState.playing && ref.current.paused) {
        ref.current.play().catch((err) => logger.error('Error playing audio file', err));
      } else if (playbackState === PlaybackState.paused && !ref.current.paused) {
        ref.current.pause();
      }
    }
  };
  useEffect(updatePlaybackState, [playbackState]);

  // seek effect
  useEffect(() => {
    if (ref.current && seekTimeMs !== undefined) {
      ref.current.currentTime = getSecondsFromMilliseconds(seekTimeMs);
    }
  }, [seekTimeMs]);

  useEffect(() => {
    // if the stock item changed ensure we save where the last item was at
    if (lastStockItemId.current && ref.current && lastStockItemId.current !== stockItemId) {
      const currentTimeMs = getMillisecondsFromSeconds(ref.current.currentTime);
      dispatch(updateElapsedTime(lastStockItemId.current, currentTimeMs));
    }

    if (ref.current && audioMp3Url && audioMp3Url !== ref.current.src) {
      ref.current.src = audioMp3Url;
      ref.current.currentTime = getSecondsFromMilliseconds(elapsedTimeMs);
    }

    lastStockItemId.current = stockItemId;
  }, [stockItemId, audioMp3Url, dispatch, elapsedTimeMs]);

  return (
    <audio
      ref={ref}
      onLoadedData={() => updatePlaybackState()}
      onTimeUpdate={() =>
        stockItemId && ref.current && dispatch(updateElapsedTime(stockItemId, ref.current.currentTime * 1000))
      }
      onError={(err) => {
        LogRocket.captureMessage(`Audio ${stockItemId || ''} failed to load`, {
          extra: { src: err.currentTarget.src },
        });
        if (stockItemId) {
          dispatch(stockItemErrored(stockItemId));
          skipStockItem();
        }
      }}
      onEnded={() => stockItemId && dispatch(stockItemFullyViewed(stockItemId))}
    />
  );
};
