import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ContentClass } from '../../../common/enums/ContentClasses';
import { setNewObjectOrPushToExisting } from '../../../common/util/util';
import { GroupType } from '../batch/batch.types';
import { initialState } from './group.state';

export type GroupPayload = {
  groups: GroupType[];
  contentClass: ContentClass;
};
export const groupsSlice = createSlice({
  name: 'groupsByClass',
  initialState,
  reducers: {
    groupsSucceeded: (state, action: PayloadAction<GroupPayload>) => {
      const { contentClass, groups } = action.payload;
      const existingGroupsInClass = state?.[contentClass] ?? {};

      const newMappedGroups = groups.reduce<Record<string, GroupType[]>>((acc, val) => {
        const valueType = val.type ?? '';
        return setNewObjectOrPushToExisting(acc, valueType, val);
      }, {});

      state[contentClass] = {
        ...existingGroupsInClass,
        ...newMappedGroups,
      };
    },
  },
});

export default groupsSlice.reducer;
