import { convertS3ToCloudFrontUri } from '../../common/util/util';
import { Content } from '../api/CollectionApi.types';
import ContentApi from '../api/ContentApi';

export async function fetchContentFromIds(contentIds: number[]): Promise<Content[]> {
  const { content } = await ContentApi.getContentViaContentIds(contentIds);
  return content.map((item) => {
    if (item.encodings.thumbnail.length) {
      item.encodings.thumbnail[0].uri = convertS3ToCloudFrontUri(item.encodings.thumbnail[0].uri);
    } else {
      console.warn('No thumbnails found');
    }
    if (item.encodings.preview.length) {
      item.encodings.preview[0].uri = convertS3ToCloudFrontUri(item.encodings.preview[0].uri);
    } else {
      console.warn('No previews found');
    }
    return item;
  });
}
