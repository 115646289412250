import React from 'react';

import { RemoveIcon, SelectIcon } from '../../../common/assets/icons/select-remove-icon';
import PreviewStateStatusIcon, { PreviewState } from '../previewStateStatusIcon/PreviewStateStatusIcon';
import { GenericBatchContentListItemProps } from '../reviewListItem/BatchContentListItem';
import './VideoPreviewThumbnail.scss';

export type VideoPreviewThumbnailProps = {
  isInappropriate: boolean;
  contentRequestId?: number;
};

type OwnProps = VideoPreviewThumbnailProps & GenericBatchContentListItemProps;

export default function VideoPreviewThumbnail(props: OwnProps): JSX.Element {
  const {
    thumbnailUrl,
    stockItemId,
    isCurrent,
    isSelected,
    isInappropriate,
    previewState,
    idx,
    handleKeyDown,
    handleSelectionToggle,
    thumbnailRef,
    handlePreviewClick,
    contentRequestId,
  } = props;

  const overlayClasses = 'thumbnailOverlay absolute z-10 w-full h-full bg-cool-gray-700 bg-opacity-50';
  const topRightIconClasses = 'absolute h-6 top-3 right-3 z-20';
  const topLeftIconClasses = 'absolute top-3 left-3 z-20';
  const innappropriateBarClasses = 'h-2 absolute w-full bg-orange-500 bottom-0';
  const borderClasses = isSelected ? 'border-4 border-blue-600' : 'border border-gray-400';

  const contentRequestLabelClasses = `${topLeftIconClasses} text-sm text-white font-bold rounded-md bg-pink-700 px-1 py-0.5`;

  const previewIcon = <PreviewStateStatusIcon previewState={previewState} classes={topRightIconClasses} />;
  const overlayDiv =
    previewState === PreviewState.REJECTED || previewState === PreviewState.INVALID ? (
      <div className={overlayClasses} />
    ) : null;

  return (
    <div className="previewThumbnail relative my-1 mx-1">
      <div
        className="relative"
        role="button"
        ref={thumbnailRef}
        onClick={(e: React.MouseEvent) => handlePreviewClick(e, true)}
        tabIndex={0}
        onKeyDown={(e) => handleKeyDown(e, stockItemId, idx, true)}
      >
        {contentRequestId && <span className={contentRequestLabelClasses}>CR</span>}
        {previewIcon}
        {overlayDiv}
        <div className={`relative border-solid ${borderClasses}`}>
          <img src={thumbnailUrl} alt="Preview for stock item" />
          {isInappropriate && <div className={innappropriateBarClasses} />}
        </div>
      </div>
      {!isCurrent && (
        <div
          className="selectionButton absolute right-3 bottom-3 z-30 focus:outline-none"
          role="button"
          onClick={() => handleSelectionToggle(stockItemId, true)}
          tabIndex={0}
          onKeyDown={(e) => e.preventDefault()}
        >
          {isSelected ? <RemoveIcon /> : <SelectIcon />}
        </div>
      )}
    </div>
  );
}
