import React from 'react';

import Alert, { AlertType } from '../../../common/components/alert/Alert';
import { ClickableTextField } from '../../../common/components/clickableTextField/ClickableTextField';
import SingleCheckBox from '../../../common/components/singleCheckBox/SingleCheckBox';
import {
  GenresSelect,
  InstrumentsSelect,
  MoodsSelect,
} from '../../containers/moodGenresInstruments/moodsGenresInstrumentsSelect';
import RejectionReasons from '../../containers/rejectionReasons/RejectionReasons';
import { StockItemType } from '../../store/batch/batch.types';
import Keywords from '../keywords/Keywords';

type OwnProps = {
  currentStockItem: StockItemType;
  updateStockItemMeta: (
    stockItemId: string,
    stockItemCreatorId: string,
    key: string,
    value: string[] | boolean | string,
    toastsEnabled?: boolean
  ) => void;
};

export default function AudioSingleItemEdit(props: OwnProps): JSX.Element {
  const { currentStockItem, updateStockItemMeta } = props;

  const renderTitle = () => {
    const { meta, _id, creatorId } = currentStockItem;

    return (
      <ClickableTextField
        key={_id}
        text={meta.title}
        updateTextValue={(newTitle: string) => updateStockItemMeta(_id, creatorId, 'title', newTitle)}
      />
    );
  };

  const handleKeywordsChange = (keywords: string[]) => {
    updateStockItemMeta(currentStockItem._id, currentStockItem.creatorId, 'keywords', keywords);
  };

  const renderKeywords = () => {
    const { keywords } = currentStockItem.meta;

    return (
      <div className="py-3">
        <Keywords
          keywords={keywords}
          label="Keywords:"
          id="keyword-chips"
          handleKeywordsChange={handleKeywordsChange}
        />
      </div>
    );
  };

  const renderCheckboxes = () => {
    const { meta, _id, creatorId } = currentStockItem;
    const { audio } = meta;

    const updateHasVocals = () => {
      updateStockItemMeta(_id, creatorId, 'audio.hasVocals', !audio?.hasVocals);
    };

    return (
      <div className="py-3">
        <div className="mb-4">
          <SingleCheckBox isChecked={!!meta.isExclusive} label="Is Exclusive" disabled={true} value="is-exclusive" />
        </div>
        <div>
          <SingleCheckBox isChecked={!!audio?.hasVocals} label="Vocals" handleChange={updateHasVocals} value="vocals" />
        </div>
      </div>
    );
  };

  const renderCurrentStatus = () => {
    const { status } = currentStockItem.review;

    if (status === 'pending') {
      return null;
    }
    return <Alert title={`This stock item has been ${status}`} type={AlertType.INFO} />;
  };

  const renderValidationErrors = () => {
    const validationErrors = Object.values(currentStockItem.validationFailure ?? {});
    return validationErrors.length ? (
      <div className="pb-6">
        <Alert
          title="This stock item has invalid metadata and cannot be approved"
          type={AlertType.WARNING}
          messages={validationErrors}
        />
      </div>
    ) : undefined;
  };

  const renderSelectDropdown = () => {
    return (
      <div className="grid grid-flow-row gap-y-6 py-3">
        <MoodsSelect stockItem={currentStockItem} />
        <GenresSelect stockItem={currentStockItem} />
        <InstrumentsSelect stockItem={currentStockItem} />
      </div>
    );
  };

  return (
    <div className="p-4 bg-white">
      <RejectionReasons />
      {renderCurrentStatus()}
      {renderValidationErrors()}
      {renderTitle()}
      {renderKeywords()}
      {renderSelectDropdown()}
      {renderCheckboxes()}
    </div>
  );
}
