import React from 'react';

import Button, { ButtonSize, ButtonTextSize, ButtonType } from '../../../common/components/button/Button';

type ButtonProps = {
  handlePermissionsSave: () => void;
};

function PermissionsSaveButton(props: ButtonProps): JSX.Element {
  return (
    <div>
      <Button
        isSubmit={false}
        type={ButtonType.PRIMARY}
        onClickAction={props.handlePermissionsSave}
        size={ButtonSize.MEDIUM}
        textSize={ButtonTextSize.MEDIUM}
        message="Save"
      />
    </div>
  );
}

export default PermissionsSaveButton;
