import { createSelector } from '@reduxjs/toolkit';
import LogRocket from 'logrocket';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.scss';
import { LOG_ROCKET_ENABLED } from './common/config/logRocket';
import Roles from './common/enums/Roles';
import PrivateRoute from './common/pages/PrivateRoute';
import Login from './common/pages/login/Login';
import { PRIVATE_ROUTES, ROUTES } from './common/routes';
import { useDispatch } from './common/store/store';
import { resetToasts } from './common/store/toasts/toasts.reducer';
import { selectToasts } from './common/store/toasts/toasts.selectors';
import { ToastsState, ToastTypes } from './common/store/toasts/toasts.state';
import { getUser } from './common/store/user/user.actions';
import {
  selectEmail,
  selectIsLoggedIn,
  selectLoading,
  selectName,
  selectPermissions,
  selectRoles,
  selectUserId,
  selectUsername,
} from './common/store/user/user.selectors';
import { getFlagValue } from './common/util/util';

function ReviewApp(): JSX.Element {
  const dispatch = useDispatch();

  const { isLoggedIn, permissions, roles, username, loading, toasts, name, userId, email } = useSelector(selector);

  useEffect(() => {
    if (isLoggedIn && userId && LOG_ROCKET_ENABLED) {
      LogRocket.identify(userId, {
        isAdmin: roles.includes(Roles.ADMIN),
      });
    }
  }, [isLoggedIn, userId, name, email, username, roles]);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    const fireToast = (toasts: ToastsState) => {
      switch (toasts.toastType) {
        case ToastTypes.error:
          toast.error(toasts.message, { autoClose: false });
          break;
        case ToastTypes.success:
          toast.success(toasts.message);
          break;
        case ToastTypes.warning:
          toast.warn(toasts.message);
          break;
        case ToastTypes.info:
        default:
          toast.info(toasts.message);
          break;
      }
      dispatch(resetToasts());
    };

    if (toasts.message) {
      fireToast(toasts);
    }
  }, [toasts, dispatch]);

  if (loading) {
    return <div>Loading</div>;
  }

  const {
    HOME,
    COLLECTION_SEARCH,
    COLLECTION_EDIT,
    AUDIO_BATCH_REVIEW,
    VIDEO_BATCH_REVIEW,
    MANAGE_REVIEWERS,
    VR360_REVIEW,
    TEMPLATE_REVIEW,
    CONTRIBUTOR_PORTAL,
  } = PRIVATE_ROUTES;

  const ffSingleReviewQueueEnabled = getFlagValue('SINGLE_REVIEW_QUEUE_ENABLED');

  return (
    <>
      <Router>
        <Switch>
          <Route exact path={ROUTES.LOGIN} component={Login} />
          <PrivateRoute
            userRoles={roles}
            requiredRoles={HOME.roles}
            exact
            path={HOME.path}
            Component={HOME.component}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoute
            userRoles={roles}
            requiredRoles={COLLECTION_SEARCH.roles}
            exact
            path={COLLECTION_SEARCH.path}
            Component={COLLECTION_SEARCH.component}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoute
            userRoles={roles}
            requiredRoles={COLLECTION_EDIT.roles}
            exact
            path={COLLECTION_EDIT.path}
            Component={COLLECTION_EDIT.component}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoute
            userRoles={roles}
            requiredRoles={VIDEO_BATCH_REVIEW.roles}
            requiredPermissions={VIDEO_BATCH_REVIEW.reviewPermissions}
            reviewerPermissions={permissions}
            exact
            path={VIDEO_BATCH_REVIEW.path}
            Component={VIDEO_BATCH_REVIEW.component}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoute
            userRoles={roles}
            requiredRoles={AUDIO_BATCH_REVIEW.roles}
            requiredPermissions={AUDIO_BATCH_REVIEW.reviewPermissions}
            reviewerPermissions={permissions}
            exact
            path={AUDIO_BATCH_REVIEW.path}
            Component={AUDIO_BATCH_REVIEW.component}
            isLoggedIn={isLoggedIn}
          />
          {ffSingleReviewQueueEnabled && (
            <PrivateRoute
              userRoles={roles}
              requiredRoles={VR360_REVIEW.roles}
              requiredPermissions={VR360_REVIEW.reviewPermissions}
              reviewerPermissions={permissions}
              exact
              path={VR360_REVIEW.path}
              Component={VR360_REVIEW.component}
              isLoggedIn={isLoggedIn}
            />
          )}
          {ffSingleReviewQueueEnabled && (
            <PrivateRoute
              userRoles={roles}
              requiredRoles={TEMPLATE_REVIEW.roles}
              requiredPermissions={TEMPLATE_REVIEW.reviewPermissions}
              reviewerPermissions={permissions}
              exact
              path={TEMPLATE_REVIEW.path}
              Component={TEMPLATE_REVIEW.component}
              isLoggedIn={isLoggedIn}
            />
          )}
          <PrivateRoute
            userRoles={roles}
            requiredRoles={MANAGE_REVIEWERS.roles}
            exact
            path={MANAGE_REVIEWERS.path}
            Component={MANAGE_REVIEWERS.component}
            isLoggedIn={isLoggedIn}
          />
          <PrivateRoute
            userRoles={roles}
            requiredRoles={CONTRIBUTOR_PORTAL.roles}
            exact
            path={CONTRIBUTOR_PORTAL.path}
            Component={CONTRIBUTOR_PORTAL.component}
            isLoggedIn={isLoggedIn}
          />
          <Route component={() => <h2>Page Not Found!</h2>} />
        </Switch>
      </Router>
      <ToastContainer position={toast.POSITION.TOP_CENTER} autoClose={2000} hideProgressBar />
    </>
  );
}

export default ReviewApp;

const selector = createSelector(
  [
    selectIsLoggedIn,
    selectPermissions,
    selectRoles,
    selectUsername,
    selectLoading,
    selectToasts,
    selectName,
    selectUserId,
    selectEmail,
  ],
  (isLoggedIn, permissions, roles, username, loading, toasts, name, userId, email) => ({
    isLoggedIn,
    permissions,
    roles,
    username,
    loading,
    toasts,
    name,
    userId,
    email,
  })
);
