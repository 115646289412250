import { SvgIcon } from '@mui/material';
import React from 'react';

import { ReactComponent as ErrorIcon } from '../../../common/assets/icons/error.svg';

export const AudioError = (): JSX.Element => {
  return (
    <div className="flex rounded bg-red-100">
      <div className="w-2 h-auto bg-red-500" />
      <div className="pl-4 h-auto flex py-6">
        <SvgIcon component={ErrorIcon} />
        <p className="ml-4">Audio data failed to load. The item will be skipped.</p>
      </div>
    </div>
  );
};
