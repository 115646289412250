import { Chip } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import Alert, { AlertType } from '../../../../../common/components/alert/Alert';
import Button, { ButtonSize, ButtonTextSize, ButtonType } from '../../../../../common/components/button/Button';
import { ContentClass } from '../../../../../common/enums/ContentClasses';
import { useDispatch } from '../../../../../common/store/store';
import { requestCollectionMetadataSuggestions } from '../../../../store/CollectionEdit/CollectionEdit.reducer';
import { selectCollection } from '../../../../store/CollectionEdit/CollectionEdit.selectors';
import { MetadataRequestType } from '../CollectionViewTab.types';

type OwnProps = {
  contentClass: ContentClass;
};
const CollectionViewAutomatedSuggestionForm = ({ contentClass }: OwnProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const collection = useSelector(selectCollection);

  if (!collection) {
    return null;
  }
  const contentForClass = collection.content?.[contentClass];

  const onSubmit = () => {
    const suggestion = {
      contentClassForSuggestion: contentClass,
      suggestion: {
        ...collection.suggestion,
        [contentClass]: {
          metadata: {
            ...collection?.suggestion?.[contentClass]?.metadata,
            request: {
              contentIds: contentForClass,
              requestDate: new Date(),
              requestType: MetadataRequestType.PRIMARY_AND_TOP_TAGS,
              requestId: uuidv4(),
            },
          },
        },
      },
    };
    void dispatch(requestCollectionMetadataSuggestions({ id: collection._id, suggestion }));
  };

  return (
    <>
      <div className="p-4 w-full">
        {!contentForClass?.length && (
          <div className="py-4">
            <Alert type={AlertType.WARNING} title="Please add some content before requesting metadata suggestions" />
          </div>
        )}
        <p>Content Ids:</p>
        <div className="py-4 flex flex-wrap border border-gray-400 rounded-md">
          {collection.content?.[contentClass]?.map((contentId, index) => {
            return (
              <div key={index} className="p-1">
                <Chip label={contentId} />
              </div>
            );
          })}
        </div>
        <div className="py-4 flex w-full justify-end">
          <div>
            <Button
              message="Request Automated Keywords"
              type={ButtonType.PRIMARY_YELLOW}
              size={ButtonSize.MEDIUM}
              textSize={ButtonTextSize.SMALL}
              isSubmit={true}
              onClickAction={onSubmit}
              disabled={!contentForClass?.length}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default CollectionViewAutomatedSuggestionForm;
