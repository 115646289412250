import React, { useEffect } from 'react';

import { logger } from '../../../common/util/logger';
import {
  changePlaybackState,
  changeTrack,
  PlaybackState,
  seekToTime,
  updateWaveformData,
} from '../../store/audio/AudioPlayer.actions';
import { useAudioPlayerContext } from '../../store/audio/AudioPlayer.context';
import { StockItemType } from '../../store/batch/batch.types';
import { AudioElement } from './AudioElement';
import { AudioControls } from './audioControls';
import { AudioError } from './audioError';
import { AudioProgressBar, WaveformData } from './audioProgressBar';
import { TimeBar } from './timeBar';

const MILLISECONDS_TO_REWIND = 15000;

type OwnProps = {
  stockItem: StockItemType;
  skipStockItem: (stockItemId: string, notes?: string) => void;
};
export const AudioPreview = ({ stockItem, skipStockItem }: OwnProps): JSX.Element | null => {
  const { audioPlayerState, dispatch } = useAudioPlayerContext();
  const { _id: stockItemId } = stockItem;

  // trigger change in stock item in the audio player when the overall selected stock item changes
  useEffect(() => {
    if (stockItem) {
      dispatch(changeTrack(stockItem));
    }
  }, [stockItem, dispatch]);

  const { waveformData, elapsedTimeMs, errored: stockItemError } = audioPlayerState.stockItems[stockItemId] || {};
  useEffect(() => {
    if (waveformData) return;

    const url = stockItem.content?.thumbnail?.svg?.[0];
    if (url) {
      fetch(url, {
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
      })
        .then((data) => data.json())
        .then((data: WaveformData) => {
          dispatch(updateWaveformData(stockItemId, data));
        })
        .catch((err) => {
          logger.error('Failed to fetch waveform image', err, { stockItemId });
          dispatch(updateWaveformData(stockItemId, undefined));
        });
    } else {
      logger.warn('No waveform image provided', { stockItemId });
    }
  }, [stockItem, stockItemId, waveformData, dispatch]);

  const { playbackState, isWaveformLoading } = audioPlayerState.player;

  if (isWaveformLoading) {
    return null;
  }

  const durationMs = stockItem.meta?.durationMs || 0;

  return (
    <>
      <AudioElement
        dispatch={dispatch}
        audioPlayerState={audioPlayerState}
        skipStockItem={() => skipStockItem(stockItemId, 'Audio failed to play')}
      />
      <div className="grid grid-rows-3">
        <div className="row-span-3 pt-6 pb-4">
          {stockItemError ? (
            <AudioError />
          ) : (
            <>
              <AudioProgressBar
                waveformData={waveformData}
                currentTimeMs={elapsedTimeMs}
                durationTimeMs={durationMs}
                setTimeSeeked={(timeSeekedMs: number) => dispatch(seekToTime(timeSeekedMs))}
              />
              <TimeBar currentTimeMs={elapsedTimeMs} durationMs={durationMs} />
            </>
          )}
          <AudioControls
            onPlay={() => dispatch(changePlaybackState(PlaybackState.playing))}
            onPause={() => dispatch(changePlaybackState(PlaybackState.paused))}
            rewindAudio={() => {
              const rewindTimeMs = elapsedTimeMs - MILLISECONDS_TO_REWIND;
              dispatch(seekToTime(rewindTimeMs));
            }}
            isPlaying={playbackState === PlaybackState.playing}
            disabled={stockItemError}
          />
        </div>
      </div>
    </>
  );
};
