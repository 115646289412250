import { TabContext, TabPanel } from '@mui/lab';
import { Box, Divider, Tab, Tabs } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ContentClass } from '../../../../common/enums/ContentClasses';
import { selectCollection } from '../../../store/CollectionEdit/CollectionEdit.selectors';
import CollectionMetadataSuggestionStatus from './automatedSuggestion/CollectionMetadataSuggestionStatus';
import CollectionViewAutomatedSuggestionForm from './automatedSuggestion/CollectionViewAutomatedSuggestionForm';

const CollectionMetadataSuggestionClassTabs = (): JSX.Element | null => {
  const classesWithContent = useSelector(selector);

  const [suggestionClass, updateSuggestionClass] = useState(
    (window.sessionStorage.getItem('metadataSuggestionClassTabValue') as ContentClass) ||
      (classesWithContent[0] as ContentClass)
  );

  useEffect(() => {
    window.sessionStorage.setItem('metadataSuggestionClassTabValue', suggestionClass);
  }, [suggestionClass]);

  const handleClassChange = (event: React.ChangeEvent<unknown>, newClassValue: ContentClass) => {
    updateSuggestionClass(newClassValue);
  };

  return (
    <div className="pt-4">
      <TabContext value={suggestionClass}>
        <Tabs value={suggestionClass} onChange={handleClassChange} aria-label="Content Class Toggle">
          {classesWithContent.map((contentClass, key) => (
            <Tab key={key} label={contentClass} value={contentClass} />
          ))}
        </Tabs>
        <Box className="border-2 rounded border-slate-200">
          {classesWithContent.map((contentClass, key) => (
            <TabPanel sx={{ padding: 'unset' }} value={contentClass || ''} key={key}>
              <div>
                <CollectionViewAutomatedSuggestionForm contentClass={suggestionClass} />
                <Divider />
                <CollectionMetadataSuggestionStatus contentClass={suggestionClass} />
              </div>
            </TabPanel>
          ))}
        </Box>
      </TabContext>
    </div>
  );
};
export default CollectionMetadataSuggestionClassTabs;

const selector = createSelector([selectCollection], (collection) => {
  const content = collection?.content ?? {};
  return Object.entries(content).map((contentObj) => {
    if (contentObj[1].length > 0) {
      return contentObj[0];
    }
  });
});
