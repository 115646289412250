import { Autocomplete, TextField, Chip } from '@mui/material';
import React, { FocusEventHandler, SyntheticEvent } from 'react';

import theme from '../util/theme';

export interface ChipInputProps {
  id: string;
  label: string;
  placeholder: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  options?: string[];
  value?: string[];
  onChange: (e: SyntheticEvent, value: string[]) => void;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  freeSolo?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const ChipInput = (props: ChipInputProps): JSX.Element => {
  const {
    options = [],
    label,
    onChange,
    value = [],
    placeholder,
    error,
    helperText,
    onBlur,
    disabled,
    freeSolo = true,
  } = props;

  return (
    <Autocomplete
      className="w-full"
      clearOnEscape={false}
      value={value}
      disableClearable={true}
      freeSolo={freeSolo}
      multiple
      options={options}
      onChange={onChange}
      renderInput={(params) => {
        return (
          <TextField
            label={label}
            {...params}
            error={error}
            helperText={helperText}
            onBlur={onBlur}
            disabled={disabled}
            variant="standard"
            placeholder={placeholder}
            onKeyDown={(e) => {
              // By default, if you press Backspace while the TextField is empty, then the Autocomplete will delete the most recently added value.
              if (e.key === 'Backspace') {
                e.stopPropagation();
              }
            }}
          />
        );
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            sx={{
              backgroundColor: theme.colors.gray[200],
            }}
            label={option}
            {...getTagProps({ index })}
            key={index}
          />
        ))
      }
    />
  );
};

export default ChipInput;
