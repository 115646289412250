import { set } from 'lodash';

import { wasabiAxios } from '../../common/api/wasabiAxios';
import { ContentType } from '../../common/enums/ContentTypes';
import {
  ClaimBatchSuccessPayload,
  GroupType,
  GroupTypeMap,
  StockItemType,
  ValidationFailureType,
} from '../store/batch/batch.types';

export interface UpdateMetaResponse {
  stockItem: StockItemType;
  validationFailure?: ValidationFailureType;
}

export interface BatchApproveResponse {
  numApproved: number;
}

class BatchReviewApi {
  public batchReviewSource = 'batch';

  public getBatch = async (contentTypes: ContentType[]): Promise<ClaimBatchSuccessPayload> => {
    const postData = { contentTypes };
    return (await wasabiAxios.post<ClaimBatchSuccessPayload>('/api/v1/review/claim/batch-by-type', postData)).data;
  };

  public updateMeta = async (
    stockItemId: string,
    creatorId: string,
    key: string,
    value: string[] | boolean | string | GroupType[] | GroupTypeMap
  ): Promise<UpdateMetaResponse> => {
    const updates = {
      fields: {},
      skipValidationOfChanges: true, // force our changes to go through
      validateEntireStockItemOnUpdate: true, // check for new validation failures
      requireTemplateMeta: true,
    };

    set(updates.fields, key, value);

    const route = `/api/v1/user/${creatorId}/content/${stockItemId}/update-meta`;
    return (await wasabiAxios.post<UpdateMetaResponse>(route, updates)).data;
  };

  public rejectStockItem = async (
    stockItemId: string,
    rejectReason: string,
    rejectComment?: string,
    softReject?: boolean
  ): Promise<unknown> => {
    const postData = {
      stockItemId,
      rejectReason,
      softReject,
      rejectComment,
      source: this.batchReviewSource,
    };

    const route = '/api/v1/review/reject';
    return wasabiAxios.post<unknown>(route, postData);
  };

  public rejectStockItems = async (
    stockItemIds: string[],
    rejectReason: string,
    rejectComment: string,
    softReject?: boolean
  ): Promise<unknown> => {
    const postData = {
      stockItemIds,
      rejectReason,
      rejectComment,
      softReject,
      source: this.batchReviewSource,
    };

    const route = '/api/v1/review/batch-reject';
    return wasabiAxios.post<unknown>(route, postData);
  };

  public batchApprove = async (
    reviewBatchId: string,
    stockItemIds: string[],
    autoApproveStockItemIds: string[]
  ): Promise<BatchApproveResponse> => {
    const postData = {
      reviewBatchId,
      stockItemIds,
      autoApproveStockItemIds,
      source: this.batchReviewSource,
    };
    const route = '/api/v1/review/approve';
    return (await wasabiAxios.post<BatchApproveResponse>(route, postData)).data;
  };

  public skipStockItem = async (reviewBatchId: string, stockItemId: string, notes: string): Promise<unknown> => {
    const postData = {
      reviewBatchId,
      stockItemId,
      notes: notes || undefined,
      source: this.batchReviewSource,
    };
    const route = '/api/v1/review/skip';
    return wasabiAxios.post<unknown>(route, postData);
  };
}

export default BatchReviewApi;
