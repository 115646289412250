import { wasabiAxios } from '../../common/api/wasabiAxios';
import { BrandValuesInterface } from '../store/brandValues/brandValues.state';

export interface GetBrandValuesResponse {
  brandValues: BrandValuesInterface;
}

class BrandValuesApi {
  public getBrandValues = async (): Promise<GetBrandValuesResponse> => {
    const route = '/review-api/v1/brand-values';
    return (await wasabiAxios.get<GetBrandValuesResponse>(route)).data;
  };
}

export default BrandValuesApi;
