import * as yup from 'yup';

import CrossSellButtonClass from '../../../enums/CrossSellButtonClass';

export enum FormInputNames {
  ALLOW_SEO_INDEX = 'allowSeoIndex',
  DIRECTORY_BACKGROUND_IMAGE_URL = 'directoryBackgroundImageUrl',
  HERO_BG_IMG_URL = 'heroBackgroundImageUrl',
  SHOW_IN_DIRECTORY = 'showInDirectory',
  SHOW_IN_MAIN_NAV_DROPDOWN = 'showInMainNavDropdown',
  IS_EXPLORE = 'isExplore',
  IS_HOMEPAGE = 'isHomepage',
  ROUTE_URI = 'routeUri',
  REDIRECT_URI = 'redirectUri',
  HERO_TITLE = 'heroTitle',
  HERO_SUBTITLE = 'heroSubtitle',
  DESCRIPTION = 'description',
  META_TITLE = 'metaTitle',
  META_DESCRIPTION = 'metaDescription',
  META_KEYWORDS = 'metaKeywords',
  PREVIEW_CLIP_ID = 'previewClipId',
  TOP_TAGS = 'topTags',
  CS_LEFT_BG_IMG_URL = 'crossSell.left.backgroundImageUrl',
  CS_LEFT_BUTTON_CLASS = 'crossSell.left.buttonClass',
  CS_LEFT_LINK_TEXT = 'crossSell.left.linkText',
  CS_LEFT_TITLE = 'crossSell.left.title',
  CS_LEFT_URL = 'crossSell.left.url',
  CS_RIGHT_BG_IMG_URL = 'crossSell.right.backgroundImageUrl',
  CS_RIGHT_BUTTON_CLASS = 'crossSell.right.buttonClass',
  CS_RIGHT_LINK_TEXT = 'crossSell.right.linkText',
  CS_RIGHT_TITLE = 'crossSell.right.title',
  CS_RIGHT_URL = 'crossSell.right.url',
}

export const FormSchema = yup.object().shape({
  [FormInputNames.ALLOW_SEO_INDEX]: yup.boolean(),
  [FormInputNames.CS_LEFT_BG_IMG_URL]: yup.string(),
  [FormInputNames.CS_LEFT_BUTTON_CLASS]: yup.mixed<CrossSellButtonClass>(),
  [FormInputNames.CS_LEFT_LINK_TEXT]: yup.string(),
  [FormInputNames.CS_LEFT_TITLE]: yup.string(),
  [FormInputNames.CS_LEFT_URL]: yup.string(),
  [FormInputNames.CS_RIGHT_BG_IMG_URL]: yup.string(),
  [FormInputNames.CS_RIGHT_BUTTON_CLASS]: yup.mixed<CrossSellButtonClass>(),
  [FormInputNames.CS_RIGHT_LINK_TEXT]: yup.string(),
  [FormInputNames.CS_RIGHT_TITLE]: yup.string(),
  [FormInputNames.CS_RIGHT_URL]: yup.string(),
  [FormInputNames.DESCRIPTION]: yup.string(),
  [FormInputNames.DIRECTORY_BACKGROUND_IMAGE_URL]: yup.string(),
  [FormInputNames.HERO_BG_IMG_URL]: yup.string(),
  [FormInputNames.HERO_SUBTITLE]: yup.string(),
  [FormInputNames.HERO_TITLE]: yup.string(),
  [FormInputNames.IS_EXPLORE]: yup.boolean(),
  [FormInputNames.IS_HOMEPAGE]: yup.boolean(),
  [FormInputNames.META_DESCRIPTION]: yup.string(),
  [FormInputNames.META_KEYWORDS]: yup.string(),
  [FormInputNames.META_TITLE]: yup.string(),
  [FormInputNames.PREVIEW_CLIP_ID]: yup.number().typeError('Please enter a valid clip ID'),
  [FormInputNames.REDIRECT_URI]: yup.string(),
  [FormInputNames.ROUTE_URI]: yup.string(),
  [FormInputNames.SHOW_IN_DIRECTORY]: yup.boolean(),
  [FormInputNames.SHOW_IN_MAIN_NAV_DROPDOWN]: yup.boolean(),
  [FormInputNames.TOP_TAGS]: yup
    .array()
    .of(yup.string())
    .test(
      'check-length',
      () => 'No more than 3 tags allowed',
      (value) => ((value as string[]) || []).length <= 3
    ),
});

export const ButtonClassOptions = {
  [CrossSellButtonClass.AB_GREEN]: 'AB Green',
  [CrossSellButtonClass.GS_BLUE]: 'GS Blue',
  [CrossSellButtonClass.VB_RED]: 'VB Red',
};

export enum MetadataRequestType {
  PRIMARY = 'PRIMARY',
  TOP_TAGS = 'TOP_TAGS',
  PRIMARY_AND_TOP_TAGS = 'PRIMARY_AND_TOP_TAGS',
  OUTDATED_SCHEMA = 'OUTDATED_SCHEMA',
}
