import { Create } from '@mui/icons-material';
import React, { useState } from 'react';

import { KeyboardKeys } from '../../enums/KeyboardKeys';
import TextInput from '../textInput/TextInput';
import './ClickableTextField.scss';

export interface ClickableTextFieldProps {
  text: string;
  updateTextValue: (newValue: string) => void;
}

export const ClickableTextField = ({ text, updateTextValue }: ClickableTextFieldProps): JSX.Element => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [textCopy, setTextCopy] = useState<string>(text);

  const updateAndDropEditingMode = () => {
    setEditMode(false);
    updateTextValue(textCopy);
  };
  return (
    <div className="py-3">
      {editMode ? (
        <TextInput
          margin="dense"
          defaultValue={textCopy}
          autoFocus={true}
          onKeyDown={(event: React.KeyboardEvent) => {
            if (event.key === KeyboardKeys.ENTER) updateAndDropEditingMode();
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            setTextCopy(event.target.value)
          }
          onBlur={() => updateAndDropEditingMode()}
        />
      ) : (
        <div className="grid grid-cols-12">
          <h4 tabIndex={0} onFocus={() => setEditMode(true)} className="font-bold title break-words col-span-11">
            {textCopy}
          </h4>
          <Create
            role="button"
            className="edit-button cursor-pointer justify-self-end"
            onClick={() => setEditMode(true)}
            titleAccess="Edit Title"
          />
        </div>
      )}
    </div>
  );
};
