import { FormControl, FormControlLabel, FormGroup, Switch } from '@mui/material';
import { yellow } from '@mui/material/colors';
import React from 'react';

interface Option {
  label: string;
  isChecked: boolean;
  value: string;
}

interface SwitchUserProps {
  options: Option[];
  handlePermissionsChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function SwitchesGroup(props: SwitchUserProps): JSX.Element {
  return (
    <FormControl component="fieldset">
      <FormGroup>
        {props.options.map((option) => (
          <FormControlLabel
            key={option.value}
            control={
              <Switch
                sx={{
                  color: yellow[300],
                  '&$checked': { color: yellow[500] },
                  '&$checked + $track': { backgroundColor: yellow[500] },
                }}
                checked={option.isChecked}
                onChange={props.handlePermissionsChange}
                name={option.value}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}
