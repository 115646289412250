enum Roles {
  ADMIN = 'admin', // admin powers on the portal (can view other users, superpowers, etc)
  CONTRIBUTOR = 'contributor', // contribute powers on the portal (upload, submit)
  REVIEWER = 'reviewer', // review powers on the portal (can access review queue)
  BATCH_REVIEWER = 'batch-reviewer', // batch review powers on the portal (can access batch review)
  REVIEWER_MANAGER = 'reviewer-manager', // can see the stats of other reviewers
  COLLECTION_EDITOR = 'collection-editor',
  ONLY_4K = 'only4k', // will only publish 4k formats
  NO_PAYMENT = 'no-payment', // no payment information is needed or solicited
  NO_REVIEW = 'no-review', // submitting content will automatically publish
  IMAGE_UPLOAD = 'image-upload', // can upload images-photos
  VIDEO_UPLOAD = 'video-upload', // can upload videos
  AUDIO_UPLOAD = 'audio-upload', // can upload audio
  AUTO_APPROVE = 'auto-approve', // user's account auto-approve's content submitted for approval- Difference between autoApprove and noReviewNeeded = To an autoApprove contributor, it still appears that they have to submit content (i.e. the button says submit, they go through all checks, have a 'pending' tab, etc.)
  UPLOAD_EXPIRATION_EXEMPT = 'upload-expiration-exempt', // user's uploaded content that is more than env.DAYS_UNTIL_UPLOADS_EXPIRE old will not be automatically rejected
  MANAGE_ARTISTS = 'manage-artists', // can create/edit artist profiles
  IMAGE_PARTNER = 'image-partner', // select CACE image upload partners
}

export default Roles;
