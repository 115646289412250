import { Checkbox } from '@mui/material';
import React from 'react';

interface Props {
  onChange: (includeMultiAsset: boolean) => void;
  includeMultiAsset: boolean;
}

const MultiAssetCheckbox = ({ onChange, includeMultiAsset }: Props): JSX.Element => {
  const onClick = (includeMultiAsset: boolean): void => onChange(includeMultiAsset);

  return (
    <div className="flex flex-wrap w-full">
      <span className="text-sm my-auto mr-4">Include Multi-Asset Collections</span>
      <Checkbox value={includeMultiAsset} onClick={() => onClick(!includeMultiAsset)} />
    </div>
  );
};
export default MultiAssetCheckbox;
