import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { ContentClass } from '../../../common/enums/ContentClasses';
import { useDispatch } from '../../../common/store/store';
import { ChangeNoticeType } from '../../api/CollectionApi.types';
import { generateChangeNotice, importContent } from '../../store/CollectionEdit/CollectionEdit.reducer';
import { selectCollection } from '../../store/CollectionEdit/CollectionEdit.selectors';

const ImportCollection = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const collection = useSelector(selectCollection);
  if (!collection) {
    return null;
  }

  const [open, setOpen] = React.useState(false);
  const [importId, setImportId] = React.useState('');
  const [importClass, setImportClass] = React.useState(collection.classes[0]);

  const onSubmit = () => {
    //dispatch import call
    void dispatch(
      importContent({
        id: collection._id,
        importCollectionId: parseInt(importId),
        contentClass: importClass,
      })
    );
    void dispatch(
      generateChangeNotice({
        id: parseInt(importId),
        changeNoticeType: ChangeNoticeType.IMPORT,
      })
    );
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Import">
        <Button color="primary" variant="contained" onClick={() => setOpen(true)}>
          Import from Single Asset Collection
        </Button>
      </Tooltip>

      <Dialog onClose={() => setOpen(false)} aria-labelledby={`confirm-clone-collection`} open={open}>
        <DialogTitle>Import Content</DialogTitle>
        <DialogActions>
          <div className="pt-4">
            <TextField
              id="collection-id"
              label="Collection Id"
              variant="outlined"
              value={importId}
              onChange={(event) => setImportId(event.target.value)}
              fullWidth
              required
            />
          </div>
          <div className="pt-4">
            <FormControl>
              <InputLabel id="collection-class">Class</InputLabel>
              <Select
                id="class-select"
                defaultValue={importClass}
                label="Class"
                onChange={(event) => setImportClass(event.target.value as ContentClass)}
              >
                {collection.classes.map((contentClass, key) => (
                  <MenuItem key={key} value={contentClass}>
                    {contentClass}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="pt-4">
            <Button variant="contained" color="primary" type="submit" onClick={onSubmit}>
              Import
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ImportCollection;
