import React, { useEffect, useState } from 'react';

import { SelectionIcon } from '../../../common/components/selectionIcon/SelectionIcon';
import { formatTime } from '../../../common/util/util';
import { useAudioPlayerContext } from '../../store/audio/AudioPlayer.context';
import { AudioViewedState } from '../../store/audio/AudioPlayer.reducer';
import PreviewStateStatusIcon, { PreviewState } from '../previewStateStatusIcon/PreviewStateStatusIcon';
import { GenericBatchContentListItemProps } from '../reviewListItem/BatchContentListItem';

type AudioTrackListItemProps = {
  title: string;
  durationMs: number;
};

type OwnProps = AudioTrackListItemProps & GenericBatchContentListItemProps;

export default function AudioTrackListItem(props: OwnProps): JSX.Element | null {
  const {
    title,
    durationMs,
    stockItemId,
    isSelected,
    previewState,
    idx,
    handleKeyDown,
    thumbnailRef,
    handlePreviewClick,
    handleSelectionToggle,
    numberOfSelectedIds,
    isCurrent,
  } = props;
  const { audioPlayerState } = useAudioPlayerContext();
  const [hover, setHover] = useState<boolean>(false);
  const [showSelectionIcon, setShowSelectionIcon] = useState<boolean>(hover);
  const selectionClasses = isSelected ? 'bg-blue-100 border-blue-100' : 'border-gray-200';

  useEffect(() => {
    // don't show the selection icon for the current item if only 1 item is selected
    if (isCurrent) setShowSelectionIcon(hover && numberOfSelectedIds > 1);
    else setShowSelectionIcon(hover);
  }, [hover, numberOfSelectedIds, isCurrent]);

  const previewStatusIcon =
    previewState !== PreviewState.NONE ? (
      <PreviewStateStatusIcon previewState={previewState} classes="ml-auto" />
    ) : null;

  const formattedDuration = formatTime(durationMs);

  const audioItemActionOrInfo =
    (showSelectionIcon && (
      <SelectionIcon
        key={stockItemId}
        handleClick={() => handleSelectionToggle(stockItemId, true)}
        isSelected={isSelected}
      />
    )) ||
    previewStatusIcon ||
    formattedDuration;

  const partiallyViewedClasses =
    audioPlayerState?.stockItems[stockItemId]?.viewedState === AudioViewedState.PARTIALLY_VIEWED
      ? 'bg-gray-100 border-gray-200'
      : '';
  return (
    <div
      className={`previewThumbnail w-100 h-10 border-solid border outline-none relative ${selectionClasses} ${partiallyViewedClasses}`}
      role="button"
      ref={thumbnailRef}
      onClick={(e: React.MouseEvent) => {
        handlePreviewClick(e, true);
      }}
      tabIndex={0}
      onKeyDown={(e) => handleKeyDown(e, stockItemId, idx, true)}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      {isSelected && <div className="absolute w-1 h-10 bg-blue-600" />}
      <div className="text-base flex flex-row py-2 px-4">
        <div className={`w-96 truncate ${isSelected ? 'text-blue-700 font-semibold' : 'text-black'}`}>
          {`${idx + 1}. ${title}`}
        </div>
        <div className="flex-grow text-right text-gray-500">{audioItemActionOrInfo}</div>
      </div>
    </div>
  );
}
