import AuthApi from '../../api/AuthApi';
import { AppThunk } from '../store';
import { showErrorToast } from '../toasts/toasts.reducer';
import { userSlice } from './user.reducer';

const { userLoginSucceeded, userLoginFailed, userLogoutSucceeded, getUserSucceeded, getUserFailed } = userSlice.actions;

const hasStatusCode = (obj: unknown): obj is { response?: { status?: number } } => {
  return typeof obj === 'object';
};

export const loginUser =
  (login: string, password: string): AppThunk =>
  async (dispatch) => {
    try {
      const authApi = new AuthApi();
      const payload = await authApi.loginUser({
        login,
        password,
      });
      dispatch(userLoginSucceeded(payload));
    } catch (error) {
      if (hasStatusCode(error) && error?.response?.status === 401) {
        dispatch(userLoginFailed('Username or Password incorrect.'));
      } else {
        dispatch(showErrorToast('An error occurred. Please try again later.'));
      }
    }
  };

export const logoutUser = (): AppThunk => async (dispatch) => {
  try {
    const authApi = new AuthApi();
    await authApi.logoutUser();
    dispatch(userLogoutSucceeded());
  } catch (error) {
    // Do nothing for now
  }
};

export const getUser = (): AppThunk => async (dispatch) => {
  try {
    const authApi = new AuthApi();
    const payload = await authApi.getUser();
    dispatch(getUserSucceeded(payload));
  } catch (error) {
    dispatch(getUserFailed());
  }
};
