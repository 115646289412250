import React, { useEffect } from 'react';

import { KeyboardKeys } from '../../../common/enums/KeyboardKeys';
import { isATextInput } from '../../../common/util/util';
import { AudioPlayerAction, changeReviewBatch, togglePlaybackState } from './AudioPlayer.actions';
import { audioPlayerReducer, AudioPlayerState, initialAudioPlayerState } from './AudioPlayer.reducer';

/**
 * This no-op function takes the place of a real "dispatch" function in the default AudioPlayerContext values.
 * "AudioPlayerContextProvider" will replace this with the actual dispatch method from the state reducer.
 * We only use this method to check in "useAudioPlayerContext" if there was an "AudioPlayerContextProvider" higher in
 * the React component tree.
 */
const noOpDispatch = () => null;

interface AudioPlayerContextValues {
  audioPlayerState: AudioPlayerState;
  dispatch: React.Dispatch<AudioPlayerAction>;
}

const AudioPlayerContext = React.createContext<AudioPlayerContextValues>({
  audioPlayerState: initialAudioPlayerState([], ''),
  dispatch: noOpDispatch,
});

interface AudioPlayerContextProviderProps extends React.PropsWithChildren<unknown> {
  stockItemIds: string[];
  reviewBatchId: string;
}

const AudioPlayerContextProvider = ({
  children,
  stockItemIds,
  reviewBatchId,
}: AudioPlayerContextProviderProps): JSX.Element => {
  const [state, dispatch] = React.useReducer(audioPlayerReducer, initialAudioPlayerState(stockItemIds, reviewBatchId));
  const value = { audioPlayerState: state, dispatch };

  // effect to reset the underlying state when switching to a new batch
  useEffect(() => {
    if (reviewBatchId !== state.reviewBatchId) {
      dispatch(changeReviewBatch(stockItemIds, reviewBatchId));
    }
  }, [reviewBatchId, stockItemIds, dispatch, state.reviewBatchId]);

  //effect to add listener for spacebar presses to play/pause
  useEffect(() => {
    const playPauseOnSpacebarPress = (event: KeyboardEvent) => {
      if (event.target instanceof Node) {
        const node = event.target;
        if (event.key === KeyboardKeys.SPACE && !isATextInput(node.nodeName)) {
          event.preventDefault();
          dispatch(togglePlaybackState());
        }
      }
    };

    window.addEventListener('keydown', playPauseOnSpacebarPress);
    return () => {
      window.removeEventListener('keydown', playPauseOnSpacebarPress);
    };
  }, [dispatch]);

  return <AudioPlayerContext.Provider value={value}>{children}</AudioPlayerContext.Provider>;
};

const useAudioPlayerContext = (): AudioPlayerContextValues => {
  const context = React.useContext(AudioPlayerContext);
  const { dispatch } = context;
  if (dispatch === noOpDispatch) {
    throw new Error('Attempted to use AudioPlayerContext when no AudioPlayerContextProvider available');
  }

  return context;
};

export { AudioPlayerContextProvider, useAudioPlayerContext };
