import { CircularProgress } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ContentClass } from '../../../../../../common/enums/ContentClasses';
import { useDispatch } from '../../../../../../common/store/store';
import {
  selectCollection,
  selectIsLoading,
  selectIsUpdating,
} from '../../../../../store/CollectionEdit/CollectionEdit.selectors';
import { fetchSuggestionList } from '../../../../../store/SuggestionList/SuggestionList.reducer';
import SuggestedContentItem from './SuggestedContentItem';

type OwnProps = {
  contentClass: ContentClass;
};

const SuggestionList = (props: OwnProps): JSX.Element | null => {
  const { contentClass } = props;
  const dispatch = useDispatch();

  const { collection, isLoading, isUpdating } = useSelector(selector);

  const pendingContentIds: number[] = collection?.suggestion?.[contentClass]?.content?.pendingContentIds ?? [];

  useEffect(() => {
    if (pendingContentIds.length) {
      void dispatch(fetchSuggestionList(pendingContentIds));
    }
  }, [collection, dispatch]);

  if (!pendingContentIds.length) {
    return null;
  }

  if (isLoading || isUpdating) {
    return (
      <div data-testid="collection-content-list-loading" className="flex flex-row flex-wrap pt-6 justify-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div data-testid="collection-content-list" className="grid grid-flow-row grid-cols-4">
        {pendingContentIds.map((id) => (
          <SuggestedContentItem id={id} key={id} />
        ))}
      </div>
    </>
  );
};
export default SuggestionList;

const selector = createSelector(
  [selectCollection, selectIsLoading, selectIsUpdating],
  (collection, isLoading, isUpdating) => ({ collection, isLoading, isUpdating })
);
