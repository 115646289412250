import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';

import CheckBoxIcon from '../checkBoxIcon/CheckBoxIcon';

export type OwnProps = {
  disabled?: boolean;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  label: string;
  value: string;
  isChecked: boolean;
};

export default function SingleCheckBox({ value, isChecked, handleChange, label, disabled }: OwnProps): JSX.Element {
  return (
    <FormControlLabel
      key={value}
      control={
        <Checkbox
          color="primary"
          checked={isChecked}
          onChange={handleChange}
          name={value}
          checkedIcon={<CheckBoxIcon isChecked={true} disabled={disabled} />}
          icon={<CheckBoxIcon isChecked={false} disabled={disabled} />}
        />
      }
      label={label}
      disabled={disabled}
    />
  );
}
