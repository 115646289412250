import { InputLabel } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import CheckBoxGroup from '../../../common/components/checkBoxGroup/CheckBoxGroup';
import { selectBrandValues } from '../../store/brandValues/brandValues.selectors';

type OwnProps = {
  selectedValues: string[];
};

function BrandValues({ selectedValues }: OwnProps): JSX.Element {
  const contentBrandValues = useSelector(selectBrandValues);

  const options = contentBrandValues
    ? Object.keys(contentBrandValues).map((brandValue) => {
        const isChecked = selectedValues.includes(brandValue);
        const label = contentBrandValues[brandValue];
        return {
          label,
          isChecked,
          value: brandValue,
        };
      })
    : [];

  return (
    <div className="mt-6">
      <InputLabel shrink id="brandValues-label">
        <div className="flex items-center">
          <p className="pr-2">Values:</p>
        </div>
      </InputLabel>
      <div className="mt-2">
        <CheckBoxGroup id="brandValuesGroup" options={options} disabled />
      </div>
    </div>
  );
}

export default BrandValues;
