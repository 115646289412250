import React from 'react';

import { Options } from '../../../common/components/multiSelect/MultiSelect';
import { MultiSelectDropdown } from '../../../common/components/multiSelect/MultiSelectDropdown';
import { ContentClass } from '../../../common/enums/ContentClasses';
import { useDispatch } from '../../../common/store/store';
import { updateStockItemMeta } from '../../store/batch/batch.actions';
import { GroupType } from '../../store/batch/batch.types';

const mapGroupTypeToOptions = (groupTypes?: GroupType[]): Options[] => {
  if (!groupTypes || !groupTypes.length) return [];
  return groupTypes.filter((item) => item.id).map((item) => ({ id: item.id, value: item.title, label: item.title }));
};
const mapOptionsToGroupType = (options: Options[]): GroupType[] =>
  options.map(({ id, value }) => ({ id: id as number, title: value }));

type GroupSelectProps = {
  keyName: string;
  labelName: string;
  stockItemId: string;
  contentClass: ContentClass;
  stockItemCreatorId: string;
  allTypes: GroupType[];
  selectedTypes?: GroupType[];
};

export const GroupSelect = ({
  keyName,
  labelName,
  stockItemId,
  stockItemCreatorId,
  contentClass,
  allTypes,
  selectedTypes,
}: GroupSelectProps): JSX.Element => {
  const dispatch = useDispatch();

  return (
    <MultiSelectDropdown
      key={keyName}
      allOptions={mapGroupTypeToOptions(allTypes)}
      selectedOptions={mapGroupTypeToOptions(selectedTypes)}
      setSelectedOptions={(selected: Options[]) =>
        dispatch(
          updateStockItemMeta(stockItemId, stockItemCreatorId, contentClass, {
            [keyName]: mapOptionsToGroupType(selected),
          })
        )
      }
      label={labelName}
    />
  );
};
