import { FormControlLabel, SelectChangeEvent, Switch, TextField } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { ContentClass } from '../../../common/enums/ContentClasses';
import { useDispatch } from '../../../common/store/store';
import { saveTopPanelChange } from '../../store/CollectionEdit/CollectionEdit.reducer';
import { selectCollection } from '../../store/CollectionEdit/CollectionEdit.selectors';
import CollectionClasses from './curation/CollectionClasses';

const CollectionEditTop = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const collection = useSelector(selectCollection);

  if (!collection) {
    return null;
  }

  const handleNameChange = (event: React.FocusEvent<HTMLInputElement>) => {
    void dispatch(saveTopPanelChange({ name: event.target.value }));
  };

  const handleShowInApiChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    void dispatch(saveTopPanelChange({ showInApi: event.target.checked }));
  };

  const handleIsSearchIndexableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    void dispatch(saveTopPanelChange({ isSearchIndexable: event.target.checked }));
  };

  const handleClassesChange = (event: SelectChangeEvent<unknown>) => {
    void dispatch(saveTopPanelChange({ classes: event.target.value as ContentClass[] }));
  };

  const created = collection.createdAt
    ? new Date(Date.parse(collection.createdAt)).toLocaleDateString('en-us', {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })
    : '';

  const updated = collection.updatedAt
    ? new Date(Date.parse(collection.updatedAt)).toLocaleDateString('en-us', {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })
    : '';

  return (
    <div className="grid grid-cols-4">
      <div className="col-start-1 row-start-1 col-span-1">
        <div>
          <TextField
            id="name"
            label="Name"
            variant="standard"
            onBlur={handleNameChange}
            defaultValue={collection.name}
            fullWidth
          />
        </div>
      </div>
      <div className="col-start-1 row-start-2 col-span-1 pt-4">
        <div>
          <CollectionClasses onChange={handleClassesChange} classes={collection.classes} disabled />
        </div>
      </div>
      <div className="col-start-4 row-start-1 col-span-1 grid grid-cols-2">
        <div className="col-start-1 row-start-1 col-span-1 px-2">
          <TextField id="status" label="Status" value={collection.status} fullWidth disabled />
        </div>
        <div className="col-start-2 row-start-1 col-span-1 px-2">
          <TextField id="active" label="Active" value={collection.isActive.toString()} fullWidth disabled />
        </div>
      </div>
      <div className="col-start-4 row-start-2 col-span-1 px-2 pt-4">
        <TextField id="status" label="Created" value={created} fullWidth disabled />
      </div>
      <div className="col-start-4 row-start-3 col-span-1 px-2 pt-4">
        <TextField id="status" label="Updated" value={updated} fullWidth disabled />
      </div>
      <div className="col-start-1 row-start-3 col-span-2 pt-4">
        <FormControlLabel
          control={
            <Switch
              defaultChecked={collection.isSearchIndexable}
              onChange={handleIsSearchIndexableChange}
              name="isSearchIndexable"
            />
          }
          label="Is Search Indexable"
        />
        <FormControlLabel
          control={<Switch defaultChecked={collection.showInApi} onChange={handleShowInApiChange} name="showInApi" />}
          label="Show In API"
        />
      </div>
    </div>
  );
};

export default CollectionEditTop;
