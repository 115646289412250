import React from 'react';

interface Props {
  text: string;
  isActive: boolean;
  onClick: () => void;
}

const SearchOptionChip = ({ text, isActive, onClick }: Props): JSX.Element => {
  const styles = isActive ? 'bg-yellow-500 text-black' : 'bg-gray-200 text-gray-500';

  return (
    <span
      key={text}
      className={`rounded-full px-2 py-1 font-semibold text-sm flex align-center cursor-pointer active:bg-gray-300 border-2 border-transparent hover:border-gray-300 transition duration-300 ease-in-out ${styles}`}
      onClick={onClick}
      data-testid="search-option-chip"
    >
      {text}
    </span>
  );
};
export default SearchOptionChip;
