import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import React, { useState } from 'react';

import { ContentClass } from '../../../common/enums/ContentClasses';
import { ContentType } from '../../../common/enums/ContentTypes';
import { formatBytes, numberWithCommas } from '../../../common/util/util';
import { StockItemType } from '../../store/batch/batch.types';

type OwnProps = {
  stockItem: StockItemType;
};

function ContentDetailsTable({ stockItem }: OwnProps): JSX.Element {
  const [showingData, setShowingData] = useState(false);

  const { previewStats, content } = stockItem;
  const { class: contentClass } = content;
  const stockItemMeta = getMetaFromStockItem(stockItem);

  const columnsPerRow = contentClass === ContentClass.audio ? 2 : 3;
  const columnsClass = contentClass === ContentClass.audio ? 'grid-cols-2' : 'grid-cols-3';

  const showFileName = showingData && contentClass !== ContentClass.audio;
  const displayData = showingData ? stockItemMeta : stockItemMeta.slice(0, columnsPerRow);

  return (
    <div className="bg-white py-2 px-4 border-gray-100 border-t-2 border-b-2">
      <div className="grid grid-cols-12">
        <div className={`col-span-11 grid ${columnsClass}`}>
          {displayData.map((data, index) => {
            const isLastInRow = (index + 1) % columnsPerRow === 0;
            const borderClass = isLastInRow ? '' : 'border-r-2';
            const isFirstRow = index - columnsPerRow < 0;

            return (
              <div
                key={data}
                className={`h-8 mr-4 flex items-center justify-start border-gray-100 ${borderClass} ${
                  isFirstRow ? '' : 'pt-2 pb-2'
                }`}
              >
                <p>{data}</p>
              </div>
            );
          })}
        </div>
        <div
          onClick={() => setShowingData(!showingData)}
          className="cursor-pointer col-span-1 flex justify-end h-8 items-center"
        >
          {showingData ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </div>
      </div>
      {showFileName && (
        <span className="min-h-8 flex items-center mt-3">
          <div className="w-1/5">
            <b className="whitespace-nowrap pr-2">File Name:</b>
          </div>
          <div className="w-4/5">{previewStats.downloadFilename}</div>
        </span>
      )}
    </div>
  );
}

export default ContentDetailsTable;

function getMetaFromStockItem(stockItem: StockItemType): string[] {
  const { previewStats, meta, content } = stockItem;
  const { class: contentClass, type } = content;
  const { fileSizeBytes, width, height, frameRate, bitrateKbps, videoCodec, audioCodec } = previewStats;

  const { softwareVersion, audio } = meta;

  const allMeta: string[] = [];
  const fileSize = formatBytes(fileSizeBytes || 0);
  if (contentClass === ContentClass.video && type !== ContentType.TEMPLATE) {
    allMeta.push(fileSize);
    allMeta.push(`${width || 0}x${height || 0}`);
    allMeta.push(`${frameRate || 0} fps`);
    allMeta.push(`${numberWithCommas(bitrateKbps)} kbps`);
    allMeta.push(videoCodec || 'No video codec');
    allMeta.push(audioCodec || 'No audio codec');
  } else if (type === ContentType.TEMPLATE) {
    allMeta.push(softwareVersion || 'No software version');
    allMeta.push(`${frameRate as number} fps`);
    allMeta.push(`${fileSize || 0}`);
  } else if (contentClass === ContentClass.audio) {
    const { bpm = 0, bitDepth = 0, sampleRate = 0 } = audio || {};
    allMeta.push(`BPM: ${bpm || 0}`);
    allMeta.push(`Bit Depth: ${bitDepth || 0} bit`);
    allMeta.push(`Sample Rate: ${sampleRate || 0} kHz`);
    allMeta.push(`File Size: ${fileSize || 0}`);
  }

  return allMeta;
}
