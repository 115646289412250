import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';

import theme from '../../util/theme';

function TextInput({ placeholder, ...props }: TextFieldProps): JSX.Element {
  return (
    <TextField
      sx={{ '& input:valid:focus + fieldset': { borderColor: theme.colors.blue[500] } }}
      variant="outlined"
      placeholder={placeholder || ''}
      fullWidth
      className="text-input relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 rounded-md focus:outline-none focus:ring focus:ring-blue focus:border-blue-400 focus:z-10 sm:text-sm sm:leading-5"
      {...props}
    />
  );
}

export default TextInput;
