import { FileCopy } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogTitle, TextField, Tooltip } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { useDispatch } from '../../../common/store/store';
import { ChangeNoticeType, CreateCollectionDraft } from '../../api/CollectionApi.types';
import CollectionStatus from '../../enums/CollectionStatus';
import { createCollection, generateChangeNotice } from '../../store/CollectionEdit/CollectionEdit.reducer';
import { selectClonedId, selectCollection } from '../../store/CollectionEdit/CollectionEdit.selectors';

const CloneCollection = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const { collection, clonedId } = useSelector(selector);

  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState(`${collection?.name || ''} copy`);

  if (clonedId) {
    return <Redirect to={`/collection-tool/edit/${clonedId}`} />;
  }

  if (!collection) {
    return null;
  }

  const onSubmit = () => {
    const prunedCollection = omit(
      collection,
      '_id',
      'suggestions',
      'createdAt',
      'updatedAt',
      'stockblocksId',
      'ok',
      '__v'
    );
    const clonedCollection: CreateCollectionDraft = {
      ...prunedCollection,
      name: name || '',
      status: CollectionStatus.DRAFT,
      isActive: true,
    };
    void dispatch(createCollection(clonedCollection));
    if (collection.suggestion) {
      void dispatch(
        generateChangeNotice({
          id: collection._id,
          changeNoticeType: ChangeNoticeType.CLONE,
        })
      );
    }
  };

  return (
    <>
      <Tooltip title="Clone">
        <Button color="primary" variant="outlined" onClick={() => setOpen(true)}>
          <FileCopy />
        </Button>
      </Tooltip>

      <Dialog onClose={() => setOpen(false)} aria-labelledby={`confirm-clone-collection`} open={open}>
        <DialogTitle>Clone Collection</DialogTitle>
        <DialogActions>
          <div className="pt-4">
            <TextField
              id="name"
              label="Name"
              variant="outlined"
              value={name}
              onChange={(event) => setName(event.target.value)}
              fullWidth
              required
            />
          </div>
          <div className="pt-4">
            <Button variant="contained" color="primary" type="submit" onClick={onSubmit}>
              Clone
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default CloneCollection;

const selector = createSelector([selectCollection, selectClonedId], (collection, clonedId) => ({
  collection,
  clonedId,
}));
