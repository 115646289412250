import { InputLabel } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import AutocompleteSelect from '../../../common/components/autocompleteSelect/AutocompleteSelect';
import Button, { ButtonSize, ButtonTextSize, ButtonType } from '../../../common/components/button/Button';
import TextArea from '../../../common/components/textArea/TextArea';
import { ContentClass } from '../../../common/enums/ContentClasses';
import { useDispatch } from '../../../common/store/store';
import { filterAndSortRejectReasons } from '../../../common/util/util';
import { rejectStockItem } from '../../store/batch/batch.actions';
import { selectCurrentStockItem } from '../../store/batch/batch.selectors';
import { ReviewStatuses } from '../../store/batch/batch.types';
import { selectRejectionReasons } from '../../store/rejectionReason/rejectionReason.selectors';
import { ContentTypeRejectionReasonsInterface } from '../../store/rejectionReason/rejectionReason.state';
import { RejectionReason } from '../../store/rejectionReason/rejectionReason.types';

function RejectionReasons(): JSX.Element {
  const dispatch = useDispatch();
  const { currentStockItem, rejectionReasons } = useSelector(selector);

  const [hideRejectDetails, setHideRejectDetails] = useState(true);
  const [rejectComments, setRejectComments] = useState<string | null>(null);
  const [selectedRejectReason, setSelectedRejectReason] = useState<RejectionReason | null>(null);
  const [selectedRejectionReasonFilter, setSelectedRejectReasonFilter] = useState<string | null>(null);

  const handleSetSelectedRejectionReasonFilter = (filterName: string) => {
    if (selectedRejectionReasonFilter === filterName) {
      setSelectedRejectReasonFilter(null);
    } else {
      setSelectedRejectReasonFilter(filterName);
    }
  };

  const renderRejectReasons = () => {
    if (currentStockItem.review.status !== ReviewStatuses.pending || !rejectionReasons || !rejectionReasons.length) {
      return null;
    }

    const applicableRejectReasons = filterAndSortRejectReasons(rejectionReasons);
    let formattedApplicableReasons = applicableRejectReasons.map((reason) => ({
      ...reason,
      value: reason.value,
      label: `${reason.softReject ? 'Soft Reject: ' : ''}${reason.text || ''}`,
    }));

    const filterButtonsWithDuplicates = formattedApplicableReasons.map((reason) => reason.category || '');
    const filterButtons = Array.from(new Set(filterButtonsWithDuplicates));

    if (selectedRejectionReasonFilter) {
      formattedApplicableReasons = formattedApplicableReasons.filter((reason) => {
        return reason.category === selectedRejectionReasonFilter || reason.value === selectedRejectReason?.value;
      });
    }

    const renderCategories = () => {
      const { content } = currentStockItem;
      if (content.class === ContentClass.video) {
        return filterButtons.map((buttonText) => {
          const buttonType =
            selectedRejectionReasonFilter === buttonText ? ButtonType.INFO_ACTIVE : ButtonType.INFO_LIGHT;
          return (
            <div className="mr-4 mb-2" key={buttonText}>
              <Button
                message={buttonText}
                type={buttonType}
                size={ButtonSize.SMALL}
                textSize={ButtonTextSize.MEDIUM}
                isSubmit={false}
                onClickAction={() => handleSetSelectedRejectionReasonFilter(buttonText)}
              />
            </div>
          );
        });
      }
    };

    return (
      <div className="pb-3 flex flex-col w-full">
        <InputLabel shrink={true}>Reject Reasons:</InputLabel>
        <div className="flex flex-wrap left mt-2 mb-2"> {renderCategories()}</div>
        <AutocompleteSelect
          onSelectionUpdated={handleRejectReasonChange}
          options={formattedApplicableReasons}
          selectedValue={selectedRejectReason?.value}
          placeholder="Common Rejection Reasons"
          disabled={currentStockItem.deleted}
          openChangeValue={selectedRejectionReasonFilter || ''}
        />
      </div>
    );
  };

  const renderRejectReasonDetails = () => {
    const { status } = currentStockItem.review;
    const isRejected = status === ReviewStatuses.rejected;

    if (hideRejectDetails || status !== 'pending') {
      return null;
    }
    return (
      <div className="py-3">
        <TextArea
          defaultValue={rejectComments || selectedRejectReason?.defaultComment || ''}
          placeholder="Comments for the contributor"
          id="rejection-comment"
          label="Comments"
          onChange={handleRejectCommentChange}
          disabled={isRejected}
          variant="outlined"
          maxRows={12}
        />
        <div className="col-xs-offset-7 form-group pt-3 flex justify-end">
          <Button
            size={ButtonSize.SMALL}
            textSize={ButtonTextSize.SMALL}
            isSubmit={false}
            type={ButtonType.SECONDARY}
            message="Send Rejection"
            onClickAction={callRejectStockItem}
            disabled={isRejected}
          />
        </div>
      </div>
    );
  };

  const handleRejectReasonChange = (newSelectedReason: RejectionReason | null) => {
    const [selectedReason] =
      rejectionReasons?.filter((reason) => reason.value === (newSelectedReason && newSelectedReason.value)) || [];

    if (selectedReason) {
      setSelectedRejectReason({
        value: selectedReason.value,
        softReject: selectedReason.softReject,
        defaultComment: selectedReason.defaultComment,
      });
      setHideRejectDetails(false);
    } else {
      setSelectedRejectReason(null);
      setHideRejectDetails(true);
      setSelectedRejectReasonFilter(null);
    }
  };

  const handleRejectCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRejectComments(event.target.value);
  };

  const callRejectStockItem = () => {
    dispatch(
      rejectStockItem(
        currentStockItem._id,
        selectedRejectReason?.value || '',
        rejectComments || selectedRejectReason?.defaultComment,
        selectedRejectReason?.softReject
      )
    );
  };

  return (
    <>
      {renderRejectReasons()}
      {renderRejectReasonDetails()}
    </>
  );
}
export default RejectionReasons;

const selector = createSelector(
  [selectCurrentStockItem, selectRejectionReasons],
  (currentStockItem, rejectionReasons) => {
    const type = currentStockItem.content.type as keyof ContentTypeRejectionReasonsInterface;
    return {
      currentStockItem,
      rejectionReasons: rejectionReasons && rejectionReasons[type] ? rejectionReasons[type] : [],
    };
  }
);
