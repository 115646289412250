import { ContentType } from '../../enums/ContentTypes';
import Roles from '../../enums/Roles';

export const initialState: UserState = {
  isLoggedIn: false,
  loading: true,
};

export interface UserState {
  _id?: string;
  isLoggedIn: boolean;
  roles?: Roles[];
  permissions?: ContentType[];
  loading: boolean;
  loginErrorMessage?: string;
  username?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  profileImage?: string;
}
