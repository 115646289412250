import React from 'react';

export interface PaginationSummaryProps {
  start: number;
  end: number;
  total: number;
}

const PaginationSummary = (props: PaginationSummaryProps): JSX.Element => {
  const { start, end, total } = props;
  return (
    <p className="text-sm text-gray-700">
      Showing <span className="font-medium">{start}</span> to <span className="font-medium">{end}</span> of{' '}
      <span className="font-medium">{total}</span> results
    </p>
  );
};
export default PaginationSummary;
