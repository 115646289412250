import usePagination, { UsePaginationItem } from '@mui/material/usePagination';
import React, { ChangeEvent } from 'react';

interface PaginationControlsProps {
  numberOfPages: number;
  currentPage: number;
  onChange: (newPageNumber: number) => void;
}

const PaginationControls = (props: PaginationControlsProps): JSX.Element => {
  const { numberOfPages, currentPage, onChange } = props;

  const onChangeMiddleware = (event: ChangeEvent<unknown>, newPageNumber: number) => onChange(newPageNumber);

  const { items } = usePagination({
    boundaryCount: 1,
    count: numberOfPages,
    page: currentPage,
    hidePrevButton: false,
    hideNextButton: false,
    showFirstButton: true,
    showLastButton: true,
    onChange: onChangeMiddleware,
  });

  const paginationLinks = items.map((i, count) => {
    return (
      <ButtonLink {...i} currentPage={currentPage} key={count}>
        <PaginationButton {...i} />
      </ButtonLink>
    );
  });

  return <nav aria-label="Pagination">{paginationLinks}</nav>;
};
export default PaginationControls;

type ButtonLinkProps = React.PropsWithChildren<UsePaginationItem> & { currentPage: number };

const ButtonLink = (props: ButtonLinkProps): JSX.Element => {
  const { selected, disabled, onClick, children, type, currentPage, page } = props;

  if (disabled || type === 'end-ellipsis' || type === 'start-ellipsis' || currentPage === page) {
    return <span className={buttonClasses(selected, true)}>{children}</span>;
  }

  return (
    <button className={buttonClasses(selected, disabled)} onClick={onClick}>
      {children}
    </button>
  );
};

const buttonClasses = (selected: boolean, disabled: boolean): string => {
  const baseStyles = [
    'relative',
    'inline-flex',
    'items-center',
    'px-2',
    'py-2',
    'border',
    'bg-white',
    'text-sm',
    'font-medium',
    'text-gray-500',
  ];

  baseStyles.push(selected ? 'border-gray-500' : 'border-gray-300');
  if (!disabled) {
    baseStyles.push('hover:bg-gray-100');
  }

  return baseStyles.join(' ');
};

const PaginationButton = (item: UsePaginationItem): JSX.Element => {
  const { type } = item;
  switch (type) {
    case 'previous':
      return <p>&larr;</p>;
    case 'next':
      return <p>&rarr;</p>;
    case 'start-ellipsis':
    case 'end-ellipsis':
      return <p>...</p>;
    case 'first':
      return <p>First</p>;
    case 'last':
      return <p>Last</p>;
    default:
      return <p>{item.page}</p>;
  }
};
