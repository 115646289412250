export enum ContentType {
  SFX = 'sfx',
  MUSIC = 'music',
  LOOP = 'loop',
  PHOTO = 'photo',
  ILLUSTRATION = 'illustration',
  VECTOR = 'vector',
  MOTION_BACKGROUND = 'motion-background',
  FOOTAGE = 'footage',
  TEMPLATE = 'template',
  VR_360 = 'vr360',

  // Legacy types
  LEGACY_LOOP = 'loops',
  LEGACY_MOTION_BACKGROUND = 'animation',

  // Reviewer Batch Stats Template type
  STATS_TEMPLATES = 'templates',
}

const stringEnumValues = Object.values<string>(ContentType);
export const isContentType = (string: string): string is ContentType => {
  return stringEnumValues.includes(string);
};

export const batchVideoContentTypes = [
  ContentType.FOOTAGE,
  ContentType.MOTION_BACKGROUND,
  ContentType.LEGACY_MOTION_BACKGROUND,
];

export const allowedAudioContentTypes = [ContentType.MUSIC];
