export enum ToastTypes {
  error = 'Error',
  success = 'Success',
  warning = 'Warning',
  info = 'Info',
  none = '',
}

export const initialState: ToastsState = {
  toastType: ToastTypes.none,
  message: '',
};

export interface ToastsState {
  toastType?: ToastTypes;
  message?: string;
}
