import { wasabiAxios } from '../../common/api/wasabiAxios';
import { ContentTypeRejectionReasonsInterface } from '../store/rejectionReason/rejectionReason.state';

export interface RejectionReasonsResponse {
  rejectionReasons: ContentTypeRejectionReasonsInterface;
}

class RejectionReasonsApi {
  public getRejectionReasons = async (): Promise<RejectionReasonsResponse> => {
    const route = '/review-api/v1/rejection-reasons';
    return (await wasabiAxios.get<RejectionReasonsResponse>(route)).data;
  };
}

export default RejectionReasonsApi;
