import { Box, Button, FormLabel, SelectChangeEvent, TextField } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { ContentClass } from '../../../common/enums/ContentClasses';
import { useDispatch } from '../../../common/store/store';
import theme from '../../../common/util/theme';
import { CreateCollectionDraft } from '../../api/CollectionApi.types';
import { createCollection, updateClasses, updateName } from '../../store/CreateCollection/CreateCollection.reducer';
import {
  selectCreatedId,
  selectDraftCollection,
  selectError,
  selectIsCreating,
} from '../../store/CreateCollection/CreateCollection.selectors';
import CollectionClasses from '../collectionEditPanel/curation/CollectionClasses';

const CreateCollection = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { collection, createdId, isCreating, error } = useSelector(selector);

  useEffect(() => {
    if (createdId) {
      history.push(`/collection-tool/edit/${createdId}`);
    }
  }, [createdId, history]);

  const { handleSubmit } = useForm<CreateCollectionDraft>();

  const handleNameChange = (event: React.FocusEvent<HTMLInputElement>) => {
    dispatch(updateName(event.target.value));
  };

  const handleClassesChange = (event: SelectChangeEvent<unknown>) => {
    dispatch(
      updateClasses(
        typeof event.target.value === 'string'
          ? (event.target.value.split(',') as ContentClass[])
          : (event.target.value as ContentClass[])
      )
    );
  };

  const onSubmit = () => {
    void dispatch(createCollection(collection));
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Box sx={style}>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormLabel>Create Collection </FormLabel>
        <TextField
          id="name"
          label="Name"
          variant="outlined"
          onBlur={handleNameChange}
          defaultValue={collection.name}
          helperText={error}
          fullWidth
          required
          sx={{ marginY: theme.margin[2] }}
        />
        <CollectionClasses onChange={handleClassesChange} classes={collection.classes} />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isCreating}
          sx={{ marginY: theme.margin[2] }}
        >
          Submit
        </Button>
      </form>
    </Box>
  );
};
export default CreateCollection;

const selector = createSelector(
  [selectDraftCollection, selectCreatedId, selectIsCreating, selectError],
  (collection, createdId, isCreating, error) => ({
    collection,
    createdId,
    isCreating,
    error,
  })
);
