import { Info } from '@mui/icons-material';
import { InputLabel } from '@mui/material';
import React from 'react';

import CheckBoxGroup from '../../../common/components/checkBoxGroup/CheckBoxGroup';
import Modal from '../../../common/components/modal/Modal';
import { allVisualStandards, labelMapping, VisualStandards as VisualStandardsType } from '../../enums/VisualStandards';

type OwnProps = {
  selectedStandards: VisualStandardsType[];
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

function VisualStandards({ selectedStandards, handleChange }: OwnProps): JSX.Element {
  const options = allVisualStandards.map((standard) => {
    const isChecked = selectedStandards.includes(standard);
    const label = labelMapping(standard);
    return {
      label,
      isChecked,
      value: standard,
    };
  });
  const modalTrigger = <Info style={{ color: '#B9BCC1' }} />;
  const subHeaderClass = 'text-xl font-semibold  pt-6';
  return (
    <>
      <InputLabel shrink id="visualStandards-label">
        <div className="flex items-center">
          <p className="pr-2">Visual Standards: </p>
          <Modal
            trigger={modalTrigger}
            ariaDescription="Overview of visual standards requirements"
            ariaTitle="Visual Standards"
          >
            <>
              <h1 className="text-2xl font-semibold"> Visual Standards </h1>
              <h2 className={subHeaderClass}> Vibrancy and Contrast </h2>
              <p>
                Shot has vibrancy and contrast - non-flat lighting and use of multiple color tones. Content should have
                visual intensity and appear vivid not &quot;dull&quot;. Color and lighting contrast are necessary to
                meet this standard.
              </p>
              <h2 className={subHeaderClass}> Emotional and Inspiring</h2>
              <p>Shot conveys a clear concept or emotion.</p>
              <h2 className={subHeaderClass}> Cinematic but Practical </h2>
              We want our content to sit somewhere between the stocky, sterile, drab content stock media is known for
              and the over-stylized, art house content you find on some of our competitors. We want to have usable but
              attractive content.
            </>
          </Modal>
        </div>
      </InputLabel>
      <div className="mt-2">
        <CheckBoxGroup id="visualStandardsGroup" options={options} handleChange={handleChange} />
      </div>
    </>
  );
}

export default VisualStandards;
