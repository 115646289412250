import { VolumeUp } from '@mui/icons-material';
import React from 'react';

import { ContentClass } from '../../../common/enums/ContentClasses';
import { StockItemType } from '../../store/batch/batch.types';
import VideoPreview from '../videoPreview/VideoPreview';
import './Preview.scss';

type OwnProps = {
  stockItem: StockItemType;
};

function Preview(props: OwnProps): JSX.Element {
  return <div className="item-preview relative">{getStockItemPreview(props.stockItem)}</div>;
}

export default Preview;

function getStockItemPreview(stockItem: StockItemType) {
  const contentClass = stockItem.content.class;
  if (contentClass === ContentClass.video) {
    return (
      <>
        {stockItem.previewStats.audioCodec && (
          <div id="audio-indicator">
            <VolumeUp fontSize="large" className="fa fa-volume-up" />
          </div>
        )}
        <VideoPreview stockItem={stockItem} />
      </>
    );
  }
}
