import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../common/store/store';
import { checkRequiredFieldsByContentType } from '../../../common/util/util';
import {
  SelectedContentTypesInterface,
  SelectedGroupsInterface,
  SelectedKeywordsInterface,
  StockItemMapInterface,
  StockItemsInterface,
} from './batch.state';
import {
  ContributorType,
  ReviewStatuses,
  StockItemThumbnailData,
  StockItemType,
  TemplateMetaType,
} from './batch.types';

export const selectBatchClaimed = (state: RootState): boolean => state.review.batch.batchClaimed;

export const selectIsBatchLoading = (state: RootState): boolean => state.review.batch.isLoading;

export const selectContributor = (state: RootState): ContributorType | undefined => state.review.batch.contributor;

export const selectSelectedIds = (state: RootState): string[] => state.review.batch.stockItems.selectedIds;

export const selectSelectedId = (state: RootState): string => {
  const { selectedIds } = state.review.batch.stockItems;
  return selectedIds[0];
};

export const selectReviewBatchId = (state: RootState): string | undefined => state.review.batch.reviewBatchId;

export const selectVisibleIds = (state: RootState): string[] => state.review.batch.stockItems.visibleIds;

export const selectViewedStockItems = (state: RootState): string[] => state.review.batch.viewedStockItems;

export const selectTemplateMeta = (state: RootState): TemplateMetaType => state.review.batch.templateMeta;

export const selectCurrentStockItem = (state: RootState): StockItemType => {
  const { stockItems } = state.review.batch;
  const { byId, selectedIds } = stockItems;
  return byId[selectedIds[0]];
};

export const selectStockItemsById = (state: RootState): StockItemMapInterface => state.review.batch.stockItems.byId;

export const selectVisibleStockItems = createSelector(
  [selectSelectedIds, selectVisibleIds, selectStockItemsById, selectViewedStockItems],
  (selectedIds, visibleIds, byId, viewedStockItems): StockItemThumbnailData[] => {
    return visibleIds.map((id: string) => {
      const stockItem = byId[id];
      const isSelected = selectedIds.includes(id);
      const isViewed = viewedStockItems.includes(id);
      const { _id, thumbnailUrl, validationFailure, review, meta, contentRequestId } = stockItem;
      const { title, durationMs } = meta;
      const isCurrent = _id === selectedIds[0];
      const isRejected =
        review.status === ReviewStatuses.rejected || (isViewed && !checkRequiredFieldsByContentType(stockItem));
      const hasRequiredFields = checkRequiredFieldsByContentType(stockItem);
      return {
        id: _id,
        thumbnailUrl,
        title,
        durationMs,
        isCurrent,
        isInvalid: !!validationFailure || !hasRequiredFields,
        isReviewed: review.status !== ReviewStatuses.pending,
        isRejected,
        isViewed,
        isSelected,
        isInappropriate: meta.isSensitiveContent || false,
        contentRequestId,
      };
    });
  }
);

export const selectSelectedKeywords = (state: RootState): SelectedKeywordsInterface =>
  state.review.batch.selectedKeywords;

export const selectSelectedCategories = (state: RootState): SelectedGroupsInterface =>
  state.review.batch.selectedCategories;

export const selectSelectedMoods = (state: RootState): SelectedGroupsInterface => state.review.batch.selectedMoods;

export const selectSelectedGenres = (state: RootState): SelectedGroupsInterface => state.review.batch.selectedGenres;

export const selectSelectedInstruments = (state: RootState): SelectedGroupsInterface =>
  state.review.batch.selectedInstruments;

export const selectSelectedContentTypes = (state: RootState): SelectedContentTypesInterface =>
  state.review.batch.selectedContentTypes;

export const selectStockItems = (state: RootState): StockItemsInterface => state.review.batch.stockItems;

export const selectIsApproving = (state: RootState): boolean => state.review.batch.isApproving;
