import { ContentClass } from '../../../common/enums/ContentClasses';
import { ContentType } from '../../../common/enums/ContentTypes';
import { RootState } from '../../../common/store/store';
import { GroupType } from '../batch/batch.types';
import { CategoriesState } from './group.state';

export const selectVideoCategories = (state: RootState): GroupType[] =>
  state.review.groupsByClass[ContentClass.video].category ?? [];

export const selectMoods = (state: RootState): GroupType[] => {
  const audioGroups = state.review.groupsByClass?.[ContentClass.audio] ?? {};
  return (audioGroups.mood ?? []).filter((group) => group.searchType === ContentType.MUSIC);
};

export const selectGenres = (state: RootState): GroupType[] => {
  const audioGroups = state.review.groupsByClass?.[ContentClass.audio] ?? {};
  return (audioGroups.genre ?? []).filter((group) => group.searchType === ContentType.MUSIC);
};

export const selectInstruments = (state: RootState): GroupType[] => {
  const audioGroups = state.review.groupsByClass?.[ContentClass.audio] ?? {};
  return (audioGroups.instrument ?? []).filter((group) => group.searchType === ContentType.MUSIC);
};

export const selectGroupsByClass = (state: RootState): CategoriesState => state.review.groupsByClass;
