import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, SelectProps, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';

import SelectInput from '../selectInput/SelectInput';
import { renderValues } from './renderValues';

export type Options = {
  id?: number;
  value: string;
  label: string;
};

type OwnProps = {
  options: Options[];
  selected: Options[];
  placeholder?: string;
  id: string;
  labelId: string;
  label: string;
  onDelete?: (selected: string) => void;
  renderValuesAsChips?: boolean;
  customStyles?: Record<'labelStyles' | 'selectStyles' | 'checkBoxStyles', SxProps<Theme>>;
  disabled?: boolean;
};

function MultiSelect({
  onChange,
  selected,
  options,
  placeholder,
  id,
  labelId,
  label,
  onDelete,
  renderValuesAsChips = false,
  customStyles = { labelStyles: {}, selectStyles: {}, checkBoxStyles: {} },
  disabled = false,
  ...props
}: OwnProps & SelectProps): JSX.Element {
  const selectedValues = selected.map((option) => option.value);
  const { labelStyles, selectStyles, checkBoxStyles } = customStyles;
  return (
    <FormControl fullWidth>
      <InputLabel shrink={true} sx={labelStyles}>
        {label}
      </InputLabel>
      <Select
        labelId={labelId}
        id={id}
        multiple
        value={selectedValues}
        onChange={onChange}
        renderValue={(selected: unknown) => renderValues(selected as string[], renderValuesAsChips, disabled, onDelete)}
        fullWidth
        input={<SelectInput disabled={disabled} />}
        sx={selectStyles}
        SelectDisplayProps={{ style: { padding: 0 } }}
        {...props}
      >
        {placeholder && (
          <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {options.map((option) => {
          const checked = selected.findIndex((selected) => selected.value === option.value) > -1;
          return (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox sx={checkBoxStyles} checked={checked} />
              <ListItemText primary={option.label} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default MultiSelect;
