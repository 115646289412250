import { Save, Undo } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';

import { ContentClass } from '../../../../../common/enums/ContentClasses';
import { useDispatch } from '../../../../../common/store/store';
import { resetReorderPreview, saveCollection } from '../../../../store/CollectionEdit/CollectionEdit.reducer';
import {
  selectCollection,
  selectIsLoading,
  selectIsUpdating,
  selectNewContentIdOrder,
} from '../../../../store/CollectionEdit/CollectionEdit.selectors';
import { fetchContentList } from '../../../../store/ContentList/ContentList.reducer';
import { selectIsContentListLoading } from '../../../../store/ContentList/ContentList.selectors';
import ContentItem from './ContentItem';

export type OwnProps = {
  contentClass: ContentClass;
};

const ContentItemList = (props: OwnProps): JSX.Element | null => {
  const { contentClass } = props;
  const dispatch = useDispatch();
  const { collection, isLoading, isUpdating, newContentIdOrder, isContentListLoading } = useSelector(selector);

  useEffect(() => {
    if (contentClass && collection?.content?.[contentClass]?.length) {
      void dispatch(fetchContentList(collection.content[contentClass] || []));
      dispatch(resetReorderPreview());
    }
  }, [collection, dispatch]);

  if (!collection) {
    return null;
  }

  if (isLoading || isUpdating || isContentListLoading) {
    return (
      <div data-testid="collection-content-list-loading" className="flex flex-row flex-wrap pt-6 justify-center">
        <CircularProgress />
      </div>
    );
  }

  const contentOrder = newContentIdOrder ? newContentIdOrder : collection?.content?.[contentClass] || [];
  const onSaveClick = () => {
    const updates = {
      id: collection._id,
      updates: {
        content: {
          ...collection.content,
          [contentClass]: newContentIdOrder,
        },
      },
    };
    void dispatch(saveCollection(updates));
  };
  const onResetClick = () => dispatch(resetReorderPreview());

  return (
    <>
      <div className="my-4 grid grid-cols-3">
        <div className="col-start-2 text-center my-auto">
          <p className="text-sm font-extralight italic text-gray-700 ">Drag to re-order</p>
        </div>
        <div className="text-right">
          <span className="mx-1">
            <Button
              variant="contained"
              color="primary"
              disabled={!newContentIdOrder}
              onClick={onSaveClick}
              startIcon={<Save />}
            >
              Save
            </Button>
          </span>
          <span className="mx-1">
            <Button
              variant="outlined"
              color="secondary"
              disabled={!newContentIdOrder}
              onClick={onResetClick}
              startIcon={<Undo />}
            >
              Reset
            </Button>
          </span>
        </div>
      </div>
      <div data-testid="collection-content-list" className="grid grid-flow-row grid-cols-4">
        <DndProvider backend={HTML5Backend}>
          {contentOrder.map((id, idx) => (
            <ContentItem id={id} location={idx} contentClass={contentClass} key={id} />
          ))}
        </DndProvider>
      </div>
    </>
  );
};
export default ContentItemList;

const selector = createSelector(
  [selectCollection, selectIsLoading, selectIsUpdating, selectNewContentIdOrder, selectIsContentListLoading],
  (collection, isLoading, isUpdating, newContentIdOrder, isContentListLoading) => ({
    collection,
    isLoading,
    isUpdating,
    newContentIdOrder,
    isContentListLoading,
  })
);
