import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncThunkConfig } from '../../../common/store/store';
import { showErrorToast, showSuccessToast } from '../../../common/store/toasts/toasts.reducer';
import { logger } from '../../../common/util/logger';
import ManageReviewersApi from '../../api/ManageReviewersApi';
import { isReviewPermission, ReviewPermission } from '../../enums/ReviewPermissions';
import { initialState } from './manageReviewer.state';

const api = new ManageReviewersApi();

const refreshReviewers = createAsyncThunk('manageReviewer/refreshReviewers', async () => api.getReviewersList());

const saveReviewPermissions = createAsyncThunk<void, void, AsyncThunkConfig>(
  'manageReviewer/saveReviewPermissions',
  async (action, thunkAPI) => {
    const state = thunkAPI.getState();
    const { editedReviewerPermissions, selectedUserId } = thunkAPI.getState().review.manageReviewer;

    if (!selectedUserId) {
      throw new ManageReviewerReducerError('Invalid state for saving permissions', { state });
    }

    const stockItemTypes = Object.entries(editedReviewerPermissions)
      .filter(([, isChecked]) => isChecked)
      .map(([type]) => type);

    try {
      const response = await api.updateReviewerPermissions(stockItemTypes, selectedUserId);
      if (response.updateReviewerStockItemType) {
        thunkAPI.dispatch(showSuccessToast('Reviewer permissions updated'));
        await thunkAPI.dispatch(refreshReviewers());
      } else {
        const message = 'Failed to update reviewer permissions';
        thunkAPI.dispatch(showErrorToast(message));
        logger.error(message, { response, state });
      }
    } catch (error) {
      const message = 'Error updating reviewer permissions';
      thunkAPI.dispatch(showErrorToast(message));
      logger.error(message, error);
    }
  }
);

export const manageReviewerSlice = createSlice({
  name: 'manageReviewer',
  initialState,
  reducers: {
    openEditReviewerModal: (state, action: PayloadAction<string>) => {
      const userId = action.payload;
      const user = state.reviewers.find((r) => r.userId === userId);

      if (!user) {
        throw new ManageReviewerReducerError('No user data found for the selected user id.', { action, state });
      }

      const userPermissions = user.allowedStockItemTypes.split(',').filter(isReviewPermission);

      const newModalState = { ...initialState.editedReviewerPermissions };
      userPermissions.forEach((type) => (newModalState[type] = true));

      state.editedReviewerPermissions = newModalState;
      state.userNameBeingEdited = `${user.firstName} ${user.lastName}`;
      state.selectedUserId = userId;
      state.isModalOpen = true;
    },
    closeEditReviewerModal: (state) => {
      state.isModalOpen = false;
    },
    changeReviewPermission: (state, action: PayloadAction<{ permission: ReviewPermission; newValue: boolean }>) => {
      state.editedReviewerPermissions[action.payload.permission] = action.payload.newValue;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshReviewers.pending, (state) => {
        state.isLoading = true;
        state.reviewers = [];
      })
      .addCase(refreshReviewers.rejected, (state, action) => {
        state.isLoading = false;
        state.reviewers = [];
        logger.error('Error refreshing list of reviewers', action.error);
      })
      .addCase(refreshReviewers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reviewers = action.payload.data;
      })
      .addCase(saveReviewPermissions.fulfilled, (state) => {
        state.isModalOpen = false;
      });
  },
});

const { openEditReviewerModal, closeEditReviewerModal, changeReviewPermission } = manageReviewerSlice.actions;

export {
  refreshReviewers,
  saveReviewPermissions,
  openEditReviewerModal,
  closeEditReviewerModal,
  changeReviewPermission,
};
export default manageReviewerSlice.reducer;

class ManageReviewerReducerError extends Error {
  constructor(message: string, public readonly meta: object) {
    super(message);
  }
}
