import { AxiosResponse } from 'axios';

import { wasabiAxios } from '../../common/api/wasabiAxios';
import { Content } from './CollectionApi.types';

export interface GetContentResponse {
  content: Content[];
}

const createGetContentBatches = <T>(contentIds: T[]): T[][] => {
  if (contentIds.length <= 100) {
    return [contentIds];
  }

  const batch = contentIds.slice(0, 100);
  return [batch, ...createGetContentBatches(contentIds.slice(100))];
};

const mergeBatchResponses = (responses: AxiosResponse<GetContentResponse>[]) =>
  responses.reduce(
    (acc, val) => {
      acc.content.unshift(...val.data.content);
      return acc;
    },
    { content: [] } as GetContentResponse
  );

const getContentViaContentIds = async (contentIds: number[]): Promise<GetContentResponse> => {
  const promises = createGetContentBatches(contentIds).map((batch) =>
    wasabiAxios.get<GetContentResponse>(`/review-api/v1/content`, {
      params: { contentIds: batch },
    })
  );

  return mergeBatchResponses(await Promise.all(promises));
};

const getContentViaUrlIds = async (urlIds: string[]): Promise<GetContentResponse> => {
  const promises = createGetContentBatches(urlIds).map((batch) =>
    wasabiAxios.get<GetContentResponse>(`/review-api/v1/content`, {
      params: { urlIds: batch },
    })
  );

  return mergeBatchResponses(await Promise.all(promises));
};

export default { getContentViaContentIds, getContentViaUrlIds };
