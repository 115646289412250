import { AppThunk } from '../../../common/store/store';
import { showErrorToast } from '../../../common/store/toasts/toasts.reducer';
import RejectionReasonsApi from '../../api/RejectionReasonsApi';
import { rejectionReasonSlice } from './rejectionReason.reducer';

const { rejectReasonsSucceeded } = rejectionReasonSlice.actions;

export const makeRejectReasonsRequest = (): AppThunk => async (dispatch) => {
  try {
    const api = new RejectionReasonsApi();
    const payload = await api.getRejectionReasons();
    dispatch(rejectReasonsSucceeded(payload));
  } catch (error) {
    dispatch(showErrorToast('Error requesting rejection reasons'));
  }
};
