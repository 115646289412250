import { Cancel, Delete } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogTitle, IconButton, Tooltip } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectCollection, selectIsUpdating } from '../../../store/CollectionEdit/CollectionEdit.selectors';

type OwnProps = {
  onConfirm: () => void;
  name: string;
  color: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  icon: JSX.Element;
};

const ButtonWithDialog = (props: OwnProps): JSX.Element | null => {
  const { onConfirm, name, color, icon } = props;
  const { collection, isUpdating } = useSelector(selector);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!collection) {
    return null;
  }

  return (
    <>
      <Tooltip title={name}>
        <Button variant="outlined" color={color} disabled={isUpdating} onClick={handleClickOpen}>
          {icon}
        </Button>
      </Tooltip>
      <Dialog onClose={handleClose} aria-labelledby={`confirm-${name}-collection`} open={open}>
        <DialogTitle>{name} Collection?</DialogTitle>
        <DialogActions>
          <IconButton
            onClick={() => {
              onConfirm();
              setOpen(false);
            }}
          >
            Yes <Delete />
          </IconButton>
          <IconButton onClick={handleClose}>
            No <Cancel />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ButtonWithDialog;

const selector = createSelector([selectCollection, selectIsUpdating], (collection, isUpdating) => ({
  collection,
  isUpdating,
}));
