import { createSelector } from '@reduxjs/toolkit';
import { difference, union } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Button, { ButtonSize, ButtonTextSize, ButtonType } from '../../../common/components/button/Button';
import { Options } from '../../../common/components/multiSelect/MultiSelect';
import { MultiSelectDropdown } from '../../../common/components/multiSelect/MultiSelectDropdown';
import SingleCheckBox from '../../../common/components/singleCheckBox/SingleCheckBox';
import { ContentClass } from '../../../common/enums/ContentClasses';
import { useDispatch } from '../../../common/store/store';
import { addOrRemoveItemFromArray, getGroupsForType, getTitleArrayForGroups } from '../../../common/util/util';
import Keywords from '../../components/keywords/Keywords';
import VisualStandards from '../../components/visualStandards/VisualStandards';
import { VisualStandards as VisualStandardsType } from '../../enums/VisualStandards';
import { resetAllSelectedPreviews, updateManyStockItemsMeta } from '../../store/batch/batch.actions';
import {
  selectSelectedCategories,
  selectSelectedContentTypes,
  selectSelectedGenres,
  selectSelectedInstruments,
  selectSelectedKeywords,
  selectSelectedMoods,
  selectStockItems,
} from '../../store/batch/batch.selectors';
import { GroupsMapInterface, KeywordsMapInterface, StockItemMapInterface } from '../../store/batch/batch.state';
import { GroupType } from '../../store/batch/batch.types';
import { selectGroupsByClass } from '../../store/groups/groups.selectors';

type allowedMetaKeys = 'keywords' | 'categoryTitles' | 'moods' | 'genres' | 'instruments';

type OwnProps = {
  contentClass: ContentClass;
};

function MultiItemEdit({ contentClass }: OwnProps): JSX.Element {
  const dispatch = useDispatch();
  const {
    selectedKeywords,
    selectedCategories,
    selectedMoods,
    selectedGenres,
    selectedInstruments,
    selectedStockItemsCount,
    selectedContentTypes,
    allAvailableCategories,
    allAvailableMoods,
    allAvailableGenres,
    allAvailableInstruments,
    getStockItemsForMetaKey,
    getStockItemsForVisualStandards,
    getStockItemsForVocals,
  } = useSelector(selector);

  const [selectedVisualStandards, setSelectedVisualStandards] = useState<VisualStandardsType[]>([]);
  const [selectedHasVocals, setSelectedHasVocals] = useState<boolean>(false);

  const updateMeta = (newMetaList: string[], metaKey: allowedMetaKeys, allGroups?: GroupType[]) => {
    const selectedMeta: OldMetaType = {
      keywords: selectedKeywords,
      categoryTitles: selectedCategories,
      moods: selectedMoods,
      genres: selectedGenres,
      instruments: selectedInstruments,
    };
    let itemRemoved = false;
    let editedItems: string[] = [];
    if (newMetaList.length < selectedMeta[metaKey].length) {
      // item removed
      editedItems = difference(selectedMeta[metaKey], newMetaList);
      itemRemoved = true;
    } else if (newMetaList.length > selectedMeta[metaKey].length) {
      // item added
      editedItems = difference(newMetaList, selectedMeta[metaKey]);
    }
    const stockItemsToUpdate = getStockItemsForMetaKey(editedItems, metaKey, itemRemoved);
    dispatch(updateManyStockItemsMeta(stockItemsToUpdate, metaKey, allGroups));
  };

  const handleKeywordsChange = (keywords: string[]) => {
    updateMeta(keywords, 'keywords');
  };

  const handleVisualStandardsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const newVisualStandards = addOrRemoveItemFromArray(name as VisualStandardsType, [...selectedVisualStandards]);
    setSelectedVisualStandards(newVisualStandards);
    const stockItemsToUpdate = getStockItemsForVisualStandards(newVisualStandards);
    dispatch(updateManyStockItemsMeta(stockItemsToUpdate, 'visualStandards'));
  };

  const handleVocalsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setSelectedHasVocals(checked);
    const stockItemsToUpdate = getStockItemsForVocals(checked);
    dispatch(updateManyStockItemsMeta(stockItemsToUpdate, 'hasVocals'));
  };

  const renderGroup = (
    metaKey: allowedMetaKeys,
    selectedGroups: string[],
    label: string,
    allAvailable: GroupType[]
  ) => {
    const selectedGroupsFormatted = selectedGroups.map((selected) => {
      return {
        value: selected,
        label: selected,
      };
    });

    // Setting available groups to the selected groups because we only
    // allow adding groups if all selected stock items have the same content type
    let availableGroups = selectedGroupsFormatted;

    // If they all have the same content type, get all available groups for this content type
    if (selectedContentTypes.length === 1) {
      availableGroups = getGroupsForType(selectedContentTypes[0], allAvailable);
    }

    const updateGroups = (selected: Options[]) => {
      const groups = selected.map((option) => option.value);
      updateMeta(groups, metaKey, allAvailable);
    };

    return (
      <div className="mt-4">
        <MultiSelectDropdown
          label={label}
          key={`${label.toLowerCase()}-select`}
          selectedOptions={selectedGroupsFormatted}
          allOptions={availableGroups}
          setSelectedOptions={(selected) => updateGroups(selected)}
        />
      </div>
    );
  };

  return (
    <div className="p-4">
      <div className="pb-3">
        <h4 className="pb-3">{`${selectedStockItemsCount} items selected for editing`}</h4>
        <Button
          size={ButtonSize.SMALL}
          isSubmit={false}
          type={ButtonType.SECONDARY}
          textSize={ButtonTextSize.SMALL}
          message="Deselect"
          onClickAction={() => dispatch(resetAllSelectedPreviews())}
        />
      </div>
      {contentClass === ContentClass.video && (
        <VisualStandards selectedStandards={selectedVisualStandards} handleChange={handleVisualStandardsChange} />
      )}
      <div className="pt-3 pb-6">
        <Keywords
          keywords={selectedKeywords}
          label="Keywords:"
          id="keyword-chips"
          handleKeywordsChange={handleKeywordsChange}
        />
      </div>
      {contentClass === ContentClass.video
        ? renderGroup('categoryTitles', selectedCategories, 'Categories', allAvailableCategories)
        : [
            renderGroup('moods', selectedMoods, 'Moods:', allAvailableMoods),
            renderGroup('genres', selectedGenres, 'Genres:', allAvailableGenres),
            renderGroup('instruments', selectedInstruments, 'Instruments:', allAvailableInstruments),
          ]}
      {contentClass === ContentClass.audio && (
        <div className="py-6">
          <SingleCheckBox
            isChecked={selectedHasVocals}
            label="Vocals"
            handleChange={handleVocalsChange}
            value="vocals"
          />
        </div>
      )}
    </div>
  );
}

export default MultiItemEdit;

// Helper functions
const selector = createSelector(
  [
    selectSelectedKeywords,
    selectSelectedCategories,
    selectSelectedMoods,
    selectSelectedGenres,
    selectSelectedInstruments,
    selectSelectedContentTypes,
    selectStockItems,
    selectGroupsByClass,
  ],
  (
    selectedKeywords,
    selectedCategories,
    selectedMoods,
    selectedGenres,
    selectedInstruments,
    contentTypes,
    stockItems,
    groupsByClass
  ) => {
    const allAvailableCategories = groupsByClass[ContentClass.video]?.category || [];
    const allAvailableMoods = groupsByClass[ContentClass.audio]?.mood || [];
    const allAvailableInstruments = groupsByClass[ContentClass.audio]?.instrument || [];
    const allAvailableGenres = groupsByClass[ContentClass.audio]?.genre || [];
    const { selectedIds, byId } = stockItems;
    const selectedMeta = {
      keywords: selectedKeywords.byKeyword,
      categoryTitles: selectedCategories.byTitle,
      moods: selectedMoods.byTitle,
      instruments: selectedInstruments.byTitle,
      genres: selectedGenres.byTitle,
    };
    const aggregatedKeywords = Object.keys(selectedKeywords.byKeyword).sort();
    const aggregatedCategories = Object.keys(selectedCategories.byTitle).sort();
    const aggregatedMoods = Object.keys(selectedMoods.byTitle).sort();
    const aggregatedInstruments = Object.keys(selectedInstruments.byTitle).sort();
    const aggregatedGenres = Object.keys(selectedGenres.byTitle).sort();
    const selectedContentTypes = Object.keys(contentTypes.byContentType).sort();
    return {
      selectedKeywords: aggregatedKeywords,
      selectedCategories: aggregatedCategories,
      selectedMoods: aggregatedMoods,
      selectedGenres: aggregatedGenres,
      selectedInstruments: aggregatedInstruments,
      selectedStockItemsCount: selectedIds.length,
      selectedContentTypes,
      allAvailableCategories,
      allAvailableMoods,
      allAvailableGenres,
      allAvailableInstruments,
      getStockItemsForMetaKey: (metaData: string[], metaKey: allowedMetaKeys, itemRemoved: boolean) => {
        return getStockItemsForMetaKey(metaData, metaKey, itemRemoved, selectedMeta, byId, selectedIds);
      },
      getStockItemsForVisualStandards: (standards: VisualStandardsType[]) => {
        return getStockItemsForVisualStandards(standards, byId, selectedIds);
      },
      getStockItemsForVocals: (hasVocals: boolean) => {
        return getStockItemsForVocals(hasVocals, byId, selectedIds);
      },
    };
  }
);

type SelectedMetaType = {
  keywords: KeywordsMapInterface;
  categoryTitles: GroupsMapInterface;
  moods: GroupsMapInterface;
  genres: GroupsMapInterface;
  instruments: GroupsMapInterface;
};

type OldMetaType = {
  keywords: string[];
  categoryTitles: string[];
  moods: string[];
  genres: string[];
  instruments: string[];
};

export type StockItemDataForEdit = {
  id: string;
  creatorId: string;
  keywords?: string[];
  categoryTitles?: string[];
  moods?: string[];
  genres?: string[];
  instruments?: string[];
  visualStandards?: VisualStandardsType[];
  hasVocals?: boolean;
};

function getStockItemsForVisualStandards(
  standards: VisualStandardsType[],
  byId: StockItemMapInterface,
  selectedIds: string[]
): StockItemDataForEdit[] {
  return selectedIds.reduce((filtered: StockItemDataForEdit[], id: string) => {
    const stockItem = byId[id];
    const visualStandards = stockItem.meta.visualStandards || [];
    if (difference(visualStandards, standards)) {
      const stockItemData: StockItemDataForEdit = {
        id: stockItem._id,
        creatorId: stockItem.creatorId,
        visualStandards: standards,
      };
      filtered.push(stockItemData);
    }
    return filtered;
  }, []);
}

function getStockItemsForVocals(
  hasVocals: boolean,
  byId: StockItemMapInterface,
  selectedIds: string[]
): StockItemDataForEdit[] {
  return selectedIds.reduce((filtered: StockItemDataForEdit[], id: string) => {
    const stockItem = byId[id];
    const currentHasVocals = !!stockItem.meta.audio?.hasVocals;
    if (hasVocals !== currentHasVocals) {
      const stockItemData: StockItemDataForEdit = {
        id: stockItem._id,
        creatorId: stockItem.creatorId,
        hasVocals,
      };
      filtered.push(stockItemData);
    }
    return filtered;
  }, []);
}

/**
 * This function accepts meta data that needs to be added or removed from currently
 * selected stock items (stock item ids stored in the selectedMeta object above).
 * This returns an array of stock items and their new meta data
 * @param metaData The value to be added or removed
 * @param metaKey The type of meta that will be added or removed (ex: "keywords", "categoryTitles")
 * @param itemRemoved bool to remove or add
 * @param selectedMeta object that contains all selected meta keyed by the meta type
 * @param byId object of id => stockItem
 * @param selectedIds array of stock item ids that have the selected meta value
 * @returns {*}
 */
function getStockItemsForMetaKey(
  metaData: string[],
  metaKey: allowedMetaKeys,
  itemRemoved: boolean,
  selectedMeta: SelectedMetaType,
  byId: StockItemMapInterface,
  selectedIds: string[]
): StockItemDataForEdit[] {
  const selectedItem = metaData[0];
  const iterator = itemRemoved ? selectedMeta[metaKey][selectedItem] : selectedIds;
  return iterator.map((id: string) => {
    const stockItem = byId[id];
    const categoryTitles = getTitleArrayForGroups(stockItem.meta.stockBlocks?.categories);
    const moods = getTitleArrayForGroups(stockItem.meta.audio?.moods);
    const genres = getTitleArrayForGroups(stockItem.meta.audio?.genres);
    const instruments = getTitleArrayForGroups(stockItem.meta.audio?.instruments);
    const oldMeta: OldMetaType = {
      keywords: stockItem.meta.keywords,
      categoryTitles,
      moods,
      genres,
      instruments,
    };
    const stockItemData: StockItemDataForEdit = {
      id: stockItem._id,
      creatorId: stockItem.creatorId,
    };
    stockItemData[metaKey] = itemRemoved ? difference(oldMeta[metaKey], metaData) : union(oldMeta[metaKey], metaData);
    return stockItemData;
  });
}
