import React from 'react';

import { ContentClass } from '../../../../common/enums/ContentClasses';
import SearchOptionChip from './SearchOptionChip';

interface Props {
  onChange: (selectedClasses: ContentClass[]) => void;
  selectedClasses: ContentClass[];
}

const ContentClassSelect = ({ onChange, selectedClasses }: Props): JSX.Element => {
  const isAudioSelected = selectedClasses.includes(ContentClass.audio);
  const isImageSelected = selectedClasses.includes(ContentClass.image);
  const isVideoSelected = selectedClasses.includes(ContentClass.video);

  const onClick = (contentClass: ContentClass, currentlySelected: boolean): void =>
    onChange(
      currentlySelected
        ? selectedClasses.filter((selectedClass) => selectedClass !== contentClass)
        : selectedClasses.concat(contentClass)
    );

  return (
    <div className="flex flex-wrap w-full">
      <span className="text-sm my-auto mr-4">Content Class</span>
      <SearchOptionChip
        text="Audio"
        isActive={isAudioSelected}
        onClick={() => onClick(ContentClass.audio, isAudioSelected)}
      />
      <SearchOptionChip
        text="Image"
        isActive={isImageSelected}
        onClick={() => onClick(ContentClass.image, isImageSelected)}
      />
      <SearchOptionChip
        text="Video"
        isActive={isVideoSelected}
        onClick={() => onClick(ContentClass.video, isVideoSelected)}
      />
    </div>
  );
};
export default ContentClassSelect;
