import { combineReducers } from '@reduxjs/toolkit';

import batchReducer from './batch/batch.reducer';
import brandValuesReducer from './brandValues/brandValues.reducer';
import groupsReducer from './groups/groups.reducer';
import manageReviewerReducer from './manageReviewer/manageReviewer.reducer';
import rejectionReasonReducer from './rejectionReason/rejectionReason.reducer';
import reviewerBatchStatsReducer from './reviewerBatchStats/reviewerBatchStats.reducer';

export const reviewReducer = combineReducers({
  batch: batchReducer,
  brandValues: brandValuesReducer,
  groupsByClass: groupsReducer,
  manageReviewer: manageReviewerReducer,
  rejectionReason: rejectionReasonReducer,
  reviewerBatchStats: reviewerBatchStatsReducer,
});
