import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import React, { useEffect } from 'react';

import tailwindTheme from '../../../../common/util/theme';
import CollectionMetadataSuggestionClassTabs from './CollectionMetadataSuggestionClassTabs';
import CollectionViewForm from './CollectionViewForm';

enum ViewTabValue {
  AUTOMATED_SUGGESTIONS = 'automatedSuggestions',
  VIEW_FORM = 'viewForm',
}

const CollectionViewTab = (): JSX.Element | null => {
  const [tabValue, setTabValue] = React.useState(
    window.sessionStorage.getItem('viewTabValue') || ViewTabValue.VIEW_FORM
  );

  useEffect(() => {
    window.sessionStorage.setItem('viewTabValue', tabValue);
  }, [tabValue]);

  const handleTabChange = (event: React.ChangeEvent<unknown>, newTabValue: ViewTabValue) => {
    setTabValue(newTabValue);
  };

  return (
    <TabContext value={tabValue}>
      <TabList
        variant="standard"
        value={tabValue}
        onChange={handleTabChange}
        aria-label="View Tabs"
        TabIndicatorProps={{ sx: { display: 'none' } }}
        sx={{
          backgroundColor: tailwindTheme.colors.gray[200],
          borderRadius: '20px',
          display: 'inline-block',
          maxHeight: '40px',
          minHeight: 'unset',
          minWidth: 'unset',
        }}
      >
        <Tab
          label="Automated Suggestions"
          value={ViewTabValue.AUTOMATED_SUGGESTIONS}
          sx={{
            backgroundColor:
              tabValue === ViewTabValue.AUTOMATED_SUGGESTIONS ? '#36414D' : tailwindTheme.colors.gray[200],
            borderRadius: '20px',
            color: tabValue === ViewTabValue.AUTOMATED_SUGGESTIONS ? '#FFFFFF !important' : '#626C78',
            fontSize: '14px',
            lineHeight: '14px',
            minHeight: 'unset',
            outline: 'unset !important',
            padding: '12px 16px',
            textTransform: 'capitalize',
          }}
        />
        <Tab
          label="View Form"
          value={ViewTabValue.VIEW_FORM}
          sx={{
            backgroundColor: tabValue === ViewTabValue.VIEW_FORM ? '#36414D' : tailwindTheme.colors.gray[200],
            borderRadius: '20px',
            color: tabValue === ViewTabValue.VIEW_FORM ? '#FFFFFF !important' : '#626C78',
            fontSize: '14px',
            lineHeight: '14px',
            minHeight: 'unset',
            outline: 'unset !important',
            padding: '12px 16px',
            textTransform: 'capitalize',
          }}
        />
      </TabList>
      <div>
        <TabPanel sx={{ padding: 'unset' }} value={ViewTabValue.AUTOMATED_SUGGESTIONS}>
          <CollectionMetadataSuggestionClassTabs />
        </TabPanel>
        <TabPanel sx={{ padding: 'unset' }} value={ViewTabValue.VIEW_FORM}>
          <div className="pt-4">
            <CollectionViewForm />
          </div>
        </TabPanel>
      </div>
    </TabContext>
  );
};
export default CollectionViewTab;
