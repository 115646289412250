import { Button } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import TextInput from '../../components/textInput/TextInput';
import { PRIVATE_ROUTES } from '../../routes';
import { useDispatch } from '../../store/store';
import { loginUser } from '../../store/user/user.actions';
import { selectIsLoggedIn, selectLoginErrorMessage } from '../../store/user/user.selectors';

function Login(): JSX.Element {
  const dispatch = useDispatch();
  const { isLoggedIn, loginErrorMessage } = useSelector(selector);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  if (isLoggedIn) {
    return <Redirect to={PRIVATE_ROUTES.HOME.path} />;
  }

  const login = (e: React.FormEvent) => {
    e.preventDefault();

    if (username && password) {
      dispatch(loginUser(username, password));
      setPassword('');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full text-center">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src="https://d3g7htsbjjywiv.cloudfront.net/assets/common/images/logos/storyblocks-icon.svg"
            alt="Storyblocks Logo"
          />
          <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">Sign in to your account</h2>
        </div>
        <form onSubmit={login} className="mt-8">
          <input type="hidden" name="remember" value="true" />
          <TextInput
            id="username"
            label="Email address or username"
            defaultValue={username}
            type="text"
            required={true}
            placeholder="Email address or username"
            onChange={(e) => setUsername(e.target.value)}
            className="mb-3 rounded-md shadow-sm w-full"
            variant="standard"
          />
          <TextInput
            label="Password"
            id="password"
            value={password}
            type="password"
            required={true}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            className="mb-3 rounded-md shadow-sm w-full"
            variant="standard"
          />
          <Button variant="contained" size="medium" className="rounded-2xl" type="submit">
            Sign In
          </Button>
          <div className={`${loginErrorMessage ? 'visible' : 'invisible'} flex text-sm justify-center mt-3`}>
            <span className="px-2 block sm:inline text-red-700">{loginErrorMessage}</span>
          </div>
        </form>
      </div>
    </div>
  );
}
export default Login;

const selector = createSelector([selectIsLoggedIn, selectLoginErrorMessage], (isLoggedIn, loginErrorMessage) => ({
  isLoggedIn,
  loginErrorMessage,
}));
