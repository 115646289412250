import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';

interface ModalProps extends React.PropsWithChildren<unknown> {
  isOpen: boolean;
  onClose: () => void;
  title: string;
}

const Modal = (props: ModalProps): JSX.Element | null => {
  const { isOpen, onClose, children, title } = props;

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-40" />
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="border-0 rounded-lg shadow-lg w-full bg-white outline-none focus:outline-none py-7 px-4 w-auto relative">
          <IconButton onClick={onClose} className="absolute top-0 right-0">
            <Close />
          </IconButton>
          <div className="w-full">
            <div className="p-5 border-b border-solid border-slate-200 rounded-t">
              <span className="font-semibold" data-private={'lipsum'}>
                {title}
              </span>
            </div>
          </div>
          {children}
        </div>
      </div>
    </>
  );
};
export default Modal;
