import { Box, Chip, FormControl, Input, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';

import { ContentClass } from '../../../../common/enums/ContentClasses';
import theme from '../../../../common/util/theme';

export type OwnProps = {
  onChange: (event: SelectChangeEvent<ContentClass[]>) => void;
  classes: ContentClass[];
  disabled?: boolean;
};

const CollectionClasses = (props: OwnProps): JSX.Element | null => {
  const { onChange, classes, disabled } = props;

  return (
    <Box sx={{ marginY: theme.margin[2] }}>
      <FormControl fullWidth>
        <InputLabel id="select-classes">Classes</InputLabel>
        <Select
          labelId="select-classes-label"
          id="select-classes"
          value={classes}
          onChange={onChange}
          fullWidth
          disabled={disabled}
          required
          variant="standard"
          multiple
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {Object.keys(ContentClass).map((contentClass) => (
            <MenuItem key={contentClass} value={contentClass}>
              {contentClass}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CollectionClasses;
