import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { Content } from '../../api/CollectionApi.types';
import { fetchContentFromIds } from '../storeUtil';

const contentAdapter = createEntityAdapter<Content>({
  selectId: (content) => content._id,
});

const initialState = contentAdapter.getInitialState({
  isContentListLoading: false,
  contentListError: '',
});

const fetchContentList = createAsyncThunk('contentList/fetchContentList', async (contentList: number[]) => {
  return await fetchContentFromIds(contentList);
});

const contentSlice = createSlice({
  name: 'contentList',
  initialState: initialState,
  reducers: {
    // Can pass adapter functions directly as case reducers.  Because we're passing this
    // as a value, `createSlice` will auto-generate the `bookAdded` action type / creator
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContentList.pending, (state) => {
        state.isContentListLoading = true;
      })
      .addCase(fetchContentList.fulfilled, (state, action) => {
        state.isContentListLoading = false;
        contentAdapter.setAll(state, action.payload);
      })
      .addCase(fetchContentList.rejected, (state, action) => {
        state.isContentListLoading = false;
        if (action.error.message) {
          state.contentListError = action.error.message;
        } else {
          state.contentListError = 'Failed to load content list';
        }
      });
  },
});

const simpleSelectors = contentAdapter.getSelectors();

const { reducer } = contentSlice;

export type ContentListState = typeof initialState;

export { reducer, fetchContentList, simpleSelectors, initialState };
