import { Button, Dialog, DialogActions, DialogTitle, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { ContentClass } from '../../../../../../common/enums/ContentClasses';
import { useDispatch } from '../../../../../../common/store/store';
import { ChangeNoticeType, RejectionReason } from '../../../../../api/CollectionApi.types';
import { generateChangeNotice, saveCollection } from '../../../../../store/CollectionEdit/CollectionEdit.reducer';
import { selectCollection } from '../../../../../store/CollectionEdit/CollectionEdit.selectors';
import { resetRejected } from '../../../../../store/SuggestionList/SuggestionList.reducer';

export type OwnProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  rejectedList: number[];
  contentClass: ContentClass;
};

const RejectionReasonModal = ({ open, setOpen, rejectedList, contentClass }: OwnProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const collection = useSelector(selectCollection);
  const [reason, setReason] = React.useState(RejectionReason.QUALITY);
  const pendingContentIds = collection?.suggestion?.[contentClass]?.content?.pendingContentIds;
  if (!pendingContentIds) {
    return null;
  }

  const onSubmit = () => {
    if (rejectedList.length) {
      const rejected = rejectedList.map((id) => {
        return {
          id,
          reason,
        };
      });
      const rejectedContent = collection.suggestion?.[contentClass]?.content?.rejectedContent ?? [];
      void dispatch(
        saveCollection({
          id: collection._id,
          updates: {
            suggestion: {
              ...collection.suggestion,
              [contentClass]: {
                content: {
                  ...collection.suggestion?.[contentClass]?.content,
                  pendingContentIds: pendingContentIds.filter((id) => !rejectedList.includes(id)),
                  rejectedContent: rejectedContent.concat(rejected),
                },
              },
            },
          },
        })
      );
    } else {
      void dispatch(
        generateChangeNotice({
          id: collection._id,
          rejectEntireCollection: true,
          rejectEntireCollectionReason: reason,
          rejectContentClass: contentClass,
          changeNoticeType: ChangeNoticeType.REJECT_ALL,
        })
      );
    }
    dispatch(resetRejected());
    setOpen(false);
  };

  return (
    <Dialog onClose={() => setOpen(false)} aria-labelledby={`confirm-clone-collection`} open={open}>
      <DialogTitle>Rejection Reason</DialogTitle>
      <DialogActions className="p-6">
        <div className="p-4">
          <Select
            labelId="rejection-reason-label"
            id="rejection-reason-select"
            value={reason}
            label="Rejection Reason"
            onChange={(event) => setReason(event.target.value as RejectionReason)}
          >
            {Object.values(RejectionReason).map((reason, key) => (
              <MenuItem key={key} value={reason}>
                {reason}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="p-4">
          <Button variant="contained" color="primary" type="submit" onClick={onSubmit}>
            Reject
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default RejectionReasonModal;
