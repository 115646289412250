import { wasabiAxios } from '../../common/api/wasabiAxios';
import { ContentType } from '../../common/enums/ContentTypes';
import { ReviewerBatchStatsType } from '../store/reviewerBatchStats/reviewerBatchStats.types';

export interface QueueInfo {
  totalBatches: number;
  totalItems: number;
}

export interface ReviewersBatchStatsResponse {
  data: ReviewerBatchStatsType;
  queueBatchInfo: {
    [ContentType.FOOTAGE]: QueueInfo;
    [ContentType.LEGACY_MOTION_BACKGROUND]: QueueInfo;
    [ContentType.TEMPLATE]: QueueInfo;
    [ContentType.VR_360]: QueueInfo;
    [ContentType.PHOTO]: QueueInfo;
    [ContentType.ILLUSTRATION]: QueueInfo;
    [ContentType.VECTOR]: QueueInfo;
    [ContentType.MUSIC]: QueueInfo;
  };
}

class ReviewerBatchStatsApi {
  public getReviewerBatchStats = async (): Promise<ReviewersBatchStatsResponse> => {
    const route = '/review-api/v1/reviewers/stats/batch-submitted';
    return (await wasabiAxios.get<ReviewersBatchStatsResponse>(route)).data;
  };
}

export default ReviewerBatchStatsApi;
