import { ContentType } from '../../enums/ContentTypes';
import Roles from '../../enums/Roles';
import { RootState } from '../store';

export const selectIsLoggedIn = (state: RootState): boolean => state.user.isLoggedIn;

export const selectLoginErrorMessage = (state: RootState): string | undefined => state.user.loginErrorMessage;

export const selectPermissions = (state: RootState): ContentType[] | undefined => state.user.permissions;

export const selectRoles = (state: RootState): Roles[] => state.user.roles ?? [];

export const selectUsername = (state: RootState): string | undefined => state.user.username;

export const selectProfileImage = (state: RootState): string | undefined => state.user.profileImage;

export const selectLoading = (state: RootState): boolean => state.user.loading;

export const selectName = (state: RootState): string => `${state.user.firstName ?? ''} ${state.user.lastName ?? ''}`;

export const selectUserId = (state: RootState): string | undefined => state.user._id;

export const selectEmail = (state: RootState): string | undefined => state.user.email;
