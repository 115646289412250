import { QueueInfo } from '../../api/ReviewerBatchStatsApi';
import { ReviewerBatchStatsType } from './reviewerBatchStats.types';

export const initialState: ReviewerBatchStatsState = {
  isLoading: true,
  contentTypeReviewerBatchStats: undefined,
  queueCounts: {
    video: {
      totalBatches: 0,
      totalItems: 0,
    },
    audio: {
      totalBatches: 0,
      totalItems: 0,
    },
    vr360: {
      totalBatches: 0,
      totalItems: 0,
    },
    template: {
      totalBatches: 0,
      totalItems: 0,
    },
  },
};

export interface ReviewQueueCounts {
  video: QueueInfo;
  audio: QueueInfo;
  vr360: QueueInfo;
  template: QueueInfo;
}

export interface ReviewerBatchStatsState {
  isLoading: boolean;
  queueCounts: ReviewQueueCounts;
  contentTypeReviewerBatchStats?: ReviewerBatchStatsType;
}
