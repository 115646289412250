import { Typography } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import { difference } from 'lodash';
import React, { SyntheticEvent, useMemo } from 'react';
import { useSelector } from 'react-redux';

import ChipInput from '../../../../../common/components/ChipInput';
import Button, { ButtonSize, ButtonTextSize, ButtonType } from '../../../../../common/components/button/Button';
import { ContentClass } from '../../../../../common/enums/ContentClasses';
import { useDispatch } from '../../../../../common/store/store';
import { displayError, saveCollection } from '../../../../store/CollectionEdit/CollectionEdit.reducer';
import { selectCollection } from '../../../../store/CollectionEdit/CollectionEdit.selectors';

type OwnProps = {
  contentClass: ContentClass;
};
const MetadataAutomatedSuggestion = ({ contentClass }: OwnProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const selector = useMemo(
    () =>
      createSelector([selectCollection], (collection) => {
        const pendingTopTags = collection?.suggestion?.[contentClass]?.metadata?.pendingTopTags;
        const pendingKeywords = collection?.suggestion?.[contentClass]?.metadata?.pendingKeywords;
        const currentTopTagsLength = collection?.view?.topTags?.length ?? 0;
        const rejectedTopTags = collection?.suggestion?.[contentClass]?.metadata?.rejectedTopTags ?? [];
        const rejectedKeywords = collection?.suggestion?.[contentClass]?.metadata?.rejectedKeywords ?? [];
        const submitTopTagsDisabled = !pendingTopTags || pendingTopTags.length + currentTopTagsLength > 3;
        return {
          collection,
          pendingTopTags,
          pendingKeywords,
          currentTopTagsLength,
          rejectedTopTags,
          rejectedKeywords,
          submitTopTagsDisabled,
        };
      }),
    [contentClass]
  );

  const {
    collection,
    pendingTopTags,
    pendingKeywords,
    currentTopTagsLength,
    rejectedTopTags,
    rejectedKeywords,
    submitTopTagsDisabled,
  } = useSelector(selector);

  if (!collection || (!pendingTopTags && !pendingKeywords)) {
    return null;
  }

  const onTopTagChange = (e: SyntheticEvent, newPendingTopTags: string[]) => {
    const removedTags = difference(pendingTopTags, newPendingTopTags);
    const newRejectedTags = [...rejectedTopTags, ...removedTags];
    void dispatch(
      saveCollection({
        id: collection._id,
        updates: {
          suggestion: {
            ...collection.suggestion,
            [contentClass]: {
              metadata: {
                ...collection.suggestion?.[contentClass]?.metadata,
                pendingTopTags: newPendingTopTags,
                rejectedTopTags: newRejectedTags,
              },
            },
          },
        },
      })
    );
  };

  const onTopTagSubmit = () => {
    if (pendingTopTags && pendingTopTags.length + currentTopTagsLength <= 3) {
      void dispatch(
        saveCollection({
          id: collection._id,
          updates: {
            view: {
              ...collection.view,
              allowSeoIndex: collection.view?.allowSeoIndex || true,
              isExplore: collection.view?.isExplore || false,
              isHomepage: collection.view?.isHomepage || false,
              showInDirectory: collection.view?.showInDirectory || true,
              topTags: collection.view?.topTags ? collection.view.topTags.concat(pendingTopTags) : pendingTopTags,
            },
            suggestion: {
              ...collection.suggestion,
              [contentClass]: {
                metadata: {
                  ...collection.suggestion?.[contentClass]?.metadata,
                  pendingTopTags: [],
                  approvedTopTags: collection.suggestion?.[contentClass]?.metadata?.approvedTopTags
                    ? collection?.suggestion?.[contentClass]?.metadata?.approvedTopTags?.concat(pendingTopTags)
                    : pendingTopTags,
                },
              },
            },
          },
        })
      );
    } else {
      dispatch(
        displayError(
          'Adding Suggested Top Tags is over the max limit of 3. Please check to make sure you there are not more than 3 top tags in the suggestions or remove top tags in the view form.'
        )
      );
    }
  };

  const onKeywordChange = (e: SyntheticEvent, newPendingKeywords: string[]) => {
    const removedKeywords = difference(pendingKeywords, newPendingKeywords);
    const newRejectedKeywords = [...rejectedKeywords, ...removedKeywords];
    void dispatch(
      saveCollection({
        id: collection._id,
        updates: {
          suggestion: {
            ...collection.suggestion,
            [contentClass]: {
              metadata: {
                ...collection.suggestion?.[contentClass]?.metadata,
                pendingKeywords: newPendingKeywords,
                rejectedKeywords: newRejectedKeywords,
              },
            },
          },
        },
      })
    );
  };

  const onKeywordSubmit = () => {
    if (pendingKeywords) {
      void dispatch(
        saveCollection({
          id: collection._id,
          updates: {
            view: {
              ...collection.view,
              allowSeoIndex: collection.view?.allowSeoIndex || true,
              isExplore: collection.view?.isExplore || false,
              isHomepage: collection.view?.isHomepage || false,
              showInDirectory: collection.view?.showInDirectory || true,
              metaKeywords: collection.view?.metaKeywords
                ? `${collection.view.metaKeywords},${pendingKeywords.join(',')}`
                : pendingKeywords.join(','),
            },
            suggestion: {
              ...collection.suggestion,
              [contentClass]: {
                metadata: {
                  ...collection.suggestion?.[contentClass]?.metadata,
                  pendingKeywords: [],
                  approvedKeywords: collection.suggestion?.[contentClass]?.metadata?.approvedKeywords
                    ? collection?.suggestion?.[contentClass]?.metadata?.approvedKeywords?.concat(pendingKeywords)
                    : pendingKeywords,
                },
              },
            },
          },
        })
      );
    }
  };

  return (
    <div className="py-4">
      <div className="pt-6">
        <Typography variant="h6" gutterBottom>
          Suggested Metadata
        </Typography>
        <Typography variant="overline" gutterBottom>
          Request Id: {collection?.suggestion?.[contentClass]?.metadata?.request?.requestId}, Total Top Tags:{' '}
          {collection?.suggestion?.[contentClass]?.metadata?.pendingTopTags?.length}, Total Keywords:{' '}
          {collection?.suggestion?.[contentClass]?.metadata?.pendingKeywords?.length}
        </Typography>
      </div>
      <div className="py-4">
        <ChipInput
          label="Pending Top Tags"
          id="pending-top-tags"
          placeholder=""
          onChange={onTopTagChange}
          value={pendingTopTags}
          freeSolo={false}
        />
        <div className="py-4 flex justify-end">
          <Button
            message="Add Top Tags to Collection"
            type={ButtonType.PRIMARY_YELLOW}
            size={ButtonSize.MEDIUM}
            textSize={ButtonTextSize.SMALL}
            isSubmit={true}
            onClickAction={onTopTagSubmit}
            disabled={submitTopTagsDisabled}
          />
        </div>
      </div>
      <div className="py-4">
        <ChipInput
          id="pending-primary-keywords"
          label="Pending Primary Keywords"
          placeholder=""
          onChange={onKeywordChange}
          value={pendingKeywords}
          freeSolo={false}
        />
        <div className="py-4 flex justify-end">
          <Button
            message="Add Keywords to Collection"
            type={ButtonType.PRIMARY_YELLOW}
            size={ButtonSize.MEDIUM}
            textSize={ButtonTextSize.SMALL}
            isSubmit={true}
            onClickAction={onKeywordSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default MetadataAutomatedSuggestion;
