import * as yup from 'yup';

import { ContentClass } from '../../../../common/enums/ContentClasses';

export enum FormInputNames {
  INPUT_VALUES = 'inputValues',
  MAX_ITEMS_TO_RETURN = 'maxItemsToReturn',
  CAN_CONTAIN_REPEATS = 'canContainRepeats',
  KEYWORD = 'keyword',
  CONTENT_CLASS = 'contentClass',
  CONTENT_TYPE = 'contentType',
  IS_MANDATORY = 'isMandatory',
  WEIGHT = 'weight',
}

export const FormSchema = yup.object().shape({
  [FormInputNames.MAX_ITEMS_TO_RETURN]: yup
    .number()
    .typeError('Please enter a number')
    .required('Please enter the maximum number of items to return'),
  [FormInputNames.CAN_CONTAIN_REPEATS]: yup.boolean().required(),
  [FormInputNames.INPUT_VALUES]: yup
    .array()
    .of(
      yup.object().shape({
        [FormInputNames.KEYWORD]: yup.string().test(
          'check-no-keyword',
          () => 'Please enter a keyword',
          (value) => ((value as string) || '').trim().length > 0
        ),
        [FormInputNames.CONTENT_CLASS]: yup.mixed<ContentClass>().required(),
        [FormInputNames.CONTENT_TYPE]: yup.mixed<ValidContentType>().required(),
        [FormInputNames.IS_MANDATORY]: yup.boolean().required(),
      })
    )
    .min(1),
});

export type SuggestionFormValidation = {
  [FormInputNames.INPUT_VALUES]: InputValue[];
  [FormInputNames.MAX_ITEMS_TO_RETURN]: number;
  [FormInputNames.CAN_CONTAIN_REPEATS]: boolean;
};

export interface InputValue {
  keyword: string;
  weight: number;
  isMandatory: boolean;
  contentClass: ContentClass;
  contentType: ValidContentType;
}

export enum ValidContentType {
  sfx = 'sfx',
  music = 'music',
  loop = 'loop',
  photo = 'photo',
  illustration = 'illustration',
  vector = 'vector',
  'motion-background' = 'motion-background',
  footage = 'footage',
  template = 'template',
  vr360 = 'vr360',
}

export enum VideoContentType {
  'motion-background' = 'motion-background',
  footage = 'footage',
  template = 'template',
  vr360 = 'vr360',
}

export enum AudioContentType {
  sfx = 'sfx',
  music = 'music',
  loop = 'loop',
}

export enum ImageContentType {
  photo = 'photo',
  illustration = 'illustration',
  vector = 'vector',
}

export enum CollectionRequestType {
  CONTENT_FROM_KEYWORDS = 'CONTENT_FROM_KEYWORDS',
}
