import React from 'react';

import CollectionEdit from '../collection-tool/pages/CollectionEdit';
import CollectionSearch from '../collection-tool/pages/CollectionSearch';
import { AudioBatchReview } from '../review-app/pages/review/batchReview/AudioBatchReview';
import VideoBatchReview from '../review-app/pages/review/batchReview/VideoBatchReview';
import ManageReviewers from '../review-app/pages/review/manageReviewers/ManageReviewers';
import TemplateReview from '../review-app/pages/review/singleReview/TemplateReview';
import VR360Review from '../review-app/pages/review/singleReview/VR360Review';
import { allowedAudioContentTypes, batchVideoContentTypes, ContentType } from './enums/ContentTypes';
import Roles from './enums/Roles';
import ContributorPortalRedirect from './pages/ContributorPortalRedirect';
import Home from './pages/home/Home';

export interface PrivateRoute {
  path: string;
  component: React.ElementType;
  roles: Roles[];
  reviewPermissions?: ContentType[];
  featureFlag?: string;
}

export interface NavigablePrivateRoute extends PrivateRoute {
  title: string;
  imagePath: {
    default: string;
    active: string;
  };
}

export const isNavigablePrivateRoute = (route: PrivateRoute): route is NavigablePrivateRoute => {
  return 'title' in route && 'imagePath' in route;
};

export const ROUTES = {
  LOGIN: '/login',
  LOGOUT: '/logout',
};

export const PRIVATE_ROUTES = {
  HOME: {
    path: '/',
    component: Home,
    roles: [Roles.REVIEWER, Roles.BATCH_REVIEWER, Roles.ADMIN],
    title: 'Home',
    imagePath: {
      active: '/images/sideNav/active/homeIcon.svg',
      default: '/images/sideNav/default/homeIcon.svg',
    },
  },
  COLLECTION_SEARCH: {
    path: '/collection-tool/search',
    component: CollectionSearch,
    roles: [Roles.COLLECTION_EDITOR],
    title: 'Collection Tool',
    imagePath: {
      active: '/images/sideNav/active/collectionTool.svg',
      default: '/images/sideNav/default/collectionTool.svg',
    },
  },
  COLLECTION_EDIT: {
    path: '/collection-tool/edit/:collectionId',
    component: CollectionEdit,
    roles: [Roles.COLLECTION_EDITOR],
  },
  VIDEO_BATCH_REVIEW: {
    path: '/video-batch-review',
    component: VideoBatchReview,
    roles: [Roles.BATCH_REVIEWER],
    reviewPermissions: batchVideoContentTypes,
    title: 'Video Review',
    imagePath: {
      active: '/images/sideNav/active/videoReviewIcon.svg',
      default: '/images/sideNav/default/videoReviewIcon.svg',
    },
  },
  AUDIO_BATCH_REVIEW: {
    path: '/audio-batch-review',
    component: AudioBatchReview,
    roles: [Roles.BATCH_REVIEWER],
    reviewPermissions: allowedAudioContentTypes,
    title: 'Audio Review',
    imagePath: {
      active: '/images/sideNav/active/audioReviewIcon.svg',
      default: '/images/sideNav/default/audioReviewIcon.svg',
    },
  },
  TEMPLATE_REVIEW: {
    path: '/template-review',
    component: TemplateReview,
    roles: [Roles.REVIEWER],
    reviewPermissions: [ContentType.TEMPLATE],
    title: 'Template Review',
    imagePath: {
      active: '/images/sideNav/active/templatesReviewIcon.svg',
      default: '/images/sideNav/default/templatesReviewIcon.svg',
    },
  },
  VR360_REVIEW: {
    path: '/vr360-review',
    component: VR360Review,
    roles: [Roles.REVIEWER],
    reviewPermissions: [ContentType.VR_360],
    title: 'VR360 Review',
    imagePath: {
      active: '/images/sideNav/active/vr360ReviewIcon.svg',
      default: '/images/sideNav/default/vr360ReviewIcon.svg',
    },
  },
  MANAGE_REVIEWERS: {
    path: '/manage-reviewer',
    component: ManageReviewers,
    roles: [Roles.REVIEWER_MANAGER],
    title: 'Manage Reviewers',
    imagePath: {
      active: '/images/sideNav/active/manageReviewersIcon.svg',
      default: '/images/sideNav/default/manageReviewersIcon.svg',
    },
  },
  CONTRIBUTOR_PORTAL: {
    path: '/contributor-portal-redirect',
    component: ContributorPortalRedirect,
    roles: [Roles.REVIEWER, Roles.BATCH_REVIEWER, Roles.ADMIN, Roles.REVIEWER_MANAGER],
    title: 'Contributor Portal',
    imagePath: {
      active: '/images/sideNav/default/contributorPortalIcon.svg',
      default: '/images/sideNav/default/contributorPortalIcon.svg',
    },
  },
};
