import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import round from 'lodash/round';
import LogRocket from 'logrocket';
import React, { useState } from 'react';

import { ContentClass } from '../../../common/enums/ContentClasses';
import { ContributorType } from '../../store/batch/batch.types';

type OwnProps = {
  creator: ContributorType;
  contentClass: ContentClass;
};

function CreatorDetailsTable(props: OwnProps): JSX.Element {
  const { creator, contentClass } = props;
  const {
    firstName,
    lastName,
    username,
    acceptanceRatio, // acceptance ratio is calculated based on the provided content class in wasabi
    numPending, // num pending is calculated based on the provided content class in wasabi
  } = creator;

  const { numSubmitted, numManuallyRejected, numManuallyApproved } = getStatsForContentClass(contentClass, creator);

  const [showingData, setShowingData] = useState(false);

  const acceptancePercent = round(acceptanceRatio * 100, 2);
  const { bgColor, textColor } = determineAcceptancePercentColors(acceptancePercent);
  const acceptanceRatioClasses = `mr-3 px-1 font-semibold rounded ${bgColor} ${textColor}`;

  return (
    <div className="bg-white py-2 px-4">
      <div className="flex flex-col">
        <div className="flex justify-between" role="heading">
          <div className="h-8 flex items-center">
            <p>User Info:</p>
            <p className="font-bold pl-1" data-private={'lipsum'}>{` ${firstName} ${lastName} (${username})`}</p>
          </div>
          <div onClick={() => setShowingData(!showingData)} className="cursor-pointer flex items-center" role="button">
            <p className={acceptanceRatioClasses}>{`${acceptancePercent}%`}</p>
            {showingData ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </div>
        </div>
        {showingData && (
          <ExpandedStats
            submitted={numSubmitted}
            rejected={numManuallyRejected}
            approved={numManuallyApproved}
            pending={numPending}
          />
        )}
      </div>
    </div>
  );
}

export default CreatorDetailsTable;

function ExpandedStats({
  submitted,
  approved,
  rejected,
  pending,
}: {
  submitted: number;
  approved: number;
  rejected: number;
  pending: number;
}): JSX.Element {
  return (
    <div className="h-8 flex justify-between" role="row">
      <ExpandedDataField label="Submitted" value={submitted} />
      <ExpandedDataDivider />
      <ExpandedDataField label="Approved" value={approved} />
      <ExpandedDataDivider />
      <ExpandedDataField label="Rejected" value={rejected} />
      <ExpandedDataDivider />
      <ExpandedDataField label="Pending" value={pending} />
    </div>
  );
}

function ExpandedDataField(props: { label: string; value: number }): JSX.Element {
  const { label, value } = props;
  return (
    <div className="flex items-center justify-center">
      <p>
        {value} {label}
      </p>
    </div>
  );
}

function ExpandedDataDivider(): JSX.Element {
  return <div className="border-r-2 border-gray-100" />;
}

function determineAcceptancePercentColors(acceptanceRatio: number): { bgColor: string; textColor: string } {
  if (acceptanceRatio >= 80) {
    return { bgColor: 'bg-green-100', textColor: 'text-green-700' };
  }
  if (acceptanceRatio >= 30) {
    return { bgColor: 'bg-orange-100', textColor: 'text-orange-700' };
  }
  return { bgColor: 'bg-red-100', textColor: 'text-red-700' };
}

function getStatsForContentClass(contentClass: ContentClass, contributor: ContributorType) {
  switch (contentClass) {
    case ContentClass.audio:
      return contributor.audioStats;
    case ContentClass.video:
      return contributor.videoStats;
    case ContentClass.image:
      return contributor.imageStats;
    default:
      LogRocket.captureMessage(
        "Unknown content class encountered while rendering CreatorDetailsTable. This shouldn't happen.",
        {
          extra: contentClass,
        }
      );
      return {
        numManuallyApproved: 0,
        numManuallyRejected: 0,
        numSubmitted: 0,
      };
  }
}
