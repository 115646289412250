import { PanoViewer } from '@egjs/view360';
import React from 'react';
import videojs, { VideoJsPlayer } from 'video.js';
import 'video.js/dist/video-js.css';

import { logger } from '../../../common/util/logger';

interface OwnProps {
  options: videojs.PlayerOptions;
}
export const VrVideoJS = (props: OwnProps): JSX.Element => {
  const { options } = props;
  const vrVideoRef = React.useRef<HTMLVideoElement>(null);

  // This seperate functional component fixes the removal of the videoelement
  // from the DOM when calling the dispose() method on a player
  const VideoHtml = () => (
    <div className="viewer" data-testid="vr-viewer">
      <video id="video" crossOrigin="" ref={vrVideoRef} className="video-js vjs-big-play-centered" playsInline></video>
    </div>
  );

  React.useEffect(() => {
    const videoElement = vrVideoRef.current;
    let player: VideoJsPlayer;
    if (videoElement) {
      player = videojs('video', options, () => {
        const videoContainer = document.querySelector('#video');
        const view360Container = document.createElement('div');
        view360Container.className = 'container';
        if (videoContainer) {
          videoContainer.insertBefore(view360Container, videoContainer.firstChild);
        }
        videoElement.style.display = 'none';
        logger.info('vr player is ready');
        new PanoViewer(view360Container, {
          video: videoElement,
        });
      });
    }
    return () => {
      if (player) {
        player.dispose();
      }
    };
  }, [options]);

  return <VideoHtml />;
};

export default VrVideoJS;
