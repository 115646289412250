import { AnyAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { isEqual, toString } from 'lodash';

import { ContentClass } from '../../../common/enums/ContentClasses';
import { ContentStatus } from '../../../common/enums/ContentStatus';
import { AsyncThunkConfig } from '../../../common/store/store';
import { logger } from '../../../common/util/logger';
import CollectionApi from '../../api/CollectionApi';
import {
  ChangeNoticeType,
  Collection,
  CollectionContent,
  CollectionView,
  Content,
  CreateCollectionDraft,
  RejectionReason,
} from '../../api/CollectionApi.types';
import { RejectedContent } from '../../api/CollectionApiSuggestion.types';
import ContentApi from '../../api/ContentApi';
import CollectionStatus from '../../enums/CollectionStatus';
import BulkUploadError from '../../pages/collectionEditPanel/bulk/BulkUploadError';
import { WasabiErrorResponse } from '../../pages/collectionEditPanel/error/WasabiError';
import { CollectionEditState, SaveEditPanelTopChangeArguments } from './CollectionEdit.types';

const initialState: CollectionEditState = {
  isUpdating: false,
  isLoading: false,
};

const updateCollectionView = createAsyncThunk<AnyAction, CollectionView, AsyncThunkConfig>(
  'collectionEdit/updateCollectionView',
  async (view, { getState, dispatch }) => {
    const collection = getState().collectionTool.edit.collection;
    if (collection) {
      return dispatch(saveCollection({ id: collection._id, updates: { view } }));
    }
    return dispatch(displayError('illegal state - no collection'));
  }
);

const addOneToCollection = createAsyncThunk<AnyAction, string, AsyncThunkConfig>(
  'collectionEdit/addOneToCollection',
  async (urlId, { getState, dispatch }) => {
    const { collection } = getState().collectionTool.edit;

    if (!collection) {
      return dispatch(displayError('illegal state - no collection'));
    }

    const {
      content: [content],
    } = await ContentApi.getContentViaUrlIds([urlId]);

    if (!content) {
      return dispatch(displayError(`No content found for urlId - ${urlId}`));
    }
    const contentClass = content.class;

    if (collection.content?.[contentClass]?.includes(content._id)) {
      return dispatch(displayError(`Collection already contains ${urlId}`));
    }
    if (!collection.classes.includes(content.class)) {
      return dispatch(displayError(`Content class not listed on collection - ${content.class}`));
    }
    if (content.status !== ContentStatus.ACTIVE) {
      return dispatch(displayError('Content is not active'));
    }

    const id = collection._id;
    const contentIdsByClass = {
      ...collection.content,
      [contentClass]: [...(collection.content?.[contentClass] || []), content._id],
    };

    return dispatch(saveCollection({ id, updates: { content: contentIdsByClass } }));
  }
);

const addAllToCollection = createAsyncThunk<AnyAction, string[], AsyncThunkConfig>(
  'collectionEdit/addAllToCollection',
  async (urlIds, { getState, dispatch }) => {
    const { collection } = getState().collectionTool.edit;

    if (!collection) {
      return dispatch(displayError('illegal state - no collection'));
    }

    const response = await ContentApi.getContentViaUrlIds(urlIds);
    const content = response.content;
    const errorMessages: string[] = [];

    if (content.length === 0) {
      return dispatch(displayError(`No content found for any urlId in file`));
    } else if (content.length < urlIds.length) {
      // figure out which contentIds don't exist to show the error
      urlIds.forEach((urlId) => {
        const urlIdExistsInContentList = content.some((contentItem) => contentItem.urlId === urlId);
        if (!urlIdExistsInContentList) {
          errorMessages.push(`Content item ${urlId} does not exist.`);
        }
      });
    }

    const validContentIds: CollectionContent = {
      [ContentClass.audio]: collection.content?.[ContentClass.audio] || [],
      [ContentClass.image]: collection.content?.[ContentClass.image] || [],
      [ContentClass.video]: collection.content?.[ContentClass.video] || [],
    };

    //sorting array based on url id to preserve order
    content.sort((a, b) => urlIds.indexOf(a.urlId) - urlIds.indexOf(b.urlId));

    content.forEach((contentItem: Content) => {
      const contentClass = contentItem.class;
      if (collection.content?.[contentClass]?.includes(contentItem._id)) {
        errorMessages.push(`Collection already contains ${contentItem.urlId}.`);
      } else if (!collection.classes.includes(contentItem.class)) {
        errorMessages.push(`Content item ${contentItem.urlId} class not listed on collection - ${contentItem.class}.`);
      } else if (contentItem.status !== ContentStatus.ACTIVE) {
        errorMessages.push(`Content item ${contentItem.urlId} is not active.`);
      } else {
        // Make sure the content url id is present in url id list
        const collectionOrder = urlIds.indexOf(contentItem.urlId);
        if (collectionOrder !== -1) {
          validContentIds[contentClass] = [...(validContentIds[contentClass] || []), contentItem._id];
        }
      }
    });

    if (errorMessages.length > 0) {
      return dispatch(
        displayError(
          new BulkUploadError(
            'There were errors with some content items. Please correct these and re-try.',
            errorMessages
          )
        )
      );
    }

    const id = collection._id;
    return dispatch(saveCollection({ id, updates: { content: validContentIds } }));
  }
);

const loadNewCollection = createAsyncThunk<AnyAction, number, AsyncThunkConfig>(
  'collectionEdit/loadNewCollection',
  async (collectionId, { dispatch }) => {
    try {
      const collection = await CollectionApi.getByCollectionId(collectionId);
      return dispatch(updateCollection(collection));
    } catch (e) {
      return dispatch(displayError(e));
    }
  }
);

const removeOneFromCollection = createAsyncThunk<
  AnyAction,
  { contentId: number; reason?: RejectionReason; contentClass: ContentClass },
  AsyncThunkConfig
>('collectionEdit/removeOneFromCollection', async ({ contentId, reason, contentClass }, { getState, dispatch }) => {
  const { collection } = getState().collectionTool.edit;

  if (!collection) {
    return dispatch(displayError('illegal state - no collection'));
  }
  const contentIdsByClass = {
    ...collection.content,
    [contentClass]: collection.content?.[contentClass]?.filter((id) => contentId !== id),
  };

  const updates: Partial<Collection> = {
    content: contentIdsByClass,
  };
  if (reason) {
    const reject: RejectedContent = {
      id: contentId,
      reason,
    };
    const approvedContentIds = collection.suggestion?.[contentClass]?.content?.approvedContentIds ?? [];
    const filteredApprovedContentIds = approvedContentIds.filter((id) => id !== contentId);

    const rejectedContent = collection.suggestion?.[contentClass]?.content?.rejectedContent ?? [];
    const updatedRejectedContent = rejectedContent.length ? [...rejectedContent, reject] : [reject];
    updates.suggestion = {
      ...collection.suggestion,
      content: {
        ...collection.suggestion?.content,
        rejectedContent: updatedRejectedContent,
        approvedContentIds: filteredApprovedContentIds,
      },
    };
  }
  return dispatch(
    saveCollection({
      id: collection._id,
      updates,
    })
  );
});

const saveCollection = createAsyncThunk<AnyAction, { id: number; updates: Partial<Collection> }, AsyncThunkConfig>(
  'collectionEdit/saveCollection',
  async ({ id, updates }, { getState, dispatch }) => {
    const { _id: userId } = getState().user;
    try {
      const collection = await CollectionApi.updateCollection(id, updates, userId || '');
      return dispatch(updateCollection(collection));
    } catch (e) {
      return dispatch(displayError(e));
    }
  }
);

const createCollection = createAsyncThunk<AnyAction, CreateCollectionDraft, AsyncThunkConfig>(
  'collectionEdit/createCollection',
  async (collection, { getState, dispatch }) => {
    const { _id: userId } = getState().user;
    try {
      const clonedCollection = await CollectionApi.createCollection(collection, userId || '');
      return dispatch(updateClonedId(clonedCollection._id));
    } catch (e) {
      return dispatch(displayError(e));
    }
  }
);

const generateChangeNotice = createAsyncThunk<
  AnyAction,
  {
    id: number;
    rejectEntireCollection?: boolean;
    rejectEntireCollectionReason?: RejectionReason;
    rejectContentClass?: ContentClass;
    changeNoticeType: ChangeNoticeType;
  },
  AsyncThunkConfig
>(
  'collectionEdit/saveCollection',
  async (
    { id, rejectEntireCollection, rejectEntireCollectionReason, rejectContentClass, changeNoticeType },
    { dispatch }
  ) => {
    try {
      const collection = await CollectionApi.generateChangeNotice(
        id,
        changeNoticeType,
        rejectEntireCollection,
        rejectEntireCollectionReason,
        rejectContentClass
      );
      return dispatch(updateCollection(collection));
    } catch (e) {
      return dispatch(displayError(e));
    }
  }
);

const saveTopPanelChange = createAsyncThunk<AnyAction, SaveEditPanelTopChangeArguments, AsyncThunkConfig>(
  'collectionEdit/saveTopPanelChange',
  (updates, { getState, dispatch }) => {
    const collection = getState().collectionTool.edit.collection;
    if (!collection) {
      return dispatch(displayError('illegal state - no collection'));
    }

    return dispatch(saveCollection({ id: collection._id, updates }));
  }
);

const requestCollectionContentSuggestions = createAsyncThunk<
  AnyAction,
  { id: number; suggestion: Partial<Collection> },
  AsyncThunkConfig
>('collectionEdit/collectionContentSuggestions', async ({ id, suggestion }, { dispatch }) => {
  try {
    const collection = await CollectionApi.requestCollectionContentSuggestions(id, suggestion);
    return dispatch(updateCollection(collection));
  } catch (e) {
    return dispatch(displayError(e));
  }
});

const requestCollectionMetadataSuggestions = createAsyncThunk<
  AnyAction,
  { id: number; suggestion: Partial<Collection> },
  AsyncThunkConfig
>('collectionEdit/collectionMetadataSuggestions', async ({ id, suggestion }, { dispatch }) => {
  try {
    const collection = await CollectionApi.requestCollectionMetadataSuggestions(id, suggestion);
    return dispatch(updateCollection(collection));
  } catch (e) {
    return dispatch(displayError(e));
  }
});

const requestMoreContentSuggestions = createAsyncThunk<
  AnyAction,
  { collectionId: number; contentClass: ContentClass },
  AsyncThunkConfig
>('collectionEdit/requestMoreContentSuggestions', async ({ collectionId, contentClass }, { dispatch }) => {
  try {
    const collection = await CollectionApi.requestMoreContentSuggestions(collectionId, contentClass);
    return dispatch(updateCollection(collection));
  } catch (e) {
    return dispatch(displayError(e));
  }
});

const importContent = createAsyncThunk<
  AnyAction,
  { id: number; importCollectionId: number; contentClass: ContentClass },
  AsyncThunkConfig
>('collectionEdit/importContent', async ({ id, importCollectionId, contentClass }, { getState, dispatch }) => {
  const { _id: userId } = getState().user;
  try {
    const collection = await CollectionApi.importContent(id, importCollectionId, contentClass, userId || '');
    return dispatch(updateCollection(collection));
  } catch (e) {
    return dispatch(displayError(e));
  }
});

const collectionEditSlice = createSlice({
  name: 'collectionEdit',
  initialState,
  reducers: {
    displayError: (state, action: PayloadAction<unknown>) => {
      // Make sure that if the error message is different we're not letting the old error hang around
      state.error = undefined;
      state.errorMessages = undefined;

      if (action.payload instanceof Error) {
        state.error = action.payload.message;
        if (action.payload instanceof BulkUploadError) {
          state.errorMessages = action.payload.errors;
        }
        if (typeof action.payload === 'object' && 'isAxiosError' in action.payload) {
          const axiosError = action.payload as AxiosError<WasabiErrorResponse>;
          state.error = axiosError.response?.data.errorMessage; // eslint-disable-line
        }
      } else if (typeof action.payload === 'string') {
        state.error = action.payload;
      } else {
        state.error = toString(action.payload);
      }
    },
    resetError: (state) => {
      state.error = undefined;
      state.errorMessages = undefined;
    },
    resetForNewCollection: (state) => {
      state.collection = {
        _id: -1, //Will be overwritten when actually sent to content-service
        name: '',
        content: {},
        isActive: false,
        isSearchIndexable: false,
        showInApi: false,
        status: CollectionStatus.DRAFT,
        classes: [],
      };
      state.newContentIdOrder = undefined;
    },
    updateCollection: (state, action: PayloadAction<Collection>) => {
      state.collection = action.payload;
    },
    updateClonedId: (state, action: PayloadAction<number>) => {
      state.clonedId = action.payload;
    },
    updateReorderPreview: (
      state,
      action: PayloadAction<{ newIndex: number; contentId: number; contentClass: ContentClass }>
    ) => {
      const { newIndex, contentId, contentClass } = action.payload;
      const { collection, newContentIdOrder } = state;
      if (collection) {
        const contentIds = newContentIdOrder ? newContentIdOrder : collection.content?.[contentClass] || [];
        const oldIndex = contentIds.indexOf(contentId);
        const newContentIds = [...contentIds];
        newContentIds.splice(oldIndex, 1);
        newContentIds.splice(newIndex, 0, contentId);

        if (isEqual(newContentIds, collection.content?.[contentClass])) {
          state.newContentIdOrder = undefined;
        } else {
          state.newContentIdOrder = newContentIds;
        }
      }
    },
    resetReorderPreview: (state) => {
      state.newContentIdOrder = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadNewCollection.pending, (state) => {
        state.isLoading = true;
        state.collection = undefined;
        state.newContentIdOrder = undefined;
        state.clonedId = undefined;
      })
      .addCase(loadNewCollection.fulfilled, (state) => {
        state.isLoading = false;
        state.error = undefined;
        state.errorMessages = undefined;
      })
      .addCase(loadNewCollection.rejected, (state, action) => {
        state.isLoading = false;
        logger.error('Failed to load collection data', action.error);
      })
      .addCase(saveCollection.pending, (state) => {
        state.isUpdating = true;
        state.error = undefined;
        state.errorMessages = undefined;
      })
      .addCase(saveCollection.fulfilled, (state) => {
        state.isUpdating = false;
      })
      .addCase(saveCollection.rejected, (state, action) => {
        state.isUpdating = false;
        logger.error('Failed to save collection data', action.error);
      })
      .addCase(createCollection.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(createCollection.fulfilled, (state) => {
        state.isUpdating = false;
        state.error = undefined;
        state.errorMessages = undefined;
      })
      .addCase(createCollection.rejected, (state, action) => {
        state.isUpdating = false;
        logger.error('Failed to clone collection', action.error);
      })
      .addCase(requestMoreContentSuggestions.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(requestMoreContentSuggestions.fulfilled, (state) => {
        state.isUpdating = false;
      })
      .addCase(requestMoreContentSuggestions.rejected, (state, action) => {
        state.isUpdating = false;
        logger.error('Failed to request more suggestions', action.error);
      })
      .addCase(importContent.pending, (state) => {
        state.isUpdating = true;
        state.error = undefined;
      })
      .addCase(importContent.fulfilled, (state) => {
        state.isUpdating = false;
      })
      .addCase(importContent.rejected, (state, action) => {
        state.isUpdating = false;
        logger.error('Failed to import content', action.error);
      });
  },
});
const { actions, reducer } = collectionEditSlice;
const {
  displayError,
  resetError,
  resetForNewCollection,
  updateClonedId,
  updateCollection,
  updateReorderPreview,
  resetReorderPreview,
} = actions;

export {
  addOneToCollection,
  addAllToCollection,
  createCollection,
  displayError,
  resetError,
  initialState,
  loadNewCollection,
  reducer,
  removeOneFromCollection,
  resetForNewCollection,
  resetReorderPreview,
  saveCollection,
  updateCollectionView,
  updateReorderPreview,
  saveTopPanelChange,
  requestCollectionContentSuggestions,
  requestCollectionMetadataSuggestions,
  requestMoreContentSuggestions,
  generateChangeNotice,
  importContent,
};
