import { Delete, KeyboardBackspace, Link as LinkIcon, Publish } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Button, Tab, Tabs, Tooltip } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Alert, { AlertType } from '../../../common/components/alert/Alert';
import { ContentClass } from '../../../common/enums/ContentClasses';
import { useDispatch } from '../../../common/store/store';
import { ChangeNoticeType } from '../../api/CollectionApi.types';
import CollectionStatus from '../../enums/CollectionStatus';
import { displayError, generateChangeNotice, saveCollection } from '../../store/CollectionEdit/CollectionEdit.reducer';
import {
  selectCollection,
  selectError,
  selectErrorMessages,
} from '../../store/CollectionEdit/CollectionEdit.selectors';
import CloneCollection from './CloneCollection';
import CollectionEditTop from './CollectionEditTop';
import CollectionCurationTab from './curation/CollectionCurationTab';
import ButtonWithDialog from './top/ButtonWithDialog';
import CollectionViewTab from './view/CollectionViewTab';

enum TabValue {
  CURATION = 'curation',
  VIEW = 'view',
}

const CollectionEditPanel = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const collection = useSelector(selectCollection);
  const [tabValue, setTabValue] = React.useState(TabValue.CURATION);

  if (!collection) {
    return null;
  }

  const publishCollection = () => {
    if (collection.view) {
      void dispatch(
        saveCollection({
          id: collection._id,
          updates: {
            status: CollectionStatus.PUBLISHED,
            isActive: true,
          },
        })
      );
      if (collection.suggestion) {
        void dispatch(
          generateChangeNotice({
            id: collection._id,
            changeNoticeType: ChangeNoticeType.PUBLISH,
          })
        );
      }
    } else {
      dispatch(displayError('Collection view is missing'));
    }
  };

  const archiveCollection = () => {
    void dispatch(
      saveCollection({
        id: collection._id,
        updates: {
          status: CollectionStatus.ARCHIVED,
          isActive: false,
        },
      })
    );
  };

  const handleTabChange = (event: React.ChangeEvent<unknown>, newTabValue: TabValue) => {
    setTabValue(newTabValue);
  };

  const collectionClass = getCollectionUriClass(collection.classes);

  let linkToCollection;
  if (collection.view?.routeUri) {
    if (process.env.REACT_APP_WASABI_BASE_URI === 'https://contribute.storyblocks.com') {
      linkToCollection = `https://www.storyblocks.com/${collectionClass}/collections/${collection.view?.routeUri}`;
    } else if (process.env.REACT_APP_WASABI_BASE_URI === 'https://wasabi-staging.videoblocksdev.com') {
      linkToCollection = `https://sb-staging.videoblocksdev.com/${collectionClass}/collections/${collection.view?.routeUri}`;
    } else {
      linkToCollection = `https://www.storyblocks.com/${collectionClass}/collections/${collection.view?.routeUri}`;
    }
  }

  return (
    <div className="grid grid-cols-6 py-2">
      <div className="col-start-1 col-span-1 row-start-1">
        <Link to="/collection-tool/search">
          <Tooltip title="Back to Search">
            <Button variant="outlined" color="primary">
              <KeyboardBackspace />
            </Button>
          </Tooltip>
        </Link>
      </div>
      {collection.status === CollectionStatus.ARCHIVED ? (
        <div className="col-start-3 col-span-2 row-start-1 pb-4">
          <Alert title="Collection is Archived" type={AlertType.WARNING} />
        </div>
      ) : (
        <></>
      )}

      <div className="flex justify-end col-start-6 col-span-1 row-start-1 pb-4">
        <div className="pr-4">
          {linkToCollection ? (
            <a target="_blank" rel="noopener noreferrer" href={linkToCollection}>
              <Tooltip title="View on Storyblocks.com">
                <Button variant="outlined" color="primary">
                  <LinkIcon />
                </Button>
              </Tooltip>
            </a>
          ) : (
            <></>
          )}
        </div>
        <div className="pr-4">
          <CloneCollection />
        </div>
        {collection.status !== CollectionStatus.PUBLISHED || !collection.isActive ? (
          <div className="pr-4">
            <ButtonWithDialog icon={<Publish />} name="Publish" color={'primary'} onConfirm={publishCollection} />
          </div>
        ) : (
          <></>
        )}
        <div>
          <ButtonWithDialog icon={<Delete />} name="Archive" color={'secondary'} onConfirm={archiveCollection} />
        </div>
      </div>
      <div className="row-start-2 col-span-6 p-6 bg-white">
        <CollectionError />
        <CollectionEditTop />
        <TabContext value={tabValue}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="Curation or View Toggle">
            <Tab label="Curation" value={TabValue.CURATION} />
            <Tab label="View" value={TabValue.VIEW} />
          </Tabs>
          <Box className="border-2 rounded border-slate-200">
            <TabPanel value={TabValue.CURATION}>
              <CollectionCurationTab />
            </TabPanel>
            <TabPanel value={TabValue.VIEW}>
              <CollectionViewTab />
            </TabPanel>
          </Box>
        </TabContext>
      </div>
    </div>
  );
};
export default CollectionEditPanel;

const CollectionError = (): JSX.Element | null => {
  const { error, messages } = useSelector(errorSelector);

  if (error) {
    return (
      <div className="w-3/4 mx-auto py-4">
        <Alert title={error || 'An unexpected error occurred'} type={AlertType.ERROR} messages={messages} />
      </div>
    );
  }

  return null;
};

const errorSelector = createSelector([selectError, selectErrorMessages], (error, errorMessages) => ({
  error,
  messages: errorMessages,
}));

function getCollectionUriClass(classes: ContentClass[]): 'images' | 'audio' | 'video' {
  if (classes.length > 1) {
    return 'video';
  }
  if (classes[0] === ContentClass.image) {
    return 'images';
  }
  return classes[0];
}
