import { createSelector } from '@reduxjs/toolkit';
import React from 'react';
import { useSelector } from 'react-redux';

import NewModal from '../../../common/components/modal/NewModal';
import { useDispatch } from '../../../common/store/store';
import { logger } from '../../../common/util/logger';
import { ReviewPermission, ReviewPermissions } from '../../enums/ReviewPermissions';
import {
  changeReviewPermission,
  closeEditReviewerModal,
  saveReviewPermissions,
} from '../../store/manageReviewer/manageReviewer.reducer';
import {
  selectEditedReviewerPermissions,
  selectIsModalOpen,
  selectUserNameBeingEdited,
} from '../../store/manageReviewer/manageReviewer.selectors';
import PermissionsSaveButton from './PermissionsSaveButton';
import SwitchPermissions from './SwitchPermissions';

const EditReviewerModal = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const { isModalOpen, permissions, userName } = useSelector(selector);

  const onClose = () => dispatch(closeEditReviewerModal());
  const handlePermissionsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeReviewPermission({ permission: e.target.name as ReviewPermission, newValue: e.target.checked }));
  };
  const handlePermissionsSave = () => {
    dispatch(saveReviewPermissions()).catch((e) => logger.error('Error saving review permissions', e));
  };

  const options = Object.entries(ReviewPermissions).map(([permission, label]) => ({
    label,
    isChecked: permissions[permission as ReviewPermission],
    value: permission,
  }));

  return (
    <NewModal isOpen={isModalOpen} onClose={onClose} title={userName}>
      <SwitchPermissions options={options} handlePermissionsChange={handlePermissionsChange} />
      <PermissionsSaveButton handlePermissionsSave={handlePermissionsSave} />
    </NewModal>
  );
};
export default EditReviewerModal;

export const selector = createSelector(
  [selectIsModalOpen, selectEditedReviewerPermissions, selectUserNameBeingEdited],
  (isModalOpen, editedReviewerPermissions, userNameBeingEdited) => ({
    isModalOpen,
    permissions: editedReviewerPermissions,
    userName: userNameBeingEdited,
  })
);
