import { InputLabel, SelectChangeEvent } from '@mui/material';
import React from 'react';

import Alert, { AlertType } from '../../../common/components/alert/Alert';
import { ClickableTextField } from '../../../common/components/clickableTextField/ClickableTextField';
import { Options } from '../../../common/components/multiSelect/MultiSelect';
import { MultiSelectDropdown } from '../../../common/components/multiSelect/MultiSelectDropdown';
import SingleCheckBox from '../../../common/components/singleCheckBox/SingleCheckBox';
import SingleSelect from '../../../common/components/singleSelect/SingleSelect';
import { batchVideoContentTypes, ContentType } from '../../../common/enums/ContentTypes';
import {
  addOrRemoveItemFromArray,
  getGroupsForType,
  getGroupsFromTitles,
  stockItemHasStandardsOrValues,
} from '../../../common/util/util';
import RejectionReasons from '../../containers/rejectionReasons/RejectionReasons';
import {
  GroupType,
  ReviewStatuses,
  StockItemType,
  TemplateMetaMap,
  TemplateMetaType,
} from '../../store/batch/batch.types';
import BrandValues from '../brandValues/BrandValues';
import Keywords from '../keywords/Keywords';
import VisualStandards from '../visualStandards/VisualStandards';

type OwnProps = {
  templateMeta: TemplateMetaType;
  currentStockItem: StockItemType;
  categories: GroupType[];
  categoriesDisabled?: boolean;
  allowedContentTypes: ContentType[];
  visibleStockItemCount: number;
  hasItemBeenViewed: boolean;
  updateStockItemMeta: (
    stockItemId: string,
    stockItemCreatorId: string,
    key: string,
    value: string[] | boolean | string | GroupType[],
    toastsEnabled?: boolean
  ) => void;
};

export default function VideoSingleItemEdit(props: OwnProps): JSX.Element {
  const {
    currentStockItem,
    categories,
    visibleStockItemCount,
    updateStockItemMeta,
    hasItemBeenViewed,
    templateMeta,
    categoriesDisabled,
    allowedContentTypes,
  } = props;

  const handleKeywordsChange = (keywords: string[]) => {
    updateStockItemMeta(currentStockItem._id, currentStockItem.creatorId, 'keywords', keywords);
  };

  const renderContentRequestMessage = () => {
    if (currentStockItem.contentRequestId) {
      return (
        <div className="pb-6">
          <Alert type={AlertType.INFO} title={'This clip is a Content Request. Please see separate instructions.'} />
        </div>
      );
    }
  };

  const renderValidationFailure = () => {
    const validationFailures = Object.values(currentStockItem.validationFailure || {});
    if (!validationFailures.length || currentStockItem.review.status !== ReviewStatuses.pending) {
      return null;
    }
    const alertTitle = 'This stock item has invalid metadata and cannot be approved.';
    return (
      <div className="pb-6">
        <Alert title={alertTitle} type={AlertType.WARNING} messages={validationFailures} />
      </div>
    );
  };

  const renderAutoRejectionMessage = () => {
    if (batchVideoContentTypes.includes(currentStockItem.content?.type)) {
      const hasValuesOrStandards = stockItemHasStandardsOrValues(currentStockItem);
      const isStockItemViewed = visibleStockItemCount === 1 || hasItemBeenViewed;
      if (isStockItemViewed && !hasValuesOrStandards) {
        const alertTitle =
          'This clip does not meet our content brand standards. Clips must meet at least 1 visual or 1 value standard in order to be approved. ';
        return (
          <div className="pb-6">
            <Alert title={alertTitle} type={AlertType.INFO} />
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const renderCurrentStatus = () => {
    const { status } = currentStockItem.review;

    if (status === 'pending') {
      return null;
    }
    return <Alert title={`This stock item has been ${status}`} type={AlertType.INFO} />;
  };

  const renderReleases = () => {
    const { releases } = currentStockItem;
    if (!releases.length) {
      return null;
    }

    return (
      <div className="py-3">
        <InputLabel shrink={true}>Releases:</InputLabel>
        <div className="mt-2">
          {releases.map((release) => (
            <a href={release.fileUrl} key={release.fileName} className="bg-gray-200 mr-2 mb-2 py-2 px-4 rounded">
              {release.fileName}
            </a>
          ))}
        </div>
      </div>
    );
  };

  const renderCheckboxes = () => {
    const { meta, _id, creatorId } = currentStockItem;

    const updateIsSensitiveContent = () => {
      updateStockItemMeta(_id, creatorId, 'isSensitiveContent', !meta.isSensitiveContent);
    };

    const updateIsEditorial = () => {
      updateStockItemMeta(_id, creatorId, 'isEditorial', !meta.isEditorial);
    };

    return (
      <div className="py-3">
        <SingleCheckBox
          isChecked={meta.isSensitiveContent || false}
          label="Contains sensitive content"
          handleChange={updateIsSensitiveContent}
          value="sensitive-content"
        />
        {meta.isSensitiveContent && (
          <div className="py-6">
            <Alert title="This item may contain inappropriate keywords or title." type={AlertType.WARNING} />
          </div>
        )}
        <div>
          <SingleCheckBox
            isChecked={meta.isEditorial || false}
            label="Is Editorial"
            handleChange={updateIsEditorial}
            value="is-editorial"
          />
        </div>
      </div>
    );
  };

  const renderTitle = () => {
    const { meta, _id, creatorId } = currentStockItem;

    return (
      <ClickableTextField
        key={_id}
        text={meta.title}
        updateTextValue={(newTitle: string) => updateStockItemMeta(_id, creatorId, 'title', newTitle)}
      />
    );
  };

  const renderKeywords = () => {
    const { keywords } = currentStockItem.meta;

    return (
      <div className="py-3">
        <Keywords
          keywords={keywords}
          label="Keywords:"
          id="keyword-chips"
          handleKeywordsChange={handleKeywordsChange}
        />
      </div>
    );
  };

  const handleVisualStandardsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedVisualStandards = [...(currentStockItem?.meta?.visualStandards || [])];
    const { name } = event.target;
    const updatedStandards = addOrRemoveItemFromArray(name, selectedVisualStandards);
    updateStockItemMeta(currentStockItem._id, currentStockItem.creatorId, 'visualStandards', updatedStandards);
  };

  const renderCategories = () => {
    const { _id, creatorId, content, meta } = currentStockItem;

    if (!categories) {
      return null;
    }

    const availableCategories = getGroupsForType(content.type, categories);
    const selectedCategoryTitles = (meta.stockBlocks?.categories || [])
      .filter((selected: GroupType) => selected)
      .map((selected: GroupType) => ({ label: selected.title, value: selected.title }));

    const updateCategories = (options: Options[]) => {
      const categoryTitles = options.map((option) => option.value);
      const categoryGroups = getGroupsFromTitles(categoryTitles, categories);
      updateStockItemMeta(_id, creatorId, 'categories', categoryGroups);
    };

    return (
      <div className="col-span-2 flex w-full pr-2">
        <MultiSelectDropdown
          label="Categories:"
          key="categories-select"
          selectedOptions={selectedCategoryTitles}
          allOptions={availableCategories}
          setSelectedOptions={updateCategories}
          disabled={categoriesDisabled}
        />
      </div>
    );
  };

  const renderContentType = () => {
    const contentTypes = [
      { value: ContentType.FOOTAGE, label: 'Footage' },
      // DR-2200: We've temporarily reverted back to the old key name because there's a clash between `motion-background`, `motion-backgrounds`, and `animation`
      // This is the quickest quick fix b/c the larger fix would be trying to change content-service, wasabi, review and stockblocks to all consolidate on the same key!
      { value: ContentType.LEGACY_MOTION_BACKGROUND, label: 'Animation' },
      { value: ContentType.TEMPLATE, label: 'Template' },
      { value: ContentType.VR_360, label: 'VR 360' },
    ];
    const contentTypesToSelect = allowedContentTypes.map((item) => {
      return contentTypes.find((type) => type.value === item) || { value: item, label: item };
    });

    let contentTypeDisabled = false;
    if (allowedContentTypes.includes(ContentType.VR_360) || allowedContentTypes.includes(ContentType.TEMPLATE)) {
      contentTypeDisabled = true;
    }

    const { content, _id, creatorId } = currentStockItem;

    const updateContentType = (event: SelectChangeEvent<unknown>) => {
      const { value } = event.target;
      updateStockItemMeta(_id, creatorId, 'contentType', value as string);
      // Reset categories since different content types have different categories
      updateStockItemMeta(_id, creatorId, 'categoryTitles', [], false);
    };

    return (
      <SingleSelect
        options={contentTypesToSelect}
        selectedValue={content.type}
        id="content-type-select"
        labelId="content-type-select"
        label="Content Type:"
        onChange={updateContentType}
        disabled={contentTypeDisabled}
      />
    );
  };

  const renderVisualStandards = () => {
    if (batchVideoContentTypes.includes(currentStockItem.content.type)) {
      return (
        <VisualStandards
          selectedStandards={currentStockItem?.meta?.visualStandards || []}
          handleChange={handleVisualStandardsChange}
        />
      );
    } else {
      return;
    }
  };

  const renderDescription = () => {
    const { meta, content } = currentStockItem;
    const { type } = content;
    const { description = '' } = meta;
    if (type === ContentType.TEMPLATE) {
      return (
        <div className="py-3">
          <InputLabel className="mb-1" shrink={true}>
            Templates Metadata:
          </InputLabel>
          <div className="text-sm">{description || 'None'}</div>
        </div>
      );
    } else {
      return;
    }
  };

  const renderTemplateMeta = () => {
    const { content, _id, creatorId, meta } = currentStockItem;
    const { type } = content;
    const { maxResolution, softwareVersion, softwarePlugins } = meta;
    const { highestResolutions, softwareVersions, plugins } = templateMeta;
    const convertedPlugins = convertIntoSelectOptions(plugins);

    const updateTemplateMeta = (metaKey: string, event: SelectChangeEvent<unknown>) => {
      const { value } = event.target;
      updateStockItemMeta(_id, creatorId, metaKey, value as string);
    };

    const updatePlugins = (pluginOptions: Options[]) => {
      const plugins = pluginOptions.map((option) => option.value);
      updateStockItemMeta(_id, creatorId, 'softwarePlugins', plugins);
    };

    if (type === ContentType.TEMPLATE) {
      return (
        <div>
          <div className="py-3">
            <SingleSelect
              options={convertIntoSelectOptions(highestResolutions)}
              selectedValue={maxResolution || ''}
              id="maxResolution"
              labelId="maxResolutionLabel"
              label="Resolution:"
              onChange={(e) => updateTemplateMeta('maxResolution', e)}
            />
          </div>
          <div className="py-3">
            <SingleSelect
              options={convertIntoSelectOptions(softwareVersions)}
              selectedValue={softwareVersion || ''}
              id="softwareVersion"
              labelId="softwareVersionLabel"
              label="Software Version:"
              onChange={(e) => updateTemplateMeta('softwareVersion', e)}
            />
          </div>
          <div className="py-3">
            <MultiSelectDropdown
              allOptions={convertedPlugins}
              selectedOptions={getOptionsFromValues(softwarePlugins, convertedPlugins) || []}
              setSelectedOptions={(selectedOptions: Options[]) => updatePlugins(selectedOptions)}
              label="Required Plugins:"
            />
          </div>
        </div>
      );
    } else {
      return;
    }
  };

  function getOptionsFromValues(values: string[] = [], allOptions: Options[]): Options[] {
    return allOptions.filter((option: Options) => values.includes(option.value));
  }

  function convertIntoSelectOptions(options: TemplateMetaMap[]): Options[] {
    return options.map((option) => {
      return {
        value: option.value,
        label: option.text,
      };
    });
  }

  function renderBrandValues() {
    const { meta, content } = currentStockItem;
    if (batchVideoContentTypes.includes(content?.type)) {
      return <BrandValues selectedValues={meta?.brandValues || []} />;
    } else {
      return;
    }
  }

  return (
    <div className="grid grid-cols-1">
      {renderContentRequestMessage()}
      {renderValidationFailure()}
      {renderAutoRejectionMessage()}
      {renderCurrentStatus()}
      <RejectionReasons />
      {renderVisualStandards()}
      {renderBrandValues()}
      {renderReleases()}
      {renderCheckboxes()}
      {renderTitle()}
      {renderDescription()}
      {renderKeywords()}
      {renderTemplateMeta()}
      <div className="grid grid-cols-3 pt-3">
        {renderCategories()}
        {renderContentType()}
      </div>
    </div>
  );
}
