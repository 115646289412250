import { ContentClass } from '../../../common/enums/ContentClasses';
import { GroupType } from '../batch/batch.types';

export type CategoriesState = {
  [contentClass in ContentClass]: {
    [type: string]: GroupType[];
  };
};

export const initialState: CategoriesState = {
  [ContentClass.audio]: {},
  [ContentClass.video]: {},
  [ContentClass.image]: {},
};
