import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useDispatch } from '../../../../common/store/store';
import { addOneToCollection } from '../../../store/CollectionEdit/CollectionEdit.reducer';
import { selectCollection } from '../../../store/CollectionEdit/CollectionEdit.selectors';

const CollectionCurationSingleAdd = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const collection = useSelector(selectCollection);
  const [urlId, setUrlId] = useState<string | undefined>();
  if (!collection) {
    return null;
  }

  const handleUrlIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrlId(event.target.value);
  };

  const clickUrlIdButton = () => {
    if (urlId) {
      void dispatch(addOneToCollection(urlId));
    }
  };
  return (
    <div className="pt-4">
      <div className="grid grid-cols-4">
        <div className="col-span-3 col-start-1 row-start-1">
          <TextField
            onChange={handleUrlIdChange}
            fullWidth
            id="add-urlid"
            label="Single add via UrlId"
            variant="standard"
          />
        </div>
        <div className="col-span-1 col-start-4 row-start-1 pt-3">
          <Button variant="contained" color="primary" onClick={clickUrlIdButton}>
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CollectionCurationSingleAdd;
