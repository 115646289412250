import { IconButton, SvgIcon } from '@mui/material';
import React from 'react';

import { ReactComponent as PauseIcon } from '../../../common/assets/icons/pause-button.svg';
import { ReactComponent as PlayIcon } from '../../../common/assets/icons/play-button.svg';
import { ReactComponent as RewindIcon } from '../../../common/assets/icons/rewind-button.svg';

type AudioControlsProp = {
  onPlay: () => void;
  onPause: () => void;
  rewindAudio: () => void;
  isPlaying: boolean;
  disabled: boolean;
};
export const AudioControls = ({
  onPlay,
  onPause,
  rewindAudio,
  isPlaying,
  disabled,
}: AudioControlsProp): JSX.Element => {
  const styles = {
    '&:hover': { background: 'none' },
    '&:focus': { background: 'none' },
    '&:active': { background: 'none' },
  };

  return (
    <div className="grid grid-cols-5 mt-6">
      <IconButton
        size="small"
        className="col-start-2 focus:outline-none justify-self-end"
        sx={styles}
        onClick={() => rewindAudio()}
        disabled={disabled}
        disableRipple={true}
      >
        <SvgIcon component={RewindIcon} viewBox="0 0 17 21" fontSize="small" />
      </IconButton>
      {isPlaying ? (
        <IconButton
          size="small"
          className="focus:outline-none"
          sx={styles}
          onClick={() => onPause()}
          disabled={disabled}
          disableRipple={true}
        >
          <SvgIcon component={PauseIcon} viewBox="0 0 34 34" fontSize="large" />
        </IconButton>
      ) : (
        <IconButton
          size="small"
          onClick={() => onPlay()}
          className="focus:outline-none"
          sx={styles}
          disabled={disabled}
          disableRipple={true}
        >
          <SvgIcon component={PlayIcon} viewBox="0 0 34 34" fontSize="large" />
        </IconButton>
      )}
    </div>
  );
};
