import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RejectionReasonsResponse } from '../../api/RejectionReasonsApi';
import { initialState } from './rejectionReason.state';

export const rejectionReasonSlice = createSlice({
  name: 'rejectionReason',
  initialState,
  reducers: {
    rejectReasonsSucceeded: (state, action: PayloadAction<RejectionReasonsResponse>) => {
      state.contentTypeRejectionReasons = action.payload.rejectionReasons;
    },
  },
});

export default rejectionReasonSlice.reducer;
