import { createSelector } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Loading from '../../../../common/components/loading/Loading';
import { ContentClass } from '../../../../common/enums/ContentClasses';
import { allowedAudioContentTypes, ContentType } from '../../../../common/enums/ContentTypes';
import { useDispatch } from '../../../../common/store/store';
import { AudioPreview } from '../../../components/audioPreview/AudioPreview';
import ContentDetailsTable from '../../../components/contentDetailsTable/ContentDetailsTable';
import CreatorDetailsTable from '../../../components/creatorDetailsTable/CreatorDetailsTable';
import BatchActionButtons from '../../../containers/batchActionButtons/BatchActionButtons';
import BatchContentList from '../../../containers/batchContentList/BatchContentList';
import MultiItemEdit from '../../../containers/multiItemEdit/MultiItemEdit';
import SingleItemEdit from '../../../containers/singleItemEdit/SingleItemEdit';
import { AudioPlayerContextProvider } from '../../../store/audio/AudioPlayer.context';
import { makeClaimBatchRequest, skipStockItem } from '../../../store/batch/batch.actions';
import {
  selectBatchClaimed,
  selectContributor,
  selectCurrentStockItem,
  selectIsBatchLoading,
  selectReviewBatchId,
  selectSelectedIds,
  selectVisibleIds,
  selectVisibleStockItems,
} from '../../../store/batch/batch.selectors';
import { makeGroupsRequest } from '../../../store/groups/groups.action';
import { makeRejectReasonsRequest } from '../../../store/rejectionReason/rejectionReason.actions';

export function AudioBatchReview(): JSX.Element {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(makeClaimBatchRequest(allowedAudioContentTypes));
    dispatch(makeRejectReasonsRequest());
    dispatch(makeGroupsRequest(ContentClass.audio));
  }, [dispatch]);

  const {
    batchClaimed,
    isBatchLoading,
    currentStockItem,
    contributor,
    isMultiEdit,
    reviewBatchId,
    visibleIds,
    visibleStockItems,
  } = useSelector(selector);

  const skipStockItemOnError = (stockItemId: string, notes = 'Failed to play audio file') => {
    if (!reviewBatchId) return;
    dispatch(skipStockItem(reviewBatchId, stockItemId, notes));
  };

  if (isBatchLoading || !batchClaimed || reviewBatchId === undefined) {
    return <Loading isBatchLoading={isBatchLoading} />;
  }

  return (
    <AudioPlayerContextProvider stockItemIds={visibleIds} reviewBatchId={reviewBatchId}>
      <div className="m-4 grid grid-cols-10 audioBatch" style={{ columnGap: '1.875rem' }}>
        <div className="col-start-1 col-span-4">
          <div className="grid-cols-4 overflow-scroll">
            <div className="row-span-2 bg-white">
              {contributor && <CreatorDetailsTable creator={contributor} contentClass={ContentClass.audio} />}
              <ContentDetailsTable stockItem={currentStockItem} />
              <div className="row-span-2 bg-white px-4">
                <AudioPreview stockItem={currentStockItem} skipStockItem={skipStockItemOnError} />
              </div>
            </div>
            <div className="py-8">
              <BatchActionButtons contentTypes={allowedAudioContentTypes} visibleStockItems={visibleStockItems} />
            </div>
            <div className="row-span-3 bg-white">
              <BatchContentList
                contentClass={ContentClass.audio}
                columnsPerRow={1}
                visibleStockItems={visibleStockItems}
              />
            </div>
          </div>
        </div>
        <div className="col-span-6 bg-white">
          {isMultiEdit ? (
            <MultiItemEdit contentClass={ContentClass.audio} />
          ) : (
            <SingleItemEdit contentClass={ContentClass.audio} allowedContentTypes={[ContentType.MUSIC]} />
          )}
        </div>
      </div>
    </AudioPlayerContextProvider>
  );
}

const selector = createSelector(
  [
    selectBatchClaimed,
    selectIsBatchLoading,
    selectCurrentStockItem,
    selectContributor,
    selectSelectedIds,
    selectReviewBatchId,
    selectVisibleIds,
    selectVisibleStockItems,
  ],
  (
    batchClaimed,
    isBatchLoading,
    currentStockItem,
    contributor,
    selectedIds,
    reviewBatchId,
    visibleIds,
    visibleStockItems
  ) => ({
    batchClaimed,
    isBatchLoading,
    currentStockItem,
    contributor,
    selectedIds,
    reviewBatchId,
    visibleIds,
    isMultiEdit: selectedIds.length > 1,
    visibleStockItems,
  })
);
