import { isEmpty, round, some, toLower } from 'lodash';

import { GroupType, StockItemType } from '../../review-app/store/batch/batch.types';
import { RejectionReason } from '../../review-app/store/rejectionReason/rejectionReason.types';
import { Options } from '../components/multiSelect/MultiSelect';
import { batchVideoContentTypes, ContentType } from '../enums/ContentTypes';

const MILLISECONDS_IN_A_SECOND = 1000;
const SECONDS_IN_A_MINUTE = 60;

export function getContentTypeToCategoryMapping(type: string): string {
  switch (type) {
    case ContentType.VR_360:
      return ContentType.FOOTAGE;
    case ContentType.LEGACY_MOTION_BACKGROUND:
    case 'motion-backgrounds':
      return ContentType.MOTION_BACKGROUND;
    case ContentType.PHOTO:
      return 'photograph';
    default:
      return type;
  }
}

export function validateKeywordList(keywords: string[]): string[] {
  const result: string[] = [];
  return keywords.reduce((validatedKeywords, keywordString) => {
    const words = keywordString.split(',');
    const trimmedWords = words
      .map((word) => word.trim())
      .filter((word) => {
        const existsInKeywords = some(validatedKeywords, (keyword) => toLower(word) === toLower(keyword));
        const meetsLengthReq = word.length >= 2 && word.length <= 50;
        return !isEmpty(word) && !existsInKeywords && meetsLengthReq;
      });
    return [...validatedKeywords, ...trimmedWords];
  }, result);
}

export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const sizeNumber = parseFloat((bytes / k ** i).toFixed(dm));

  return `${sizeNumber} ${sizes[i]}`;
}

export function filterAndSortRejectReasons(applicableReasons: RejectionReason[]): RejectionReason[] {
  return applicableReasons.slice().sort((a, b) => {
    // Sort alphabetically by reject reason, but have "select reason..."
    // always on top, then soft rejects, and "other" always last.
    if (a.value === '') return -999;
    if (b.value === '') return 999;
    if (a.text === 'Other') return 999;
    if (b.text === 'Other') return -999;
    if (a.softReject && !b.softReject) return -500;
    if (b.softReject && !a.softReject) return 500;
    if (a.text && b.text) {
      if (a.text < b.text) return -1;
      if (a.text > b.text) return 1;
    }

    return 0;
  });
}

export function getGroupsForType(type: string, availableCategories: GroupType[]): Options[] {
  const categoryType = getContentTypeToCategoryMapping(type);

  return availableCategories
    .filter((category) => category.searchType === categoryType)
    .map((category) => ({
      value: category.title,
      label: category.title,
    }));
}

export function getGroupsForContentType(type: ContentType, availableCategories: GroupType[]): GroupType[] {
  return availableCategories ? availableCategories.filter((group) => group.searchType === type) : [];
}

export function numberWithCommas(x?: number): string {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return '';
}

export function hasRequiredValue<T>(givenValues: T[] = [], requiredValues: T[] = []): boolean {
  return givenValues.some((value) => requiredValues.includes(value));
}

export function addOrRemoveItemFromArray<T>(item: T, array: T[]): T[] {
  const index = array.indexOf(item);
  if (index > -1) {
    array.splice(index, 1);
  } else {
    array.push(item);
  }
  return array;
}

export function checkRequiredFieldsByContentType(stockItem: StockItemType): boolean {
  if (batchVideoContentTypes.includes(stockItem.content.type)) {
    return stockItemHasStandardsOrValues(stockItem);
  }
  return true;
}

export function stockItemHasStandardsOrValues(stockItem: StockItemType): boolean {
  return !isEmpty(stockItem.meta?.visualStandards) || !isEmpty(stockItem.meta?.brandValues);
}

export function formatTime(milliseconds?: number): string {
  if (!milliseconds) milliseconds = 0;
  const seconds = Math.round(milliseconds / MILLISECONDS_IN_A_SECOND);
  const minutes = Math.floor(seconds / SECONDS_IN_A_MINUTE);
  const finalSeconds = seconds - minutes * SECONDS_IN_A_MINUTE;

  let finalSecondsString = finalSeconds.toString();
  let minutesString = minutes.toString();

  if (finalSeconds < 10) {
    finalSecondsString = `0${finalSeconds}`;
  }

  if (minutes < 10) {
    minutesString = `0${minutes}`;
  }

  return `${minutesString}:${finalSecondsString}`;
}

export const getFlagValue = (flagName: string): boolean | undefined => {
  const flags = window.SB_CONFIG?.features ?? {};
  return flags[flagName];
};

export const setNewObjectOrPushToExisting = <U>(
  obj: Record<string | number, U[]> = {},
  key: string | number,
  value: U
): Record<string | number, U[]> => {
  if (obj[key]) obj[key].push(value);
  else obj[key] = [value];
  return obj;
};

export function getTitleArrayForGroups(groups: GroupType[] | undefined): string[] {
  if (groups && groups.length) {
    return groups.map((group: GroupType) => group.title);
  }
  return [];
}

export function getGroupsFromTitles(titles: string[], allGroupTypes: GroupType[]): GroupType[] {
  return titles.reduce((selectedGroupTypes: GroupType[], title) => {
    const titleGroup = allGroupTypes.find((groupType) => groupType.title === title);
    if (titleGroup) selectedGroupTypes.push(titleGroup);
    return selectedGroupTypes;
  }, []);
}

export const getDivAndClassSelector = (className: string): string =>
  `& div[class^='${className}'], div[class*='${className}']`;

export const getSecondsFromMilliseconds = (milliseconds?: number): number =>
  round((milliseconds ?? 0) / MILLISECONDS_IN_A_SECOND);

export const getMillisecondsFromSeconds = (seconds?: number): number => (seconds ?? 0) * MILLISECONDS_IN_A_SECOND;

const textInputNodeNames = ['INPUT', 'TEXTAREA'];

export const isATextInput = (nodeName: string): boolean => {
  return textInputNodeNames.includes(nodeName);
};

const AMAZON_BUCKET_CLOUDFRONT_DOMAINS: { [key: string]: string } = {
  'mediary-unittest': 'd1pypjcsctnf1b.cloudfront.net',
  'mediary-unittest2': 'd1pypjcsctnf1b.cloudfront.net',
  'mediary-dev': 'dozx8b5tj6x6j.cloudfront.net',
  'mediary-dev2': 'd1q7vrw85qgl6j.cloudfront.net',
  'vb-wasabi-test': 'd1sx3h0fsy7vrw.cloudfront.net',
  'mediary-test2': 'dv7auk4chfeq0.cloudfront.net',
  'vb-wasabi': 'dh9qz98jqjqdu.cloudfront.net',
  'storyblocks-content': 'dm0qx8t0i9gc9.cloudfront.net',
  'videoblocks-content': 'd2v9y0dukr6mq2.cloudfront.net',
  'graphicstock-content': 'd1yn1kh78jj1rr.cloudfront.net',
  'audioblocks-content': 'd1490khl9dq1ow.cloudfront.net',
};

const s3BucketRegex = /https:\/\/([^/]+)\.s3\.amazonaws.com/i;

export function convertS3ToCloudFrontUri(uri: string): string {
  const bucketMatch = RegExp(s3BucketRegex).exec(uri);
  if (bucketMatch && bucketMatch[1]) {
    const bucket = bucketMatch[1];
    const cloudFrontDomain = AMAZON_BUCKET_CLOUDFRONT_DOMAINS[bucket];
    return cloudFrontDomain ? uri.replace(`${bucket}.s3.amazonaws.com`, cloudFrontDomain) : uri;
  } else {
    return uri;
  }
}
