import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';

import { UserResponse } from '../../api/AuthApi';
import { initialState, UserState } from './user.state';

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLoginSucceeded: (state: Draft<UserState>, action: PayloadAction<UserResponse>): void => {
      const { email, firstName, lastName, username, profileImage, roles, _id, allowedContentTypes } =
        action.payload.user;
      state.isLoggedIn = true;
      state.roles = roles;
      state.loading = false;
      state.loginErrorMessage = undefined;
      state.email = email;
      state.firstName = firstName;
      state.lastName = lastName;
      state.username = username;
      state.profileImage = profileImage;
      state._id = _id;
      state.permissions = allowedContentTypes;
    },
    userLoginFailed: (state: Draft<UserState>, action: PayloadAction<string>): void => {
      state.isLoggedIn = false;
      state.loading = false;
      state.loginErrorMessage = action.payload;
    },
    userLogoutSucceeded: (state: Draft<UserState>): void => {
      state.isLoggedIn = false;
      state.loading = false;
    },
    getUserSucceeded: (state: Draft<UserState>, action: PayloadAction<UserResponse>): void => {
      const { email, firstName, lastName, username, roles, profileImage, _id, allowedContentTypes } =
        action.payload.user;
      state.isLoggedIn = true;
      state.roles = roles;
      state.loading = false;
      state.email = email;
      state.firstName = firstName;
      state.lastName = lastName;
      state.username = username;
      state.profileImage = profileImage;
      state._id = _id;
      state.permissions = allowedContentTypes;
    },
    getUserFailed: (state: Draft<UserState>): void => {
      state.loading = false;
    },
  },
});

export default userSlice.reducer;
