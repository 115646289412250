import { FormControlLabel, FormControl, Checkbox as MaterialCheckbox } from '@mui/material';
import React from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

import { FormErrorProp } from './Form.types';

interface OwnProps<T> extends UseControllerProps<T> {
  id: string;
  label: string;
  required?: boolean;
}

function Checkbox<T>(props: OwnProps<T> & FormErrorProp): JSX.Element {
  const { name, control, id, label, required, error } = props;

  const {
    field: { onChange, onBlur, value },
  } = useController({ name, control });

  return (
    <div className="flex">
      <FormControl error={!!error}>
        <FormControlLabel
          control={
            <MaterialCheckbox
              id={id}
              indeterminate={value === undefined}
              checked={value as boolean}
              onChange={(event) => {
                onChange(event);
                onBlur();
              }}
              color="primary"
            />
          }
          label={required ? `${label}*` : label}
        />
      </FormControl>
    </div>
  );
}

export default Checkbox;
