import { Clear } from '@mui/icons-material';
import { Modal as MuiModal } from '@mui/material';
import React from 'react';

export type OwnProps = {
  trigger: React.ReactNode;
  ariaTitle: string;
  ariaDescription: string;
};

function Modal({
  children,
  trigger,
  ariaTitle,
  ariaDescription,
}: OwnProps & React.PropsWithChildren<unknown>): JSX.Element {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div onClick={handleOpen} className="cursor-pointer">
        {trigger}
      </div>
      <MuiModal open={open} onClose={handleClose} aria-labelledby={ariaTitle} aria-describedby={ariaDescription}>
        <div className="flex fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-50 h-screen w-screen">
          <div className="bg-white m-auto p-4 w-6/12 justify-center rounded-lg">
            <div className="float-right" aria-label="Close" onClick={handleClose}>
              <Clear />
            </div>
            {children}
          </div>
        </div>
      </MuiModal>
    </>
  );
}

export default Modal;
