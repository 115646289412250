import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';

import { AsyncThunkConfig } from '../../../common/store/store';
import CollectionApi, {
  CollectionSearchOptions,
  CollectionSortableField,
  SortDirection,
} from '../../api/CollectionApi';
import { CollectionSearchResult } from '../../api/CollectionApi.types';

export interface CollectionSearchState {
  isSearching: boolean;
  searchError?: SerializedError;
  searchOptions: CollectionSearchOptions;
  includeMultiAsset: boolean;
  searchResults?: CollectionSearchResult;
  pagination: CollectionSearchPaginationState;
}

export interface CollectionSearchPaginationState {
  pageNumber: number;
  resultsPerPage: number;
  offset: number;
}

const initialState: CollectionSearchState = {
  isSearching: false,
  includeMultiAsset: false,
  searchOptions: {
    sortBy: CollectionSortableField.updatedAt,
    sortDirection: SortDirection.DESC,
  },
  pagination: {
    pageNumber: 1,
    resultsPerPage: 10,
    offset: 0,
  },
};

const searchCollections = createAsyncThunk<CollectionSearchResult, void, AsyncThunkConfig>(
  'collectionSearch/searchCollections',
  async (payload, thunkAPI) => {
    const { searchOptions, pagination } = thunkAPI.getState().collectionTool.search;
    return CollectionApi.searchCollections(searchOptions, pagination.offset, pagination.resultsPerPage);
  }
);

const collectionSearchSlice = createSlice({
  name: 'collectionSearch',
  initialState,
  reducers: {
    changeSearchOptions: (state, action: PayloadAction<Partial<CollectionSearchOptions>>) => {
      state.searchOptions = { ...state.searchOptions, ...action.payload };
      state.pagination.pageNumber = 1;
      state.pagination.offset = 0;
    },
    changePageNumber: (state, action: PayloadAction<number>) => {
      const newPageNumber = action.payload;
      const { resultsPerPage, pageNumber } = state.pagination;

      if (pageNumber !== newPageNumber) {
        state.pagination.pageNumber = newPageNumber;
        state.pagination.offset = (newPageNumber - 1) * resultsPerPage;
      }
    },
    changeIncludeMultiAsset: (state, action: PayloadAction<boolean>) => {
      const includeMultiAsset = action.payload;
      state.includeMultiAsset = includeMultiAsset;
      if (includeMultiAsset) {
        state.searchOptions.includeClasses = state.searchOptions.classes;
        state.searchOptions.classes = undefined;
      } else {
        state.searchOptions.classes = state.searchOptions.includeClasses;
        state.searchOptions.includeClasses = undefined;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchCollections.fulfilled, (state, action) => {
        state.searchResults = action.payload;
        state.isSearching = false;
      })
      .addCase(searchCollections.pending, (state) => {
        state.isSearching = true;
        state.searchError = undefined;
      })
      .addCase(searchCollections.rejected, (state, action) => {
        state.isSearching = false;
        state.searchError = action.error;
      });
  },
});

const { actions, reducer } = collectionSearchSlice;
const { changePageNumber, changeSearchOptions, changeIncludeMultiAsset } = actions;

export { changePageNumber, changeSearchOptions, changeIncludeMultiAsset, initialState, reducer, searchCollections };
