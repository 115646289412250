import { createSelector, EntityState } from '@reduxjs/toolkit';

import { RootState } from '../../../common/store/store';
import { Content } from '../../api/CollectionApi.types';
import { selectSuggestionList } from '../SuggestionList/SuggestionList.selectors';
import { simpleSelectors } from './ContentList.reducer';

export const selectIsContentListLoading = (state: RootState): boolean =>
  state.collectionTool.contentList.isContentListLoading;

export const selectContentById = createSelector(
  [selectSuggestionList, (state, contentId: number) => contentId],
  (suggestionList, contentId) => simpleSelectors.selectById(suggestionList, contentId)
);

export const selectContentList = (state: RootState): EntityState<Content> => {
  const { ids, entities } = state.collectionTool.contentList;
  return { ids, entities };
};
