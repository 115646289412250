import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetBrandValuesResponse } from '../../api/BrandValuesApi';
import { initialState } from './brandValues.state';

export const brandValuesSlice = createSlice({
  name: 'brandValues',
  initialState,
  reducers: {
    brandValuesSucceeded: (state, action: PayloadAction<GetBrandValuesResponse>) => {
      state.contentBrandValues = action.payload.brandValues;
    },
  },
});

export default brandValuesSlice.reducer;
